import { useNavigate } from 'react-router-dom';

import VehicleCreateFormContainer from 'legacy/prebilling/vehicles/VehicleCreateFormContainer';
import PageListWrapper from 'legacy/shared/styles/custom/PageList';

const VehicleCreatePage = ({ userState }) => {
  const navigate = useNavigate();
  const navigateBackToVehicleList = () => {
    navigate('/vehicles');
  };

  return (
    <PageListWrapper>
      <VehicleCreateFormContainer
        handleRedirect={navigateBackToVehicleList}
        userState={userState}
      />
    </PageListWrapper>
  );
};

export default VehicleCreatePage;
