import AnimatedField from 'legacy/shared/controls/AnimatedField/v1/AnimatedField';

const MaintenanceTypeField = ({ maintenanceTypeLabel }) => {
  return (
    <AnimatedField
      placeholder="Maintenance Type"
      name="maintenanceType"
      value={maintenanceTypeLabel}
      disabled={true}
    />
  );
};

export default MaintenanceTypeField;
