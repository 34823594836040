import useAlertFormsProviderContext from 'legacy/features/alerts/services/providers/AlertFormsProvider/hooks/useAlertFormsProviderContext';
import React from 'react';
import { BlueButton } from 'legacy/shared/styles/custom/Button';
import IconSvgComponent from 'legacy/shared/controls/WcpIcon/IconSvgComponent';

const AddAlertRecipientRowButton = ({ title, alertNotificationGuid }) => {
  const {
    actions: { addAlertRecipientAction },
  } = useAlertFormsProviderContext();
  return (
    <BlueButton
      type="button"
      addRecipient
      onClick={() =>
        addAlertRecipientAction({
          alertNotificationGuid,
        })
      }
    >
      <IconSvgComponent svgFileName="plus-white" alt="Add Another Recpient" />
      {title}
    </BlueButton>
  );
};

export default AddAlertRecipientRowButton;
