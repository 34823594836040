import useDevicesAndBillingSummaryProviderContext from 'legacy/features/billing/services/providers/hooks/useDevicesAndBillingSummaryProviderContext';
import {
  AlertCount,
  AlertIcon,
  AlertItem,
  AlertText,
  AlertTextWrapper,
  NeedAttentionText,
} from 'legacy/shared/styles/custom/AlertBox';
import { RemoveButton } from 'legacy/shared/styles/custom/Button';
import {
  ClearButtonWrapper,
  SubscriptionAlertWrapper,
} from 'legacy/shared/styles/custom/DevicesAndBilling';
import IconSvgComponent from 'legacy/shared/controls/WcpIcon/IconSvgComponent';

const SubscriptionAlertBox = ({ toggleFilter }) => {
  const {
    state: { deviceSubscriptions, showNeedsAttentionDevicesFilter, subscriptionExpirationSummary },
  } = useDevicesAndBillingSummaryProviderContext();

  const { aboutToExpire, haveExpired } = subscriptionExpirationSummary;

  let showHighAlert = haveExpired.length > 0;
  let totalNeedsAttentionCount = aboutToExpire.length + haveExpired.length;

  return (
    <>
      <SubscriptionAlertWrapper
        id="subscriptionNeedsAttentionControl"
        onClick={() => toggleFilter(true)}
        highAlert={showHighAlert}
        title="Click to filter to these devices only"
      >
        <AlertIcon highAlert={showHighAlert} />
        <div>
          <NeedAttentionText highAlert={showHighAlert}>
            {totalNeedsAttentionCount} Devices need attention
          </NeedAttentionText>
          <AlertTextWrapper>
            <AlertText>
              <AlertItem highAlert={showHighAlert}>
                <AlertCount>{aboutToExpire.length}</AlertCount> Are about to expire
              </AlertItem>
              <AlertItem highAlert={showHighAlert}>
                <AlertCount>{haveExpired.length}</AlertCount> Have expired
              </AlertItem>
            </AlertText>
          </AlertTextWrapper>
        </div>
        {showNeedsAttentionDevicesFilter && (
          <ClearButtonWrapper>
            <RemoveButton
              type="button"
              nonPrimary
              title="Clear Needs Attention Filter"
              id="clearNeedsAttentionFilterButton"
              onClick={(e) => {
                //necessary to stop click from being swallowed by parent
                e.stopPropagation();
                toggleFilter(false);
              }}
            >
              <IconSvgComponent svgFileName={'remove-x-black'} alt="Clear Needs Attention Filter" />
            </RemoveButton>
          </ClearButtonWrapper>
        )}
      </SubscriptionAlertWrapper>
    </>
  );
};

export default SubscriptionAlertBox;
