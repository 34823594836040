import { configurationsOtaLogsFetchError } from 'legacy/features/configurations/helpers/notifications';
import { useGenericFetchQuery } from 'legacy/shared/utilities/hooks/useGenericFetchQuery';

import { getConfigurationsOtaLogs } from 'legacy/core/api/configuration';

export const useFetchConfigurationsOtaLogsQuery = (initialQueryParams) => {
  const { fetch, isLoading, data } = useGenericFetchQuery({
    apiFn: getConfigurationsOtaLogs,
    hookName: useFetchConfigurationsOtaLogsQuery.name,
    initialQueryParams,
    errorCallback: configurationsOtaLogsFetchError,
  });

  return {
    fetch,
    isLoading,
    data,
  };
};
