import styled from 'styled-components';

export const StyledAvatarImage = styled.div`
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  width: 120px;
  height: 120px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 20px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
  background-image: url(${(props) => props?.image});
`;

export const StyledAvatarControlContainer = styled.div`
  width: 300px;
  height: 300px;
  margin: 0px auto 30px auto;
`;
