export const phoneRegexError = 'Please enter a phone number in the format +1 (234) 567-8901';
export const phoneExtensionRegexError = 'Please enter an extension containing only numbers';
export const emailRegexError = 'Please enter an email address in the format address@domain.com';
export const usernameRegexError =
  'Please enter an alphanumeric username without capital letters that is less than 128 characters, and that does not start with a number';
export const dateRegexError = (dateFieldLabel) =>
  `Please enter a ${dateFieldLabel} in the format MM/DD/YYYY`;
export const passwordRegexError =
  'Enter a password with at least 8 characters, uppercase, lowercase, and numeric characters';
export const passwordMatchError = 'Please enter matching passwords';
export const deviceIdRegexError = 'Please enter a valid 32 character VSG ID';
export const vinRegexError =
  'Please enter a valid VIN (note that O,I, and Q are not valid characters)';
export const alphanumericExtendedRegexError = 'Please enter only alphanumeric characters';
export const asciiRegexError = 'Please enter only ASCII characters';
export const numericRegexError = 'Please enter numbers only';
export const wimAccountIdRegexError = 'Please enter a valid six digit account ID';
export const appliedInformationRegexError = 'Please enter an eight digit numeric value';
export const positiveIntegerRegexError = 'Please enter a positive whole number';
export const requiredError = 'This field is required';
