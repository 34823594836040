import { SimpleDropdownBtn, SimpleDropdownItem } from 'legacy/shared/styles/custom/DropdownMenu';

const DeleteMaintenanceEventMenuItem = ({ onMenuItemClick }) => {
  return (
    <SimpleDropdownItem>
      <SimpleDropdownBtn onClick={onMenuItemClick}>Delete Maintenance Event</SimpleDropdownBtn>
    </SimpleDropdownItem>
  );
};

export default DeleteMaintenanceEventMenuItem;
