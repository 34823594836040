import WcpTabList from 'legacy/shared/controls/WcpTabView/v2/WcpTabList';
import React from 'react';

const WcpTabView = ({ tabs, styleProp }) => {
  const configurationsTableNav = styleProp === 'configurationsTableNav';
  const vehicleDeviceTable = styleProp === 'vehicleDeviceTable';
  return (
    <WcpTabList
      configurationsTableNav={configurationsTableNav}
      vehicleDeviceTable={vehicleDeviceTable}
      tabs={tabs}
    />
  );
};

export default WcpTabView;
