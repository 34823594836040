import React, { useRef, useState, useCallback } from 'react';
import { createPortal } from 'react-dom';

export default ({ children, onRequestClose }) => {
  const modalPortal = useRef();
  const handleRequestClose = useCallback((e) => {
    onRequestClose();
  });

  return createPortal(
    <div ref={modalPortal} style={styles.portal}>
      {[
        React.cloneElement(children, {
          handleRequestClose,
        }),
        <div style={styles.overlay} onClick={handleRequestClose} />,
      ]}
    </div>,
    document.body,
  );
};

const styles = {
  portal: {
    display: 'flex',
    position: 'fixed',
    top: '0',
    left: '0',
    width: '100%',
    height: '100%',
    zIndex: 99999,
    justifyContent: 'center',
    alignItems: 'center',
    overflowY: 'auto',
    overflowX: 'hidden',
  },
  overlay: {
    position: 'fixed',
    top: '0',
    left: '0',
    width: '100%',
    height: '100%',
    opacity: 0.2,
    backgroundColor: '#211f50',
    overflow: 'auto',
  },
};
