import { maintenanceSummaryTableColumns } from 'legacy/features/maintenance/components/MaintenanceSummary/tables/MaintenanceSummaryTableColumns';
import WcpTable from 'legacy/shared/controls/WcpTable/v2/WcpTable';

const MaintenanceSummaryTable = ({ maintenanceEvents, handleUpdateListingCount }) => {
  return (
    maintenanceEvents && (
      <WcpTable
        columns={maintenanceSummaryTableColumns}
        tableSourceData={maintenanceEvents}
        emptyText="No maintenance events are currently scheduled for this organization"
        setListingCount={handleUpdateListingCount}
      />
    )
  );
};

export default MaintenanceSummaryTable;
