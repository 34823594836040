import useVehiclePlaybackProviderContext from 'legacy/features/playback/state/provider/hooks/useVehiclePlaybackProviderContext';
import useMakeFormikField from 'legacy/shared/controls/WcpForm/v1/hooks/useMakeFormikField';
import { useFormikContext } from 'formik';
import {
  DateRangeValidationDiv,
  FormAsterisk,
  FormLabelAnimated,
} from 'legacy/shared/styles/custom/Form';
import {
  PlaybackDateDiv,
  MatchDateButtonWrapperDiv,
} from 'legacy/shared/styles/custom/VehiclePlayback';
import StyledDayPickerInput from 'legacy/shared/controls/WcpDayPicker/StyledDayPickerInput';
import {
  formatISOTimestampToShortDate,
  getLongDateInIsoFormat,
  getShortDateFromDatePicker,
} from 'legacy/shared/utilities/misc/time';
import { BlueButton } from 'legacy/shared/styles/custom/Button';

const VehiclePlaybackFormStartDateField = () => {
  const {
    actions: { setPlaybackRequestFormAction },
    state: {
      playbackRequestForm: { readOnly, startDate, endDate },
    },
  } = useVehiclePlaybackProviderContext();

  const { validationResult } = useMakeFormikField({
    fieldName: 'startDateField',
    fieldValue: startDate,
    triggerValidation: true,
  });

  return (
    <>
      <PlaybackDateDiv>
        <FormLabelAnimated animated>
          Start Date (MM/DD/YYYY)<FormAsterisk>*</FormAsterisk>
        </FormLabelAnimated>

        <StyledDayPickerInput
          placeholder={formatISOTimestampToShortDate(startDate)}
          name="startDateField"
          value={formatISOTimestampToShortDate(startDate)}
          onDayChange={(date) => {
            if (date) {
              setPlaybackRequestFormAction({ startDate: getLongDateInIsoFormat(date) });
            }
          }}
          disabled={readOnly}
        />
        <DateRangeValidationDiv>{validationResult.errorMessage}</DateRangeValidationDiv>
      </PlaybackDateDiv>
      {startDate !== endDate && !readOnly && (
        <MatchDateButtonWrapperDiv title="Sets the end date to the same date as the start date">
          <BlueButton
            matchButton
            type="button"
            onClick={() => {
              setPlaybackRequestFormAction({ endDate: startDate });
            }}
          >
            MATCH
          </BlueButton>
        </MatchDateButtonWrapperDiv>
      )}
    </>
  );
};

export default VehiclePlaybackFormStartDateField;
