const { apiCall } = require('legacy/core/api/API');

export const deleteVsgFromSubscriptionApi = async ({ organizationId, vsgDuid }) => {
  const apiResponse = await apiCall({
    method: 'del',
    path: `/subscriptions/devices/${vsgDuid}`,
    queryStringParameters: {
      organization_id: `${organizationId}`,
    },
  });
  return apiResponse.data.message;
};
