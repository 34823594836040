import React, { useState } from 'react';
import OrganizationUserActionMenu from 'legacy/features/organizations/components/menus/OrganizationUserTableActionMenu';
import {
  CardLabel,
  CardValue,
  CardRow,
  CardName,
  CardType,
  ShowCollapseButton,
  CardContainer,
  ButtonWrapper,
  CardOuterWrapper,
  CardIconWrapper,
  CardCollapsedInfo,
  ActionMenuBtnWrapper,
  CardExpandedInfo,
} from 'legacy/shared/styles/custom/MobileCard';
import { TableAvatar } from 'legacy/shared/styles/custom/TableAvatar';
import { useSelector } from 'react-redux';

const OrganizationUserListingCard = ({ user, organization }) => {
  const [expanded, setExpanded] = useState(false);

  const handleToggleShowCollapse = () => {
    setExpanded(!expanded);
  };

  return (
    <CardOuterWrapper>
      <CardContainer userExpanded={expanded}>
        <ActionMenuBtnWrapper>
          <OrganizationUserActionMenu user={user} organization={organization} />
        </ActionMenuBtnWrapper>
        <CardIconWrapper>
          <TableAvatar profilePicture={user.avatar} userCard />
          <CardCollapsedInfo>
            <CardRow header userCardHeader>
              <CardName title={user.username}>{user.username}</CardName>
            </CardRow>
            <CardRow header userCardHeader title={user.userTitle}>
              <CardType>{user.userTitle}</CardType>
            </CardRow>
          </CardCollapsedInfo>
        </CardIconWrapper>
        <CardExpandedInfo expanded={expanded}>
          <CardRow potentialLongValue title={user.firstName}>
            <CardLabel>First Name:</CardLabel>
            <CardValue>{user.firstName}</CardValue>
          </CardRow>
          <CardRow potentialLongValue title={user.lastName}>
            <CardLabel>Last Name:</CardLabel>
            <CardValue>{user.lastName}</CardValue>
          </CardRow>
          <CardRow potentialLongValue title={user.email}>
            <CardLabel>Email:</CardLabel>
            <CardValue>{user.email}</CardValue>
          </CardRow>
          <CardRow>
            <CardLabel>Role:</CardLabel>
            <CardValue>{user.userRole}</CardValue>
          </CardRow>
          <CardRow>
            <CardLabel>Status:</CardLabel>
            <CardValue>{user.isActive === true ? 'Enabled' : 'Disabled'}</CardValue>
          </CardRow>
        </CardExpandedInfo>
      </CardContainer>
      <ButtonWrapper>
        <ShowCollapseButton onClick={(e) => handleToggleShowCollapse(e)}>
          {expanded ? 'COLLAPSE' : 'SEE DETAILS'}
        </ShowCollapseButton>
      </ButtonWrapper>
    </CardOuterWrapper>
  );
};

export default OrganizationUserListingCard;
