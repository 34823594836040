import NewFilteredDropdownPopup from 'legacy/shared/controls/WcpFilteredDropdown/v3/NewFilteredDropdownPopup';
import NewFilteredDropdownSelector from 'legacy/shared/controls/WcpFilteredDropdown/v3/NewFilteredDropdownSelector';
import newUseFilteredDropdown from 'legacy/shared/controls/WcpFilteredDropdown/v3/newUseFilteredDropdown';
import useClickOutsideContainerHandler from 'legacy/shared/utilities/hooks/useClickOutsideContainerHandler';
import { FilteredDropdownWrapper } from 'legacy/shared/controls/WcpFilteredDropdown/v3/styles/FilteredDropdownStyles';
import { useEffect } from 'react';
import { FormAsterisk, FormLabelAnimated } from 'legacy/shared/styles/custom/Form';

// LEGACY V2 FD
const NewFilteredDropdown = ({
  styleOptions: {
    disabled = false,
    hideClearX = false,
    restrictHeight = true,
    required = false,
    narrowWidth = false,
  } = {},
  title,
  placeholderLabel = 'Make a selection',
  setup,
  defaultOption,
  defaultItem,
  customSort,
  dropdownOptions,
  handleItemSelect: handleSelect,
  clearOnSelection,
  dataCyId,
}) => {
  // deprecated prop
  if (defaultItem) {
    defaultOption = defaultItem;
  }
  if (setup) {
    if (setup.dropdownOptions === undefined) {
      throw new Error(
        `FilteredDropdown: setup.dropdownOptions must be provided. Received ${setup.dropdownOptions}`,
      );
    }

    if (setup.initialValue === undefined) {
      throw new Error(
        `FilteredDropdown: setup.initialValue must be provided. Received ${setup.initialValue}`,
      );
    }

    if (setup.onSelect === undefined) {
      throw new Error(
        `FilteredDropdown: setup.callback must be provided. Received ${setup.onSelect}`,
      );
    }

    if (setup.filterOptions) {
      dropdownOptions = setup.dropdownOptions
        .getAllOptions(setup.customSort)
        .filter(setup.filterOptions);
    } else {
      dropdownOptions = setup.dropdownOptions.getAllOptions(setup.customSort);
    }
    defaultOption = setup.initialValue
      ? setup.dropdownOptions.getOptionByValue(setup.initialValue)
      : null;

    handleSelect = setup.onSelect;
  } else {
    if (dropdownOptions === undefined) {
      throw new Error('FilteredDropdown: dropdownOptions must be provided');
    }

    // defaultOption must be an object with a label and value property or null
    if (defaultOption === undefined) {
      throw new Error(
        `FilteredDropdown: defaultOption must be null or an object. Received "${defaultOption}"`,
      );
    }

    if (handleSelect === undefined) {
      throw new Error(
        `FilteredDropdown: handleItemSelect must be provided. Received "${handleSelect}"`,
      );
    }
  }

  // dropdownOptions changed
  useEffect(() => {
    console.log('dropdownOptions changed');

    if (setup.filterOptions) {
      dropdownOptions = setup.dropdownOptions
        .getAllOptions(setup.customSort)
        .filter(setup.filterOptions);
    } else {
      dropdownOptions = setup.dropdownOptions.getAllOptions(setup.customSort);
    }
  }, [setup.dropdownOptions]);

  useEffect(() => {
    setSelectedOption(defaultOption);
  }, [setup.initialValue]);

  const containerRef = useClickOutsideContainerHandler({ handler: () => setIsOpen(false) });

  const {
    selectedOption,
    setSelectedOption,
    options,
    isOpen,
    setIsOpen,
    searchText,
    setSearchText,
    hoveredItem,
    setHoveredItem,
    openStyle,
  } = newUseFilteredDropdown({
    defaultOption,
    dropdownOptions,
    handleItemSelect: (item) => {
      handleSelect(item);
      setIsOpen(false);
    },
    clearOnSelection,
  });

  return (
    <FilteredDropdownWrapper disabled={disabled} data-cy={dataCyId}>
      <div ref={containerRef} style={openStyle}>
        <FormLabelAnimated animated>
          {title}
          {required && <FormAsterisk>*</FormAsterisk>}
        </FormLabelAnimated>
        <div>
          <NewFilteredDropdownSelector
            narrowWidth={narrowWidth}
            hideClearX={hideClearX}
            placeholderLabel={placeholderLabel}
            // parent state
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            selectedOption={clearOnSelection ? '' : selectedOption}
            setSelectedOption={setSelectedOption}
            onClearFilter={() => {
              handleSelect({ value: null });
              setSelectedOption(null);
            }}
          />
          {isOpen && (
            <NewFilteredDropdownPopup
              restrictHeight={restrictHeight}
              searchText={searchText}
              setSearchText={setSearchText}
              options={options}
              selectedOption={selectedOption}
              setSelectedOption={setSelectedOption}
              hoveredItem={hoveredItem}
              setHoveredItem={setHoveredItem}
              optionsDataCyId={`${dataCyId}Options`}
            />
          )}
        </div>
      </div>
    </FilteredDropdownWrapper>
  );
};

export default NewFilteredDropdown;
