import { IntegrationTextFieldWrapper, OptInFeatureWrapper } from 'legacy/shared/styles/custom/Form';
import { useCheckUserRole, userRoleKeys } from 'legacy/shared/utilities/hooks/useCheckUserRole';
import { WcpTextInputField } from 'legacy/shared/controls/WcpForm/v2/components/fields/WcpTextInputField';
import WcpCheckbox from 'legacy/shared/controls/WcpCheckbox/v1/WcpCheckbox';
import useWcpFormContext from 'legacy/shared/controls/WcpForm/v2/hooks/useWcpFormContext';
import WcpCheckboxField from 'legacy/shared/controls/WcpForm/v2/components/fields/WcpCheckboxField';

const AppliedInformationIntegrationCheckboxField = () => {
  const isUserRoleAuthorized = useCheckUserRole(userRoleKeys.FIELD_SOLUTION_ENGINEER);

  const {
    formValues: { appliedInformationEnabled },
  } = useWcpFormContext();

  return (
    <OptInFeatureWrapper>
      <WcpCheckboxField
        dataKey={'appliedInformationEnabled'}
        label="Enable Applied Information Integration"
        disabled={!isUserRoleAuthorized}
      />

      <IntegrationTextFieldWrapper show={appliedInformationEnabled && isUserRoleAuthorized}>
        <WcpTextInputField
          dataKey="appliedInformationBusinessId"
          label="Applied Information Business ID"
          disabled={!isUserRoleAuthorized}
          required={true}
        />
      </IntegrationTextFieldWrapper>
    </OptInFeatureWrapper>
  );
};

export default AppliedInformationIntegrationCheckboxField;
