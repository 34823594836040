import FilteredDropdown from 'legacy/shared/controls/WcpFilteredDropdown/v2/FilteredDropdown';
import React from 'react';
import { RecipientWrapper } from 'legacy/shared/styles/custom/RecipientGroups';

const mapUserToDropdownOption = (user) =>
  user
    ? {
        label: `${user.firstName} ${user.lastName}`,
        value: user.username,
      }
    : null;

const RecipientSelectDropdown = ({ index, selectedUser, availableUsers, handleUserSelect }) => {
  const dropdownOptions = availableUsers.map(mapUserToDropdownOption);

  const defaultItem = mapUserToDropdownOption(selectedUser);

  return (
    <RecipientWrapper>
      <FilteredDropdown
        styleOptions={{
          hideClearX: true,
          required: true,
        }}
        title="Recipient"
        placeholderLabel="Choose a recipient"
        defaultItem={defaultItem}
        dropdownOptions={dropdownOptions}
        handleItemSelect={(selectedItem) => {
          const selectedUser = availableUsers?.find((u) => u.username === selectedItem?.value);
          handleUserSelect(selectedUser);
        }}
      />
    </RecipientWrapper>
  );
};

export default RecipientSelectDropdown;
