import OrganizationDetailContainer from 'legacy/features/organizations/containers/OrganizationDetailContainer';
import OrganizationDetailModalProvider from 'legacy/features/organizations/providers/modal/OrganizationDetailModalProvider/OrganizationDetailModalProvider';
import PageListWrapper from 'legacy/shared/styles/custom/PageList';

const OrganizationDetailPage = () => {
  return (
    <OrganizationDetailModalProvider>
      <PageListWrapper>
        <OrganizationDetailContainer />
      </PageListWrapper>
    </OrganizationDetailModalProvider>
  );
};

export default OrganizationDetailPage;
