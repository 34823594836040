import { handleActions, combineActions } from 'redux-actions';

import {
  beginLoadOrganizationData,
  endLoadOrganizationData,
  submitUserRegistration,
  completeUserRegistration,
  completeLogin,
  rejectLogin,
  requestLoggedInStatus,
  receiveLoggedInStatus,
  rejectLoggedInStatus,
  successForgotResetPassword,
  successFirstSigninResetPassword,
  receiveUser,
  requestLogout,
  successLogout,
  timedOut,
  resetEmail,
  setOrgId,
  requestLogin,
  requestGetPasswordResetCode,
  successGetPasswordResetCode,
  rejectGetPasswordResetCode,
  requestForgottenUsername,
  rejectResetForgottenUsername,
  // two factor
  requestSetSelectedMfaMethod,
  successSetSelectedMfa,
  receiveVerifyTwoFactorMethodAttempt,
  successVerifyTwoFactorMethod,
  successVerifyMfaAuthCode,
  requestGetForgottenUsername,
  successGetForgottenUsername,
  rejectGetForgottenUsername,
  requestSelectMfaMethodRedirect,
} from './actions';

import { getFilteredOrgIdInLocal } from 'legacy/shared/utilities/misc/localStore';
import { successAssociateFSEData } from 'legacy/core/redux/organizations/actions';

const defaultState = {
  loggedIn: false,
  loggingIn: false,
  requestingLogOut: false,
  loadingOrgData: false,
  requestingForgottenCredentials: false,
  timedOut: false,
  resetEmail: false,
  checkingSession: false,
  registering: false,
  user: {},
  needsMfaSetup: false,
  selectedMfaMethod: false,
  redirectToVerifyMfa: false,
  redirectToSelectMfa: false,
  mfaType: null,
  mfaDestination: null,
  authenticationAppSecretCode: null,
  session: '',
  username: '',
  filteredOrganizationId: getFilteredOrgIdInLocal(),
};

const user = handleActions(
  {
    [beginLoadOrganizationData]: (state) => ({
      ...state,
      //loadingOrgData: true,
    }),
    [endLoadOrganizationData]: (state) => ({
      ...state,
      //loadingOrgData: false,
    }),
    [submitUserRegistration]: (state) => ({
      ...state,
      registering: true,
    }),
    [completeUserRegistration]: (state, action) => ({
      ...state,
      registering: false,
      user: action.payload,
    }),
    [requestLogin]: (state, action) => ({
      ...state,
      loggingIn: true,
    }),
    [combineActions(requestGetPasswordResetCode, requestGetForgottenUsername)]: (
      state,
      action,
    ) => ({
      ...state,
      requestingForgottenCredentials: true,
    }),
    [combineActions(
      successGetPasswordResetCode,
      rejectGetPasswordResetCode,
      successGetForgottenUsername,
      rejectGetForgottenUsername,
    )]: (state, action) => ({
      ...state,
      requestingForgottenCredentials: false,
    }),
    [receiveUser]: (state, { payload: { user } }) => ({
      ...state,
      user: user.username === state.user.username ? user : state.user,
    }),
    [completeLogin]: (state, { payload: { user } }) => ({
      ...state,
      user,
      filteredOrganizationId: user.organizationId,
      loggingIn: false,
      loggedIn: true,
      checkingSession: false,
    }),
    [rejectLogin]: (state) => ({
      ...state,
      user: defaultState.user,
      loggingIn: false,
      loggedIn: false,
    }),
    [timedOut]: (state) => ({
      ...state,
      timedOut: true,
    }),
    [resetEmail]: (state) => ({
      ...state,
      resetEmail: true,
    }),

    [requestLoggedInStatus]: (state) => ({ ...state, loadingOrgData: true, checkingSession: true }),
    [receiveLoggedInStatus]: (state, action) => ({
      ...state,
      loadingOrgData: false,
    }),
    [rejectLoggedInStatus]: (state) => ({
      ...state,
      checkingSession: false,
      loggedIn: false,
      loggingIn: false,
    }),
    [requestLogout]: (state) => ({
      ...state,
      requestingLogOut: true,
      redirectToSelectMfa: false,
      redirectToVerifyMfa: false,
    }),
    [successLogout]: (state) => ({
      ...state,
      loggedIn: false,
      requestingLogOut: false,
    }),
    [setOrgId]: (state, { payload }) => {
      return {
        ...state,
        filteredOrganizationId: payload.organizationId,
      };
    },

    [successFirstSigninResetPassword]: (state) => ({
      ...state,
    }),

    // set 2fa
    [requestSelectMfaMethodRedirect]: (state, { payload: { needsMfaSetup } }) => ({
      ...state,
      redirectToSelectMfa: true,
      needsMfaSetup,
    }),
    [requestSetSelectedMfaMethod]: (state) => ({
      ...state,
      redirectToVerifyMfa: false,
      selectedMfaMethod: true,
    }),
    [successSetSelectedMfa]: (
      state,
      { payload: { mfaType, authenticationAppSecretCode, smsVerificationCode } },
    ) => ({
      ...state,
      mfaType,
      authenticationAppSecretCode,
      smsVerificationCode,
      selectedMfaMethod: false,
      redirectToVerifyMfa: true,
      needsMfaSetup: false,
    }),

    // verify 2fa
    [receiveVerifyTwoFactorMethodAttempt]: (
      state,
      { payload: { username, session, mfaType, mfaDestination } },
    ) => ({
      ...state,
      redirectToVerifyMfa: true,
      session,
      username,
      mfaType,
      mfaDestination,
    }),

    [successVerifyTwoFactorMethod]: (state) => ({
      ...state,
      redirectToVerifyMfa: false,
    }),

    [successVerifyMfaAuthCode]: (state) => ({
      ...state,
      authenticationAppSecretCode: null,
      smsVerificationCode: null,
      redirectToVerifyMfa: false,
      user: { ...state.user, twoFactorAuthMethod: state.mfaType },
    }),
    [successAssociateFSEData]: (state, { payload }) => {
      return {
        ...state,
        orgPermissions: payload,
      };
    },
  },
  defaultState,
);
export default user;
