import { useQuery } from '@tanstack/react-query';

import { apiCall } from 'legacy/core/api/API';
import { userMapping } from 'legacy/features/users/utilities/userMapping';
import { userRoleOptions } from 'legacy/shared/constants/users';
import { mapToFrontend } from 'legacy/shared/utilities/helpers/mapping/mapper';

const useFetchFseUsersQuery = () => {
  return useQuery({
    queryKey: ['fetchFseUsers'],
    queryFn: async () =>
      await apiCall({
        endpoint: 'cloudfrontAPI',
        path: '/user',
        method: 'get',
        queryStringParameters: {
          user_role: `${userRoleOptions.FIELD_SOLUTION_ENGINEER.value}`,
        },
      }),
    select: (data) => data.body.message.map((user) => mapToFrontend(user, userMapping)),
  });
};

export default useFetchFseUsersQuery;
