import {
  ActionMenuBtnWrapper,
  CardContainer,
  CardIconWrapper,
  CardLabel,
  CardName,
  CardOuterWrapper,
  CardRow,
  CardValue,
} from 'legacy/shared/styles/custom/MobileCard';

const AlertSummaryCard = ({ alert, actionMenuComponent }) => {
  let {
    alertDetails: {
      alertInfo: { alertName, isActive, testModeEnabled },
      decorated: { targetVehicleOrGroupName, summaryTable },
    },
  } = alert;

  let { recipientColumn, conditionColumn } = summaryTable;

  const alertTitle = `${summaryTable.alertName} ${testModeEnabled ? ' (TEST MODE) ' : ''}  ${
    isActive ? '' : '(Deactivated)'
  }`;
  const alertRecipients = `${alert?.alertNotifications?.length > 1 ? '(Multi-level)' : ''} ${
    summaryTable.recipientColumn
  } `;
  return (
    <CardOuterWrapper>
      <CardContainer alertSummary>
        <ActionMenuBtnWrapper>{actionMenuComponent(alert)}</ActionMenuBtnWrapper>
        <CardIconWrapper>
          <CardRow header>
            <CardName
              deactivated={!isActive || testModeEnabled}
              potentialLongValue
              title={alertTitle}
            >
              {alertTitle}
            </CardName>
          </CardRow>
        </CardIconWrapper>
        <CardRow potentialLongValue>
          <CardLabel>Condition:</CardLabel>
          <CardValue title={conditionColumn}>{conditionColumn}</CardValue>
        </CardRow>
        <CardRow potentialLongValue>
          <CardLabel>Value:</CardLabel>
          <CardValue>{summaryTable.valueColumn} </CardValue>
        </CardRow>
        <CardRow potentialLongValue>
          <CardLabel>Vehicle/Group:</CardLabel>
          <CardValue>{targetVehicleOrGroupName}</CardValue>
        </CardRow>
        <CardRow potentialLongValue>
          <CardLabel>Recipient:</CardLabel>
          <CardValue title={alertRecipients}>{alertRecipients}</CardValue>
        </CardRow>
        <CardRow potentialLongValue>
          <CardLabel>Method:</CardLabel>
          <CardValue title={`Method: ${summaryTable.methodColumn}`}>
            {summaryTable.methodColumn}
          </CardValue>
        </CardRow>
        {/* <CardRow potentialLongValue>
          <CardLabel>Repeat Notification:</CardLabel>
          <CardValue
            title={`Repeat Notification: ${getCooldownPeriodLabelFromValue(
              alert.cooldownMinutes,
            )} `}
          >
            {getCooldownPeriodLabelFromValue(alert.cooldownMinutes)}
          </CardValue>
        </CardRow>
        <CardRow potentialLongValue>
          <CardLabel>Wait to Send Alert:</CardLabel>
          <CardValue title={`Wait To Send Alert: ${getDelayLabelFromValue(alert.delay)} `}>
            {getDelayLabelFromValue(alert.delay)}
          </CardValue>
        </CardRow> */}
      </CardContainer>
    </CardOuterWrapper>
  );
};

export default AlertSummaryCard;
