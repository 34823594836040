import { characterLimitValidator } from 'legacy/shared/utilities/helpers/validation/validators';
import { vehicleYearValidator } from 'legacy/features/vehicles/validators/vehicleYearValidator';
import { requiredValidator } from 'legacy/shared/utilities/helpers/validation/validators';
import {
  alphanumericExtendedRegex,
  vinRegex,
  deviceIdRegex,
  asciiRegex,
} from 'legacy/shared/utilities/helpers/validation/validationRegularExpressions';
import {
  alphanumericExtendedRegexError,
  vinRegexError,
  deviceIdRegexError,
  asciiRegexError,
} from 'legacy/shared/utilities/helpers/validation/validationErrorMessages';

import { regexValidator } from 'legacy/shared/utilities/helpers/validation/validators';

const vehicleValidators = {
  label: requiredValidator(
    characterLimitValidator(
      regexValidator(alphanumericExtendedRegex, alphanumericExtendedRegexError),
    ),
  ),
  vin: requiredValidator(regexValidator(vinRegex, vinRegexError)),
  type: requiredValidator(
    regexValidator(alphanumericExtendedRegex, alphanumericExtendedRegexError),
  ),
  make: requiredValidator(
    characterLimitValidator(
      regexValidator(alphanumericExtendedRegex, alphanumericExtendedRegexError),
    ),
  ),
  model: requiredValidator(
    characterLimitValidator(
      regexValidator(alphanumericExtendedRegex, alphanumericExtendedRegexError),
    ),
  ),
  licensePlate: requiredValidator(
    characterLimitValidator(
      regexValidator(alphanumericExtendedRegex, alphanumericExtendedRegexError),
    ),
  ),
  licenseState: requiredValidator(
    regexValidator(alphanumericExtendedRegex, alphanumericExtendedRegexError),
  ),
  duid: requiredValidator(regexValidator(deviceIdRegex, deviceIdRegexError)),
  year: requiredValidator(vehicleYearValidator()),
  notes: characterLimitValidator(regexValidator(asciiRegex, asciiRegexError)),
  alias: characterLimitValidator(
    regexValidator(alphanumericExtendedRegex, alphanumericExtendedRegexError),
  ),
};

export const validateVehicle = (values) => {
  const labelErrors = vehicleValidators.label(values.label, 36);
  const vinErrors = vehicleValidators.vin(values.vin.toUpperCase());
  const typeErrors = vehicleValidators.type(values.type);
  const makeErrors = vehicleValidators.make(values.make, 128);
  const modelErrors = vehicleValidators.model(values.model, 128);
  const licensePlateErrors = vehicleValidators.licensePlate(values.licensePlate, 12);
  const licenseStateErrors = vehicleValidators.licenseState(values.licenseState);
  const deviceIdErrors = vehicleValidators.duid(values.duid);
  const yearErrors = vehicleValidators.year(values.year);
  const notesErrors = vehicleValidators.notes(values.notes, 1000);
  const aliasErrors = vehicleValidators.alias(values.alias, 36);

  return {
    ...(labelErrors ? { label: labelErrors } : {}),
    ...(vinErrors ? { vin: vinErrors } : {}),
    ...(typeErrors ? { type: typeErrors } : {}),
    ...(makeErrors ? { make: makeErrors } : {}),
    ...(modelErrors ? { model: modelErrors } : {}),
    ...(licensePlateErrors ? { licensePlate: licensePlateErrors } : {}),
    ...(licenseStateErrors ? { licenseState: licenseStateErrors } : {}),
    ...(deviceIdErrors ? { duid: deviceIdErrors } : {}),
    ...(yearErrors ? { year: yearErrors } : {}),
    ...(notesErrors ? { notes: notesErrors } : {}),
    ...(aliasErrors ? { alias: aliasErrors } : {}),
  };
};
