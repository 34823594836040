import { useState } from 'react';
import { useParams } from 'react-router-dom';

import { useFetchOrganizationQuery } from 'legacy/features/organizations/api/queries/useFetchOrganizationQuery';
import OrganizationDetailHeader from 'legacy/features/organizations/components/header/OrganizationDetailHeader';
import OrganizationFseListingTab from 'legacy/features/organizations/components/tabs/OrganizationFseListingTab';
import OrganizationUserListingTab from 'legacy/features/organizations/components/tabs/OrganizationUserListingTab';
import { TABS } from 'legacy/features/organizations/constants/constants';
import LoadingWrapper from 'legacy/shared/controls/WcpLoadingWrapper/v1/LoadingWrapper';
import WcpTabView from 'legacy/shared/controls/WcpTabView/v2/WcpTabView';

const OrganizationDetailContainer = () => {
  const { organizationId } = useParams();

  const fetchOrganizationQuery = useFetchOrganizationQuery({ organizationId });

  const [activeTab, setActiveTab] = useState(TABS.USERS);

  return (
    <LoadingWrapper isLoading={!fetchOrganizationQuery.data || fetchOrganizationQuery.isFetching}>
      <OrganizationDetailHeader
        activeTabUserType={activeTab}
        organization={fetchOrganizationQuery.data}
      />
      <WcpTabView
        tabs={[
          {
            label: 'Users',
            content: <OrganizationUserListingTab organization={fetchOrganizationQuery.data} />,
            initialTab: activeTab === TABS.USERS,
            handleTabChange: () => setActiveTab(TABS.USERS),
          },
          {
            label: 'Field Solution Engineers',
            content: <OrganizationFseListingTab organization={fetchOrganizationQuery.data} />,
            initialTab: activeTab === TABS.FSES,
            handleTabChange: () => setActiveTab(TABS.FSES),
          },
        ]}
      />
    </LoadingWrapper>
  );
};

export default OrganizationDetailContainer;
