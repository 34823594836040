import { useEffect, useState } from 'react';
import Media from 'react-media';

import OtaLogCard from 'legacy/features/configurations/mobile/OtaLogCard';
import { useFetchFirmwareOtaLogsQuery } from 'legacy/features/firmware/hooks/useFetchFirmwareOtaLogsQuery';
import { firmwareUpdatesLogsTableColumns } from 'legacy/features/firmware/pages/FirmwareUpdatesLogsTableColumns';
import DateRangeSubmitForm from 'legacy/shared/controls/DateRangeSubmitForm/DateRangeSubmitForm';
import { SORT_ORDER } from 'legacy/shared/constants/table';
import { ActionButtonWithIcon } from 'legacy/shared/styles/custom/Button';
import { Cards } from 'legacy/shared/styles/custom/MobileCard';
import { LogsFiltersDiv } from 'legacy/shared/styles/custom/OtaUpdates';
import { QueryAndExportDiv } from 'legacy/shared/styles/custom/OtaUpdates';
import PageListWrapper, { PageListHead, PageListTitle } from 'legacy/shared/styles/custom/PageList';
import { SectionHead } from 'legacy/shared/styles/custom/Section';
import { createExportDownload } from 'legacy/shared/utilities/misc/csvExport';
import { decorateOtaLogData } from 'legacy/shared/utilities/misc/decorateOtaLogData';
import { sortDescendingAlpha } from 'legacy/shared/utilities/misc/general';
import { sizes } from 'legacy/shared/utilities/misc/media';
import {
  formatISOTimestampToShortDate,
  getAMonthAgo,
  getNow,
} from 'legacy/shared/utilities/misc/time';
import { validateLogsRange } from 'legacy/shared/utilities/helpers/validation/validators';
import FilteredDropdown from 'legacy/shared/controls/WcpFilteredDropdown/v2/FilteredDropdown';
import WcpTable from 'legacy/shared/controls/WcpTable/v2/WcpTable';
import { extractDropdownOptions } from 'legacy/shared/utilities/classes/dropdown/dropdownUtils';

const FirmwareUpdatesLogsPage = () => {
  const defaultStartDate = formatISOTimestampToShortDate(getAMonthAgo());
  const defaultEndDate = formatISOTimestampToShortDate(getNow());

  const { fetch, isLoading, data } = useFetchFirmwareOtaLogsQuery();

  const [filteredLogs, setFilteredLogs] = useState(null);
  const [unfilteredLogs, setUnfilteredLogs] = useState(null);

  const [vehicleNameFilter, setVehicleNameFilter] = useState(null);
  const [deviceFilter, setDeviceFilter] = useState(null);
  const [actionFilter, setActionFilter] = useState(null);
  const [listingCount, setListingCount] = useState(filteredLogs?.length);

  useEffect(() => {
    decorateOtaLogData(data?.logs);
    setUnfilteredLogs(data?.logs);
    setFilteredLogs(data?.logs?.sort((v1, v2) => sortDescendingAlpha(v1.timestamp, v2.timestamp)));
  }, [data?.logs]);

  useEffect(() => {
    let filteredData = unfilteredLogs
      ?.filter((l) => (vehicleNameFilter ? l.vehicle_name === vehicleNameFilter : true))
      ?.filter((l) => (deviceFilter ? l.product_name === deviceFilter : true))
      ?.filter((l) => (actionFilter ? l.statusDisplayName === actionFilter : true));
    setFilteredLogs(
      filteredData?.sort((v1, v2) => sortDescendingAlpha(v1.timestamp, v2.timestamp)),
    );
  }, [vehicleNameFilter, deviceFilter, actionFilter]);

  useEffect(() => {
    setListingCount(filteredLogs?.length ? filteredLogs.length : 0);
  }, [filteredLogs]);

  const prepareDataForExport = () => {
    let otaLogsForExport = unfilteredLogs.map((l) => ({
      Timestamp: l.formattedTime,
      Vehicle_Name: l.vehicle_name,
      Device_Name: l.product_name,
      Version: l.version,
      Action: l.statusDisplayName,
      Authorizer: l.authorizerDisplay,
    }));

    let csvFileName = 'wcp_firmware_ota_logs_export';
    createExportDownload(otaLogsForExport, csvFileName);
  };

  return (
    <PageListWrapper>
      <PageListHead stackable logs>
        <SectionHead>
          <PageListTitle>Firmware OTA Logs</PageListTitle>
        </SectionHead>
      </PageListHead>
      <div>
        <QueryAndExportDiv>
          <DateRangeSubmitForm
            defaultStartDate={defaultStartDate}
            defaultEndDate={defaultEndDate}
            isLoading={isLoading}
            submitHandler={({ startDate, endDate }) => fetch({ startDate, endDate })}
            validationFunction={validateLogsRange}
            confirmText="Generate"
          />
          {filteredLogs?.length > 0 && (
            <ActionButtonWithIcon onClick={prepareDataForExport} logsExport>
              Export to .csv
            </ActionButtonWithIcon>
          )}
        </QueryAndExportDiv>
        <div>
          {unfilteredLogs && (
            <LogsFiltersDiv>
              <FilteredDropdown
                title={'Vehicle'}
                placeholderLabel={'All Vehicles'}
                dropdownOptions={extractDropdownOptions({
                  collection: filteredLogs,
                  propName: 'vehicle_name',
                })}
                handleItemSelect={(selectedVehicle) => {
                  setVehicleNameFilter(selectedVehicle ? selectedVehicle.label : null);
                }}
                defaultItem={null}
                styleOptions={{ restrictHeight: false }}
              />
              <FilteredDropdown
                title={'Device'}
                placeholderLabel={'All Devices'}
                dropdownOptions={extractDropdownOptions({
                  collection: filteredLogs,
                  propName: 'product_name',
                })}
                handleItemSelect={(selectedProduct) => {
                  setDeviceFilter(selectedProduct ? selectedProduct.label : null);
                }}
                defaultItem={null}
                styleOptions={{ restrictHeight: false }}
              />
              <FilteredDropdown
                title={'Action'}
                placeholderLabel={'All Actions'}
                dropdownOptions={extractDropdownOptions({
                  collection: filteredLogs,
                  propName: 'statusDisplayName',
                })}
                handleItemSelect={(selectedAction) => {
                  setActionFilter(selectedAction ? selectedAction.label : null);
                }}
                defaultItem={null}
                styleOptions={{ restrictHeight: false }}
              />
            </LogsFiltersDiv>
          )}
        </div>
      </div>
      {filteredLogs && (
        <>
          <h5>
            {listingCount === unfilteredLogs?.length
              ? `Total of ${listingCount} Log${listingCount !== 1 ? 's' : ''}`
              : `${listingCount} of ${unfilteredLogs?.length} Log${
                  filteredLogs?.length !== 1 ? 's' : ''
                }`}
          </h5>
          <Media
            queries={{
              tablet: { maxWidth: sizes.tablet },
              mobile: { maxWidth: sizes.mobile },
            }}
          >
            {(matches) =>
              matches.tablet ? (
                <div>
                  <Cards>
                    {filteredLogs.map((fld) => (
                      <OtaLogCard log={fld} config={false} />
                    ))}
                  </Cards>
                </div>
              ) : (
                <WcpTable
                  tableClassName="table-simple"
                  columns={firmwareUpdatesLogsTableColumns}
                  tableSourceData={filteredLogs}
                  rowKey={(record, i) => i}
                  defaultSortOrder={SORT_ORDER.DESC}
                  setListingCount={setListingCount}
                  emptyText="No OTA logs are currently associated with this organization"
                />
              )
            }
          </Media>
        </>
      )}
    </PageListWrapper>
  );
};

export default FirmwareUpdatesLogsPage;
