import MaintenanceItemContainer from 'legacy/features/maintenance/components/MaintenanceBuilder/containers/MaintenanceItemContainer';
import MaintenanceTypeDropdown from 'legacy/features/maintenance/components/MaintenanceBuilder/dropdowns/MaintenanceTypeDropdown';
import MaintenanceBuilderContextProvider from 'legacy/features/maintenance/providers/MaintenanceBuilderContextProvider/MaintenanceBuilderContextProvider';
import { MaintenanceTypeControlDiv } from 'legacy/shared/styles/custom/Maintenance';
import { sortDescendingAlpha } from 'legacy/shared/utilities/misc/general';

const MaintenanceBuilder = ({
  handleSelectedIntervalTypes,
  handleSetMaintenanceItems,
  initialState,
  setScheduleMaintenanceIsValid,
  handleMaintenanceItemsDirty,
  handleMaintenanceItemsValid,
  vehicle,
  orgUsesEngineHours,
  orgUsesEnhancedTelematics,
}) => {
  return (
    <MaintenanceBuilderContextProvider
      initialState={initialState}
      onStateChanged={({ selectedIntervalTypes, maintenanceItems, rowValidStates }) => {
        // if rowValidStates is empty or undefined return
        if (!rowValidStates || rowValidStates.length === 0) return false;

        const isValid = rowValidStates.every((row) => row.isValid);
        const isDirty = rowValidStates.some((row) => row.isDirty);

        // pass selected state changes up to the parent
        // if handleSelectedIntervalTypes is not defined, don't call it
        if (handleMaintenanceItemsDirty) handleMaintenanceItemsDirty({ isDirty });
        if (handleMaintenanceItemsValid) handleMaintenanceItemsValid({ isValid });
        if (handleSelectedIntervalTypes) handleSelectedIntervalTypes({ selectedIntervalTypes });
        if (handleSetMaintenanceItems) handleSetMaintenanceItems({ maintenanceItems });
        return false;
      }}
    >
      {(
        {
          addMaintenanceItemAction,
          updateMaintenanceItemAction,
          removeMaintenanceItemAction,
          isMaintenanceItemValidAction,
        },
        { maintenanceItems },
      ) => {
        let sortedMaintenanceItems = maintenanceItems.sort((a, b) =>
          sortDescendingAlpha(a.rawCreatedDate, b.rawCreatedDate),
        );
        return (
          <MaintenanceTypeControlDiv>
            <MaintenanceTypeDropdown
              handleSelect={(selectedItem) => {
                const maintenanceItem = {
                  maintenanceType: selectedItem,
                };
                // this just gets called once
                addMaintenanceItemAction({ maintenanceItem });
              }}
            />

            <div>
              {sortedMaintenanceItems?.map((item) => (
                <MaintenanceItemContainer
                  vehicle={vehicle}
                  orgUsesEngineHours={orgUsesEngineHours}
                  orgUsesEnhancedTelematics={orgUsesEnhancedTelematics}
                  key={item.id}
                  maintenanceItem={item}
                  handleIsMaintenceBuilderValid={({ isValid }) => {
                    if (setScheduleMaintenanceIsValid) setScheduleMaintenanceIsValid({ isValid });
                    isMaintenanceItemValidAction({ id: item.id, isValid });
                  }}
                  handleUpdateMaintenanceItem={({ updatedMaintenanceItem }) => {
                    updateMaintenanceItemAction({ maintenanceItem: updatedMaintenanceItem });
                  }}
                  handleRemoveMaintenanceItem={(id) => removeMaintenanceItemAction({ id })}
                />
              ))}
            </div>
          </MaintenanceTypeControlDiv>
        );
      }}
    </MaintenanceBuilderContextProvider>
  );
};

export default MaintenanceBuilder;
