import React from 'react';
import { SimpleDropdownBtn, SimpleDropdownItem } from 'legacy/shared/styles/custom/DropdownMenu';

const DeleteGroupMenuItem = ({ onMenuItemClick }) => {
  return (
    <SimpleDropdownItem>
      <SimpleDropdownBtn onClick={onMenuItemClick}>Delete</SimpleDropdownBtn>
    </SimpleDropdownItem>
  );
};

export default DeleteGroupMenuItem;
