import { useParams } from 'react-router-dom';

import DeviceLogsSummary from 'legacy/features/billing/ui/components/DeviceLogsSummary/DeviceLogsSummary';
import { getFormattedDuid } from 'legacy/shared/utilities/helpers/formatting/getFormattedDuid';
import PageListWrapper, {
  PageListActionButtonContainer,
  PageListHead,
  PageListTitle,
} from 'legacy/shared/styles/custom/PageList';

const DeviceLogsPage = () => {
  const { vsgDuid } = useParams();

  return (
    <PageListWrapper>
      <PageListHead stackable>
        <PageListTitle>{`Activity Logs for ${getFormattedDuid(vsgDuid)}`}</PageListTitle>
        <PageListActionButtonContainer></PageListActionButtonContainer>
      </PageListHead>
      <DeviceLogsSummary />
    </PageListWrapper>
  );
};

export default DeviceLogsPage;
