//must be first import
import store from 'legacy/core/redux/store';

import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk';
import { createRoot } from 'react-dom/client';
import { Provider as ReduxProvider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import App from 'legacy/App';

import { unregister } from './registerServiceWorker';
import { GlobalBaseStyles } from './legacy/shared/styles/global/base-styles';
import { GlobalModalStyles } from './legacy/shared/styles/global/modal-styles';
import { GlobalResetStyles } from './legacy/shared/styles/global/reset-styles';
import { GlobalTableStyles } from './legacy/shared/styles/global/table-styles';
import { clientSideID } from './tools/launchdarkly/ld-constants';

// Create a client
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

(async () => {
  const LDProvider = await asyncWithLDProvider({
    clientSideID,
  });

  const container = document.getElementById('root');
  const root = createRoot(container);

  root.render(
    <ReduxProvider store={store}>
      <LDProvider>
        <QueryClientProvider client={queryClient}>
          <GlobalResetStyles />
          <GlobalBaseStyles />
          <GlobalModalStyles />
          <GlobalTableStyles />
          <BrowserRouter>
            <App />
          </BrowserRouter>
          <ReactQueryDevtools />
        </QueryClientProvider>
      </LDProvider>
    </ReduxProvider>,
  );
})();

unregister();
