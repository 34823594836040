import { useSelector } from 'react-redux';
import {
  REGENERATE_GTT_ACCESS_KEY_MODAL_KEY,
  RECEIVE_GTT_ACCESS_KEY_MODAL_KEY,
} from 'legacy/features/integrations/providers/modal/constants/partnershipsModalKeys';
import { useWcpModalContext } from 'legacy/shared/controls/WcpModal/v2/WcpModalProvider';
import { SimpleDropdownBtn, SimpleDropdownItem } from 'legacy/shared/styles/custom/DropdownMenu';
import useSetGttAccessKeyMutation from 'legacy/features/organizations/api/mutations/useSetGttAccessKeyMutation';

const GenerateGttKeyMenuItem = ({ partnership }) => {
  const organizationId = useSelector((state) => state.user.filteredOrganizationId);
  const setGttAccessKeyMutation = useSetGttAccessKeyMutation();

  // confirmation modal to regenerate the key and modal for receiving the key
  const [regenerateGttAccessKeyModalContext, receiveGttAccessKeyModalContext] = useWcpModalContext(
    REGENERATE_GTT_ACCESS_KEY_MODAL_KEY,
    RECEIVE_GTT_ACCESS_KEY_MODAL_KEY,
  );

  const handleClick = async () => {
    // if the GTT setup is already complete, open the modal to confirm key regeneration
    //replace with prop for gttSetup
    if (true) {
      regenerateGttAccessKeyModalContext.modalActions.openModal({
        organizationId,
        handleSubmitCompleted: async ({ gttAccessKey }) => {},
      });
    }
    // otherwise, generate a new access key
    else {
      const gttAccessKeyMutation = await setGttAccessKeyMutation.mutateAsync({ organizationId });
      const gttAccessKey = gttAccessKeyMutation.data.message.gtt_access_id;

      receiveGttAccessKeyModalContext.modalActions.openModal({
        gttAccessKey,
        handleConfirmed: receiveGttAccessKeyModalContext.modalActions.closeModal,
      });
    }
  };
  return (
    <SimpleDropdownItem>
      <SimpleDropdownBtn onClick={async () => await handleClick()}>
        Generate Miovision Access Key
      </SimpleDropdownBtn>
    </SimpleDropdownItem>
  );
};

export default GenerateGttKeyMenuItem;
