import { Cards } from 'legacy/shared/styles/custom/MobileCard';
import PartnershipsActionMenu from 'legacy/features/integrations/ui/menus/PartnershipsActionMenu';
import PartnershipsSummaryCard from 'legacy/features/integrations/ui/components/mobile/PartnershipsSummaryCard';

const PartnershipsSummaryCardContainer = ({ partnerships }) => {
  const getActionMenu = (partnership) => {
    return <PartnershipsActionMenu partnership={partnership} />;
  };
  return (
    <div>
      <Cards topMargin>
        {partnerships.map((p) => (
          <PartnershipsSummaryCard
            partnership={p}
            key={p.id}
            actionMenuComponent={() => getActionMenu(p)}
          />
        ))}
      </Cards>
    </div>
  );
};

export default PartnershipsSummaryCardContainer;
