import { MapZoomControlsDiv } from 'legacy/shared/styles/custom/SharedMapStyledComponents';
import IconSvgComponent from 'legacy/shared/controls/WcpIcon/IconSvgComponent';

const MapZoomControls = ({ mapboxApi }) => {
  return (
    <MapZoomControlsDiv>
      <div onClick={() => mapboxApi.zoomIn()}>
        <IconSvgComponent svgFileName={'zoom-in'} alt="Zoom in" />
      </div>
      <div onClick={() => mapboxApi.zoomOut()}>
        <IconSvgComponent svgFileName={'zoom-out'} alt="Zoom out" />
      </div>
    </MapZoomControlsDiv>
  );
};

export default MapZoomControls;
