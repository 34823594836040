import { addNotification } from 'legacy/core/redux/ui/actions';
import { createNotification, LEVELS } from 'legacy/shared/utilities/misc/notification';

export const updateFetchError = ({ err }) =>
  addNotification({
    notification: createNotification(LEVELS.ERROR, 'Error Getting Updates', err),
  });

export const firmwareOtaLogsFetchError = ({ err }) =>
  addNotification({
    notification: createNotification(LEVELS.ERROR, 'Error Getting Logs', err),
  });

export const updateFailure = ({ err }) =>
  addNotification({
    notification: createNotification(LEVELS.ERROR, 'Failed to update firmware', err),
  });

export const partialUpdateFailure = ({ responseMessageArray }) => {
  return addNotification({
    notification: createNotification(
      LEVELS.WARNING,
      'Some updates had issues',
      responseMessageArray
        .map(
          (message) =>
            `${message.device_id}: ${message.statusCode === 200 ? message.message : message.error}`,
        )
        .join(', '),
    ),
  });
};

export const updateFirmwareSuccess = () =>
  addNotification({
    notification: createNotification(LEVELS.SUCCESS, 'Successfully started firmware updates'),
  });
