import { useMutation } from '@tanstack/react-query';

import { apiCall } from 'legacy/core/api/API';

const useSetGttAccessKeyMutation = () => {
  const mutation = useMutation({
    mutationFn: async ({ organizationId }) =>
      await apiCall({
        endpoint: 'cloudfrontAPI',
        path: `/organization/${organizationId}/generate_gtt_token`,
        method: 'post',
      }),

    //select: (data) => data.message.gtt_access_id TODO - is there an equivalent to this for mutations?
  });

  return mutation;
};

export default useSetGttAccessKeyMutation;
