import EditOrganizationUserForm from 'legacy/features/organizations/components/forms/UserForm/EditOrganizationUserForm';

const EditUserModalContent = ({ modalState, modalActions }) => {
  const { user } = modalState;

  return (
    <EditOrganizationUserForm
      user={user}
      handleSubmitCompleted={modalActions.closeModal}
      handleCancel={modalActions.closeModal}
    />
  );
};

export default EditUserModalContent;
