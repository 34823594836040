import { useNavigate } from 'react-router-dom';

import MaintenanceScheduler from 'legacy/features/maintenance/components/MaintenanceScheduler/MaintenanceScheduler';
import PageListWrapper, { PageListHead, PageListTitle } from 'legacy/shared/styles/custom/PageList';

const MaintenanceSchedulerPage = () => {
  const navigate = useNavigate();

  return (
    <PageListWrapper>
      <PageListHead noBottomMargin>
        <PageListTitle>Schedule Maintenance</PageListTitle>
      </PageListHead>
      <MaintenanceScheduler navigateBackToMaintenance={() => navigate('/maintenance')} />
    </PageListWrapper>
  );
};

export default MaintenanceSchedulerPage;
