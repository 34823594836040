import React, { useState } from 'react';
import { vehicleLockStatusViewData, vehicleTypeViewData } from 'legacy/shared/constants/vehicle';
import {
  CardLabel,
  CardValue,
  CardRow,
  CardName,
  CardType,
  ShowCollapseButton,
  CardContainer,
  ButtonWrapper,
  CardOuterWrapper,
  VehicleIcon,
  CardIconWrapper,
  CardCollapsedInfo,
  ActionMenuBtnWrapper,
  CardExpandedInfo,
  CardSimNotActive,
  CardBottomWrapper,
} from 'legacy/shared/styles/custom/MobileCard';
import { firmwareStatus } from 'legacy/shared/constants/firmwareStatus';
import ProgressBar from 'legacy/shared/controls/ProgressBar/ProgressBar';
import ButtonWithLoader from 'legacy/shared/controls/ButtonWithLoader/ButtonWithLoader';
import PermissionProtectedElement from 'legacy/core/components/authorization/PermissionProtectedElement';
import { permissionData } from 'legacy/shared/constants/users';

export default function ConfigOtaUpdateCard({
  device,
  vehicleStatuses,
  handleUpdateFirmware,
  handleUpdateConfiguration,
  updatePostLoading,
}) {
  const [expanded, setExpanded] = useState(false);
  const handleToggleShowCollapse = () => {
    setExpanded(!expanded);
  };

  const displayVehicleIcon = (vehicleType) => {
    switch (vehicleType) {
      case 'AMBULANCE':
        return 'car4';
      case 'TOW':
        return 'car2';
      case 'DOT':
        return 'car1';
      case 'FIRE':
        return 'car5';
      case 'POLICE':
        return 'car3';
      default:
        return 'car3';
    }
  };

  return (
    <CardOuterWrapper>
      <CardContainer otaExpanded>
        <CardIconWrapper>
          <VehicleIcon svgFileName={displayVehicleIcon(device.vehicle_type)} alt="Vehicle" />
          <CardCollapsedInfo>
            <CardRow header>
              <CardName>{device.label}</CardName>
            </CardRow>
            <CardRow header>
              <CardType>{vehicleTypeViewData[device.vehicle_type].title}</CardType>
            </CardRow>
          </CardCollapsedInfo>
        </CardIconWrapper>
        <CardExpandedInfo expanded>
          <>
            <CardRow configName>
              <CardLabel>Current Config:</CardLabel>
              <CardValue>{device.current_config_name}</CardValue>
            </CardRow>
            <CardRow>
              <CardLabel>Config Status:</CardLabel>
              <CardValue>{firmwareStatus[device.config_status].displayName}</CardValue>
            </CardRow>
            <CardRow>
              <CardLabel>Update Progress:</CardLabel>
              <CardValue>
                <ProgressBar progress={firmwareStatus[device.config_status].progress} />
              </CardValue>
            </CardRow>
            <CardRow>
              {firmwareStatus[device.config_status].hasOwnProperty('buttonText') ? (
                <PermissionProtectedElement requiredPermissions={[permissionData.editotaconfig]}>
                  <ButtonWithLoader
                    disabled={
                      !vehicleStatuses[device.vehicle_id].isActive ||
                      vehicleStatuses[device.vehicle_id]?.isResponding
                    }
                    loadingStyleProp={'otaUpdateSubmittingWithSpinner'}
                    notLoadingStyleProp={'otaUpdate'}
                    confirmText={firmwareStatus[device.config_status].buttonText}
                    isLoading={device.vehicle_id === updatePostLoading}
                    clickHandler={async () => {
                      await handleUpdateConfiguration({
                        configId: device.config_id,
                        vehicleId: device.vehicle_id,
                      });
                    }}
                  />
                </PermissionProtectedElement>
              ) : (
                <></>
              )}
            </CardRow>
          </>
        </CardExpandedInfo>
      </CardContainer>
    </CardOuterWrapper>
  );
}
