import useUserActivationMutation from 'legacy/features/organizations/api/mutations/useUserActivationMutation';
import GenericConfirmationModalControls from 'legacy/shared/controls/WcpModal/v2/controls/GenericConfirmationModalControls';

const DeactivateUserConfirmationModalControls = ({
  modalState: { user },
  modalActions: { closeModal },
}) => {
  const userActivationMutation = useUserActivationMutation();

  const submitDeactivateUser = async () => {
    // deactivate the user
    await userActivationMutation.mutateAsync({
      username: user.username,
      activate: false,
    });

    // close the modal
    closeModal();
  };

  return (
    <GenericConfirmationModalControls
      confirmButtonText="Deactivate"
      submitOnConfirm={true}
      handleConfirm={submitDeactivateUser}
      handleCancel={closeModal}
    />
  );
};

export default DeactivateUserConfirmationModalControls;
