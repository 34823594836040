import useVehiclePlaybackProviderContext from 'legacy/features/playback/state/provider/hooks/useVehiclePlaybackProviderContext';
import { CloseButton } from 'legacy/shared/styles/custom/Modal';
import {
  SpecifiedEventDiv,
  SpecifiedEventHeaderDiv,
  SpecifiedEventTextDiv,
} from 'legacy/shared/styles/custom/VehiclePlayback';
import IconSvgComponent from 'legacy/shared/controls/WcpIcon/IconSvgComponent';
import {
  formatISOTimestampToLongDateWithTime,
  getYesterday,
} from 'legacy/shared/utilities/misc/time';

const VehiclePlaybackFormEventInfo = () => {
  const {
    actions: { setPlaybackRequestFormAction },
    state: {
      playbackRequestForm: { startDate, endDate },
    },
  } = useVehiclePlaybackProviderContext();

  const handleClearEvent = () => {
    setPlaybackRequestFormAction({
      selectedVehicleId: null,
      readOnly: false,
      startTime: 0,
      endTime: 23,
      startDate: getYesterday(),
      endDate: getYesterday(),
    });
  };

  return (
    <SpecifiedEventDiv>
      <SpecifiedEventHeaderDiv>
        Specified Event:
        <CloseButton
          onClick={handleClearEvent}
          style={{
            position: 'relative',
            width: 'auto',
            marginTop: 2,
            marginLeft: 5,
            cursor: 'pointer',
          }}
        >
          <IconSvgComponent svgFileName={'red-x'} alt="Clear Event" title="Clear Event" />
        </CloseButton>
      </SpecifiedEventHeaderDiv>
      <SpecifiedEventTextDiv>
        Start time: {formatISOTimestampToLongDateWithTime(startDate)}
      </SpecifiedEventTextDiv>
      <SpecifiedEventTextDiv>
        End time: {formatISOTimestampToLongDateWithTime(endDate)}
      </SpecifiedEventTextDiv>
    </SpecifiedEventDiv>
  );
};

export default VehiclePlaybackFormEventInfo;
