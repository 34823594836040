import { Link } from 'react-router-dom';

import MaintenanceActionMenu from 'legacy/features/maintenance/components/MaintenanceSummary/menus/MaintenanceActionMenu';
import {
  determineBoldDisplay,
  determineStatusDisplay,
} from 'legacy/features/maintenance/utilities/formatting';
import { EventColumn } from 'legacy/shared/styles/custom/Maintenance';
import IconSvgComponent from 'legacy/shared/controls/WcpIcon/IconSvgComponent';
import { getVehicleIcon } from 'legacy/shared/utilities/misc/vehicle';
import { TruncatedTextWithWrap } from 'legacy/shared/styles/custom/TruncatedText';

export const maintenanceSummaryTableColumns = [
  {
    title: 'Vehicle Name',
    displayProperty: 'vehicleLabel',
    className: 'name',
    width: '20%',
    render: ({ rowData, cellJsx }) => {
      return (
        <span className="vehicleNameAndIcon" title={'Vehicle Name: ' + rowData.vehicleLabel}>
          <IconSvgComponent
            svgFileName={getVehicleIcon(rowData.onlineStatus, rowData.respondingStatus)}
          />
          <TruncatedTextWithWrap>
            <Link
              to={{
                pathname: `/vehicle/${encodeURIComponent(rowData.vehicleId)}`,
              }}
              style={{ textDecoration: 'none' }}
            >
              {cellJsx}
            </Link>
          </TruncatedTextWithWrap>
        </span>
      );
    },
  },
  {
    title: 'Make',
    displayProperty: 'vehicleMake',
    className: 'make',
    width: '12%',
    render({ rowData, cellJsx }) {
      return (
        <EventColumn
          due={determineBoldDisplay(rowData.status)}
          title={'Make: ' + rowData.vehicleMake}
        >
          <TruncatedTextWithWrap>{cellJsx}</TruncatedTextWithWrap>
        </EventColumn>
      );
    },
  },

  {
    title: 'Odometer',
    displayProperty: 'odometerDisplay',
    sortProperty: 'odometer',
    width: '8%',
    render({ rowData, cellJsx }) {
      return (
        <EventColumn
          due={determineBoldDisplay(rowData.status)}
          title={'Odometer: ' + rowData.odometerDisplay}
        >
          {cellJsx}
        </EventColumn>
      );
    },
  },

  {
    title: 'Engine Hours',
    displayProperty: 'engineHoursDisplay',
    sortProperty: 'engineHours',
    width: '8%',
    render({ rowData, cellJsx }) {
      return (
        <EventColumn
          due={determineBoldDisplay(rowData.status)}
          title={'Engine Hours: ' + rowData.engineHoursDisplay}
        >
          {cellJsx}
        </EventColumn>
      );
    },
  },

  {
    title: 'Due in',
    displayProperty: 'dueInValueDisplay',
    width: '10%',
    render({ rowData }) {
      return (
        <EventColumn
          due={determineBoldDisplay(rowData.status)}
          title={`Due in: ${
            rowData.dueInValueDisplay ? rowData.dueInValueDisplay + ' ' + rowData.dueInUnit : 'Now'
          }`}
        >
          {rowData.dueInValueDisplay
            ? `${rowData.dueInValueDisplay}${rowData.dueInUnit} ${
                rowData.dueInUnit === '%' ? 'oil life remaining' : ''
              }`
            : 'Now'}
        </EventColumn>
      );
    },
  },

  {
    title: 'Type',
    displayProperty: 'maintenanceTypeDisplay',
    render({ rowData, cellJsx }) {
      return (
        <EventColumn
          due={determineBoldDisplay(rowData.status)}
          title={'Type: ' + rowData.maintenanceTypeDisplay}
        >
          {cellJsx}
        </EventColumn>
      );
    },
  },

  {
    title: 'Last in',
    displayProperty: 'lastIn',
    render({ rowData, cellJsx }) {
      return (
        <EventColumn
          due={determineBoldDisplay(rowData.status)}
          title={'Last in: ' + rowData.lastIn}
        >
          {cellJsx}
        </EventColumn>
      );
    },
  },

  {
    title: 'Status',
    displayProperty: 'status',
    width: '10%',
    render({ rowData }) {
      return determineStatusDisplay(rowData);
    },
  },

  {
    title: '',
    className: 'action',
    searchEnabled: false,
    render({ rowData }) {
      return <MaintenanceActionMenu event={rowData} />;
    },
  },
];
