import { organizationFeatureModalContracts } from 'legacy/features/organizations/providers/modal/constants/organizationFeatureModalContracts';
import WcpModalProvider from 'legacy/shared/controls/WcpModal/v2/WcpModalProvider';

const OrganizationDetailModalProvider = ({ children }) => {
  return (
    <WcpModalProvider modalContracts={organizationFeatureModalContracts}>
      {children}
    </WcpModalProvider>
  );
};

export default OrganizationDetailModalProvider;
