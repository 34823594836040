import { WcpTextInputField } from 'legacy/shared/controls/WcpForm/v2/components/fields/WcpTextInputField';

const UsernameField = ({ readOnly = false }) => {
  return (
    <WcpTextInputField
      label="Username"
      dataKey="username"
      required={true}
      showReadOnlyLabel={readOnly}
    />
  );
};

export default UsernameField;
