import { useFormikContext } from 'formik';

import ButtonWithLoader from 'legacy/shared/controls/ButtonWithLoader/ButtonWithLoader';

const VehiclePlaybackRequestFormSubmitButton = ({ isLoading }) => {
  const formik = useFormikContext();

  return (
    <ButtonWithLoader
      isLoading={isLoading}
      confirmText={'Generate Playback'}
      loadingStyleProp={'submittingWithSpinner'}
      notLoadingStyleProp={'query'}
      disabled={!formik.isValid}
    />
  );
};

export default VehiclePlaybackRequestFormSubmitButton;
