import colors from 'legacy/shared/constants/colors';
import device from 'legacy/shared/constants/sizing';
import theme from 'legacy/shared/styles/global/theme';
import styled from 'styled-components';

export const StyledForm = styled.form.attrs((props) => ({
  noValidate: true,
}))``;

export const StyledDivFormHeader = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 91px;
`;

export const StyledDivFormFooter = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;

  ${(props) =>
    props.geofenceMapCard &&
    `
    margin-top:20px;

    @media ${device.large} {
      margin-right:20px;
  }
  `};
`;
export const StyledSpanFormLegend = styled.span`
  font-family: ${theme.font};
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.01em;
  color: #${colors.haze};
`;

export const StyledSpanFormSummaryValidationMessage = styled.span`
  font-family: ${theme.font};
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.01em;
  color: ${colors.cherry};
`;

export const StyledDivFormActions = styled.div`
  a + a {
    margin-left: 10px;
  }
  //we need both of the following margins or else the spinner becomes off center
  button {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  display: flex;
`;

export const StyledSpanFormRequiredAsterisk = styled.span`
  &:before {
    content: '*';
  }
  color: ${colors.cherry};

  ${({ showRequiredText = false }) =>
    showRequiredText &&
    `
      &:after {
      content: ' - required fields';
      color: ${colors.midnight};
    }`}
`;

export const StyledDivFormBody = styled.div`
  margin-bottom: 60px;
`;
export const StyledDivFormSection = styled.div`
  color: ${colors.haze};
  margin-bottom: 18px;
  font-weight: 500;
  font-size: 18px;

  ${(props) =>
    props.gtt &&
    `
    margin-bottom: 10px;
    `};
  ${(props) =>
    props.markCompleted &&
    `
    margin-bottom: 5px;
    `};
  ${(props) =>
    props.maintenance &&
    `
          margin-bottom: 0px;
          `};
`;
export const StyledDivFormGridRow = styled.div`
  margin-bottom: 20px;

  &:last-child {
    margin-bottom: 0;
  }
  display: grid;
  grid-gap: 20px;

  grid-template-areas:
    'FormCol'
    'FormCol';

  @media ${device.large} {
    grid-template-areas: 'FormCol FormCol';
    grid-template-columns: 45% 45%;
    grid-gap: 10%;
  }

  ${(props) =>
    props.noBottom &&
    `
   margin-bottom: 0px;
    `};
`;
export const StyledDivFormRow = styled.div`
  margin-bottom: 26px;

  &:last-child {
    margin-bottom: 0;
  }
`;

export const StyledDivFormCol = styled.div`
  max-width: 100%;
  min-width: 300px;
  flex: 1 100%;
  flex: wrap;

  ${(props) =>
    props.noMinWidth &&
    `
    min-width: 0px;
    @media ${device.large} {
      min-width: 0px;
    }
    `};
`;

export const StyledDivFormIcon = styled.div`
  margin-right: 10px;
`;

export const StyledH4FormTitle = styled.h4`
  font-weight: 700;
  letter-spacing: 0.01em;
  color: ${colors.midnight};
  margin-bottom: 0;
`;

export const StyledFSEModalContentDiv = styled.div`
  height: 700px;
  overflow-x: hidden;
`;

export const StyledFSETableContentDiv = styled.div`
  margin-right: 70px;
`;
