import { WcpTextInputField } from 'legacy/shared/controls/WcpForm/v2/components/fields/WcpTextInputField';

const OrganizationNameField = ({ showReadOnly = false }) => {
  return (
    <WcpTextInputField
      label="Organization Name"
      dataKey="organizationName"
      required={true}
      showReadOnlyLabel={showReadOnly}
    />
  );
};

export default OrganizationNameField;
