import React from 'react';

import ModalPortal from 'legacy/shared/controls/WcpModal/v1/ModalPortal';
import ConfirmationModal from 'legacy/shared/controls/WcpModal/v1/ConfirmationModal';

const VerifyVsgIdModal = ({ handleUserResponse }) => {
  return (
    <ModalPortal
      onRequestClose={() => {
        handleUserResponse(false);
      }}
    >
      <ConfirmationModal
        bodyText={
          <>
            <p>
              The VSG Registration ID is not recognized. Please check you hardware to ensure that it
              was entered correctly. You can still choose to create this vehicle and edit the ID
              later on.
            </p>
            <h6>Do you want to continue?</h6>
          </>
        }
        cancelHandler={() => {
          handleUserResponse(false);
        }}
        confirmHandler={() => {
          handleUserResponse(true);
        }}
        cancelText={'Cancel'}
        confirmText={'Continue'}
        title={'VSG Registration ID'}
      />
    </ModalPortal>
  );
};

export default VerifyVsgIdModal;
