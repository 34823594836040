import { getActionForPartnershipModal } from 'legacy/features/integrations/utilities/getActionForPartnershipModal';
import {
  StyledDivModalContent,
  StyledModalText,
} from 'legacy/shared/controls/WcpModal/v2/styles/WcpModalStyles';
import { StyledBoldedText } from 'legacy/shared/styles/v2/Formatting';

export const EnableDisablePartnershipModalContent = ({ modalState }) => {
  return (
    <StyledDivModalContent>
      <StyledModalText>
        {`Are you sure you want to ${getActionForPartnershipModal(modalState.partnership.enabled).toLocaleLowerCase()} the following partnership: `}
        <StyledBoldedText>{`${modalState.partnership.name}?`}</StyledBoldedText>
      </StyledModalText>
    </StyledDivModalContent>
  );
};
