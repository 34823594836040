/**
 * External dependencies
 */
import React, { useState, useRef, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { CollapsibleDiv } from 'legacy/shared/styles/custom/Collapsible';

import getDebounced from 'legacy/shared/utilities/misc/debounce';

const CollapsibleHeader = ({ initialCollapsedState, ToggleComponent, children, cards }) => {
  const [collapsed, setCollapsed] = useState(initialCollapsedState);
  const [collapsibleHeight, setCollapsibleHeight] = useState(0);
  const initialContainer = useRef(null);

  useEffect(() => {
    if (initialCollapsedState !== undefined) {
      setCollapsed(initialCollapsedState);
    }
  }, [initialCollapsedState]);

  if (collapsibleHeight === 0 && initialContainer.current) {
    setCollapsibleHeight(ReactDOM.findDOMNode(initialContainer.current).scrollHeight);
  }

  useEffect(() => {
    const [debouncedResizeHandler] = getDebounced(() => {
      setCollapsibleHeight(0);
    }, 1000);
    window.addEventListener('resize', debouncedResizeHandler);

    return () => {
      window.removeEventListener('resize', debouncedResizeHandler);
    };
  }, []);

  return (
    <div>
      <ToggleComponent collapsed={collapsed} setCollapsed={setCollapsed} />
      <CollapsibleDiv
        cards={cards}
        // ref={initialContainer}
        collapsed={collapsed}
        maxHeight={collapsibleHeight === 0 ? '' : collapsibleHeight}
      >
        {children}
      </CollapsibleDiv>
    </div>
  );
};

export default CollapsibleHeader;
