import DropdownOptions from 'legacy/shared/utilities/classes/dropdown/DropdownOptions';
// TODO - migrate these to shared
export const timeZoneOptions = [
  { label: 'US/Alaska', value: 'US/Alaska' },
  { label: 'US/Aleutian', value: 'US/Aleutian' },
  { label: 'US/Arizona', value: 'US/Arizona' },
  { label: 'US/Central', value: 'US/Central' },
  { label: 'US/East-Indiana ', value: 'US/East-Indiana' },
  { label: 'US/Eastern', value: 'US/Eastern' },
  { label: 'US/Hawaii', value: 'US/Hawaii' },
  { label: 'US/Indiana-Starke', value: 'US/Indiana-Starke' },
  { label: 'US/Michigan', value: 'US/Michigan' },
  { label: 'US/Mountain', value: 'US/Mountain' },
  { label: 'US/Pacific', value: 'US/Pacific' },
  { label: 'US/Samoa', value: 'US/Samoa' },
];

export const activeTimeOptions = [
  {
    label: 'Always',
    value: 'Always',
  },
  {
    label: 'Expires at',
    value: 'ExpiresAt',
  },
  {
    label: 'Range',
    value: 'Range',
  },
];

export const categoryOptions = [
  /*  {
    label: 'Intersections',
    value: 'Intersections',
  }, */
  {
    label: 'School Zones',
    value: 'School Zones',
  },
  {
    label: 'OTA Updates',
    value: 'OTA Updates',
  },
  {
    label: 'Work Zones',
    value: 'Work Zones',
  },
  {
    label: 'Headquarters',
    value: 'Headquarters',
  },
  {
    label: 'Custom 1',
    value: 'Custom 1',
  },
  {
    label: 'Custom 2',
    value: 'Custom 2',
  },
  {
    label: 'Custom 3',
    value: 'Custom 3',
  },
];

export const timeZoneDropdownOptions = new DropdownOptions(timeZoneOptions);
