import useDisassociateFseMutation from 'legacy/features/organizations/api/mutations/useDisassociateFseMutation';
import { SimpleDropdownBtn, SimpleDropdownItem } from 'legacy/shared/styles/custom/DropdownMenu';
import React from 'react';

const RemoveUserMenuItem = ({ user, organization }) => {
  const dissassociateFseMutation = useDisassociateFseMutation();

  return (
    <SimpleDropdownItem>
      <SimpleDropdownBtn
        onClick={() =>
          dissassociateFseMutation.mutateAsync({
            username: user.username,
            organizationId: organization.organizationId,
          })
        }
      >
        Remove
      </SimpleDropdownBtn>
    </SimpleDropdownItem>
  );
};

export default RemoveUserMenuItem;
