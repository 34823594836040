import { useQuery } from '@tanstack/react-query';

import { apiCall } from 'legacy/core/api/API';
import { userMapping } from 'legacy/features/users/utilities/userMapping';
import { mapToFrontend } from 'legacy/shared/utilities/helpers/mapping/mapper';

const useFetchOrganizationUsersQuery = ({ organizationId }) => {
  return useQuery({
    queryKey: ['fetchOrganizationUsers'],
    queryFn: async () =>
      await apiCall({
        endpoint: 'cloudfrontAPI',
        path: `/user`,
        method: 'get',
        queryStringParameters: { organization_id: `${organizationId}` },
      }),
    select: (data) =>
      data.body.message.map((user) => {
        try {
          const mappedUser = mapToFrontend(user, userMapping);
          return mappedUser;
        } catch (e) {
          console.log(e);
        }
      }),
  });
};

export default useFetchOrganizationUsersQuery;
