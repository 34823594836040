import { ReactSVG } from 'react-svg';

const JSToCSS = (JS) => {
  let cssString = '';
  for (let objectKey in JS) {
    cssString +=
      objectKey.replace(/([A-Z])/g, (g) => `-${g[0].toLowerCase()}`) + ': ' + JS[objectKey] + ';\n';
  }

  return cssString;
};

const WcpSvgIcon = ({ fileName, altText, style }) => {
  return (
    <ReactSVG
      src={require(`assets/svg/${fileName}.svg`)}
      className="svg-icon"
      alt={altText}
      {...(style && {
        beforeInjection: (svg) => {
          svg.setAttribute('style', JSToCSS(style));
        },
      })}
    />
  );
};

// create enum
export const WcpSvgIconFactory = {
  PLUS: <WcpSvgIcon fileName="plus" altText="Create" />,
  EDIT_PENCIL_BLUE: <WcpSvgIcon fileName="edit-pencil-blue" altText="Edit" />,
  EDIT_PENCIL_GRAY: <WcpSvgIcon fileName="edit-pencil-gray" altText="Edit" />,
  DELETE: <WcpSvgIcon fileName="delete" altText="Delete" />,
  CLOSE: <WcpSvgIcon fileName="x" altText="Close" />,
};

export default WcpSvgIcon;
