import { ENABLE_DISABLE_PARTNERSHIP_MODAL_KEY } from 'legacy/features/integrations/providers/modal/constants/partnershipsModalKeys';
import { getActionForPartnershipModal } from 'legacy/features/integrations/utilities/getActionForPartnershipModal';
import { useWcpModalContext } from 'legacy/shared/controls/WcpModal/v2/WcpModalProvider';
import { SimpleDropdownBtn, SimpleDropdownItem } from 'legacy/shared/styles/custom/DropdownMenu';

const EnableDisablePartnershipMenuItem = ({ partnership }) => {
  const enableDisablePartnershipConfirmationModalContext = useWcpModalContext(
    ENABLE_DISABLE_PARTNERSHIP_MODAL_KEY,
  );
  const openEnableDisablePartnershipConfirmationModal = () => {
    enableDisablePartnershipConfirmationModalContext.modalActions.openModal({
      partnership,
    });
  };
  return (
    <SimpleDropdownItem>
      <SimpleDropdownBtn
        onClick={openEnableDisablePartnershipConfirmationModal}
      >{`${getActionForPartnershipModal(partnership.enabled)} Partnership`}</SimpleDropdownBtn>
    </SimpleDropdownItem>
  );
};

export default EnableDisablePartnershipMenuItem;
