import { firmwareStatuses } from 'legacy/shared/constants/firmwares';
import {
  Content,
  ContentInner,
  Icon,
  NonMobileDetail,
  OtaSummaryItemNotes,
  Text,
  Title,
  Wrapper,
} from 'legacy/shared/styles/custom/OtaUpdates';
import IconSvgComponent from 'legacy/shared/controls/WcpIcon/IconSvgComponent';
import { formatISOTimestampToShortDate } from 'legacy/shared/utilities/misc/time';

const FirmwareUpdateSummaryByVehicleItem = ({
  releaseDate,
  releaseNotes,
  channel,
  version,
  productName,
  allDeviceUpdatesForProduct,
}) => {
  const completeUpdates = allDeviceUpdatesForProduct.filter(
    (u) => u.firmwareStatus === firmwareStatuses.UP_TO_DATE.status,
  );
  const allDevicesComplete = completeUpdates.length === allDeviceUpdatesForProduct.length;

  return (
    <Wrapper>
      <Content className="col">
        <ContentInner offsetLeft={true}>
          <Icon>
            <IconSvgComponent svgFileName={allDevicesComplete ? 'check-success' : 'cloud'} />
          </Icon>

          {productName && <Title>{productName}</Title>}
          {version && <Title>{`Version ${version}`}</Title>}
          <NonMobileDetail>
            {channel && <Text>Channel: {channel}</Text>}
            {releaseDate && (
              <Text>{`Released: ${formatISOTimestampToShortDate(releaseDate)}`}</Text>
            )}
          </NonMobileDetail>
        </ContentInner>
      </Content>
      <NonMobileDetail>
        <OtaSummaryItemNotes>{releaseNotes}</OtaSummaryItemNotes>
      </NonMobileDetail>
    </Wrapper>
  );
};

export default FirmwareUpdateSummaryByVehicleItem;
