import { useSelector } from 'react-redux';

const FLEET_ANALYST = 'FLEET_ANALYST';
const ORGANIZATION_ADMIN = 'ORGANIZATION_ADMIN';
const SYSTEM_ADMIN = 'SYSTEM_ADMIN';
const FIELD_SOLUTION_ENGINEER = 'FIELD_SOLUTION_ENGINEER';
const MAP_ANALYST = 'MAP_ANALYST';

export const userRoleKeys = {
  FLEET_ANALYST,
  ORGANIZATION_ADMIN,
  SYSTEM_ADMIN,
  FIELD_SOLUTION_ENGINEER,
  MAP_ANALYST,
};

export const userRoleData = [
  {
    key: MAP_ANALYST, // dev key
    label: 'Map Analyst', // frontend label
    value: 'Map Analyst', // backend value
  },
  {
    key: FLEET_ANALYST,
    label: 'Fleet Analyst',
    value: 'Normal User',
  },
  {
    key: ORGANIZATION_ADMIN,
    label: 'Organization Admin',
    value: 'Organization Admin',
  },
  {
    key: FIELD_SOLUTION_ENGINEER,
    label: 'Field Solution Engineer',
    value: 'FSE',
  },
  {
    key: SYSTEM_ADMIN,
    label: 'System Admin',
    value: 'System Admin',
  },
];

// This hook is used to check if the current user has any of the roles passed in as an array of keys
export const useCheckUserRole = (...roleKeys) => {
  if (roleKeys.length === 0) {
    throw new Error('useCheckUserRole requires an at least 1 role key param');
  }

  const sessionUserRole = useSelector((state) => state.user.user.userRole);

  const roleDataValues = userRoleData
    .filter((role) => roleKeys.includes(role.key))
    .map((role) => role.value);

  return roleDataValues.includes(sessionUserRole);
};
