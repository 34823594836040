//DropdownComponent

import { shouldRenderItem } from 'legacy/core/navigation/utilities/navigation';
import {
  NavButtonIcon,
  NavButtonText,
  NavDropdown,
  NavDropdownWrapper,
  NavItem,
  StyledNavigationUl,
  StyledNavLink,
} from 'legacy/core/navigation/styles/NavigationStyles';
import colors from 'legacy/shared/constants/colors';
import IconSvgComponent from 'legacy/shared/controls/WcpIcon/IconSvgComponent';

const NavigationDropdownMenu = ({
  dropdownItems,
  isOpen,
  sidebarIsCollapsed,
  userRole,
  permissions,
  handleNavigationItemClick,
  selectedItemKey,
}) => {
  return (
    <NavDropdownWrapper open={isOpen && !sidebarIsCollapsed}>
      <NavDropdown sidebarIsCollapsed={sidebarIsCollapsed}>
        <StyledNavigationUl>
          {Object.entries(dropdownItems).map(
            ([itemKey, item]) =>
              shouldRenderItem({
                item,
                userRole,
                permissions,
              }) && (
                <NavItem key={itemKey}>
                  {item.link ? (
                    <StyledNavLink
                      isActive={itemKey === selectedItemKey}
                      to={item.link}
                      onClick={() => handleNavigationItemClick({ itemKey, link: item.link })}
                    >
                      <NavButtonIcon>
                        <IconSvgComponent
                          svgFileName={item.icon_path}
                          style={{
                            marginLeft: item.leftOffset,
                            marginRight: item.rightOffset,
                          }}
                          beforeInjection={(svg) => {
                            svg
                              .querySelectorAll('path')
                              .forEach((path) => path.setAttribute('fill', colors.haze));
                          }}
                        />
                      </NavButtonIcon>
                      <NavButtonText>{item.title}</NavButtonText>
                    </StyledNavLink>
                  ) : (
                    <NavButton>
                      <NavButtonIcon>
                        <IconSvgComponent svgFileName={item.icon_path} />
                      </NavButtonIcon>
                      <NavButtonText>{item.title}</NavButtonText>
                    </NavButton>
                  )}
                </NavItem>
              ),
          )}
        </StyledNavigationUl>
      </NavDropdown>
    </NavDropdownWrapper>
  );
};

export default NavigationDropdownMenu;
