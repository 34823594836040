import React from 'react';
import { Link, useLocation } from 'react-router-dom';

import AddFseButton from 'legacy/features/organizations/components/buttons/AddFseButton';
import CreateUserButton from 'legacy/features/organizations/components/buttons/CreateUserButton';
import EditOrganizationButton from 'legacy/features/organizations/components/buttons/EditOrganizationButton';
import { TABS } from 'legacy/features/organizations/constants/constants';
import { customerTypeDictionary } from 'legacy/shared/constants/organizations';
import {
  NameAndButtonsDiv,
  OrganizationHeader,
  OrganizationHeaderWrapper,
} from 'legacy/shared/styles/custom/Header';
import { CardLabel, CardName, CardRow, CardValue } from 'legacy/shared/styles/custom/MobileCard';
import { PageListActionButtonContainer, PageListTitle } from 'legacy/shared/styles/custom/PageList';
import { styles } from 'legacy/shared/styles/inline/inlineStyles';

const OrganizationDetailHeader = ({ organization, activeTabUserType }) => {
  console.log(organization);
  const location = useLocation();

  return (
    <div style={styles.organizations.outerContainer}>
      <NameAndButtonsDiv>
        <div>
          <Link
            to={{
              pathname: (location.state && location.state.referrer) || '/organizations',
              state: { referrer: location.state && location.state.referrer },
            }}
          ></Link>
          <PageListTitle>{organization.organizationName}</PageListTitle>
        </div>
        <PageListActionButtonContainer>
          <EditOrganizationButton organization={organization} />
          {activeTabUserType === TABS.USERS ? (
            <CreateUserButton organization={organization} />
          ) : (
            <AddFseButton organization={organization} />
          )}
        </PageListActionButtonContainer>
      </NameAndButtonsDiv>
      <OrganizationHeaderWrapper>
        <CardName contactName>Contact Name: {organization.contactName}</CardName>
        <OrganizationHeader>
          <CardRow>
            <CardLabel>Contact Email:</CardLabel>
            <CardValue>{organization.contactEmail}</CardValue>
          </CardRow>

          <CardRow>
            <CardLabel>Contact Phone:</CardLabel>
            <CardValue>{organization.contactPhoneDisplay}</CardValue>
            {organization.contactPhoneExtension && (
              <React.Fragment>
                <CardLabel style={{ marginLeft: '10px' }}>Extension:</CardLabel>
                <CardValue>{organization.contactPhoneExtension}</CardValue>
              </React.Fragment>
            )}
          </CardRow>

          <CardRow>
            <CardLabel>Address:</CardLabel>
            <CardValue>{organization.address1}</CardValue>
            <CardValue>{organization.address2}</CardValue>
            <CardValue>{organization.city}</CardValue>
            <CardValue>{organization.addressState}</CardValue>
          </CardRow>

          <CardRow>
            <CardLabel>Customer Type:</CardLabel>
            <CardValue>
              {organization?.customerType
                ? customerTypeDictionary.find((ct) => ct.value === organization?.customerType)
                    ?.label
                : 'Not Specified'}
            </CardValue>
          </CardRow>
        </OrganizationHeader>
      </OrganizationHeaderWrapper>
    </div>
  );
};

export default OrganizationDetailHeader;
