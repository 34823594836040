import WcpAvatarControl from 'legacy/shared/controls/WcpAvatarControl/v1/WcpAvatarControl';
import useWcpFormContext from 'legacy/shared/controls/WcpForm/v2/hooks/useWcpFormContext';

const WcpAvatarControlField = ({ dataKey }) => {
  const { formValues, setFormValues } = useWcpFormContext();

  return (
    <WcpAvatarControl
      avatarImage={formValues[dataKey]}
      handleChangeImage={(newImage) => setFormValues({ [dataKey]: newImage })}
      handleRemoveImage={() => setFormValues({ [dataKey]: null })}
    />
  );
};

export default WcpAvatarControlField;
