import { useQuery } from '@tanstack/react-query';

import { apiCall } from 'legacy/core/api/API';
import { userMapping } from 'legacy/features/users/utilities/userMapping';
import { mapToFrontend } from 'legacy/shared/utilities/helpers/mapping/mapper';

export const useFetchUserQuery = ({ username }) => {
  return useQuery({
    queryKey: ['fetchUser'],
    queryFn: async () =>
      await apiCall({
        method: 'get',
        path: `/user/${username}`,
      }),

    select: (data) => {
      try {
        return mapToFrontend(data.body.message, userMapping);
      } catch (error) {
        console.log('error', error);
      }
    },
  });
};

export default useFetchUserQuery;
