import { Formik } from 'formik';
import { connect } from 'react-redux';

import { editConfiguration } from 'legacy/core/redux/configurations/actions';
import { FormActions, FormAsterisk, FormFoot, FormLegend } from 'legacy/shared/styles/custom/Form';
import ButtonWithLoader from 'legacy/shared/controls/ButtonWithLoader/ButtonWithLoader';
import AnimatedField from 'legacy/shared/controls/AnimatedField/v1/AnimatedField';
import AnimatedTextarea from 'legacy/shared/controls/AnimatedField/v1/AnimatedTextarea';
import { setModalConfirmationMemoized } from 'legacy/shared/utilities/misc/modal';
import { validateConfiguration } from 'legacy/shared/utilities/helpers/validation/validators';

const setModalConfirmation = setModalConfirmationMemoized();

const ConfigurationSummaryModalContent = ({
  children,
  editConfiguration,
  configDetails,
  onSuccess,
}) => {
  return (
    <Formik
      initialValues={{
        configName: configDetails.configName || '',
        description: configDetails.description || '',
        archived: configDetails.archived || 'false',
        action: 'Edit',
      }}
      validate={validateConfiguration}
      validateOnBlur={false}
      validateOnChange={true}
      onSubmit={editConfiguration(configDetails.configId, onSuccess)}
    >
      {({
        values,
        errors,
        touched,
        dirty,
        handleChange,
        handleBlur,
        handleSubmit,
        setFieldValue,
        isSubmitting,
      }) => {
        return (
          <form style={styles.formBody}>
            <AnimatedField
              name="configName"
              placeholder="Configuration Name"
              value={values.configName}
              touched={touched.configName}
              validationError={errors.configName}
              handleChange={handleChange}
              handleBlur={handleBlur}
              required
              configName={true}
            />
            <AnimatedTextarea
              name="description"
              placeholder="Description"
              value={values.description}
              touched={touched.description}
              validationError={errors.description}
              handleChange={handleChange}
              handleBlur={handleBlur}
              required
            />
            {children}

            <FormFoot style={styles.formFoot}>
              <FormLegend>
                <FormAsterisk>*</FormAsterisk> &mdash; required fields
              </FormLegend>

              <FormActions>
                <ButtonWithLoader
                  isLoading={isSubmitting}
                  confirmText={'Confirm'}
                  loadingStyleProp={'submittingWithSpinnerModal'}
                  notLoadingStyleProp={'formConfirm'}
                  clickHandler={handleSubmit}
                />
              </FormActions>
            </FormFoot>
          </form>
        );
      }}
    </Formik>
  );
};

export default connect(null, (dispatch) => ({
  editConfiguration: (configurationId, onSuccess) => async (configuration, formikActions) => {
    const success = await dispatch(editConfiguration(configuration, configurationId));
    formikActions.setSubmitting(false);
    if (success) {
      onSuccess();
    }
  },
}))(ConfigurationSummaryModalContent);

const styles = {
  formBody: {
    paddingLeft: '24px',
    paddingRight: '24px',
  },
  formFoot: {
    marginTop: '24px',
  },
};
