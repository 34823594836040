import React, { useEffect, useState } from 'react';
import colors from 'legacy/shared/constants/colors';

const convertCamelCaseToTitleCase = (camelCaseString) => {
  if (!camelCaseString) return '';
  return camelCaseString.replace(/([A-Z])/g, ' $1').replace(/^./, (str) => str.toUpperCase());
};

const useModifyColumnProperties = ({ columns, currentSortProperty, currentSortOrder }) => {
  const [modifiedColumnProperties, setModifiedColumnProperties] = useState(null);

  useEffect(() => {
    const newProps = columns.map((column, i) => {
      // column properties for cell
      const displayProperty = column.displayProperty ? column.displayProperty : '';
      const sortProperty = column.sortProperty ? column.sortProperty : displayProperty;
      const title = column.title ? column.title : convertCamelCaseToTitleCase(displayProperty);
      const className = column.className ? column.className : 'generic';

      const searchEnabled = column.searchEnabled === undefined ? true : column.searchEnabled;
      const sortingEnabled = column.sortingEnabled === undefined ? true : column.sortingEnabled;
      const hideHeaderContent =
        column.hideHeaderContent === undefined ? false : column.hideHeaderContent;

      // override the column properties
      let overriddenColumnProperties = {
        dataIndex: displayProperty,
        key: `${displayProperty}-${i}`,
        title,
        className,
        displayProperty,
        sortProperty,
        searchEnabled,
        sortingEnabled,
      };

      // cell render
      return {
        ...column,
        ...overriddenColumnProperties,
        render: (_, rowData, rowIndex) => {
          const cellValue = rowData[displayProperty];
          let cellJsx = <span>{cellValue}</span>;

          // if a scearch matches this cell
          const matchedSubstringObject =
            searchEnabled && rowData.searchMatchSubstrings
              ? rowData.searchMatchSubstrings[displayProperty]
              : null;

          // if search is enabled and there is a match
          if (matchedSubstringObject) {
            cellJsx = (
              <span>
                {matchedSubstringObject.first}
                <span style={{ fontWeight: 'bolder', color: colors.midnight }}>
                  {matchedSubstringObject.match}
                </span>
                {matchedSubstringObject.last}
              </span>
            );
          } else if (column.searchOnly) {
            return <></>;
          }

          if (!column.render) {
            return <span title={`${title}: ${cellValue}`}>{cellJsx}</span>;
          } else {
            return column.render({
              rowData, // the original row data object
              rowIndex, // the index of the row in the table
              cellJsx, // the (search) formatted JSX element of the cell
              cellValue, // the original cell value
            });
          }
        },
        onHeaderCell: () => ({
          // props we want to pass to the header cell
          sortProperty,
          sortingEnabled,
          hideHeaderContent,

          // if column has an onHeaderCell function, call it and pass the result to the header cell (takes precedence)
          ...(column.onHeaderCell ? column.onHeaderCell() : {}),
        }),
      };
    });
    setModifiedColumnProperties(newProps);
  }, [currentSortProperty, currentSortOrder, columns]);

  return modifiedColumnProperties;
};

export default useModifyColumnProperties;
