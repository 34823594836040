import Button from 'legacy/shared/styles/custom/Button';

const ModalCancelButton = ({ handleClick, buttonText = 'Cancel' }) => (
  <>
    <Button onClick={handleClick} withRightSpacer default type="button">
      {buttonText}
    </Button>
  </>
);

export default ModalCancelButton;
