import { useEffect } from 'react';

import WcpTable from 'legacy/shared/controls/WcpTable/v2/WcpTable';
import { getUserNameDisplayFromUserList } from 'legacy/features/recipientGroups/utilities/getUserNameDisplayFromUserList';
import { manageRecipientGroupsSummaryTableColumns } from 'legacy/features/recipientGroups/components/ManageRecipientGroupsSummary/tables/ManageRecipientGroupsSummaryTableColumns';

const ManageRecipientGroupsSummaryTable = ({ recipientGroups, handleUpdateListingCount }) => {
  useEffect(() => {
    recipientGroups?.forEach((group) => {
      group.formattedUserDisplayNames = getUserNameDisplayFromUserList(group.recipientGroupMembers);
    });
  }, [recipientGroups]);

  return (
    <WcpTable
      tableClassName="table-simple"
      columns={manageRecipientGroupsSummaryTableColumns}
      tableSourceData={recipientGroups ? recipientGroups : []}
      emptyText="No recipient groups are currently associated with this organization"
      setListingCount={handleUpdateListingCount}
    />
  );
};

export default ManageRecipientGroupsSummaryTable;
