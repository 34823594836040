import { v4 as uuidv4 } from 'uuid';

import { BackendValidationError } from 'legacy/core/error/classes/BackendValidationError';
import { alertRecipientType } from 'legacy/features/alerts/data/enums/alertRecipientType';
import { alertRecipientMapping } from 'legacy/features/alerts/services/mapping/definitions/alertRecipientMapping';
import { mapToFrontend, mapToBackend } from 'legacy/shared/utilities/helpers/mapping/mapper';
import { alertMethodConstants } from 'legacy/features/alerts/data/enums/alertMethodConstants';

export const alertNotificationMapping = {
  user_group_id: {
    frontendProperty: 'recipientGroup.recipientGroupId',
  },
  user_group_name: {
    frontendProperty: 'recipientGroup.recipientGroupName',
  },
  user_list: {
    frontendProperty: 'alertRecipients',
    transformValueToFrontend: (value) => value.map((v) => mapToFrontend(v, alertRecipientMapping)),
    transformValueToBackend: (value) => value.map((v) => mapToBackend(v, alertRecipientMapping)),
  },
  cooldown_minutes: {
    frontendProperty: 'cooldownMinutes',
  },
  delay_minutes: {
    frontendProperty: 'delayMinutes',
  },
  is_location_included: {
    frontendProperty: 'isLocationIncluded',
  },
  child_alert_id: {
    frontendProperty: 'childAlertId',
  },

  decorateFrontend: (notification) => {
    // set guid
    notification.alertNotificationGuid = uuidv4();

    // recipient group
    if (notification.recipientGroup.recipientGroupId) {
      // if there is no recipientGroupName, throw an error
      // if (!notification.recipientGroup.recipientGroupName) {
      //   throw new BackendValidationError(
      //     `No recipient group name recieved for recipient group ID: ${notification.recipientGroup.recipientGroupId}`,
      //   );
      // }

      // get all alert methods
      const allAlertMethods = new Set(
        notification.alertRecipients.map((r) => r.recipientUserAlertMethod),
      );

      // replace the alert recipients with the a list of one recipient group
      notification.alertRecipientRows = [
        {
          recipientRowGuid: uuidv4(),
          recipientRowAlertMethod:
            allAlertMethods.size === 1
              ? allAlertMethods.values().next().value
              : alertMethodConstants.CUSTOM,
          recipientGroup: {
            recipientGroupId: notification.recipientGroup.recipientGroupId,
            recipientGroupName: notification.recipientGroup.recipientGroupName,
            recipientGroupMembers: notification.alertRecipients,
          },
        },
      ];
    } else {
      // individual recipients are nested to match structure of recipient groups
      notification.alertRecipientRows = notification.alertRecipients.map((r) => ({
        recipientRowGuid: uuidv4(),
        recipientRowAlertMethod: r.recipientUserAlertMethod,
        recipientUser: {
          recipientUserName: r.recipientUserName,
          recipientUserFullName: r.recipientUserFullName,
          recipientUserAlertMethod: r.recipientUserAlertMethod,
        },
      }));
    }

    // cleanup
    delete notification.alertRecipients;
    // delete notification.recipientGroup;
    return notification;
  },

  preBackendTransform: (notification) => {
    notification.recipientGroup = {
      recipientGroupId: null,
    };
    // map recipients
    notification.alertRecipients = notification.alertRecipientRows
      .reduce((acc, row) => {
        if (row.recipientGroup) {
          notification.recipientGroup = {
            recipientGroupId: row.recipientGroup.recipientGroupId,
            recipientGroupName: row.recipientGroup.recipientGroupName,
          };
          return [...acc, ...row.recipientGroup.recipientGroupMembers];
        } else {
          return [...acc, row.recipientUser];
        }
      }, [])
      .filter(Boolean);

    return notification;
  },
};
