import {
  organizationPath,
  organizationFSEsPath,
  associateFSEstoOrganizationPath,
  disassociateFSEFromOrganizationPath,
} from 'legacy/shared/v1/constants/api';
import { apiCall } from 'legacy/core/api/API';
import { undecorateOrganizationObject } from 'legacy/core/decorators/organization';

export const getOrganizations = async ({ organizationIds = [] }) => {
  const queryStringParameters =
    organizationIds.length > 0
      ? {
          organization: `[${organizationIds.map((organizationId) => `${organizationId}`).join(',')}]`,
        }
      : null;
  return await apiCall({
    endpoint: 'cloudfrontAPI',
    path: organizationPath,
    method: 'get',
    queryStringParameters,
  });
};

export const getOrganization = async (organizationId) => {
  return await apiCall({
    endpoint: 'cloudfrontAPI',
    path: `/organization/${organizationId}`,
    method: 'get',
  });
};

export const associateFSEsToOrganization = async ({ FSEs, organizationId }) => {
  return await apiCall({
    endpoint: 'cloudfrontAPI',
    path: associateFSEstoOrganizationPath(organizationId),
    body: {
      associated_fse: FSEs,
    },
    method: 'post',
  });
};

export const getAssociatedFSEData = async ({ organizationId, username }) => {
  return await apiCall({
    endpoint: 'cloudfrontAPI',
    method: 'get',
    path: `/organization/${organizationId}/associated_fse`,
    queryStringParameters: {
      username: username,
    },
  });
};

export const disassociateFSEFromOrganization = async ({ username, organizationId }) => {
  return await apiCall({
    endpoint: 'cloudfrontAPI',
    path: disassociateFSEFromOrganizationPath({ username, organizationId }),
    method: 'del',
  });
};

export const fetchOrganizationFSEs = async (organizationId) => {
  return await apiCall({
    endpoint: 'cloudfrontAPI',
    path: organizationFSEsPath(organizationId),
    method: 'get',
  });
};

export const savePermissions = async ({ organizationId, permissions }) => {
  return await apiCall({
    endpoint: 'cloudfrontAPI',
    path: `/organization/${organizationId}/permissions`,
    method: 'put',
    body: permissions,
  });
};

export const restorePermissions = async ({ organizationId }) => {
  return await apiCall({
    endpoint: 'cloudfrontAPI',
    path: `/organization/${organizationId}/resetpermissions`,
    method: 'put',
  });
};
