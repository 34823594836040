import React, { useState, useEffect } from 'react';
import Table from 'rc-table';
import { SORT_ORDER } from 'legacy/shared/constants/table';
import { tableColumnSort } from 'legacy/shared/utilities/misc/table';
import { SortArrowSpan } from 'legacy/shared/styles/custom/Table';

// custom header cell component that wraps/overwrites default table header cell (see: Table -> components prop line 56)
const SortableHeaderCell = ({ children, isSorted, sortOrder, sortIndex, style, handleSort }) => {
  const onClick = sortIndex && (() => handleSort(sortIndex));
  const sortArrow =
    sortOrder === SORT_ORDER.ASC ? String.fromCharCode(9650) : String.fromCharCode(9660);

  return (
    <th onClick={onClick} style={style}>
      {children}
      <SortArrowSpan isSorted={isSorted}>{sortArrow}</SortArrowSpan>
    </th>
  );
};

const SortableTableWithoutPagination = ({
  style,
  className,
  rowClassName,
  columns,
  data,
  onRow,
  rowKey,
  scroll,
  children,
  components,
  emptyText,
}) => {
  const [sortedData, updateSortedData] = useState({
    data,
    index: null,
    order: null,
    isSorted: false,
  });
  const handleSort = (sortIndex) => {
    const { data, order, index } = sortedData;
    let sortOrder;

    if (sortIndex === index) {
      // if we are clicking on a column thats already been sorted, we want to sort it using the reverse order
      sortOrder = order === SORT_ORDER.ASC ? SORT_ORDER.DESC : SORT_ORDER.ASC;
    } else {
      sortOrder = SORT_ORDER.ASC;
    }

    const newSortedData = tableColumnSort({
      data,
      sortIndex,
      order: sortOrder,
    });
    updateSortedData({
      data: newSortedData,
      order: sortOrder,
      index: sortIndex,
      isSorted: true,
    });
  };

  const configureCustomHeader = (column) => {
    return {
      ...column,
      onHeaderCell: (record) => {
        const prevOnHeaderCell = column.onHeaderCell ? column.onHeaderCell(record) : {};
        if (!column.dataIndex) return prevOnHeaderCell;

        const style = prevOnHeaderCell.style
          ? { ...prevOnHeaderCell.style, cursor: 'pointer' }
          : { cursor: 'pointer' };

        return {
          ...prevOnHeaderCell,
          style,
          handleSort,
          sortIndex: record.dataIndex,
          isSorted: record.dataIndex === sortedData.index,
          sortOrder: sortedData.order,
        };
      },
    };
  };

  // override columns and use existing/mandatory column props to pass in a sortIndex value and local handleSort function
  const sortableColumns = (() => {
    // if child column components are passed convert them to columns
    if (children) {
      return React.Children.map(
        children,
        (child) =>
          child &&
          child.props.hasOwnProperty('title') &&
          child.props.hasOwnProperty('dataIndex') &&
          configureCustomHeader(child.props),
      );
    }
    return columns.map((column) => configureCustomHeader(column));
  })();

  useEffect(() => {
    const { index, order } = sortedData;
    updateSortedData({ ...sortedData, data: tableColumnSort({ data, sortIndex: index, order }) });
  }, [data]);

  useEffect(() => {
    // default sort by first column
    if (!sortedData.isSorted) {
      const defaultSortIndex = sortableColumns.find((c) => c.dataIndex && c.title).dataIndex;
      handleSort(defaultSortIndex);
    }
  }, [sortedData.isSorted]);

  return (
    <Table
      style={style}
      className={className}
      rowClassName={rowClassName}
      columns={sortableColumns}
      data={sortedData.data}
      onRow={onRow}
      rowKey={rowKey}
      scroll={scroll}
      emptyText={emptyText}
      components={{
        ...components,
        header: {
          ...(components && components.header),
          ...{ cell: SortableHeaderCell },
        },
      }}
    />
  );
};

export default SortableTableWithoutPagination;
