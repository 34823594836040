import { DropdownLink, SimpleDropdownItem } from 'legacy/shared/styles/custom/DropdownMenu';

const ViewActivityLogsMenuItem = ({ username }) => {
  return (
    <SimpleDropdownItem>
      <DropdownLink to={`/activityLogs/${encodeURIComponent(username)}`}>
        View Activity Logs
      </DropdownLink>
    </SimpleDropdownItem>
  );
};

export default ViewActivityLogsMenuItem;
