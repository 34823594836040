import { StyledAccessKeyText } from 'legacy/features/organizations/components/modals/RegenerateGttAccessKeyModal/styles/regenerateGttAccessKeyModalStyles';
import {
  StyledDivModalContent,
  StyledModalText,
} from 'legacy/shared/controls/WcpModal/v2/styles/WcpModalStyles';

const RegenerateGttAccessKeyModalContent = () => {
  return (
    <StyledDivModalContent>
      <StyledModalText>
        Do you really want to generate a new access key? This cannot be undone. You will need to
        replace your old key in the Miovision cloud with the new one that will be generated.
      </StyledModalText>
    </StyledDivModalContent>
  );
};

export default RegenerateGttAccessKeyModalContent;
