import { useMutation, useQueryClient } from '@tanstack/react-query';
import { apiCall } from 'legacy/core/api/API';

const useAssociateFsesMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({ organizationId, fseUsernames }) =>
      await apiCall({
        endpoint: 'cloudfrontAPI',
        method: 'post',
        path: `/organization/${organizationId}/associated_fse`,
        body: {
          // array of fseUsernames
          associated_fse: fseUsernames,
        },
      }),
    onSuccess: () => {
      queryClient.invalidateQueries(['fetchFseUsers']);
    },
  });
};
export default useAssociateFsesMutation;
