import { ASSOCIATE_FSE_MODAL_KEY } from 'legacy/features/organizations/providers/modal/constants/organizationFeatureModalKeys';
import IconSvgComponent from 'legacy/shared/controls/WcpIcon/IconSvgComponent';
import { useWcpModalContext } from 'legacy/shared/controls/WcpModal/v2/WcpModalProvider';
import { ActionButtonText, ActionButtonWithIcon } from 'legacy/shared/styles/custom/Button';
import { styles } from 'legacy/shared/styles/inline/inlineStyles';
import { useCheckUserRole, userRoleKeys } from 'legacy/shared/utilities/hooks/useCheckUserRole';

const AddFseButton = ({ organization }) => {
  // addFSE modal
  const associateFseModalContext = useWcpModalContext(ASSOCIATE_FSE_MODAL_KEY);

  const isRoleAuthorized = useCheckUserRole(userRoleKeys.SYSTEM_ADMIN);

  return (
    isRoleAuthorized && (
      <ActionButtonWithIcon
        orgDetail
        create
        onClick={() => associateFseModalContext.modalActions.openModal({ organization })}
      >
        <ActionButtonText>Add Field Solution Engineer</ActionButtonText>
        <IconSvgComponent
          svgFileName={'plus-simple'}
          style={styles.organizations.buttonPlusIcon}
          alt="Create"
        />
      </ActionButtonWithIcon>
    )
  );
};

export default AddFseButton;
