import { findAlertNotificationSelector } from 'legacy/features/alerts/services/providers/AlertFormsProvider/utilities/findAlertNotificationSelector';
import { validateAndExtractPayload } from 'legacy/shared/utilities/helpers/reducers/validateAndExtractPayload';

export const setAlertRecipientRowsReducer = (state, payload) => {
  const { alertNotificationGuid, alertRecipientRow } = validateAndExtractPayload({
    payload,
    mustInclude: ['alertNotificationGuid', 'alertRecipientRow'],
  });

  const alertNotification = findAlertNotificationSelector(state, alertNotificationGuid);

  alertNotification.alertRecipientRows = [alertRecipientRow];

  state.allRecipientGroups.push(alertRecipientRow.recipientGroup);

  return {
    ...state,
  };
};
