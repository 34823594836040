const emailRegex =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const phoneRegex = /^\+{1}[0-9]{11,13}$/;
export const phoneExtensionRegex = /^\d+$/;
export const dateRegex = /^(0[1-9]|1[012])[- /.](0[1-9]|[12][0-9]|3[01])[- /.](19|20)\d\d$/;
export const passwordRegex = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)[A-Za-z\d\W_]{8,}$/;
export const deviceIdRegex =
  /^[0-9A-Fa-f]{8}-?[0-9A-Fa-f]{4}-?[0-9A-Fa-f]{4}-?[0-9A-Fa-f]{4}-?[0-9A-Fa-f]{12}$/;
export const usernameRegex = /((?!.*?_{2})^[a-z][a-z0-9_]{0,126}[a-z0-9]{1}$)|^[a-z]$/;
export const vinRegex =
  /^(?<wmi>[A-HJ-NPR-Z\d]{3})(?<vds>[A-HJ-NPR-Z\d]{5})(?<check>[\dX])(?<vis>(?<year>[A-HJ-NPR-Z\d])(?<plant>[A-HJ-NPR-Z\d])(?<seq>[A-HJ-NPR-Z\d]{6}))$/;
export const positiveIntegerRegex = /^[1-9]\d*$/;
export const alphanumericExtendedRegex = /^[a-zA-Z0-9 .,'_()-]*$/;
export const asciiRegex = /^[\x00-\x7F]+$/;
export const numericRegex = /^(0|[1-9][0-9]*)$/;
export const wimAccountIdRegex = /^[A-Z0-9]{6}$/;
export const appliedInformationBusinessIdRegex = /^[0-9]{8,8}$/;
