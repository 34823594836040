import React, { useContext } from 'react';
import { PermissionsHeaderButtonDiv } from 'legacy/shared/styles/custom/Button';
import { PageListActionButtonContainer } from 'legacy/shared/styles/custom/PageList';
import { OrgUserPermissionsContextApi } from 'legacy/features/organizations/helpers/context';
import ButtonWithLoader from 'legacy/shared/controls/ButtonWithLoader/ButtonWithLoader';
import { PermissionsHeaderDiv } from 'legacy/shared/styles/custom/Header';

const PermissionsHeader = ({ organization, submittingSave, submittingRestoreDefaults }) => {
  const {
    handleSavePermissions,
    handleCancelPermissionsChangesmissions,
    handleRestorePermissionsConfirmation,
  } = useContext(OrgUserPermissionsContextApi);
  return (
    <PermissionsHeaderDiv>
      <h4 title={organization.organizationName}>
        {'Manage Permissions for ' + organization.organizationName}
      </h4>
      <PageListActionButtonContainer>
        <PermissionsHeaderButtonDiv cancel>
          <ButtonWithLoader
            permissions
            disabled={submittingRestoreDefaults || submittingSave}
            confirmText={'Cancel'}
            loadingStyleProp={'submittingWithSpinner'}
            notLoadingStyleProp={'permissionsCancel'}
            clickHandler={(e) => handleCancelPermissionsChangesmissions()}
          />
        </PermissionsHeaderButtonDiv>
        <PermissionsHeaderButtonDiv>
          <ButtonWithLoader
            disabled={submittingSave}
            isLoading={submittingRestoreDefaults}
            confirmText={'Restore Defaults'}
            loadingStyleProp={'submittingWithSpinner'}
            notLoadingStyleProp={'permissions'}
            clickHandler={(e) => handleRestorePermissionsConfirmation()}
          />
        </PermissionsHeaderButtonDiv>
        <PermissionsHeaderButtonDiv>
          <ButtonWithLoader
            disabled={submittingRestoreDefaults}
            isLoading={submittingSave}
            confirmText={'Save Changes'}
            loadingStyleProp={'submittingWithSpinner'}
            notLoadingStyleProp={'permissions'}
            clickHandler={(e) => handleSavePermissions()}
          />
        </PermissionsHeaderButtonDiv>
      </PageListActionButtonContainer>
    </PermissionsHeaderDiv>
  );
};

export default PermissionsHeader;
