import MaintenanceActionMenu from 'legacy/features/maintenance/components/MaintenanceSummary/menus/MaintenanceActionMenu';
import MaintenanceSummaryCard from 'legacy/features/maintenance/components/MaintenanceSummary/mobile/MaintenanceSummaryCard';
import { Cards } from 'legacy/shared/styles/custom/MobileCard';

const MaintenanceSummaryCardContainer = ({ maintenanceEvents }) => {
  const getActionMenu = (event) => {
    return <MaintenanceActionMenu event={event} />;
  };
  return (
    <div>
      <Cards>
        {maintenanceEvents.map((m) => (
          <MaintenanceSummaryCard
            maintenanceEvent={m}
            key={m.event_id}
            actionMenuComponent={() => getActionMenu(m)}
          />
        ))}
      </Cards>
    </div>
  );
};

export default MaintenanceSummaryCardContainer;
