import { useQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getOrganization } from 'legacy/core/api/organizations';
import { decorateOrganization } from 'legacy/core/decorators/organization';
import { logWcpError } from 'legacy/core/error/utilities/ErrorLogger';
import { organizationFetchError } from 'legacy/features/organizations/helpers/notifications';

// TODO - deprecated use useFetchOrganizationQuery instead (passing filteredOrganizationId)
export const useFetchOrganizationQueryForMaintenance = ({ enabled = true } = {}) => {
  const filteredOrganizationId = useSelector((state) => state.user.filteredOrganizationId);
  const dispatch = useDispatch();
  const [organization, setOrganization] = useState(null);
  const fetchOrganizationQuery = useQuery({
    queryKey: ['fetchOrganizationQuery'],
    queryFn: () => getOrganization(filteredOrganizationId),
    enabled,
    select: ({ response: { message } }) => {
      return message;
    },
    onError: (err) => {
      logWcpError(err);
      dispatch(organizationFetchError({ err }));
    },
  });

  useEffect(() => {
    if (fetchOrganizationQuery.data && fetchOrganizationQuery.data.length > 0)
      setOrganization(decorateOrganization(fetchOrganizationQuery.data[0]));
  }, [fetchOrganizationQuery.data]);

  return [fetchOrganizationQuery, organization];
};
