import { SimpleDropdownBtn, SimpleDropdownItem } from 'legacy/shared/styles/custom/DropdownMenu';

const EditAppliedInformationBusinessIdMenuItem = ({ partnership, onMenuItemClick }) => {
  return (
    <SimpleDropdownItem>
      <SimpleDropdownBtn onClick={onMenuItemClick}>Edit Business ID</SimpleDropdownBtn>
    </SimpleDropdownItem>
  );
};

export default EditAppliedInformationBusinessIdMenuItem;
