import { useReducer } from 'react';

import { getEnableVehicleMapNamesInLocal } from 'legacy/shared/utilities/misc/localStore';
import { getPerformanceModePreferenceInLocal } from 'legacy/shared/utilities/misc/localStore';

const actionTypes = {
  MAPBOX_LOADED: 'MAPBOX_LOADED',
  SET_PERFORMANCE_MODE: 'SET_PERFORMANCE_MODE',
  SET_LIVEMAP_SIDEBAR_STATES: 'SET_LIVEMAP_SIDEBAR_STATES',
  SET_SELECTED_VEHICLE: 'SET_SELECTED_VEHICLE',
  SET_FLY_TO_COORDINATES: 'SET_FLY_TO_COORDINATES',
  SET_IS_FULL_SCREEN_MODE: 'SET_IS_FULL_SCREEN_MODE',
  SET_LAYER_VISIBILITY_OPTIONS: 'SET_LAYER_VISIBILITY_OPTIONS',
  SET_INCOMING_VEHICLES: 'SET_INCOMING_VEHICLES',
  SET_FILTERED_VEHICLES: 'SET_FILTERED_VEHICLES',
  SET_FOCUSED_ZOOM_LEVEL: 'SET_FOCUSED_ZOOM_LEVEL',
};

const initialState = {
  selectedVehicle: null,
  isFullScreenMode: false,
  isPerformanceMode: getPerformanceModePreferenceInLocal(),
  isMapboxLoaded: false,

  livemapMainSidebarOpen: false,
  livemapGeofencesSidebarOpen: false,
  livemapAddonsSidebarOpen: false,

  allVehicles: null,
  filteredVehicles: [],
  hiddenVehicleIds: [],
  visibleMapVehicles: null,

  flyToCoordinates: [],
  focusedZoomLevel: null,

  // TODO turn into an options object
  vehicleMapNamesVisible: getEnableVehicleMapNamesInLocal(),
};

const livemapReducer = (state, action) => {
  switch (action.type) {
    case actionTypes.MAPBOX_LOADED: {
      return { ...state, isMapboxLoaded: action.payload.isMapboxLoaded };
    }
    case actionTypes.SET_PERFORMANCE_MODE: {
      return { ...state, isPerformanceMode: action.payload.isPerformanceMode };
    }

    case actionTypes.SET_IS_FULL_SCREEN_MODE: {
      return { ...state, isFullScreenMode: action.payload.isFullscreen };
    }

    case actionTypes.SET_FLY_TO_COORDINATES: {
      return { ...state, flyToCoordinates: action.payload.coordinates };
    }

    case actionTypes.SET_FOCUSED_ZOOM_LEVEL: {
      return { ...state, focusedZoomLevel: action.payload.focusedZoomLevel };
    }

    case actionTypes.SET_LIVEMAP_SIDEBAR_STATES: {
      const { livemapMainSidebarOpen, livemapGeofencesSidebarOpen, livemapAddonsSidebarOpen } =
        action.payload ?? {};

      return {
        ...state,
        livemapMainSidebarOpen: livemapMainSidebarOpen ?? state.livemapMainSidebarOpen,
        livemapGeofencesSidebarOpen:
          livemapGeofencesSidebarOpen ?? state.livemapGeofencesSidebarOpen,
        livemapAddonsSidebarOpen: livemapAddonsSidebarOpen ?? state.livemapAddonsSidebarOpen,
      };
    }

    case actionTypes.SET_LAYER_VISIBILITY_OPTIONS: {
      return { ...state, vehicleMapNamesVisible: action.payload.vehicleNamesVisible };
    }

    case actionTypes.SET_INCOMING_VEHICLES: {
      let { incomingVehicles } = action.payload;

      return {
        ...state,
        allVehicles: incomingVehicles === undefined ? state.allVehicles : incomingVehicles,
      };
    }

    case actionTypes.SET_SELECTED_VEHICLE: {
      let { selectedVehicleId } = action.payload;

      let selectedVehicle =
        selectedVehicleId === undefined
          ? state.selectedVehicle
          : state.allVehicles.find((v) => v.vehicle_id === selectedVehicleId);

      return {
        ...state,
        selectedVehicle,
        flyToCoordinates: selectedVehicle
          ? [selectedVehicle.gps.longitude, selectedVehicle.gps.latitude]
          : [],
      };
    }

    case actionTypes.SET_FILTERED_VEHICLES: {
      let { filteredVehicles, hiddenVehicleIds } = action.payload;

      filteredVehicles = filteredVehicles === undefined ? state.filteredVehicles : filteredVehicles;
      hiddenVehicleIds = hiddenVehicleIds === undefined ? state.hiddenVehicleIds : hiddenVehicleIds;
      let visibleMapVehicles = !_.isEmpty(hiddenVehicleIds)
        ? filteredVehicles.filter((v) => !hiddenVehicleIds.includes(v.vehicle_id))
        : filteredVehicles;

      let newVehicleState = {
        filteredVehicles,
        hiddenVehicleIds,
        visibleMapVehicles,
      };

      return {
        ...state,
        ...newVehicleState,
      };
    }

    default: {
      throw Error(`Unknown action: ${JSON.stringify(action)}`);
    }
  }
};

const getActions = (state, dispatch) => ({
  setIncomingMapVehicles: (vehicles) =>
    dispatch({
      type: actionTypes.SET_INCOMING_VEHICLES,
      payload: { incomingVehicles: vehicles },
    }),

  setMapboxLoaded: (isMapboxLoaded) =>
    dispatch({ type: actionTypes.MAPBOX_LOADED, payload: { isMapboxLoaded } }),

  setPerformanceMode: (isPerformanceMode) =>
    dispatch({ type: actionTypes.SET_PERFORMANCE_MODE, payload: { isPerformanceMode } }),

  setFilteredMapVehicles: (filteredVehicles) =>
    dispatch({ type: actionTypes.SET_FILTERED_VEHICLES, payload: { filteredVehicles } }),

  setSelectedVehicle: (id) =>
    dispatch({
      type: actionTypes.SET_SELECTED_VEHICLE,
      payload: { selectedVehicleId: id },
    }),

  setHiddenVehicleIds: (hiddenVehicleIds) =>
    dispatch({ type: actionTypes.SET_FILTERED_VEHICLES, payload: { hiddenVehicleIds } }),

  setLivemapSidebarStates: ({
    livemapMainSidebarOpen,
    livemapGeofencesSidebarOpen,
    livemapAddonsSidebarOpen,
  }) =>
    dispatch({
      type: actionTypes.SET_LIVEMAP_SIDEBAR_STATES,
      payload: {
        livemapMainSidebarOpen,
        livemapGeofencesSidebarOpen,
        livemapAddonsSidebarOpen,
      },
    }),

  setFullScreenMode: (isFullscreen) =>
    dispatch({ type: actionTypes.SET_IS_FULL_SCREEN_MODE, payload: { isFullscreen } }),

  setFlyToCoordinates: (coordinates) =>
    dispatch({ type: actionTypes.SET_FLY_TO_COORDINATES, payload: { coordinates } }),

  setFocusedZoomLevel: (focusedZoomLevel) =>
    dispatch({ type: actionTypes.SET_FOCUSED_ZOOM_LEVEL, payload: { focusedZoomLevel } }),

  setLayerVisibilityOptions: ({ vehicleNamesVisible }) => {
    return dispatch({
      type: actionTypes.SET_LAYER_VISIBILITY_OPTIONS,
      payload: { vehicleNamesVisible },
    });
  },
});

// custom hook that returns the state and actions for the livemap feature
const useLivemapReducer = () => {
  const [state, dispatch] = useReducer(livemapReducer, initialState);

  const actions = getActions(state, dispatch);

  return [state, actions];
};

export default useLivemapReducer;
