import { SimpleDropdownBtn, SimpleDropdownItem } from 'legacy/shared/styles/custom/DropdownMenu';

const SimpleMenuItem = ({ title, onClick }) => {
  return (
    <SimpleDropdownItem>
      <SimpleDropdownBtn onClick={onClick}>{title}</SimpleDropdownBtn>
    </SimpleDropdownItem>
  );
};

export default SimpleMenuItem;
