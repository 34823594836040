import AnimatedField from 'legacy/shared/controls/AnimatedField/v1/AnimatedField';

const ReminderMileageField = ({
  formikProps: { values, touched, errors, handleChange, handleBlur },
}) => {
  return (
    <div>
      <AnimatedField
        name="reminderMileage"
        placeholder="Mileage"
        value={values.reminderMileage}
        touched={touched.reminderMileage}
        validationError={errors.reminderMileage}
        handleChange={handleChange}
        handleBlur={handleBlur}
        disabled={false}
        required
      />
    </div>
  );
};

export default ReminderMileageField;
