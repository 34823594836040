import OrganizationUserTableActionMenu from 'legacy/features/organizations/components/menus/OrganizationUserTableActionMenu';
import Badge from 'legacy/shared/styles/custom/Badge';
import { TableSpinner } from 'legacy/shared/styles/custom/Spinner';
import { TableAvatar } from 'legacy/shared/styles/custom/TableAvatar';

const useOrganizationUserTableColumns = ({ organization }) => {
  return [
    {
      title: ' ',
      displayProperty: 'avatar',
      searchEnabled: false,
      width: '50px',
      render({ rowData }) {
        return (
          <span className="userNameAndIcon">
            {rowData.loadingStatus ? (
              <TableSpinner className="spinner-margin" />
            ) : (
              <TableAvatar profilePicture={rowData.avatar} />
            )}
          </span>
        );
      },
    },
    {
      displayProperty: 'username',
    },
    {
      displayProperty: 'firstName',
    },
    {
      displayProperty: 'lastName',
    },
    {
      title: 'Title',
      displayProperty: 'userTitle',
    },
    {
      title: 'Contact Email',
      displayProperty: 'email',
    },
    {
      title: 'Role',
      displayProperty: 'userRole',
    },
    {
      title: 'Status',
      displayProperty: 'isActive',
      render({ rowData }) {
        return (
          <Badge active={rowData.isActive}>
            <span title="Status">{rowData.isActive ? 'Enabled' : 'Disabled'}</span>
          </Badge>
        );
      },
    },
    {
      title: '',
      className: 'action',
      searchEnabled: false,
      render: ({ rowData }) => {
        return <OrganizationUserTableActionMenu user={rowData} organization={organization} />;
      },
    },
  ];
};
export default useOrganizationUserTableColumns;
