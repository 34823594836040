import { RemoveButton } from 'legacy/shared/styles/custom/Button';
import IconSvgComponent from 'legacy/shared/controls/WcpIcon/IconSvgComponent';

const RemoveMaintenanceItemContainerButton = ({ handleRemoveMaintenanceItem }) => {
  return (
    <RemoveButton type="button" lineItem title="Remove">
      <IconSvgComponent
        svgFileName={'remove-feature'}
        alt="Remove"
        onClick={handleRemoveMaintenanceItem}
      />
    </RemoveButton>
  );
};

export default RemoveMaintenanceItemContainerButton;
