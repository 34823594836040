import { mapUserDataToCsv } from 'legacy/features/organizations/helpers/mapping';
import ExportCsvButton from 'legacy/shared/controls/ExportCsvButton/ExportCsvButton';

const UserListExportButton = ({ users, organizationName }) => {
  const exportData = mapUserDataToCsv({ users });
  return (
    <ExportCsvButton
      exportData={exportData}
      fileNamePrefix={`wcp_user_list_export_for_ ${organizationName}`}
    />
  );
};

export default UserListExportButton;
