import { getDefaultEndTime } from 'legacy/features/playback/state/provider/utilities/getDefaultEndTime';
import { getDefaultStartTime } from 'legacy/features/playback/state/provider/utilities/getDefaultStartTime';
import { mergePayloadWithState } from 'legacy/shared/utilities/helpers/reducers/mergePayloadWithState';

/*
  This reducer is used to set the playback request form data to the state.
*/
export const setPlaybackRequestFormReducer = (state, payload) => {
  let { selectedVehicleId } = payload;

  const playbackRequestForm = mergePayloadWithState(payload, state.playbackRequestForm);

  // Get selected vehicle
  const selectedVehicle = state.allVehicles?.find((v) => v.vehicle_id === selectedVehicleId);

  // form defaults

  // set default start time
  playbackRequestForm.startTime = getDefaultStartTime(
    playbackRequestForm.startTime,
    playbackRequestForm.readOnly,
  );

  // set default end time
  playbackRequestForm.endTime = getDefaultEndTime(
    playbackRequestForm.endDate,
    playbackRequestForm.endTime,
    playbackRequestForm.readOnly,
  );

  // merge into state if defined otherwise keep the current state
  return {
    ...state,
    playbackRequestForm: {
      ...playbackRequestForm,
    },
    playbackData: {
      ...state.playbackData,
      selectedVehicle: selectedVehicle || state.playbackData.selectedVehicle,
    },
  };
};
