import { useRef } from 'react';

import LivemapContextProvider from 'legacy/features/livemap/provider/LivemapContextProvider';
import LivemapAddonsSidebarContainer from 'legacy/features/livemap/ui/addons/LivemapAddonsSidebarContainer';
import LivemapGeofencesSidebarContainer from 'legacy/features/livemap/ui/geofences/LivemapGeofencesSidebarContainer';
import LivemapHeader from 'legacy/features/livemap/ui/header/LivemapHeader';
import LivemapComponent from 'legacy/features/livemap/ui/map/LivemapComponent';
import LivemapSidebarContainer from 'legacy/features/livemap/ui/sidebar/LivemapSidebarContainer';
import MapVehicleDetailCard from 'legacy/features/livemap/ui/vehicleDetail/MapVehicleDetailCard';
import {
  LivemapContentInnerDiv,
  MapContentDiv,
  SharedMapDiv,
  SidebarAndContentDiv,
} from 'legacy/shared/styles/custom/SharedMapStyledComponents';
import { VehicleDetailCardContainer } from 'legacy/shared/styles/custom/VehicleDetailCard';
import LoadingOverlay from 'legacy/shared/controls/WcpSpinner/LoadingOverlay';

const LivemapContainer = ({ navigateToVehicleId, vehicles, groups, geofenceActivity }) => {
  const livemapRef = useRef(null);
  return (
    <LivemapContextProvider navigateToVehicleId={navigateToVehicleId} vehicles={vehicles}>
      {(
        { setSelectedVehicle },
        { selectedVehicle, isFullScreenMode, filteredVehicles, visibleMapVehicles },
      ) => {
        return (
          <SharedMapDiv ref={livemapRef}>
            <LivemapHeader
              livemapRef={livemapRef}
              filteredVehicles={filteredVehicles}
              vehicles={vehicles}
            />

            <SidebarAndContentDiv>
              <LivemapSidebarContainer groups={groups} />

              <MapContentDiv>
                <LivemapContentInnerDiv>
                  {selectedVehicle && (
                    <VehicleDetailCardContainer>
                      <MapVehicleDetailCard
                        selectedVehicle={vehicles.find(
                          (v) => v.vehicle_id === selectedVehicle.vehicle_id,
                        )}
                        groups={groups}
                        handleRequestClose={() => {
                          setSelectedVehicle(null);
                        }}
                      />
                    </VehicleDetailCardContainer>
                  )}
                  {visibleMapVehicles ? <LivemapComponent /> : <LoadingOverlay />}
                </LivemapContentInnerDiv>
              </MapContentDiv>
              {isFullScreenMode && (
                <LivemapGeofencesSidebarContainer geofenceActivity={geofenceActivity} fullScreen />
              )}
              <LivemapAddonsSidebarContainer />
            </SidebarAndContentDiv>
          </SharedMapDiv>
        );
      }}
    </LivemapContextProvider>
  );
};

export default LivemapContainer;
