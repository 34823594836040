import { useState } from 'react';

import Change2faModal from 'legacy/features/login/Change2faModal';
import { mfaTypes } from 'legacy/shared/constants/users';
import ModalPortal from 'legacy/shared/controls/WcpModal/v1/ModalPortal';
import { ActionButtonText, TextButton } from 'legacy/shared/styles/custom/Button';
import {
  FormLabelAnimated,
  FormReadOnlyValue,
  NonTextFieldContainer,
} from 'legacy/shared/styles/custom/Form';
import { requestSetSelectedMfaMethod } from 'legacy/core/redux/user/actions';
import { useDispatch } from 'react-redux';

const Change2faButton = ({ twoFactorAuthMethod }) => {
  const dispatch = useDispatch();
  const [change2faModalActive, setChange2faModalActive] = useState(false);

  return (
    <div>
      <NonTextFieldContainer>
        <FormLabelAnimated animated>Current Two-Factor Method</FormLabelAnimated>
        <FormReadOnlyValue>
          {twoFactorAuthMethod === mfaTypes.SMS
            ? 'Text / SMS Message'
            : twoFactorAuthMethod === mfaTypes.AUTHENTICATION_APP
              ? 'Authenticator App'
              : 'Not Set'}
        </FormReadOnlyValue>
      </NonTextFieldContainer>
      <TextButton
        type="button"
        onClick={() => {
          dispatch(requestSetSelectedMfaMethod());
          setChange2faModalActive(true);
        }}
      >
        <ActionButtonText>CHANGE TWO-FACTOR</ActionButtonText>
      </TextButton>
      {/* TODO - convert to use modal contract */}
      {change2faModalActive ? (
        <ModalPortal onRequestClose={() => setChange2faModalActive(false)}>
          <Change2faModal onSuccess={() => setChange2faModalActive(false)} />
        </ModalPortal>
      ) : null}
    </div>
  );
};

export default Change2faButton;
