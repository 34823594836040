import { useState } from 'react';

import { useFetchGroupByIdQuery } from 'legacy/features/vehicleGroups/hooks/v1/useFetchGroupByIdQuery';
import { useUpdateGroupHaasMutation } from 'legacy/features/vehicleGroups/hooks/v1/useUpdateGroupHaasMutation';
import { useFetchVehiclesQuery } from 'legacy/features/vehicles/hooks/useFetchVehiclesQuery';
import { haasPublicAlertingDropdownOptions } from 'legacy/shared/constants/vehicle';
import Button from 'legacy/shared/styles/custom/Button';
import { Checkbox } from 'legacy/shared/styles/custom/Checkbox';
import CheckboxInner from 'legacy/shared/styles/custom/CheckboxInner';
import {
  CheckboxTextSpan,
  FormIcon,
  FormSectionLabel,
  FormTitle,
} from 'legacy/shared/styles/custom/Form';
import { ActionsContainer, ModalText } from 'legacy/shared/styles/custom/Modal';
import { TruncatedText } from 'legacy/shared/styles/custom/TruncatedText';
import {
  CheckboxWrapperDiv,
  HaasIntegrationWrapperDiv,
} from 'legacy/shared/styles/custom/VehicleEdit';
import { HaasOptionWrapper } from 'legacy/shared/styles/custom/VehicleEdit';
import ButtonWithLoader from 'legacy/shared/controls/ButtonWithLoader/ButtonWithLoader';
import SelectField from 'legacy/shared/controls/SelectField/SelectField';
import IconSvgComponent from 'legacy/shared/controls/WcpIcon/IconSvgComponent';
import CustomModal from 'legacy/shared/controls/WcpModal/v1/CustomModal';
import LoadingOverlay from 'legacy/shared/controls/WcpSpinner/LoadingOverlay';

export const ManageHaasIntegrationModal = ({
  modalData: { show, groupId },
  handleCloseModal,
  group,
}) => {
  const [fetchVehiclesQuery, vehicles] = useFetchVehiclesQuery({
    autoRun: show,
    apiFlags: {
      addons: false,
      cell: false,
      device: false,
      gps: false,
      meta: true,
      maintenance: false,
      obd: false,
    },
  });
  const updateGroupHaasMutation = useUpdateGroupHaasMutation({ groupName: group?.groupName });

  const [haasIntegrationEnabled, setHaasIntegrationEnabled] = useState(false);
  const [haasPublicAlerting, setHaasPublicAlerting] = useState(null);

  return (
    show &&
    (fetchVehiclesQuery.isFetching ? (
      <LoadingOverlay />
    ) : (
      <CustomModal
        customWidth={550}
        title={
          <>
            <FormIcon>
              <IconSvgComponent svgFileName="edit-pencil-blue" alt="Edit" />
            </FormIcon>
            <FormTitle>{'Manage HAAS Integration'}</FormTitle>
          </>
        }
        handleCloseModal={handleCloseModal}
      >
        <ModalText>
          <HaasIntegrationWrapperDiv>
            <FormSectionLabel>HAAS Integration</FormSectionLabel>
            <CheckboxWrapperDiv>
              <Checkbox singleCheck>
                <CheckboxInner
                  isChecked={haasIntegrationEnabled}
                  onClick={() => {
                    setHaasIntegrationEnabled(!haasIntegrationEnabled);
                  }}
                />
              </Checkbox>
              <CheckboxTextSpan>Enable HAAS Integration</CheckboxTextSpan>
            </CheckboxWrapperDiv>
            <HaasOptionWrapper>
              <SelectField
                name="haasPublicAlerting"
                placeholder={'Public Alerting'}
                disabled={!haasIntegrationEnabled}
                onChange={(e) => setHaasPublicAlerting(e.value)}
                isClearable={false}
                isSearchable={false}
                options={haasPublicAlertingDropdownOptions}
              />
            </HaasOptionWrapper>
          </HaasIntegrationWrapperDiv>
          Enabling HAAS integration/public alerting will affect all vehicles in the following group:
          <TruncatedText style={styles.groupNameText}>{group.groupName}</TruncatedText>
          Note: This checkbox does not not reflect the state of the HAAS setting for individual
          vehicles. It is used to enable/disable HAAS integration across all vehicles in the group.
        </ModalText>
        <ActionsContainer>
          <Button
            onClick={async () => {
              setHaasIntegrationEnabled(false), handleCloseModal();
            }}
            withRightSpacer
            default
            type="button"
          >
            Cancel
          </Button>
          <ButtonWithLoader
            isLoading={updateGroupHaasMutation.isPending}
            confirmText={'Submit'}
            loadingStyleProp={'submittingWithSpinnerModal'}
            notLoadingStyleProp={'mediumAlt'}
            clickHandler={async () => {
              await updateGroupHaasMutation.mutateAsync({
                groupId,
                haasIntegrationEnabled,
                haasPublicAlerting,
              });
              setHaasIntegrationEnabled(false);
              handleCloseModal();
            }}
          />
        </ActionsContainer>
      </CustomModal>
    ))
  );
};

export default ManageHaasIntegrationModal;

const styles = {
  formHead: {
    marginBottom: 42,
  },
  actionContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    marginTop: 10,
  },
  groupNameText: {
    fontWeight: 'bold',
    marginTop: 10,
    marginBottom: 30,
  },
};
