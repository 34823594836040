import { TextButton } from 'legacy/shared/styles/custom/Button';
import { MobileRemoveLineItemButtonWrapper } from 'legacy/shared/styles/custom/VehicleAddons';

const MobileRemoveMaintenanceItemButton = ({ handleRemoveMaintenanceItem }) => {
  return (
    <MobileRemoveLineItemButtonWrapper>
      <TextButton
        type="button"
        alt="Remove Maintenance Event"
        largeFont
        onClick={handleRemoveMaintenanceItem}
      >
        Remove Maintenance Event
      </TextButton>
    </MobileRemoveLineItemButtonWrapper>
  );
};

export default MobileRemoveMaintenanceItemButton;
