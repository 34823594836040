import styled from 'styled-components';

import colors from 'legacy/shared/constants/colors';

export const StyledModalsWrapper = styled.div``;

export const StyledModalText = styled.div`
  padding-right: 24px;
  margin-bottom: 24px;
  font-size: 14px;
  letter-spacing: 1;
  color: ${colors.haze};

  ${(props) =>
    props.smallHeight &&
    `
    margin-bottom: 0px;
  `};
`;

export const StyledDivModalContainer = styled.div`
  font-family: Muli, sans-serif;
  background-color: #ffffff;
  z-index: 1;
  position: relative !important;
  left: auto !important;
  right: auto !important;
  top: auto !important;
  bottom: auto !important;
  max-width: 680px;
  flex: 1 1 680px;
  margin: auto;
  padding: 48px 25px 20px 45px !important;
  background: #fff;
  box-shadow: 0 41px 51px rgba(0, 0, 0, 0.27);
  border-radius: 2px !important;
  border: none !important;

  ${(props) =>
    props.width &&
    `
    max-width: ${props.width};
  `}

  ${(props) =>
    props.height && props.height === 'auto'
      ? `height: auto; max-height: none;`
      : `max-height: ${props.height};`}
`;

export const StyledDivModalPortalContent = styled.div`
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 99999;
  justify-content: center;
  align-items: center;
  overflow-y: auto;
  overflow-x: hidden;
`;
export const StyledDivModalPortalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.2;
  background-color: #211f50;
  overflow: auto;
`;

export const StyledDivModalContent = styled.div`
  margin-top: 10px;
  margin-bottom: 10px;

  ${(props) =>
    props.marginTop &&
    `
     margin-top: ${props.marginTop};
  `}
`;

export const StyledDivActionsContainer = styled.div`
  display: flex;
  justify-content: end;
  align-items: center;
  flex-wrap: wrap;

  ${(props) =>
    props.topSpacing &&
    `
    margin-top:50px;
  `};

  button {
    margin-top: 10px;
    margin-bottom: 10px;
  }
`;

export const StyledDivModalCloseButton = styled.div`
  cursor: pointer;
  margin-top: -30px;
  margin-right: -5px;
`;

export const StyledH3ModalTitle = styled.h3`
  display: flex;
  align-items: center;
  color: ${colors.midnight};
  font-size: 24px;
  width: 100%;
  padding-left: 0px;
  ${(props) =>
    props.noIcon &&
    `
    padding-left:25px;
  `};

  ${(props) =>
    props.confirmationModal &&
    `
    margin-bottom: 42px;
  `};

  ${(props) =>
    props.smallHeight &&
    `
    margin-bottom: 20px;
  `};
`;
export const StyledDivModalIcon = styled.div`
  margin-right: 10px;
`;
export const StyledModalHeader = styled.div`
  display: flex;
`;
