/**
 * @ External Dependencies
 */
import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { getEnv } from 'legacy/shared/v1/utilities/url';
import { throttle } from 'lodash';

/**
 * @ Reducers
 */
import ui from './ui/reducer';
import sidebar from './sidebar/reducer';
import search from './search/reducer';
import user from './user/reducer';
import vehicles from './vehicles/reducer';
import organizations from './organizations/reducer';
import users from './users/reducer';
import configurations from './configurations/reducer';
import whitelists from './whitelists/reducer';

/**
 * @ Actions
 */
import { successLogout } from 'legacy/core/redux/user/actions';

import {
  getSubscriptionExpiryAlertBarDismissedInLocalStorage,
  setFilteredOrgIdInLocal,
  setSubscriptionExpiryAlertBarDismissedInLocalStorage,
} from 'legacy/shared/utilities/misc/localStore';

/**
 * @ Root Reducer
 */

const appReducer = combineReducers({
  ui,
  sidebar,
  search,
  user,
  vehicles,
  organizations,
  users,
  configurations,
  whitelists,
  cached: () => ({
    subscriptionExpiryAlertBarDismissed: getSubscriptionExpiryAlertBarDismissedInLocalStorage(),
  }),
});

//https://stackoverflow.com/a/35641992
const rootReducer = (state, action) => {
  if (action.type === successLogout().type) {
    state.user.filteredOrganizationId = null; // remove the filtered org id from the user state
    state = undefined; // this doesn't seem to be working
    setFilteredOrgIdInLocal(null);
  }

  return appReducer(state, action);
};

const composeEnhancers =
  (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({ trace: true, traceLimit: 25 })) ||
  compose;
const withReduxDevTools = composeEnhancers(applyMiddleware(thunkMiddleware));

const withoutReduxDevTools = applyMiddleware(thunkMiddleware);

const middleware = () => {
  switch (getEnv()) {
    case 'app':
      return withoutReduxDevTools;
    case 'staging':
    case 'appdev':
    case 'dev':
    case 'test':
    case 'localhost:3000':
      return withReduxDevTools;
    default:
      return withoutReduxDevTools;
  }
};

let persistedState = {
  cached: {
    subscriptionExpiryAlertBarDismissed: getSubscriptionExpiryAlertBarDismissedInLocalStorage(),
  },
};

let store = createStore(rootReducer, persistedState, middleware());

store.subscribe(
  throttle(() => {
    let state = store.getState();

    let seAlertBar = state.ui.subscriptionExpiryAlertBar;
    setSubscriptionExpiryAlertBarDismissedInLocalStorage(seAlertBar ? seAlertBar.dismissed : null);
  }),
  1000,
);

export default store;
