import useDevicesAndBillingSummaryProviderContext from 'legacy/features/billing/services/providers/hooks/useDevicesAndBillingSummaryProviderContext';
import SubscriptionSummaryHeader from 'legacy/features/billing/ui/components/DevicesAndBillingSummary/SubscriptionSummaryHeader';
import DevicesAndBillingExportButton from 'legacy/features/billing/ui/components/DevicesAndBillingSummary/buttons/DevicesAndBillingExportButton';
import DevicesSummaryFilterCheckbox from 'legacy/features/billing/ui/components/DevicesAndBillingSummary/checkboxes/DevicesSummaryFilterCheckbox';
import DevicesAndBillingSummaryCardContainer from 'legacy/features/billing/ui/components/DevicesAndBillingSummary/mobile/DevicesAndBillingSummaryCardContainer';
import SubscriptionAlertBox from 'legacy/features/billing/ui/components/DevicesAndBillingSummary/widgets/SubscriptionAlertBox';
import DevicesAndBillingSummaryTable from 'legacy/features/billing/ui/components/tables/DevicesAndBillingSummaryTable';
import { useState } from 'react';
import ListingCount from 'legacy/shared/controls/ListingCount/ListingCount';
import MediaWrapper from 'legacy/shared/controls/WcpMediaWrapper/v1/MediaWrapper';

import { ExportDiv, ExportWrapper } from 'legacy/shared/styles/custom/PageList';
import LoadingOverlay from 'legacy/shared/controls/WcpSpinner/LoadingOverlay';

const DevicesAndBillingSummary = () => {
  const {
    actions: { toggleSubscriptionExpiredFilter, toggleUnallocatedDevicesFilter },
    state: {
      deviceSubscriptions,
      filteredDeviceSubscriptions,
      subscriptionExpirationSummary,
      organizationSubscriptionSummary,
      onlyShowUnallocatedDevicesFilter,
    },
  } = useDevicesAndBillingSummaryProviderContext();
  const [listingCount, setListingCount] = useState(0);

  const { needAttention } = subscriptionExpirationSummary;

  return deviceSubscriptions ? (
    <>
      <div>
        {needAttention.length > 0 && (
          <SubscriptionAlertBox
            toggleFilter={(toggle) => toggleSubscriptionExpiredFilter(toggle)}
          />
        )}
        <SubscriptionSummaryHeader
          organizationSubscriptionSummary={organizationSubscriptionSummary}
        ></SubscriptionSummaryHeader>
      </div>
      {deviceSubscriptions.length > 0 && (
        <ExportWrapper>
          <ExportDiv>
            <DevicesAndBillingExportButton />
          </ExportDiv>
        </ExportWrapper>
      )}

      <>
        <ListingCount
          listingCount={listingCount}
          totalCount={deviceSubscriptions.length}
          dataLabel={'Device'}
        />
        <DevicesSummaryFilterCheckbox
          checked={onlyShowUnallocatedDevicesFilter}
          handleChecked={() => toggleUnallocatedDevicesFilter()}
        />

        <MediaWrapper
          mobileComponent={
            <DevicesAndBillingSummaryCardContainer handleUpdateListingCount={setListingCount} />
          }
          nonMobileComponent={
            <DevicesAndBillingSummaryTable handleUpdateListingCount={setListingCount} />
          }
        />
      </>
    </>
  ) : (
    <LoadingOverlay />
  );
};

export default DevicesAndBillingSummary;
