import { useNavigate } from 'react-router-dom';

import { putAlertsApi } from 'legacy/features/alerts/services/api/put/putAlertsApi';
import useDispatchErrorNotification from 'legacy/shared/utilities/hooks/useDispatchErrorNotification';
import useDispatchSuccessNotification from 'legacy/shared/utilities/hooks/useDispatchSuccessNotification';
import useGenericMutation from 'legacy/shared/utilities/hooks/useGenericMutation';

const useEditAlertMutation = () => {
  const navigate = useNavigate();
  const [successToast] = useDispatchSuccessNotification();
  const [errorToast] = useDispatchErrorNotification();

  const mutation = useGenericMutation({
    apiFn: putAlertsApi,
    onSuccessCallback: () => {
      successToast({ message: 'Successfully Edited Alert' });
      navigate('/managealerts');
    },

    onErrorCallback: (err) => {
      errorToast({ message: 'Error Editing Alert', err });
    },
  });

  return mutation;
};

export default useEditAlertMutation;
