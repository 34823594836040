import MaintenanceBuilder from 'legacy/features/maintenance/components/MaintenanceBuilder/MaintenanceBuilder';
import VehicleEngineHoursField from 'legacy/features/maintenance/components/MaintenanceSummary/fields/VehicleEngineHoursField';
import VehicleOdometerField from 'legacy/features/maintenance/components/MaintenanceSummary/fields/VehicleOdometerField';
import { TextButton } from 'legacy/shared/styles/custom/Button';
import { FormSectionLabel, CheckboxTextSpan } from 'legacy/shared/styles/custom/Form';
import OilLifeField from 'legacy/features/maintenance/components/MaintenanceSummary/fields/OilLifeField';
import { CheckboxWrapperDiv } from 'legacy/shared/styles/custom/VehicleEdit';
import { Checkbox } from 'legacy/shared/styles/custom/Checkbox';
import CheckboxInner from 'legacy/shared/styles/custom/CheckboxInner';
import { TextLink } from 'legacy/shared/styles/custom/Link';
import { VehicleCommunicationMessageWrapper } from 'legacy/shared/styles/custom/Maintenance';
import {
  MaintenanceWrapperDiv,
  ObdFieldsWrapperDiv,
} from 'legacy/shared/styles/custom/VehicleEdit';

const MaintenanceSection = ({
  vehicle,
  formikProps,
  handleSetMaintenanceItems,
  handleMaintenanceItemsDirty,
  handleMaintenanceItemsValid,
  setEnhancedTelematicsEnabled,
  enhancedTelematicsEnabled,
  setEnhancedTelematicsDirty,
}) => {
  return (
    <>
      <MaintenanceWrapperDiv>
        <FormSectionLabel maintenance>
          Vehicle Maintenance
          {vehicle.meta.last_online_time ? (
            <TextButton type="button" maintenance>
              <TextLink to={`/maintenancelogs/${encodeURIComponent(vehicle.vehicle_id)}`}>
                VIEW LOGS
              </TextLink>
            </TextButton>
          ) : (
            <VehicleCommunicationMessageWrapper>
              <h6>
                Vehicle maintenance is unavailable until the vehicle has been powered on and has
                communicated with WCP
              </h6>
            </VehicleCommunicationMessageWrapper>
          )}
        </FormSectionLabel>
        <CheckboxWrapperDiv enhancedTelematics>
          <Checkbox singleCheck>
            <CheckboxInner
              isChecked={enhancedTelematicsEnabled}
              onClick={() => {
                setEnhancedTelematicsEnabled(!enhancedTelematicsEnabled);
                setEnhancedTelematicsDirty(true);
              }}
            />
          </Checkbox>
          <CheckboxTextSpan>Geotab Enabled</CheckboxTextSpan>
        </CheckboxWrapperDiv>
        {vehicle.meta.last_online_time && (
          <ObdFieldsWrapperDiv>
            <VehicleOdometerField formikProps={formikProps} />
            {vehicle.obd.engine_hours && <VehicleEngineHoursField formikProps={formikProps} />}
            {vehicle.obd?.oil_life && <OilLifeField formikProps={formikProps} />}
          </ObdFieldsWrapperDiv>
        )}
      </MaintenanceWrapperDiv>
      {vehicle.meta.last_online_time && (
        <MaintenanceBuilder
          initialState={{ vehicleMaintenanceItems: vehicle.maintenance }}
          handleSetMaintenanceItems={handleSetMaintenanceItems}
          handleMaintenanceItemsDirty={handleMaintenanceItemsDirty}
          handleMaintenanceItemsValid={handleMaintenanceItemsValid}
          vehicle={vehicle}
        />
      )}
    </>
  );
};

export default MaintenanceSection;
