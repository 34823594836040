import dateFnsFormat from 'date-fns/format';
import { Formik } from 'formik';
import { useState } from 'react';

import { maintenanceStatusDropdownData } from 'legacy/features/maintenance/data/maintenanceStatusDropdownData';
import { maintenanceIntervalConstants } from 'legacy/features/maintenance/data/maintenanceIntervalConstants';
import { maintenanceStatusConstants } from 'legacy/features/maintenance/data/maintenanceStatusConstants';
import MaintenanceNotesField from 'legacy/features/maintenance/components/MaintenanceSummary/fields/MaintenanceNotesField';
import { useMarkMaintenanceEventCompletedMutation } from 'legacy/features/maintenance/hooks/mutations/useMarkMaintenanceEventCompletedMutation';
import { logWcpError } from 'legacy/core/error/utilities/ErrorLogger';
import Button from 'legacy/shared/styles/custom/Button';
import { Checkbox } from 'legacy/shared/styles/custom/Checkbox';
import CheckboxInner from 'legacy/shared/styles/custom/CheckboxInner';
import {
  CheckboxTextSpan,
  DateRangeValidationDiv,
  Form,
  FormActions,
  FormAsterisk,
  FormBody,
  FormFoot,
  FormLabelAnimated,
  FormLegend,
  FormSectionLabel,
  FormTitle,
} from 'legacy/shared/styles/custom/Form';
import {
  CheckEngineOccuredAtWrapper,
  CompletedDateWrapperDiv,
  MarkCompletedFieldsWrapperDiv,
  ReoccuringEventDiv,
} from 'legacy/shared/styles/custom/Maintenance';
import { ActionsContainer, ModalText } from 'legacy/shared/styles/custom/Modal';
import { CheckboxWrapperDiv } from 'legacy/shared/styles/custom/VehicleEdit';
import ButtonWithLoader from 'legacy/shared/controls/ButtonWithLoader/ButtonWithLoader';
import AnimatedField from 'legacy/shared/controls/AnimatedField/v1/AnimatedField';
import CustomModal from 'legacy/shared/controls/WcpModal/v1/CustomModal';
import StyledDayPickerInput from 'legacy/shared/controls/WcpDayPicker/StyledDayPickerInput';
import {
  formatISOTimestampToShortDate,
  getNow,
  getShortDateInIsoFormat,
} from 'legacy/shared/utilities/misc/time';
import { validateMarkMaintenanceCompleted } from 'legacy/shared/utilities/helpers/validation/validators';
import {
  addDisplayCommas,
  stripTrailingPercentage,
} from 'legacy/features/maintenance/utilities/formatting';
import NextIntervalTypeDropdown from 'legacy/features/maintenance/components/MaintenanceBuilder/dropdowns/NextIntervalTypeDropdown';
import NextDueDateField from 'legacy/features/maintenance/components/MaintenanceBuilder/fields/NextDueDateField';
import { getNextDueDate } from 'legacy/features/maintenance/utilities/getNextDueDate';
import NextIntervalNumberField from 'legacy/features/maintenance/components/MaintenanceBuilder/fields/NextIntervalNumberField';

export const MarkMaintenanceCompletedModal = ({ modalData: { show, event }, handleCloseModal }) => {
  const [scheduleDuplicateEvent, setScheduleDuplicateEvent] = useState(true);
  const markMaintenanceEventCompletedMutation = useMarkMaintenanceEventCompletedMutation();
  const defaultCompletedDate = formatISOTimestampToShortDate(getNow());
  const confirmHandler = async (values, formikActions) => {
    event.notes = values.notes;
    event.scheduleDuplicateEvent = scheduleDuplicateEvent;
    if (scheduleDuplicateEvent) {
      event.nextIntervalType = values.nextIntervalType;
      event.nextIntervalValue = values.nextIntervalValue;
      event.nextDueDate = values.nextDueDate;
    } else {
      event.nextIntervalValue = null;
      event.nextDueDate = null;
    }
    event.completedDate = getShortDateInIsoFormat(values.completedDate);
    try {
      let result = await markMaintenanceEventCompletedMutation.mutateAsync({
        maintenanceEvent: event,
      });
    } catch (err) {
      logWcpError(err);
    } finally {
      formikActions.setSubmitting(false);
      handleCloseModal();
    }
  };
  const oilLifeInterval = event?.intervalType?.value === maintenanceIntervalConstants.OIL_LIFE;
  let intervalDisplay = oilLifeInterval
    ? stripTrailingPercentage(event?.interval) + '%'
    : addDisplayCommas(event?.interval);
  return (
    show && (
      <CustomModal
        customWidth={650}
        title={
          <>
            <FormTitle>{'Mark Maintenance Completed'}</FormTitle>
          </>
        }
        handleCloseModal={handleCloseModal}
      >
        <>
          <ModalText></ModalText>
          <Formik
            initialValues={{
              ...event,
              completedDate: defaultCompletedDate,
              notes: event.notes ? event.notes : '',
              nextIntervalValue: oilLifeInterval
                ? event.interval + '%'
                : event.interval
                  ? addDisplayCommas(event.interval)
                  : '',
              nextIntervalType: event.intervalType,
              nextDueDate: event.dueDate ? getNextDueDate(event.rawCreatedDate, event.dueDate) : '',
            }}
            validate={validateMarkMaintenanceCompleted}
            validateOnChange={true}
            validateOnBlur={false}
            onSubmit={(values, formikActions) => confirmHandler(values, formikActions)}
          >
            {(formikProps) => {
              //console.log(nextIntervalValue);
              return (
                <Form onSubmit={formikProps.submitForm}>
                  <FormBody>
                    <CompletedDateWrapperDiv>
                      <FormLabelAnimated animated>
                        Completed Date (MM/DD/YYYY)<FormAsterisk>*</FormAsterisk>
                      </FormLabelAnimated>
                      <StyledDayPickerInput
                        placeholder={defaultCompletedDate}
                        value={defaultCompletedDate}
                        onDayChange={(day) => {
                          formikProps.setFieldValue(
                            'completedDate',
                            `${dateFnsFormat(day, 'MM/dd/yyyy')}`,
                          );
                        }}
                      />
                      <DateRangeValidationDiv>
                        {formikProps.errors.completedDate}
                      </DateRangeValidationDiv>
                    </CompletedDateWrapperDiv>
                    <MarkCompletedFieldsWrapperDiv>
                      <AnimatedField
                        smallerTextMobile
                        maintenanceModal
                        disabled={true}
                        placeholder="Vehicle Name"
                        name="vehicleName"
                        value={event.vehicleNameDisplay}
                      />
                      {event.status === maintenanceStatusConstants.CHECK_ENGINE ? (
                        <>
                          <AnimatedField
                            smallerTextMobile
                            maintenanceModal
                            disabled={true}
                            placeholder="Maintenance Type"
                            name="type"
                            value={
                              maintenanceStatusDropdownData.find(
                                (m) => m.value === maintenanceStatusConstants.CHECK_ENGINE,
                              )?.label
                            }
                          />
                          <CheckEngineOccuredAtWrapper>
                            <AnimatedField
                              smallerTextMobile
                              disabled={true}
                              placeholder="Occured at"
                              name="occuredAt"
                              value={event.createdDate}
                            />
                          </CheckEngineOccuredAtWrapper>
                        </>
                      ) : (
                        <>
                          <AnimatedField
                            smallerTextMobile
                            maintenanceModal
                            disabled={true}
                            placeholder="Maintenance Type"
                            name="type"
                            value={event.maintenanceType?.label}
                          />
                          <AnimatedField
                            smallerTextMobile
                            disabled={true}
                            placeholder="Interval Type"
                            name="intervalType"
                            value={event.intervalType?.label}
                          />
                          {event.intervalType.value === maintenanceIntervalConstants.DATE ? (
                            <AnimatedField
                              smallerTextMobile
                              disabled={true}
                              placeholder="Due Date"
                              name="dueDate"
                              value={event.dueDate}
                            />
                          ) : (
                            <AnimatedField
                              smallerTextMobile
                              disabled={true}
                              placeholder="Interval"
                              name="interval"
                              value={intervalDisplay}
                            />
                          )}
                        </>
                      )}
                    </MarkCompletedFieldsWrapperDiv>
                    {event.status === maintenanceStatusConstants.CHECK_ENGINE && (
                      <AnimatedField
                        smallerTextMobile
                        disabled={true}
                        placeholder="Code(s)"
                        name="codes"
                        value={`${event.diagnosticCode}: ${event.diagnosticCodeDescription}`}
                      />
                    )}
                    <MaintenanceNotesField formikProps={formikProps} smallerTextMobile />
                    {event.status !== maintenanceStatusConstants.CHECK_ENGINE && (
                      <div>
                        <FormSectionLabel markCompleted>
                          Schedule Future Maintenance
                        </FormSectionLabel>
                        <CheckboxWrapperDiv>
                          <Checkbox singleCheck>
                            <CheckboxInner
                              isChecked={scheduleDuplicateEvent}
                              onClick={() => {
                                setScheduleDuplicateEvent(!scheduleDuplicateEvent);
                              }}
                            />
                          </Checkbox>
                          <CheckboxTextSpan>
                            Schedule a duplicate maintenance event for this vehicle
                          </CheckboxTextSpan>
                        </CheckboxWrapperDiv>
                        {scheduleDuplicateEvent && (
                          <ReoccuringEventDiv>
                            <NextIntervalTypeDropdown
                              noOdometer={event?.odometerDisplay === 'N/A'}
                              noEngineHours={event?.engineHoursDisplay === 'N/A'}
                              placeholder="Interval Type"
                              formikProps={formikProps}
                            />
                            {formikProps.values.nextIntervalType.value ===
                            maintenanceIntervalConstants.DATE ? (
                              <NextDueDateField formikProps={formikProps} />
                            ) : (
                              <NextIntervalNumberField formikProps={formikProps} />
                            )}
                          </ReoccuringEventDiv>
                        )}
                      </div>
                    )}
                  </FormBody>
                  <FormFoot>
                    <FormLegend>
                      <FormAsterisk>*</FormAsterisk> required fields
                    </FormLegend>
                    <FormActions>
                      <ActionsContainer>
                        <Button
                          onClick={async () => {
                            handleCloseModal();
                          }}
                          withRightSpacer
                          default
                          type="button"
                        >
                          Cancel
                        </Button>
                        <ButtonWithLoader
                          disabled={
                            formikProps.errors.notes || formikProps.errors.nextIntervalValue
                          }
                          isLoading={formikProps.isSubmitting}
                          confirmText={'Mark Completed'}
                          loadingStyleProp={'submittingWithSpinnerModal'}
                          notLoadingStyleProp={'mediumAlt'}
                          clickHandler={formikProps.submitForm}
                        />
                      </ActionsContainer>
                    </FormActions>
                  </FormFoot>
                </Form>
              );
            }}
          </Formik>
        </>
      </CustomModal>
    )
  );
};

export default MarkMaintenanceCompletedModal;
