import { Navigate, useParams } from 'react-router-dom';
import { FormikProvider } from 'formik';
import EditRecipientGroupForm from 'legacy/features/recipientGroups/components/ManageRecipientGroupsForms/EditRecipientGroupForm';
import PageListWrapper, { PageListHead, PageListTitle } from 'legacy/shared/styles/custom/PageList';
import useEditRecipientGroupForm from 'legacy/features/recipientGroups/components/ManageRecipientGroupsForms/useEditRecipientGroupForm';

const EditRecipientGroupPage = () => {
  const { recipientGroupId } = useParams();
  const formik = useEditRecipientGroupForm();
  //useUpdateRecipientGroupMutation
  return recipientGroupId ? (
    <PageListWrapper>
      <PageListHead stackable noBottomMargin>
        <PageListTitle>Edit Recipient Group</PageListTitle>
      </PageListHead>
      <FormikProvider value={formik}>
        <EditRecipientGroupForm recipientGroupId={recipientGroupId} formik={formik} />
      </FormikProvider>
    </PageListWrapper>
  ) : (
    <Navigate to="/managerecipientgroups" />
  );
};

export default EditRecipientGroupPage;
