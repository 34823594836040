import { createAction } from 'redux-actions';

import {
  addChannelToWhitelist as addChannelToWhitelistAPI,
  deleteWhitelist as deleteWhitelistAPI,
  getChannels,
  getProductIds,
  getWhitelists,
  removeChannelFromWhitelist as removeChannelFromWhitelistAPI,
} from 'legacy/core/api/whitelists';
import { addNotification } from 'legacy/core/redux/ui/actions';
import { LEVELS, createNotification } from 'legacy/shared/utilities/misc/notification';

export const requestWhitelists = createAction('REQUEST_WHITELISTS');
export const receiveWhitelists = createAction('RECEIVE_WHITELISTS');
export const rejectWhitelists = createAction('REJECT_WHITELISTS');
export const requestProductsAndChannels = createAction('REQUEST_PRODUCTS_AND_CHANNELS');
export const receiveProductsAndChannels = createAction('RECEIVE_PRODUCTS_AND_CHANNELS');
export const rejectProductsAndChannels = createAction('REJECT_PRODUCTS_AND_CHANNELS');

export const fetchWhitelists = () => async (dispatch, getState) => {
  dispatch(requestWhitelists());
  try {
    const userState = getState().user;
    const filteredOrganizationId = userState.filteredOrganizationId;
    const { response } = await getWhitelists({ organizationId: filteredOrganizationId });
    if (response && response.message) {
      dispatch(receiveWhitelists(response.message));
      return true;
    }
  } catch (e) {
    console.log(e);
    dispatch(rejectWhitelists(e));
    dispatch(
      addNotification({
        notification: createNotification(LEVELS.ERROR, 'Error Getting whitelists', e),
      }),
    );
  }
};

export const fetchProductsAndChannels = () => async (dispatch) => {
  dispatch(requestProductsAndChannels());
  try {
    const { response } = await getProductIds();
    const productIds = response?.message?.product_ids;
    if (productIds && productIds.length > 0) {
      const { response: productsWithChannels } = await getChannels(productIds);
      if (productsWithChannels.message && productsWithChannels.message.length > 0) {
        dispatch(receiveProductsAndChannels(productsWithChannels.message));
        return true;
      }
    }
  } catch (e) {
    console.log(e);
    dispatch(rejectWhitelists(e));
    dispatch(
      addNotification({
        notification: createNotification(LEVELS.ERROR, 'Error Getting products and channels', e),
      }),
    );
  }
};

export const addChannelToWhitelist =
  ({ productId, channel }) =>
  async (dispatch, getState) => {
    try {
      const userState = getState().user;
      const filteredOrganizationId = userState.filteredOrganizationId;
      const { response } = await addChannelToWhitelistAPI({
        organizationId: filteredOrganizationId,
        productId,
        channel,
      });
      if (response.message) {
        dispatch(fetchWhitelists());
        return true;
      }
    } catch (e) {
      console.log(e);
      dispatch(rejectWhitelists(e));
      dispatch(
        addNotification({
          notification: createNotification(LEVELS.ERROR, 'Error adding channel to whitelist ', e),
        }),
      );
    }
  };

export const removeChannelFromWhitelist =
  ({ productId, channel }) =>
  async (dispatch, getState) => {
    try {
      const userState = getState().user;
      const filteredOrganizationId = userState.filteredOrganizationId;
      const { response } = await removeChannelFromWhitelistAPI({
        organizationId: filteredOrganizationId,
        productId,
        channel,
      });
      if (response.message) {
        dispatch(fetchWhitelists());
        return true;
      }
    } catch (e) {
      console.log(e);
      dispatch(rejectWhitelists(e));
      dispatch(
        addNotification({
          notification: createNotification(
            LEVELS.ERROR,
            'Error removing channel from whitelist',
            e,
          ),
        }),
      );
    }
  };

export const deleteWhitelist =
  ({ productId }) =>
  async (dispatch, getState) => {
    try {
      const userState = getState().user;
      const filteredOrganizationId = userState.filteredOrganizationId;
      const { response } = await deleteWhitelistAPI({
        organizationId: filteredOrganizationId,
        productId,
      });
      if (response.message) {
        dispatch(fetchWhitelists());
        return true;
      }
    } catch (e) {
      console.log(e);
      dispatch(rejectWhitelists(e));
      dispatch(
        addNotification({
          notification: createNotification(LEVELS.ERROR, 'Error adding channel to whitelist ', e),
        }),
      );
    }
  };
