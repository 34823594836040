import { logWcpError } from 'legacy/core/error/utilities/ErrorLogger';
import { useDeleteGroupMutation } from 'legacy/features/vehicleGroups/hooks/v1/useDeleteGroupMutation';
import SimpleConfirmationModal from 'legacy/shared/controls/WcpModal/v1/SimpleConfirmationModal';

const DeleteGroupModal = ({ modalData: { show, groupId, groupName }, handleCloseModal }) => {
  const deleteGroupMutation = useDeleteGroupMutation();

  return (
    show && (
      <SimpleConfirmationModal
        handleCloseModal={handleCloseModal}
        isLoading={deleteGroupMutation.isPending}
        submitHandler={async () => {
          try {
            let result = await deleteGroupMutation.mutateAsync({
              groupId,
            });
          } catch (err) {
            logWcpError(err);
          } finally {
            handleCloseModal();
          }
        }}
        bodyText={`Do you really want to delete group: `}
        boldedItem={`${groupName}?`}
        title={`Delete Group`}
        confirmText="Delete"
        cancelText="Cancel"
      />
    )
  );
};

export default DeleteGroupModal;
