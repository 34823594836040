import { CREATE_ORGANIZATION_MODAL_KEY } from 'legacy/features/organizations/providers/modal/constants/organizationFeatureModalKeys';
import IconSvgComponent from 'legacy/shared/controls/WcpIcon/IconSvgComponent';
import { useWcpModalContext } from 'legacy/shared/controls/WcpModal/v2/WcpModalProvider';
import { ActionButtonText, ActionButtonWithIcon } from 'legacy/shared/styles/custom/Button';

export const CreateOrganizationButton = () => {
  const createOrganizationModalContext = useWcpModalContext(CREATE_ORGANIZATION_MODAL_KEY);

  return (
    <ActionButtonWithIcon
      create
      onClick={() => createOrganizationModalContext.modalActions.openModal()}
    >
      <IconSvgComponent svgFileName="plus-simple" alt="Create" />
      <ActionButtonText>Create Organization</ActionButtonText>
    </ActionButtonWithIcon>
  );
};
