import useAssociateFsesMutation from 'legacy/features/organizations/api/mutations/useAssociateFsesMutation';
import GenericConfirmationModalControls from 'legacy/shared/controls/WcpModal/v2/controls/GenericConfirmationModalControls';

const AssociateFseModalControls = ({ modalState, modalActions }) => {
  const associateFseMutation = useAssociateFsesMutation();

  const submitAssociateFse = async () => {
    // associate the FSEs with the organization
    await associateFseMutation.mutateAsync({
      organizationId: modalState.organization.organizationId,
      fseUsernames: modalState.selectedFSEs,
    });

    // close the modal
    modalActions.closeModal();
  };

  return (
    <GenericConfirmationModalControls
      confirmButtonText="Confirm"
      submitOnConfirm={true}
      handleConfirm={submitAssociateFse}
      handleCancel={modalActions.closeModal}
    />
  );
};

export default AssociateFseModalControls;
