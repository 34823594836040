import { useFormikContext } from 'formik';
import { useEffect, useState } from 'react';

import { getUnitOfMeasureOptions } from 'legacy/features/alerts/data/ui/dropdown/getUnitOfMeasureOptions';
import useMakeFormikField from 'legacy/shared/controls/WcpForm/v1/hooks/useMakeFormikField';
import { FieldError, FormAsterisk, FormLabelAnimated } from 'legacy/shared/styles/custom/Form';
import { UnitOfMeasureDiv } from 'legacy/shared/styles/custom/ManageAlerts';
import SelectField from 'legacy/shared/styles/custom/SelectField';
import { alertTypeConstants } from 'legacy/features/alerts/data/enums/alertTypeConstants';
import { speedUnitConstants } from 'legacy/features/alerts/data/enums/speedUnitConstants';
import useAlertFormsProviderContext from 'legacy/features/alerts/services/providers/AlertFormsProvider/hooks/useAlertFormsProviderContext';
import { offlineTimeUnitDropdownOptions } from 'legacy/features/alerts/data/ui/dropdown/offlineTimeUnitDropdownOptions';
import { speedUnitDropdownOptions } from 'legacy/features/alerts/data/ui/dropdown/speedUnitDropdownOptions';

const UnitOfMeasureDropdown = () => {
  const {
    state: { alert },
    actions: { updateUnitOfMeasureAction },
  } = useAlertFormsProviderContext();
  const formik = useFormikContext();

  const { alertDetails } = alert;
  const { alertType } = alertDetails.alertInfo;
  const { unitOfMeasure } = alertDetails.alertInfo;

  useMakeFormikField({
    fieldName: 'unitOfMeasureField',
    fieldValue: unitOfMeasure,
    triggerValidation: true,
  });

  let options = (
    alertType === alertTypeConstants.OFFLINE_TIME_EXCEEDS
      ? offlineTimeUnitDropdownOptions
      : speedUnitDropdownOptions
  ).getAllOptions();

  useEffect(() => {
    formik.setFieldValue('unitOfMeasureField', unitOfMeasure, true);
  }, [unitOfMeasure]);

  let defaultUnitOfMeasureOption = unitOfMeasure
    ? options.find((item) => item.value === unitOfMeasure)
    : null;
  return (
    options && (
      <UnitOfMeasureDiv>
        <FormLabelAnimated animated>
          Unit of Measure<FormAsterisk>*</FormAsterisk>
        </FormLabelAnimated>
        <SelectField
          required
          mediumTextMobile
          options={options}
          value={defaultUnitOfMeasureOption}
          placeholder={'Select a unit...'}
          onChange={(selectedItem) => {
            if (selectedItem) updateUnitOfMeasureAction({ unitOfMeasure: selectedItem.value });
          }}
        />
        <FieldError topSpacing>{formik.errors.unitOfMeasureField}</FieldError>
      </UnitOfMeasureDiv>
    )
  );
};

export default UnitOfMeasureDropdown;
