import { Formik } from 'formik';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { undecorateVehicleObject } from 'legacy/core/api/vehicles';
import { logWcpError } from 'legacy/core/error/utilities/ErrorLogger';
import VehicleAssociatedGroups from 'legacy/features/vehicles/components/controls/associatedGroups/VehicleAssociatedGroups';
import VehicleFormVinField from 'legacy/features/vehicles/components/forms/custom/VehicleFormVinField';
import VehicleLicenseStateSelect from 'legacy/features/vehicles/components/forms/select/VehicleLicenseStateSelect';
import VehicleTypeSelect from 'legacy/features/vehicles/components/forms/select/VehicleTypeSelect';
import VehicleAliasField from 'legacy/features/vehicles/components/forms/textField/VehicleAliasField';
import VehicleLicensePlateField from 'legacy/features/vehicles/components/forms/textField/VehicleLicensePlateField';
import VehicleMakeField from 'legacy/features/vehicles/components/forms/textField/VehicleMakeField';
import VehicleModelField from 'legacy/features/vehicles/components/forms/textField/VehicleModelField';
import VehicleNameField from 'legacy/features/vehicles/components/forms/textField/VehicleNameField';
import VehicleNotes from 'legacy/features/vehicles/components/forms/textField/VehicleNotes';
import VehicleYearField from 'legacy/features/vehicles/components/forms/textField/VehicleYearField';
import { vehicleCreateSuccess } from 'legacy/features/vehicles/helpers/notifications';
import useCreateVehicleMutation from 'legacy/features/vehicles/hooks/useCreateVehicleMutation';
import useVerifyVsgIdQuery from 'legacy/features/vehicles/hooks/useVerifyVsgIdQuery';
import { validateVehicle } from 'legacy/prebilling/vehicles/preBillingValidators';
import VehicleDeviceIdField from 'legacy/prebilling/vehicles/VehicleDeviceIdField';
import ButtonWithLoader from 'legacy/shared/controls/ButtonWithLoader/ButtonWithLoader';
import IconSvgComponent from 'legacy/shared/controls/WcpIcon/IconSvgComponent';
import Button from 'legacy/shared/styles/custom/Button';
import FormWrapper, {
  Form,
  FormActions,
  FormAsterisk,
  FormBody,
  FormCol,
  FormFoot,
  FormGridRow,
  FormHead,
  FormIcon,
  FormLegend,
  FormMessage,
  FormTitle,
} from 'legacy/shared/styles/custom/Form';

const VehicleCreateFormContainer = ({ handleRedirect, vehicle }) => {
  const [associatedGroups, setAssociatedGroups] = useState([]);

  const [deviceIdDirty, setDeviceIsDirty] = useState(false);
  const [haasDirty, setHaasDirty] = useState(false);
  const [deviceContainerDirty, setDeviceContainerDirty] = useState(false);
  const [associatedGroupsDirty, setAssociatedGroupsDirty] = useState(false);
  const [addonsDirty, setAddonsDirty] = useState(false);
  const [resetPreferencesModalActive, setResetPreferencesModalActive] = useState(false);
  const [resetVsgConfirmed, setResetVsgConfirmed] = useState(false);
  const [serverError, setServerError] = useState(false);
  const [addonValidationErrors, setAddonValidationErrors] = useState(false);

  const [prefillMake, setPrefillMake] = useState(null);
  const [prefillModel, setPrefillModel] = useState(null);
  const [prefillYear, setPrefillYear] = useState(null);
  const [
    vsgIsValidAndAcknowledged,
    verifyVsgIdModalActive,
    setVerifyVsgIdModalActive,
    setVerifyVsgWarningConfirmed,
  ] = useVerifyVsgIdQuery();

  const filteredOrganizationId = useSelector((state) => state.user.filteredOrganizationId);

  const createVehicleMutation = useCreateVehicleMutation();

  const confirmHandler = async (values, formikActions) => {
    values.duid = values.duid.replace(/-/g, '');
    values.vin = values.vin.toUpperCase();
    let isValidAndAcknowledged = await vsgIsValidAndAcknowledged(values.duid);
    if (!isValidAndAcknowledged) {
      setVerifyVsgIdModalActive(true);
    } else {
      createVehicleMutation.mutate(
        {
          ...undecorateVehicleObject({
            vehicle: {
              ...values,
              organizationId: filteredOrganizationId,
              groups: associatedGroups,
            },
          }),
        },
        {
          onSuccess: () => {
            dispatch(vehicleCreateSuccess({ vehicleName: values.label }));
            handleRedirect();
            formikActions.setSubmitting(false);
          },
          onError: (err) => {
            logWcpError(err);
            setServerError(err);
            formikActions.setSubmitting(false);
          },
        },
      );
    }
  };

  const dispatch = useDispatch();

  return (
    <FormWrapper>
      <Formik
        initialValues={{
          label: '',
          vin: '',
          duid: '',
          licensePlate: '',
          licenseState: '',
          type: '',
          model: '',
          make: '',
          year: '',
          notes: '',
          alias: '',
          device: '',
        }}
        validate={validateVehicle}
        validateOnChange={true}
        validateOnBlur={false}
        onSubmit={(values, formikActions) => confirmHandler(values, formikActions)}
      >
        {(formikProps) => {
          return (
            <Form onSubmit={formikProps.submitForm}>
              <FormHead>
                <FormIcon>
                  <IconSvgComponent svgFileName={'plus'} />
                </FormIcon>
                <FormTitle>Vehicle Details and Connectivity</FormTitle>
              </FormHead>

              <FormBody>
                <FormGridRow>
                  <FormCol>
                    <VehicleNameField formikProps={formikProps} />
                  </FormCol>
                  <FormCol>
                    <VehicleDeviceIdField
                      formikProps={formikProps}
                      resetPreferencesModalActive={resetPreferencesModalActive}
                      handleConfirmVsgResetPrefencesAccepted={(isAccepted) => {
                        // close modal
                        setResetPreferencesModalActive(false);

                        // submit if accepted
                        if (isAccepted) {
                          setResetVsgConfirmed(true);
                          formikProps.submitForm();
                        }
                      }}
                      handleDeviceIdDirty={setDeviceIsDirty}
                      verifyVsgIdModalActive={verifyVsgIdModalActive}
                      handleConfirmVerifyVsgIdAccepted={(isAccepted) => {
                        // close modal
                        setVerifyVsgIdModalActive(false);

                        // submit if accepted
                        if (isAccepted) {
                          setVerifyVsgWarningConfirmed(true);
                          formikProps.submitForm();
                        }
                      }}
                    />
                  </FormCol>
                </FormGridRow>
                <FormGridRow>
                  <FormCol>
                    <VehicleFormVinField
                      formikProps={formikProps}
                      setVinPrefills={(make, model, year) => {
                        setPrefillMake(make);
                        setPrefillModel(model);
                        setPrefillYear(year);
                      }}
                    />
                  </FormCol>
                  <FormCol>
                    <VehicleLicensePlateField formikProps={formikProps} />
                    <VehicleLicenseStateSelect formikProps={formikProps} />
                  </FormCol>
                </FormGridRow>
                <FormGridRow>
                  <FormCol>
                    <VehicleTypeSelect formikProps={formikProps} />
                  </FormCol>
                  <FormCol>
                    <VehicleMakeField formikProps={formikProps} prefillValue={prefillMake} />
                  </FormCol>
                </FormGridRow>
                <FormGridRow>
                  <FormCol>
                    <VehicleModelField formikProps={formikProps} prefillValue={prefillModel} />
                  </FormCol>
                  <FormCol>
                    <VehicleYearField formikProps={formikProps} prefillValue={prefillYear} />
                  </FormCol>
                </FormGridRow>
                <FormGridRow>
                  <FormCol>
                    <VehicleAliasField formikProps={formikProps} />
                  </FormCol>
                </FormGridRow>
                <VehicleNotes formikProps={formikProps} />

                {/* Associated Groups */}
                <VehicleAssociatedGroups
                  vehicle={vehicle}
                  updateVehicleAssociatedGroups={(ag) => {
                    setAssociatedGroups(ag);
                  }}
                  handleIsDirty={(isDirty) => {
                    setAssociatedGroupsDirty(isDirty);
                  }}
                />
                <FormMessage message={serverError}>{serverError}</FormMessage>
              </FormBody>

              <FormFoot>
                <FormLegend>
                  <FormAsterisk>*</FormAsterisk> &mdash; required fields
                </FormLegend>
                <FormActions>
                  <Button type="button" onClick={handleRedirect} withRightSpacer mediumAlt default>
                    Cancel
                  </Button>
                  <ButtonWithLoader
                    isLoading={createVehicleMutation.isPending || formikProps.isSubmitting}
                    disabled={
                      (!formikProps.dirty &&
                        !haasDirty &&
                        !associatedGroupsDirty &&
                        !addonsDirty &&
                        !deviceContainerDirty) ||
                      addonValidationErrors
                    }
                    confirmText={'Create Vehicle'}
                    loadingStyleProp={'submittingWithSpinnerModal'}
                    notLoadingStyleProp={'mediumAlt'}
                    clickHandler={formikProps.submitForm}
                  />
                </FormActions>
              </FormFoot>
            </Form>
          );
        }}
      </Formik>
    </FormWrapper>
  );
};
export default VehicleCreateFormContainer;
