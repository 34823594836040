import { DropdownLink, SimpleDropdownItem } from 'legacy/shared/styles/custom/DropdownMenu';
import React from 'react';

const ManageOrganizationUsersMenuItem = ({ organization }) => {
  return (
    <SimpleDropdownItem>
      <DropdownLink to={`${encodeURIComponent(organization.organizationId)}`}>
        Manage Users
      </DropdownLink>
    </SimpleDropdownItem>
  );
};

export default ManageOrganizationUsersMenuItem;
