import { useState } from 'react';

import useFetchFseUsersQuery from 'legacy/features/organizations/api/queries/useFetchFseUsersQuery';
import WcpCheckbox from 'legacy/shared/controls/WcpCheckbox/v1/WcpCheckbox';
import SmallLoadingSpinner from 'legacy/shared/controls/WcpSpinner/SmallLoadingSpinner';
import WcpTable from 'legacy/shared/controls/WcpTable/v2/WcpTable';
import { useEffect } from 'react';
import {
  StyledFSEModalContentDiv,
  StyledFSETableContentDiv,
} from 'legacy/shared/controls/WcpForm/v2/styles/WcpFormStyles';
import Scrollbars from 'react-custom-scrollbars-2';
import useFetchOrganizationFseUsersQuery from 'legacy/features/organizations/api/queries/useFetchOrganizationFseUsersQuery';
import { PAGINATION_OPTIONS } from 'legacy/shared/constants/pagination';

const AssociateFseModalContent = ({ modalState: { organization }, modalActions }) => {
  const [selectedFSEs, setSelectedFSEs] = useState([]);
  const [currentFSEs, setCurrentFSEs] = useState([]);

  // fetch all FSEs
  const fetchFseUsersQuery = useFetchFseUsersQuery();

  // fetch all FSEs for the organization
  const fetchOrganizationFseUsersQuery = useFetchOrganizationFseUsersQuery({
    organizationId: organization.organizationId,
  });

  useEffect(() => {
    // update the modal state with the selected FSEs
    modalActions.setModalState({
      selectedFSEs,
    });
  }, [selectedFSEs]);

  useEffect(() => {
    if (fetchOrganizationFseUsersQuery.data && fetchFseUsersQuery.data) {
      // filter out FSEs that are already associated with the organization
      const fseUsernames = fetchOrganizationFseUsersQuery.data.map((user) => user.username);
      const currentFSEs = fetchFseUsersQuery.data.filter(
        (FSE) => !fseUsernames.includes(FSE.username),
      );
      setCurrentFSEs(currentFSEs);
    }
  }, [fetchFseUsersQuery.data, fetchOrganizationFseUsersQuery.data]);

  const selectActionColumnDefinition = ({
    selectedCollection,
    setSelectedCollection,
    ...props
  }) => {
    return {
      ...props,
      render: ({ rowData }) => {
        const { displayProperty } = props;
        const selected = selectedCollection.indexOf(rowData[displayProperty]) > -1;
        return (
          <WcpCheckbox
            handleChange={(e) => {
              // if already selected
              if (selected) {
                // deselect
                setSelectedCollection(
                  selectedCollection.filter((item) => item !== rowData[displayProperty]),
                );
              } else {
                // otherwise select
                setSelectedCollection([...selectedCollection, rowData[displayProperty]]);
              }
            }}
            checked={selected}
          />
        );
      },
    };
  };

  return fetchFseUsersQuery.isFetching && !fetchFseUsersQuery.data ? (
    <span>
      <SmallLoadingSpinner />
      <em>{`Loading all FSEs (this may take a minute)`}</em>
    </span>
  ) : (
    <StyledFSEModalContentDiv>
      <Scrollbars
        style={{
          width: '100%',
          height: '100%',
          backgroundColor: 'white',
        }}
      >
        <StyledFSETableContentDiv>
          <WcpTable
            tableSourceData={currentFSEs}
            defaultSortProperty={'firstName'}
            disablePageSize={true}
            defaultPageSize={PAGINATION_OPTIONS[0].value}
            columns={[
              selectActionColumnDefinition({
                title: ' ',
                displayProperty: 'username',
                className: 'tightRow',
                selectedCollection: selectedFSEs,
                setSelectedCollection: setSelectedFSEs,
              }),
              {
                displayProperty: 'firstName',
                className: 'tightRow',
              },
              {
                displayProperty: 'lastName',
                className: 'tightRow',
              },
              {
                displayProperty: 'email',
                className: 'tightRow',
              },
            ]}
          />
        </StyledFSETableContentDiv>
      </Scrollbars>
    </StyledFSEModalContentDiv>
  );
};

export default AssociateFseModalContent;
