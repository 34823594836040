import React from 'react';
import colors from 'legacy/shared/constants/colors';

export default ({ labelText, alignment, onChange }) => (
  <div style={{ textAlign: alignment }}>
    <input style={styles.fileInput} id="fileInput" type="file" multiple onChange={onChange} />
    <label for={'fileInput'} style={styles.fileLabel}>
      {labelText}
    </label>
  </div>
);

const styles = {
  fileInput: {
    width: '.1px',
    height: '.1px',
    opacity: 0,
    overflow: 'hidden',
    position: 'absolute',
    zIndex: -1,
  },
  fileLabel: {
    padding: '10px 40px',
    marginTop: 30,
    color: colors.midnight,
    fontSize: '12px',
    fontWeight: 700,
    letterSpacing: 0.27,
    textTransform: 'uppercase',
    borderRadius: '20.5px',
    borderColor: colors.midnight,
    borderStyle: 'solid',
    borderWidth: '2px',
    display: 'inline-block',
    textAlign: 'center',
    float: 'left',
  },
};
