import { WcpTextInputField } from 'legacy/shared/controls/WcpForm/v2/components/fields/WcpTextInputField';

const UserEmailField = ({ readOnly = false }) => {
  return (
    <div title={readOnly && 'This field cannot be edited'}>
      <WcpTextInputField label="Email" dataKey={'email'} disabled={readOnly} />
    </div>
  );
};

export default UserEmailField;
