import { replaceEmptyString } from 'legacy/shared/utilities/misc/general';
import { formatDateFromDynamoIsoString } from 'legacy/shared/utilities/misc/organizations';

const transformPhoneNumberToDisplay = (phoneNumberString) => {
  if (phoneNumberString) {
    const first = phoneNumberString.slice(2, 5);
    const second = phoneNumberString.slice(5, 8);
    const third = phoneNumberString.slice(8, 12);
    return `(${first}) ${second}-${third}`;
  }
  return null;
};

export const decorateOrganization = (organization) => ({
  organizationId: organization.organization_id,
  organizationName: organization.organization_name,
  activeUsers: organization.active_users,
  city: organization.address_city,
  state: organization.address_state,
  address1: organization.address_1,
  address2: organization.address_2,
  contactPhone: organization.contact_phone,
  contactPhoneDisplay: transformPhoneNumberToDisplay(organization.contact_phone),
  contactPhoneExtension: replaceEmptyString(organization.contact_phone_extension),
  contactEmail: organization.contact_email_id,
  contactName: organization.contact_name,
  customerType: organization.customer_type,
  timeZone: organization.timezone,
  startDate: formatDateFromDynamoIsoString(organization.start_date),
  endDate: formatDateFromDynamoIsoString(organization.end_date),
  active: organization.organization_active,
  associatedFSE: organization.associated_fse,
  gttEnabled: organization.gtt_enabled,
  gttSetup: organization.gtt_setup,
  haasEnabled: organization.haas_enabled,
  alertsActive: organization.alerts_active,
  haasSetup: organization.haas_setup,
  status: organization.organization_active ? 'Enabled' : 'Disabled',
  wimAccountId: organization.wim_account_id,
  vehicleCount: organization.vehicle_count,
  reminderMileage: organization.maintenance_reminder_mileage,
  reminderEngineHours: organization.maintenance_reminder_engine_hours,
  reminderDays: organization.maintenance_reminder_days,
  reminderOilLife: organization.maintenance_reminder_oil_life,
  thruGreenEnabled: organization.thrugreen_enabled,
  appliedInformationEnabled: organization.applied_information_enabled,
  appliedInformationBusinessId: organization.applied_information_business_id,
  subscriptionGracePeriodEndDate: organization.subscription_grace_period_end_date,
  subscriptionEndDate: organization.subscription_end_date,
  subscriptionStatus: organization.subscription_status,
});
