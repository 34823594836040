import AlertAddonDropdown from 'legacy/features/alerts/ui/components/AlertForms/shared/dropdowns/AlertAddonDropdown';
import AlertTriggerValueDropdown from 'legacy/features/alerts/ui/components/AlertForms/shared/dropdowns/AlertTriggerValueDropdown';
import UnitOfMeasureDropdown from 'legacy/features/alerts/ui/components/AlertForms/shared/dropdowns/UnitOfMeasureDropdown';
import AlertTriggerValueField from 'legacy/features/alerts/ui/components/AlertForms/shared/fields/AlertTriggerValueField';
import { alertTypeConstants } from 'legacy/features/alerts/data/enums/alertTypeConstants';
import { AlertTriggerValueWrapper } from 'legacy/shared/styles/custom/ManageAlerts';
import useAlertFormsProviderContext from 'legacy/features/alerts/services/providers/AlertFormsProvider/hooks/useAlertFormsProviderContext';

const AlertTriggerValueContainer = () => {
  const {
    state: { alert },
  } = useAlertFormsProviderContext();

  const { alertType } = alert.alertDetails.alertInfo;

  return (
    <AlertTriggerValueWrapper>
      {(alertType === alertTypeConstants.SPEED_EXCEEDS ||
        alertType === alertTypeConstants.NR_SPEED_EXCEEDS ||
        alertType === alertTypeConstants.OFFLINE_TIME_EXCEEDS) && (
        <>
          <AlertTriggerValueField />
          <UnitOfMeasureDropdown />
        </>
      )}
      {alertType === alertTypeConstants.ADDON_ACTIVE && (
        <>
          <AlertAddonDropdown />
          <AlertTriggerValueDropdown />
        </>
      )}
      {alertType === alertTypeConstants.CHECK_ENGINE_LIGHT && (
        <>
          <AlertTriggerValueDropdown />
        </>
      )}
    </AlertTriggerValueWrapper>
  );
};

export default AlertTriggerValueContainer;
