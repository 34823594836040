import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { logWcpError } from 'legacy/core/error/utilities/ErrorLogger';
import { fetchConfigurations } from 'legacy/core/redux/configurations/actions';
import ConfigurationSummaryModalContent from 'legacy/features/configurations/components/modals/ConfigurationSummaryModalContent';
import ConfigurationUploadModalContent from 'legacy/features/configurations/components/modals/ConfigurationUploadModalContent';
import { useDeleteConfigMutation } from 'legacy/features/configurations/mutations/useDeleteConfigMutation';
import { CloseButton, Container, Title } from 'legacy/shared/styles/custom/Modal';
import IconSvgComponent from 'legacy/shared/controls/WcpIcon/IconSvgComponent';
import UploadIcon from 'legacy/features/configurations/components/icons/UploadIcon';

const ConfigurationUploadModal = ({ onSuccess, handleRequestClose }) => {
  const deleteConfigMutation = useDeleteConfigMutation();
  const [modalContent, setModalContent] = useState(null);
  const [configId, setConfigId] = useState(null);
  const dispatch = useDispatch();
  const uploadCompleteHandler = useCallback((success, configDetails) => {
    if (success) {
      setConfigId(configDetails.configId);
      setModalContent(
        <ConfigurationSummaryModalContent configDetails={configDetails} onSuccess={onSuccess} />,
      );
    }
  }, []);

  useEffect(() => {
    setModalContent(<ConfigurationUploadModalContent onUploadComplete={uploadCompleteHandler} />);
  }, []);

  return (
    <Container>
      <CloseButton
        onClick={async () => {
          try {
            let result = await deleteConfigMutation.mutateAsync({
              configurationId: configId,
            });
            dispatch(fetchConfigurations());
          } catch (err) {
            logWcpError(err);
          } finally {
            handleRequestClose();
          }
        }}
      >
        <IconSvgComponent svgFileName={'x'} alt="Close" title="Close" />
      </CloseButton>
      <Title
        style={{
          marginBottom: '42px',
        }}
      >
        <UploadIcon width={20} />
        Configuration Upload
      </Title>
      {modalContent}
    </Container>
  );
};

export default ConfigurationUploadModal;
