import { WcpTextInputField } from 'legacy/shared/controls/WcpForm/v2/components/fields/WcpTextInputField';
import useWcpFormContext from 'legacy/shared/controls/WcpForm/v2/hooks/useWcpFormContext';
import React from 'react';

const FirstNameField = ({ readOnly = false }) => {
  return (
    <WcpTextInputField label="First Name" dataKey={'firstName'} showReadOnlyLabel={readOnly} />
  );
};

export default FirstNameField;
