export const whatToValidateEnum = {
  // only validate fields that have been touched
  TOUCHED_FIELDS: 'TOUCHED_FIELDS',

  // only validate fields that have been changed
  DIRTY_FIELDS: 'DIRTY_FIELDS',

  // only validate the focused field
  FOCUSED_FIELDS: 'FOCUSED_FIELDS',

  // validate all fields
  ALL_FIELDS: 'ALL_FIELDS',
};

// when to validate
export const whenToValidateEnum = {
  // validate on form load
  ON_LOAD: 'ON_LOAD',

  // validate on (any) field focus
  ON_FOCUS: 'ON_FOCUS',

  // validate on (any) field getting touched for the very first time
  ON_TOUCHED: 'ON_TOUCHED',

  // validate on (any) field blur
  ON_BLUR: 'ON_BLUR',

  // validate on (any) field change
  ON_CHANGE: 'ON_CHANGE',

  // validate on (any) field becoming dirty
  ON_DIRTY: 'ON_DIRTY',

  // validate on form submit
  ON_SUBMIT_ONLY: 'ON_SUBMIT_ONLY',
};

export const validationRestrictionEnum = {
  // only validate if form is dirty
  FORM_MUST_BE_DIRTY: 'FORM_MUST_BE_DIRTY',

  // only validate if form is dirty
  FORM_MUST_HAVE_BEEN_DIRTY: 'FORM_MUST_HAVE_BEEN_DIRTY',

  // only validate if form is touched
  FORM_MUST_BE_TOUCHED: 'FORM_MUST_BE_TOUCHED',

  // only validate if form has a focused field
  FORM_MUST_BE_FOCUSED: 'FORM_MUST_BE_FOCUSED',
};
