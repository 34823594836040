import { vehiclePlaybackAddonsMapping } from 'legacy/features/playback/services/mapping/vehiclePlaybackAddonsMapping';
import { mapToBackend, mapToFrontend } from 'legacy/shared/utilities/helpers/mapping/mapper';

export const vehiclePlaybackMapping = {
  vehicle: {
    frontendProperty: 'selectedVehicle',
    transformValueToFrontend: (vehicle) => {
      return {
        vehicleName: vehicle.vehicle_name,
        vehicleId: vehicle.vehicle_id,
      };
    },
    transformValueToBackend: (selectedVehicle) => {
      return {
        vehicle_id: selectedVehicle.vehicleId,
      };
    },
  },
  summary: {
    frontendProperty: 'summary',
    transformValueToFrontend: (summary) => {
      return {
        topSpeedResponding: summary.top_speed_responding,
        topSpeedActive: summary.top_speed_active,
        topSpeedNotResponding: summary.top_speed_not_responding,
        averageSpeedRespondingMoving: summary.average_speed_responding_moving,
        averageSpeedActiveMoving: summary.average_speed_active_moving,
        averageSpeedNotRespondingMoving: summary.average_speed_not_responding_moving,
      };
    },
    transformValueToBackend: (summary) => {
      return {
        top_speed_responding: summary.topSpeedResponding,
        top_speed_active: summary.topSpeedActive,
        top_speed_not_responding: summary.topSpeedNotResponding,
        average_speed_responding_moving: summary.averageSpeedRespondingMoving,
        average_speed_active_moving: summary.averageSpeedActiveMoving,
        average_speed_not_responding_moving: summary.averageSpeedNotRespondingMoving,
      };
    },
  },
  data: {
    frontendProperty: 'locationData',
    transformValueToFrontend: (data) => {
      return data.map((d) => ({
        lat: d.lat,
        long: d.long,
        speed: d.speed,
        time: d.time,
        status: d.status,
        heading: d.heading,
        elevation: d.elevation,
        speedSource: d.speed_source,
        addons: d.addons.map((al) => mapToFrontend(al, vehiclePlaybackAddonsMapping)),
      }));
    },
  },
  transformValueToBackend: (data) => {
    return data.map((d) => ({
      lat: d.lat,
      long: d.long,
      speed: d.speed,
      time: d.time,
      status: d.status,
      heading: d.heading,
      elevation: d.elevation,
      speed_source: d.speedSource,
      addons: d.addons.map((al) => mapToBackend(al, vehiclePlaybackAddonsMapping)),
    }));
  },
};

export const mapVehiclePlaybackStateToFrontend = (backendData) => {
  return mapToFrontend(backendData, vehiclePlaybackMapping);
};
