/**
 * @ External Dependencies
 */
import { handleActions, combineActions } from 'redux-actions';

/**
 * @ Data
 */

import { modalTypes } from 'legacy/shared/constants/modal';

/**
 * @ Actions
 */
import {
  toggleOverlay,
  hideOverlay,
  displayOverlay,
  toggleGlobalOverlay,
  closeGlobalModal,
  modalFormChanged,
  toggleCreateUserModal,
  toggleEditUserModal,
  toggleUserLimitModal,
  toggleUserCreationActiveLimitModal,
  toggleDeactivateUserModal,
  toggleEditOrganizationModal,
  addNotification,
  successAddNotification,
  successRemoveNotification,
  setSubscriptionExpiryAlertBar,
  setFirmwareAlertCount,
  toggleSidebarAlerts,
  updateLoginMessage,
  clearLoginMessage,
  setFullscreenMapModeOn,
  setFullscreenMapModeOff,
  showNewReleaseNotification,
  hideNewReleaseNotification,
  showSessionTimeoutNotification,
  hideSessionTimeoutNotification,
} from './actions';
import {
  rejectCreateOrganization,
  rejectUpdateOrganization,
} from 'legacy/core/redux/organizations/actions';
import { rejectCreateUser, rejectEditUser } from 'legacy/core/redux/users/actions';
import {
  rejectCreateVehicle,
  rejectEditVehicle,
  rejectResetVehicle,
} from 'legacy/core/redux/vehicles/actions';
import { openSidebar, closeSidebar, toggleSidebar } from 'legacy/core/redux/sidebar/actions';

/**
 * @ Reducer
 */
const defaultState = {
  overlayIsVisible: false,
  globalOverlayIsVisible: false,
  tabsData: {},
  isDefaultModalOpen: false,
  isRegistrationModalOpen: false,
  isModalOpen: false,
  isMenuExpanded: false,
  modal: {
    type: null,
    isOpen: false,
    parent: null,
    vehicleModalVehicleId: null,
    GroupModalGroupId: null,
    userModalUsername: null,
    formDirty: null,
    firmware: {
      productId: '',
      channel: '',
      version: '',
    },
  },
  firmwareUpdateAlertBar: null,
  subscriptionExpiryAlertBar: null,
  notificationsToAdd: [],
  notifications: [],
  firmwareUpdateAlerts: [],

  alertsVisible: false,
  isPageOverlayVisible: false,
  firmwareAlertCount: 0,

  loginMessage: '',
  modalFormMessage: '',
  fullscreenMapMode: false,

  // release_version
  releaseVersion: localStorage.getItem('release_version'),
  newReleaseNotificationVisible: false,
  showSessionTimeoutNotificationVisible: false,
};

/**
 * Actions Handler
 *
 * @type       {Function}
 */
const ui = handleActions(
  {
    [toggleSidebarAlerts]: (state) => ({
      ...state,
      alertsVisible: !state.alertsVisible,
      isPageOverlayVisible: !state.isPageOverlayVisible,
    }),
    [setFirmwareAlertCount]: (state, { payload }) => {
      return {
        ...state,
        firmwareAlertCount: payload,
      };
    },

    [setSubscriptionExpiryAlertBar]: (state, { payload }) => {
      if (payload) {
        const { id, message, dismissed, daysUntilExpiration } = payload;

        return {
          ...state,
          daysUntilExpiration: daysUntilExpiration || state.daysUntilExpiration,
          subscriptionExpiryAlertBar: {
            ...state.subscriptionExpiryAlertBar,
            id: id !== null ? id : state.subscriptionExpiryAlertBar.id,
            message: message !== null ? message : state.subscriptionExpiryAlertBar.message,
            dismissed: dismissed !== null ? dismissed : state.subscriptionExpiryAlertBar.dismissed,
          },
        };
      } else {
        return {
          ...state,
          subscriptionExpiryAlertBar: null,
        };
      }
    },

    [closeSidebar]: (state) => ({
      ...state,
      overlayIsVisible: false,
    }),
    [hideOverlay]: (state) => ({
      ...state,
      overlayIsVisible: false,
    }),
    [openSidebar]: (state) => ({
      ...state,
      overlayIsVisible: true,
    }),
    [displayOverlay]: (state) => ({
      ...state,
      overlayIsVisible: true,
    }),
    [toggleOverlay]: (state) => ({
      ...state,
      overlayIsVisible: !state.overlayIsVisible,
    }),
    [setFullscreenMapModeOn]: (state) => ({
      ...state,
      fullscreenMapMode: true,
    }),
    [setFullscreenMapModeOff]: (state) => ({
      ...state,
      fullscreenMapMode: false,
    }),
    [toggleSidebar]: (state) => ({
      ...state,
      overlayIsVisible: !state.overlayIsVisible,
    }),
    [toggleGlobalOverlay]: (state) => ({
      ...state,
      globalOverlayIsVisible: !state.globalOverlayIsVisible,
    }),
    [closeGlobalModal]: (state) => ({
      ...state,
      modal: defaultState.modal,
      modalFormMessage: '',
    }),
    [modalFormChanged]: (state) => ({
      ...state,
      modal: {
        ...state.modal,
        formDirty: true,
      },
    }),

    [toggleCreateUserModal]: (state, { payload: { organizationId } }) => ({
      ...state,
      modal: {
        ...state.modal,
        type: modalTypes.createUser,
        isOpen: !state.modal.isOpen,
        userModalOrganizationId: organizationId,
      },
    }),
    [toggleEditUserModal]: (state, { payload: { username, organizationId } }) => ({
      ...state,
      modal: {
        ...state.modal,
        type: modalTypes.editUser,
        isOpen: !state.modal.isOpen,
        userModalUsername: !state.modal.isOpen ? username : null,
        userModalOrganizationId: !state.modal.isOpen ? organizationId : null,
      },
    }),
    [toggleDeactivateUserModal]: (state, { payload: { username, organizationId } }) => ({
      ...state,
      modal: {
        ...state.modal,
        type: modalTypes.deactivateUser,
        isOpen: !state.modal.isOpen,
        userModalUsername: username,
        userModalOrganizationId: !state.modal.isOpen ? organizationId : null,
      },
    }),
    [toggleUserLimitModal]: (state) => ({
      ...state,
      modal: {
        ...state.modal,
        type: modalTypes.userLimit,
        isOpen: !state.modal.isOpen,
      },
    }),
    [toggleUserCreationActiveLimitModal]: (state, { payload: { username } }) => ({
      ...state,
      modal: {
        ...state.modal,
        type: modalTypes.userCreationActiveLimit,
        isOpen: !state.modal.isOpen,
        userModalUsername: username,
      },
    }),
    // add notification to list for adding via Notifier Component
    [addNotification]: (state, { payload: { notification } }) => ({
      ...state,
      notificationsToAdd: state.notificationsToAdd.concat([notification]),
    }),
    // upon successfully adding notification call this
    [successAddNotification]: (state, { payload: { notificationUid } }) => {
      return {
        ...state,
        notificationsToAdd: state.notificationsToAdd.filter(
          (notification) => notification.uid !== notificationUid,
        ),
        notifications: state.notifications.concat([notificationUid]),
      };
    },
    [updateLoginMessage]: (state, { payload: { message } }) => ({
      ...state,
      loginMessage: message,
    }),
    [clearLoginMessage]: (state, { payload }) => ({
      ...state,
      loginMessage: '',
    }),
    // upon removing this call this
    [successRemoveNotification]: (state, { payload: { notificationUid } }) => {
      return {
        ...state,
        notifications: state.notifications.filter((uid) => uid !== notificationUid),
      };
    },
    [showNewReleaseNotification]: (state, { payload: releaseVersion }) => ({
      ...state,
      releaseVersion,
      newReleaseNotificationVisible: true,
    }),
    [hideNewReleaseNotification]: (state) => ({
      ...state,
      newReleaseNotificationVisible: false,
    }),
    [showSessionTimeoutNotification]: (state) => ({
      ...state,
      sessionTimeoutNotificationVisible: true,
    }),
    [hideSessionTimeoutNotification]: (state) => ({
      ...state,
      sessionTimeoutNotificationVisible: false,
    }),
    [combineActions(
      rejectCreateOrganization,
      rejectUpdateOrganization,
      rejectCreateUser,
      rejectEditUser,

      rejectCreateVehicle,
    )]: (state, { payload }) => ({
      ...state,
      modalFormMessage: payload.errorMessage,
    }),
  },
  defaultState,
);

export default ui;
