import DevicesAndBillingSummaryProvider from 'legacy/features/billing/services/providers/DevicesAndBillingSummaryProvider/DevicesAndBillingSummaryProvider';
import DevicesAndBillingSummary from 'legacy/features/billing/ui/components/DevicesAndBillingSummary/DevicesAndBillingSummary';
import PageListWrapper, { PageListHead, PageListTitle } from 'legacy/shared/styles/custom/PageList';

const DevicesAndSubscriptionsPage = () => {
  return (
    <DevicesAndBillingSummaryProvider>
      <PageListWrapper>
        <PageListHead stackable subscriptions>
          <PageListTitle>Devices and Subscriptions</PageListTitle>
        </PageListHead>
        <DevicesAndBillingSummary />
      </PageListWrapper>
    </DevicesAndBillingSummaryProvider>
  );
};

export default DevicesAndSubscriptionsPage;
