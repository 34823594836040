import { logWcpError } from 'legacy/core/error/utilities/ErrorLogger';
import { useDeleteVsgFromSubscriptionMutation } from 'legacy/features/billing/services/reactQuery/mutations/useDeleteVsgFromSubscriptionMutation';
import SimpleConfirmationModal from 'legacy/shared/controls/WcpModal/v1/SimpleConfirmationModal';

const PermanentlyRemoveVsgModal = ({
  modalData: { show, vsgDuid, formattedDeviceId },
  handleCloseModal,
}) => {
  const deleteVsgFromSubscriptionMutation = useDeleteVsgFromSubscriptionMutation();
  return (
    show && (
      <SimpleConfirmationModal
        handleCloseModal={handleCloseModal}
        isLoading={deleteVsgFromSubscriptionMutation.isPending}
        submitHandler={async () => {
          try {
            let result = await deleteVsgFromSubscriptionMutation.mutateAsync({
              vsgDuid,
            });
          } catch (err) {
            logWcpError(err);
          } finally {
            handleCloseModal();
          }
        }}
        bodyText={`Do you really want to remove:`}
        secondaryLineBoldedItem={`${formattedDeviceId}?`}
        secondaryBodyText={
          'Warning! Removing this VSG will delete it from any associated subscriptions and will remove it from any associated vehicle. This action cannot be undone.'
        }
        title={'Permanently Remove VSG'}
        confirmText="Remove VSG"
        cancelText="Cancel"
      />
    )
  );
};

export default PermanentlyRemoveVsgModal;
