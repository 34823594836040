import React from 'react';
import AnimatedField from 'legacy/shared/controls/AnimatedField/v1/AnimatedField';

const VehicleAliasField = ({
  formikProps: { values, touched, errors, handleChange, handleBlur },
}) => {
  return (
    <div title="Vehicle alias will be displayed on the live map in place of the vehicle name whenever an alias is applied. This field should not be used for personal identifiable information.">
      <AnimatedField
        name="alias"
        placeholder="Alias"
        value={values.alias}
        touched={touched.alias}
        validationError={errors.alias}
        handleChange={handleChange}
        handleBlur={handleBlur}
        disabled={false}
        infoIcon
      />
    </div>
  );
};

export default VehicleAliasField;
