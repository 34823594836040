import {
  SummaryLabel,
  SummaryValue,
  SubscriptionOverviewTitle,
  SummaryLineWrapper,
  SubscriptionSummaryWrapper,
} from 'legacy/shared/styles/custom/DevicesAndBilling';

const SubscriptionSummaryHeader = ({ organizationSubscriptionSummary }) => {
  return (
    <SubscriptionSummaryWrapper>
      <SubscriptionOverviewTitle>Subscription Overview</SubscriptionOverviewTitle>
      <SummaryLineWrapper>
        <SummaryLabel>Total: </SummaryLabel>
        <SummaryValue>{organizationSubscriptionSummary.seatsTotal}</SummaryValue>
      </SummaryLineWrapper>
      <SummaryLineWrapper>
        <SummaryLabel>Standard:</SummaryLabel>{' '}
        <SummaryValue>
          {organizationSubscriptionSummary.seatsBasicUsed} of{' '}
          {organizationSubscriptionSummary.seatsBasicTotal} allocated
        </SummaryValue>
      </SummaryLineWrapper>
      <SummaryLineWrapper>
        <SummaryLabel>Premium:</SummaryLabel>{' '}
        <SummaryValue>
          {organizationSubscriptionSummary.seatsPremiumUsed} of{' '}
          {organizationSubscriptionSummary.seatsPremiumTotal} allocated
        </SummaryValue>
      </SummaryLineWrapper>
      <SummaryLineWrapper>
        <SummaryLabel>Next Expiration:</SummaryLabel>{' '}
        <SummaryValue>{organizationSubscriptionSummary.formattedNextExpirationDate}</SummaryValue>
      </SummaryLineWrapper>
    </SubscriptionSummaryWrapper>
  );
};

export default SubscriptionSummaryHeader;
