import React from 'react';
import styled from 'styled-components';

const StyledTabContentDiv = styled.div``;

const WcpTabItem = ({ children }) => {
  return <StyledTabContentDiv>{children}</StyledTabContentDiv>;
};

export default WcpTabItem;
