import { useRef } from 'react';
import { v4 as uuidv4 } from 'uuid';

import { Checkbox, CheckboxInput, CheckboxLabel } from 'legacy/shared/styles/custom/Checkbox';
import CheckboxInner from 'legacy/shared/styles/custom/CheckboxInner';
import { TableCheckboxFilterSpan } from 'legacy/shared/styles/custom/PageList';

const WcpCheckbox = ({ label, handleChange, checked, dataCyId, disabled }) => {
  return (
    // in the future we can explore making this a traditonal checkbox control-wise, but for now we are using the existing controls that the majority of the app uses
    <Checkbox singleCheck disabled={disabled}>
      <CheckboxInner
        data-cy={dataCyId}
        onClick={handleChange}
        isChecked={checked}
        disabledStyleProp={disabled}
      />
      <TableCheckboxFilterSpan htmlFor={dataCyId} onClick={handleChange}>
        {label}
      </TableCheckboxFilterSpan>
    </Checkbox>
  );
};

export default WcpCheckbox;
