import MaintenanceLogCard from 'legacy/features/maintenance/components/MaintenanceLogs/mobile/MaintenanceLogCard';
import { Cards } from 'legacy/shared/styles/custom/MobileCard';

const MaintenanceLogsCardContainer = ({ records }) => {
  return (
    <div>
      <Cards>
        {records.map((fld) => (
          <MaintenanceLogCard log={fld} />
        ))}
      </Cards>
    </div>
  );
};

export default MaintenanceLogsCardContainer;
