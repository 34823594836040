import AvatarControl from 'legacy/shared/controls/WcpForm/v2/components/fields/WcpAvatarControlField';
import UserEmailField from 'legacy/features/organizations/components/forms/UserForm/inputs/UserEmailField';
import FirstNameField from 'legacy/features/organizations/components/forms/UserForm/inputs/FirstNameField';
import LastNameField from 'legacy/features/organizations/components/forms/UserForm/inputs/LastNameField';
import UserPhoneNumberField from 'legacy/features/organizations/components/forms/UserForm/inputs/UserPhoneNumberField';
import UsernameField from 'legacy/features/organizations/components/forms/UserForm/inputs/UsernameField';
import UserRoleField from 'legacy/features/organizations/components/forms/UserForm/dropdowns/UserRoleField';
import UserTitleField from 'legacy/features/organizations/components/forms/UserForm/inputs/UserTitleField';
import { userValidationSchema } from 'legacy/features/organizations/components/forms/UserForm/validation/userValidationSchema';
import {
  StyledDivFormCol,
  StyledDivFormGridRow,
  StyledDivFormRow,
} from 'legacy/shared/controls/WcpForm/v2/styles/WcpFormStyles';
import WcpForm from 'legacy/shared/controls/WcpForm/v2/WcpForm';
import useCreateUserMutation from 'legacy/features/users/api/mutations/useCreateUserMutation';

const CreateOrganizationUserForm = ({ organization, handleCancel, handleSubmitCompleted }) => {
  const createUserMutation = useCreateUserMutation();

  // todo generate empty object from mapping
  const initialUserFormValues = {
    username: '',
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '1',
    userTitle: '',
    userRole: '',
    optOutOfEmails: false,
  };

  const handleSubmit = async ({ formValues }) => {
    await createUserMutation.mutateAsync({
      user: formValues,
      targetOrganizationId: organization.organizationId,
    });

    handleSubmitCompleted();
  };

  return (
    <WcpForm
      formData={initialUserFormValues}
      handleSubmit={handleSubmit}
      handleCancel={handleCancel}
      validationSchema={userValidationSchema}
      submitButtonText="Create User"
      showFormHeader={false}
    >
      <StyledDivFormGridRow>
        <StyledDivFormCol>
          <StyledDivFormRow>
            <AvatarControl />
          </StyledDivFormRow>
        </StyledDivFormCol>
        <StyledDivFormCol>
          <UsernameField />
          <FirstNameField />
          <LastNameField />
          <UserEmailField />
          <UserPhoneNumberField />
          <UserTitleField />
          <UserRoleField organization={organization} />
        </StyledDivFormCol>
      </StyledDivFormGridRow>
    </WcpForm>
  );
};

export default CreateOrganizationUserForm;
