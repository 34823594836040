import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import {
  UnsubscribeText,
  UnsubscribeWrapperDiv,
  WCPLogo,
} from 'legacy/shared/styles/custom/Unsubscribe';
import wcpLogo from 'assets/images/wcpLogo@2x.png';
import { logWcpError } from 'legacy/core/error/utilities/ErrorLogger';
import useUnsubscribeMutation from 'legacy/core/mutations/useUnsubscribeMutation';

const UnsubscribePage = () => {
  const { token } = useParams();
  const mutation = useUnsubscribeMutation();
  const unsubscribe = async ({ token }) => {
    try {
      let response = await mutation.mutateAsync({
        token: token,
      });
    } catch (err) {
      logWcpError(err);
    }
  };
  useEffect(() => {
    unsubscribe({ token });
  }, []);

  return (
    <UnsubscribeWrapperDiv>
      <WCPLogo src={wcpLogo} alt="Whelen Cloud Platform®" />
      <UnsubscribeText>
        You have been successully unsubscribed from all Whelen Cloud Platform email communications.
        <br />
        <br />
        You can re-subscribe on your profile’s edit page.
      </UnsubscribeText>
    </UnsubscribeWrapperDiv>
  );
};

export default UnsubscribePage;
