import mapboxgl from '!mapbox-gl';
import { isEmpty } from 'lodash';
import React, { useContext, useEffect, useImperativeHandle, useRef } from 'react';

import { logWcpError } from 'legacy/core/error/utilities/ErrorLogger';
import { LivemapApi } from 'legacy/features/livemap/provider/LivemapContextProvider';
import {
  MAP_RESPONDING_VEHICLES_LAYER_NAME,
  MAP_VEHICLES_SOURCE_NAME,
  MapData,
} from 'legacy/features/livemap/helpers/mapData';
import { vehicleAddonAlertLevels } from 'legacy/features/vehicles/constants/vehicleAddonAlertLevels';
import colors from 'legacy/shared/constants/colors';
import { MAPBOX_STYLE, mapboxAccessToken, whelenGpsCoordinates } from 'legacy/shared/constants/map';
import {
  vehicleOnlineStatusViewData,
  vehicleRespondingStatusViewData,
  vehicleTypeViewData,
} from 'legacy/shared/constants/vehicle';
import useGetVehicleMapBounds from 'legacy/shared/utilities/hooks/useGetVehicleMapBounds';
import {
  getEnableVehicleMapNamesInLocal,
  getLastMapLocationInLocal,
  setLastMapLocationInLocal,
} from 'legacy/shared/utilities/misc/localStore';
import { getVehicleGpsCoordinates } from 'legacy/shared/utilities/helpers/mapbox/MapAnimationUtils';

import 'mapbox-gl/dist/mapbox-gl.css';

const { mainVehicles, respondingVehicles, labels, alerts } = MapData.layers;

const MapboxComponent = ({}, ref) => {
  const {
    helpers: { handleVehicleClick },
    actions: { setMapboxLoaded, setFocusedZoomLevel },
    state: { visibleMapVehicles, isMapboxLoaded, selectedVehicle },
  } = useContext(LivemapApi);
  const mapboxApiRef = useRef(null);
  const mapboxDomRef = useRef(null);

  // exposes mapbox api handle and render method to parent component
  useImperativeHandle(ref, () => {
    return {
      getMapboxApiHandle: () => mapboxApiRef.current,
      renderVehicles: (vehicles) => renderVehicles(vehicles),
    };
  }, [isMapboxLoaded, selectedVehicle]);

  // helper function to add layer if it doesn't exist
  const addMapboxLayer = (mapboxApi, layer) => {
    if (!mapboxApi.getLayer(layer.id)) mapboxApi.addLayer(layer);
  };

  // helper function to register map events
  const registerMapEvents = (mapboxApi) => {
    // see https://github.com/mapbox/mapbox-gl-js/issues/8691 for a discussion on whether to use style.load or styledata
    // add source and layers here
    mapboxApi.on('styledata', (e) => {
      // add source
      if (!mapboxApi.getSource(MapData.source.id)) {
        mapboxApi.addSource(MapData.source.id, MapData.source.data);
      }

      // set initial label visibility must be done here
      labels.layout.visibility = getEnableVehicleMapNamesInLocal() ? 'visible' : 'none';

      // add layers - order matters
      addMapboxLayer(mapboxApi, mainVehicles);
      addMapboxLayer(mapboxApi, respondingVehicles);
      addMapboxLayer(mapboxApi, labels);
      addMapboxLayer(mapboxApi, alerts);

      if (!isMapboxLoaded) {
        setMapboxLoaded(true);
      }
    });

    // when map is moved, save the bounds to local storage
    mapboxApi.on('moveend', (e) => {
      if (mapboxApi) {
        const bounds = mapboxApi.getBounds();

        setLastMapLocationInLocal([
          [bounds.getWest(), bounds.getSouth()],
          [bounds.getEast(), bounds.getNorth()],
        ]);
      }
    });

    // select map vehicle on click. sinc responding vehicles are rendered on top of main vehicles, we need to check for responding vehicles layer
    mapboxApi.on('click', MAP_RESPONDING_VEHICLES_LAYER_NAME, (event) => {
      let focusedZoomLevel = mapboxApi.getZoom();
      setFocusedZoomLevel(focusedZoomLevel);
      let id = event.features[0].properties.id;
      handleVehicleClick(id);
    });

    // on mouse enter, change cursor to pointer
    mapboxApi.on('mouseenter', MAP_RESPONDING_VEHICLES_LAYER_NAME, () => {
      mapboxApi.getCanvas().style.cursor = 'pointer';
    });

    // on mouse leave, change cursor back to default
    mapboxApi.on('mouseleave', MAP_RESPONDING_VEHICLES_LAYER_NAME, () => {
      mapboxApi.getCanvas().style.cursor = '';
    });
  };

  // helper function to get alert level mapbox asset name
  const getAlertLevelMapboxAssetName = (addons) => {
    if (addons.some((a) => a.alert_level === vehicleAddonAlertLevels[3].label && a.is_active))
      return 'addonHighAlert';
    else if (addons.some((a) => a.alert_level === vehicleAddonAlertLevels[2].label && a.is_active))
      return 'addonMediumAlert';
    else if (addons.some((a) => a.alert_level === vehicleAddonAlertLevels[1].label && a.is_active))
      return 'addonLowAlert';
    else return ''; // mapbox does not like null here
  };

  // helper function to get mapbox feature data for a vehicle
  const getFeatureDataForVehicle = (vehicle) => {
    return {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: getVehicleGpsCoordinates(vehicle),
      },
      properties: {
        id: vehicle.vehicle_id,
        name: vehicle.meta.alias ? vehicle.meta.alias : vehicle.meta.label,
        status: vehicle.onlineStatus,
        ...(vehicle.addons.length > 0
          ? { alert_level: getAlertLevelMapboxAssetName(vehicle.addons) }
          : {}),
        halo_color:
          vehicle.onlineStatus === vehicleOnlineStatusViewData.INACTIVE.id
            ? colors.midnight
            : colors.cherry,
        type: vehicle.meta.vehicle_type,
        icon_type: vehicle.meta.out_of_service
          ? 'out-of-service'
          : `${vehicleTypeViewData[vehicle.meta.vehicle_type].icon}${
              vehicle.onlineStatus === vehicleOnlineStatusViewData.INACTIVE.id ? '_inactive' : ''
            }`,
        //if displaying out of service icon, dont rotate
        icon_rotate: vehicle.meta.out_of_service ? '' : vehicle.gps.heading,
        circle_opacity:
          vehicle.respondingStatus === vehicleRespondingStatusViewData.RESPONDING.id ? 0.2 : 0,
        vehicle_opacity:
          !selectedVehicle?.vehicle_id || vehicle.vehicle_id === selectedVehicle?.vehicle_id
            ? 1
            : 0.5,
      },
    };
  };

  // helper function to render vehicles on map
  const renderVehicles = (vehicles) => {
    if (!vehicles) return;

    let featureCollection = {
      type: 'FeatureCollection',
      features: vehicles.map((av) => getFeatureDataForVehicle(av)),
    };
    let source;
    if (isMapboxLoaded) {
      try {
        source = mapboxApiRef.current.getSource(MAP_VEHICLES_SOURCE_NAME);
        source && source.setData(featureCollection);
      } catch (err) {
        logWcpError(err);
      }
    }
  };

  // helper function to get map bounds for visible vehicles
  const getSessionBounds = () => {
    if (!visibleMapVehicles) return whelenGpsCoordinates;

    let lastMapLocation = getLastMapLocationInLocal();

    const currentVisibleVehicleBounds = useGetVehicleMapBounds({ vehicles: visibleMapVehicles });

    let sessionBounds =
      lastMapLocation === null || lastMapLocation === whelenGpsCoordinates
        ? currentVisibleVehicleBounds
        : lastMapLocation;

    setLastMapLocationInLocal(sessionBounds);

    return sessionBounds;
  };

  const initializeMap = () => {
    // if mapboxApiRef is not null, then the map has already been initialized
    if (mapboxApiRef.current || mapboxDomRef.current === null || visibleMapVehicles === null)
      return;

    mapboxgl.accessToken = mapboxAccessToken;

    const bounds = getSessionBounds();

    const mapboxOptions = {
      container: mapboxDomRef.current,
      style: MAPBOX_STYLE.STREET_MODE_URL,
      dragRotate: false,
      maxZoom: 19,
      bounds,
      fitBoundsOptions: { padding: 50, maxZoom: 15 },
    };

    // create map object
    const mapboxApi = new mapboxgl.Map(mapboxOptions);

    // register map events
    registerMapEvents(mapboxApi);

    // set mapboxApiRef to the mapbox object
    mapboxApiRef.current = mapboxApi;
  };

  useEffect(() => {
    initializeMap();
  }, [visibleMapVehicles]);

  return (
    <div
      ref={(el) => (mapboxDomRef.current = el)}
      style={{
        position: 'relative',
        width: '100%',
        height: '100%',
      }}
    />
  );
};

export default React.forwardRef(MapboxComponent);
