import { useEffect, useState } from 'react';
import Media from 'react-media';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { getLogsAnalytics } from 'legacy/core/api/analytics';
import AnalyticsDashboardCard from 'legacy/features/analyticsDashboard/mobile/AnalyticsDashboardCard';
import { SORT_ORDER } from 'legacy/shared/constants/table';
import {
  AnalyticsCheckboxFilterWrapper,
  AnalyticsDropdown,
} from 'legacy/shared/styles/custom/Analytics';
import { ActionButtonText, TextButton } from 'legacy/shared/styles/custom/Button';
import { Checkbox } from 'legacy/shared/styles/custom/Checkbox';
import CheckboxInner from 'legacy/shared/styles/custom/CheckboxInner';
import { SimpleDropdownItem } from 'legacy/shared/styles/custom/DropdownMenu';
import { Cards } from 'legacy/shared/styles/custom/MobileCard';
import StyledReactSelect from 'legacy/shared/styles/custom/SelectField';
import { TableFilterSpan } from 'legacy/shared/styles/custom/Table';
import IconSvgComponent from 'legacy/shared/controls/WcpIcon/IconSvgComponent';
import ViewLogsMenuItem from 'legacy/features/vehicles/components/menus/ViewLogsMenuItem';
import ActionMenu from 'legacy/shared/controls/WcpTable/v1/ActionMenu';
import { sizes } from 'legacy/shared/utilities/misc/media';
import WcpTable from 'legacy/shared/controls/WcpTable/v2/WcpTable';
import ExcessiveDurationPlaybackModal from 'legacy/features/analyticsDashboard/components/ExcessiveDurationPlaybackEvent';

const AnalyticsDashboardResultsTable = ({ vehicleReportData, startDate, endDate }) => {
  const navigate = useNavigate();
  const filteredOrganizationId = useSelector((state) => state.user.filteredOrganizationId);
  const [resultsFilterCheckboxIsChecked, setResultsFilterCheckboxIsChecked] = useState(false);
  const [percentOfflineDropdownValue, setPercentOfflineDropdownValue] = useState(50);
  const [onlineOfflineDropdownValue, setOnlineOfflineDropdownValue] = useState('offline');
  const [filteredReportData, setFilteredReportData] = useState(vehicleReportData);
  const [logsQueryResults, setLogsQueryResults] = useState(null);
  const [topRespondingSpeedVehicleId, setTopRespondingSpeedVehicleId] = useState(null);
  const [excessiveDurationPlaybackModalData, setExcessiveDurationPlaybackModalData] = useState({
    show: false,
  });

  const getActionMenu = ({ vehicle }) => {
    return (
      <ActionMenu>
        <SimpleDropdownItem>
          <ViewLogsMenuItem vehicle={vehicle} />
        </SimpleDropdownItem>
      </ActionMenu>
    );
  };
  useEffect(() => {
    vehicleReportData.forEach((log) => {
      log.hoursOnlineDisplay = log.hours_online.toString();
      log.hoursOfflineDisplay = log.hours_offline.toString();
      log.hoursRespondingDisplay = log.hours_responding.toString();
      log.respondingCountDisplay = log.responding_count.toString();
      log.topRespondingSpeedDisplay = log.top_responding_speed.toString();
      log.vehicleNameDisplay = log.vehicle_name;
    });
    if (resultsFilterCheckboxIsChecked) {
      setFilteredReportData(
        vehicleReportData.filter(
          (v) =>
            ((onlineOfflineDropdownValue === 'offline' ? v.hours_offline : v.hours_online) /
              v.hours_total) *
              100 >
            percentOfflineDropdownValue,
        ),
      );
    } else {
      setFilteredReportData(vehicleReportData);
    }
  }, [
    vehicleReportData,
    resultsFilterCheckboxIsChecked,
    percentOfflineDropdownValue,
    onlineOfflineDropdownValue,
  ]);

  const getTopSpeedEvent = async (vehicleId) => {
    startDate, endDate;
    try {
      let data = await getLogsAnalytics({
        startDate: startDate,
        endDate: endDate,
        groupId: null,
        vehicleId: vehicleId,
        organizationId: filteredOrganizationId,
      });
      setLogsQueryResults(data.response.message);
      setTopRespondingSpeedVehicleId(vehicleId);
    } catch (e) {
      console.log('Analytics query failed:' + e);
    }
  };

  useEffect(() => {
    if (logsQueryResults) {
      let topRepsondingSpeedOfQuery = null;
      let topRespondingSpeedEventTimestamp = null;
      let topRespondingSpeedEventDuration = null;
      for (let i = 0; i < logsQueryResults.events.length; i++) {
        if (logsQueryResults.events[i].responding) {
          const eventTopSpeed = logsQueryResults.events[i].top_speed;
          if (eventTopSpeed > topRepsondingSpeedOfQuery) {
            topRepsondingSpeedOfQuery = eventTopSpeed;
            topRespondingSpeedEventTimestamp = logsQueryResults.events[i].timestamp;
            topRespondingSpeedEventDuration = logsQueryResults.events[i].duration;
          }
        }
      }
      // redirect to vehicle playback with the specified event details
      if (topRespondingSpeedEventDuration < 86400) {
        navigate(
          `/playback/${encodeURIComponent(topRespondingSpeedVehicleId)}/${encodeURIComponent(
            topRespondingSpeedEventTimestamp,
          )}/${encodeURIComponent(topRespondingSpeedEventDuration)}`,
        );
      } else {
        setExcessiveDurationPlaybackModalData((prevState) => ({
          ...prevState,
          show: true,
        }));
      }
    }
  }, [topRespondingSpeedVehicleId]);

  const columns = [
    {
      title: 'Vehicle Name',
      displayProperty: 'vehicleNameDisplay',
      className: 'name',
      width: '15%',
      render({ cellValue, rowData, cellJsx }) {
        return (
          <span className="vehicleNameAndIcon" title={'Vehicle Name: ' + cellValue}>
            {rowData.loading_status ? (
              <TableSpinner />
            ) : (
              <IconSvgComponent svgFileName={'car-outline'} alt="Vehicle" />
            )}
            {cellJsx}
          </span>
        );
      },
    },
    {
      title: 'Hours Online',
      displayProperty: 'hoursOnlineDisplay',
      sortProperty: 'hours_online',
    },
    {
      title: 'Hours Offline',
      displayProperty: 'hoursOfflineDisplay',
      sortProperty: 'hours_offline',
    },
    {
      title: 'Hours Responding',
      displayProperty: 'hoursRespondingDisplay',
      sortProperty: 'hours_responding',
    },
    {
      title: 'Responding Events',
      displayProperty: 'respondingCountDisplay',
      sortProperty: 'responding_count',
    },
    {
      title: 'Top Responding Speed',
      displayProperty: 'topRespondingSpeedDisplay',
      sortProperty: 'top_responding_speed',
      render({ cellValue, rowData, cellJsx }) {
        return rowData.top_responding_speed !== 0 ? (
          <TextButton
            topSpeed
            type="button"
            onClick={() => getTopSpeedEvent(rowData.vehicle_id)}
            title={'Top Responding Speed: ' + cellValue}
          >
            <ActionButtonText>{cellJsx}</ActionButtonText>
          </TextButton>
        ) : (
          <span title={'Top Responding Speed: ' + cellValue}>{cellValue}</span>
        );
      },
    },
    {
      title: '',
      className: 'action',
      searchEnabled: false,
      render: ({ rowData, cellValue }) =>
        getActionMenu({
          vehicle: rowData,
        }),
    },
  ];

  let percentOptions = [];
  for (let i = 0; i <= 10; i++) {
    let percentageValue = i * 10;
    let percentageText = percentageValue + '%';
    percentOptions.push({ label: percentageText, value: percentageValue });
  }
  let onlineOfflineOptions = [];
  onlineOfflineOptions.push(
    {
      label: 'offline',
      value: 'offline',
    },
    {
      label: 'online',
      value: 'online',
    },
  );

  return (
    <>
      <AnalyticsCheckboxFilterWrapper>
        <Checkbox singleCheck>
          <CheckboxInner
            isChecked={resultsFilterCheckboxIsChecked}
            onClick={() => setResultsFilterCheckboxIsChecked(!resultsFilterCheckboxIsChecked)}
          />
        </Checkbox>

        <TableFilterSpan>
          Only display vehicles
          <AnalyticsDropdown>
            <StyledReactSelect
              name="onlineOffline"
              defaultValue={{ label: onlineOfflineDropdownValue }}
              onChange={(e) => setOnlineOfflineDropdownValue(e.value)}
              isClearable={false}
              isSearchable={false}
              options={onlineOfflineOptions}
            />
          </AnalyticsDropdown>
          for more than
          <AnalyticsDropdown>
            <StyledReactSelect
              name="percentOffline"
              defaultValue={{
                label: percentOptions.find((p) => p.value === percentOfflineDropdownValue).label,
              }}
              onChange={(e) => setPercentOfflineDropdownValue(e.value)}
              isClearable={false}
              isSearchable={false}
              options={percentOptions}
            />
          </AnalyticsDropdown>
          of the time.
        </TableFilterSpan>
      </AnalyticsCheckboxFilterWrapper>
      <Media
        queries={{
          tablet: { maxWidth: sizes.tablet },
          mobile: { maxWidth: sizes.mobile },
        }}
      >
        {(matches) =>
          matches.tablet ? (
            <div>
              <Cards>
                {filteredReportData.map((vehicle) => (
                  <AnalyticsDashboardCard
                    vehicle={vehicle}
                    actionMenuComponent={() => getActionMenu(vehicle)}
                  />
                ))}
              </Cards>
            </div>
          ) : (
            <div>
              <WcpTable
                columns={columns}
                tableSourceData={filteredReportData}
                emptyText="No data to display. Please click calculate to proceed."
              />
              <ExcessiveDurationPlaybackModal
                modalData={excessiveDurationPlaybackModalData}
                handleCloseModal={() =>
                  setExcessiveDurationPlaybackModalData((prevState) => ({
                    ...prevState,
                    show: false,
                  }))
                }
              />
            </div>
          )
        }
      </Media>
    </>
  );
};

export default AnalyticsDashboardResultsTable;
