import { useState } from 'react';

import IntegrationsSummary from 'legacy/features/integrations/ui/components/IntegrationsSummary';
import { ActionButtonWithIcon } from 'legacy/shared/styles/custom/Button';
import { AlertsActionButtonsDiv } from 'legacy/shared/styles/custom/ManageAlerts';

import PageListWrapper, {
  PageListActionButtonContainer,
  PageListHead,
  PageListTitle,
} from 'legacy/shared/styles/custom/PageList';
import IconSvgComponent from 'legacy/shared/controls/WcpIcon/IconSvgComponent';
import { TAB_NAMES } from 'legacy/features/integrations/constants/constants';
import { TabRadioWrapper } from 'legacy/shared/styles/custom/Tabs';
import PartnershipsModalProvider from 'legacy/features/integrations/providers/modal/PartnershipsModalProvider';

const IntegrationsPage = () => {
  const [tabHeaderSelection, setTabHeaderSelection] = useState(TAB_NAMES.PARTNERSHIPS);
  return (
    <PartnershipsModalProvider>
      <PageListWrapper>
        <PageListHead stackable smallBottomMargin>
          <PageListTitle>Integrations</PageListTitle>

          <PageListActionButtonContainer>
            {tabHeaderSelection === TAB_NAMES.UNIVERSAL_API && (
              <AlertsActionButtonsDiv>
                {tabHeaderSelection === TAB_NAMES.UNIVERSAL_API && (
                  <ActionButtonWithIcon integrations>
                    <IconSvgComponent svgFileName="plus-simple" alt="Create" />
                    GENERATE NEW UNIVERSAL API KEY
                  </ActionButtonWithIcon>
                )}
              </AlertsActionButtonsDiv>
            )}
          </PageListActionButtonContainer>
        </PageListHead>
        <>
          <TabRadioWrapper>
            <input
              type="radio"
              id="radioPartnerships"
              name="radioIntegrationType"
              value="partnerships"
              checked={tabHeaderSelection === TAB_NAMES.PARTNERSHIPS}
              onClick={() => {
                setTabHeaderSelection(TAB_NAMES.PARTNERSHIPS);
              }}
            />
            <label for="radioPartnerships" title="View Partnership Integrations">
              PARTNERSHIPS
            </label>

            <input
              type="radio"
              id="radioUniversalApi"
              name="radioIntegrationType"
              value="universalApi"
              checked={tabHeaderSelection === TAB_NAMES.UNIVERSAL_API}
              onClick={() => {
                setTabHeaderSelection(TAB_NAMES.UNIVERSAL_API);
              }}
            />
            <label for="radioUniversalApi" title="View Universal API Intregrations">
              UNIVERSAL API
            </label>
          </TabRadioWrapper>
          <IntegrationsSummary selectedTab={tabHeaderSelection} />
        </>
      </PageListWrapper>
    </PartnershipsModalProvider>
  );
};

export default IntegrationsPage;
