import { useQuery } from '@tanstack/react-query';

import { apiCall } from 'legacy/core/api/API';
import { userMapping } from 'legacy/features/users/utilities/userMapping';
import { mapToFrontend } from 'legacy/shared/utilities/helpers/mapping/mapper';

const useFetchOrganizationFseUsersQuery = ({ organizationId }) => {
  return useQuery({
    queryKey: ['fetchOrganizationFSEs'],
    queryFn: async () =>
      await apiCall({
        endpoint: 'cloudfrontAPI',
        path: `/organization/${organizationId}/associated_fse`,
        method: 'get',
      }),
    select: (data) =>
      data.body.message.associated_fse.map((fseUser) => mapToFrontend(fseUser, userMapping)),
  });
};

export default useFetchOrganizationFseUsersQuery;
