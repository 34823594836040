import { useState } from 'react';
import { useDispatch } from 'react-redux';

import { requestUpdateUserPassword } from 'legacy/core/redux/user/actions';
import ChangePasswordModal from 'legacy/features/login/ChangePasswordModal';
import ModalPortal from 'legacy/shared/controls/WcpModal/v1/ModalPortal';
import { ActionButtonText, TextButton } from 'legacy/shared/styles/custom/Button';

const ChangePasswordButton = () => {
  const dispatch = useDispatch();

  const [changePasswordModalActive, setChangePasswordModalActive] = useState(false);

  return (
    <div>
      <TextButton
        type="button"
        onClick={async () => {
          dispatch(requestUpdateUserPassword());
          setChangePasswordModalActive(true);
        }}
      >
        <ActionButtonText>CHANGE PASSWORD</ActionButtonText>
      </TextButton>
      {/* TODO - convert to use modal contract */}
      {changePasswordModalActive ? (
        <ModalPortal onRequestClose={() => setChangePasswordModalActive(false)}>
          <ChangePasswordModal onSuccess={() => setChangePasswordModalActive(false)} />
        </ModalPortal>
      ) : null}
    </div>
  );
};

export default ChangePasswordButton;
