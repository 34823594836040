import useAlertFormsProviderContext from 'legacy/features/alerts/services/providers/AlertFormsProvider/hooks/useAlertFormsProviderContext';
import { useFormikContext } from 'formik';
import { useEffect } from 'react';

import { AlertTriggerValueFieldDiv } from 'legacy/shared/styles/custom/ManageAlerts';
import AnimatedField from 'legacy/shared/controls/AnimatedField/v1/AnimatedField';

const AlertTriggerValueField = () => {
  const {
    state: { alert },
    actions: { updateAlertTriggerValueAction },
  } = useAlertFormsProviderContext();

  const formik = useFormikContext();

  const { alertDetails } = alert;
  const { activeInactiveDropdownOption } = alertDetails;

  useEffect(() => {
    formik.setFieldValue('alertTriggerValueField', activeInactiveDropdownOption?.value, true);
  }, [activeInactiveDropdownOption?.value]);

  useEffect(() => {
    formik.setFieldValue(
      'alertTriggerValueField',
      alertDetails.alertInfo.triggerValueObject.value,
      true,
    );
  }, [alertDetails]);

  return (
    <AlertTriggerValueFieldDiv>
      <AnimatedField
        name="alertTriggerValueField"
        required
        forceAnimated
        smallerTextMobile
        touched={formik?.touched?.alertTriggerValueField}
        validationError={formik?.errors?.alertTriggerValueField}
        label={'Value'}
        placeholder={'Value'}
        value={formik.values.alertTriggerValueField}
        handleBlur={formik.handleBlur}
        handleChange={(e) => {
          updateAlertTriggerValueAction({ triggerValue: +e.target.value });
          formik.handleChange(e);
        }}
      />
    </AlertTriggerValueFieldDiv>
  );
};

export default AlertTriggerValueField;
