import { activeTimeOptions } from 'legacy/features/geofences/geofences/constants/dropdownOptions';
import { alphanumericExtendedRegex } from 'legacy/shared/utilities/helpers/validation/validationRegularExpressions';

export const validateGeofenceFields = ({ geofenceCardData, setGeofenceValidationMessage }) => {
  let activeRangeOption = geofenceCardData.activeTimeType === activeTimeOptions[2].value;
  let expiresAtOption = geofenceCardData.activeTimeType === activeTimeOptions[1].value;
  let rangeIsValid = false;
  let expiresAtIsValid = false;
  let generalGeofenceIsValid = false;

  //active range validation
  if (activeRangeOption) {
    let timeRangeValidation = null;
    let timeZoneValidation = null;
    let atLeastOneDay = false;
    for (let i = 0; i < geofenceCardData.activeRange.length; i++) {
      if (geofenceCardData.activeRange[i].enabled === true) {
        atLeastOneDay = true;
      }
    }
    if (
      geofenceCardData.rangeStartTime === geofenceCardData.rangeEndTime ||
      geofenceCardData.rangeEndTime === '0' ||
      geofenceCardData.rangeStartTime === null ||
      geofenceCardData.rangeEndTime === null
    ) {
      timeRangeValidation = 'Please choose a valid time range.';
    } else {
      timeRangeValidation = null;
    }
    geofenceCardData.timeZone === null
      ? (timeZoneValidation = 'Please choose a time zone.')
      : (timeZoneValidation = null);
    if (timeRangeValidation !== null) {
      setGeofenceValidationMessage(timeRangeValidation);
    } else if (timeZoneValidation !== null) {
      setGeofenceValidationMessage(timeZoneValidation);
    } else if (!atLeastOneDay) {
      setGeofenceValidationMessage('Please choose at least one day');
    } else {
      rangeIsValid = true;
      setGeofenceValidationMessage(null);
    }
    //expires at validation
  } else if (expiresAtOption) {
    if (geofenceCardData.timeZone === null) {
      setGeofenceValidationMessage('Please choose a time zone.');
    } else {
      expiresAtIsValid = true;
      setGeofenceValidationMessage(null);
    }
  }
  //validation that applies to all geofences
  if (geofenceCardData.activeTimeType === null) {
    setGeofenceValidationMessage('Please choose an active time option.');
  } else if (geofenceCardData.category === null) {
    setGeofenceValidationMessage('Please choose a geofence type.');
  } else if (
    !alphanumericExtendedRegex.test(String(geofenceCardData.label)) ||
    geofenceCardData.label === ''
  ) {
    setGeofenceValidationMessage('Please enter an valid alphanumeric name ');
  } else if (geofenceCardData.label.length > 36) {
    setGeofenceValidationMessage('Please enter an name that is 36 characters or less');
  } else {
    generalGeofenceIsValid = true;
  }
  if (activeRangeOption) {
    if (rangeIsValid && generalGeofenceIsValid) {
      setGeofenceValidationMessage(null);
      return true;
    }
  } else if (expiresAtOption) {
    if (expiresAtIsValid && generalGeofenceIsValid) {
      setGeofenceValidationMessage(null);
      return true;
    }
  } else {
    if (generalGeofenceIsValid) {
      setGeofenceValidationMessage(null);
      return true;
    }
  }
};
