import { WcpTextInputField } from 'legacy/shared/controls/WcpForm/v2/components/fields/WcpTextInputField';
import { transformMaskedPhoneNumberInput } from 'legacy/shared/utilities/helpers/transformers/transformMaskedPhoneNumberInput';

const UserPhoneNumberField = ({ readOnly = false }) => {
  return (
    <WcpTextInputField
      label="Mobile Phone Number"
      dataKey={'phoneNumber'}
      showReadOnlyLabel={readOnly}
      transformInputFn={transformMaskedPhoneNumberInput}
      mask="+9 (999) 999-9999"
    />
  );
};

export default UserPhoneNumberField;
