import { useFormikContext } from 'formik';

import { alertTypeDropdownOptions } from 'legacy/features/alerts/data/ui/dropdown/alertTypeDropdownOptions';
import useAlertFormsProviderContext from 'legacy/features/alerts/services/providers/AlertFormsProvider/hooks/useAlertFormsProviderContext';
import useMakeFormikField from 'legacy/shared/controls/WcpForm/v1/hooks/useMakeFormikField';
import { FieldError } from 'legacy/shared/styles/custom/Form';
import { DropdownWrapper } from 'legacy/shared/styles/custom/ManageAlerts';
import NewFilteredDropdown from 'legacy/shared/controls/WcpFilteredDropdown/v3/NewFilteredDropdown';

const AlertTypeDropdown = () => {
  const {
    state: { alert },
    actions: { updateAlertTypeAction },
  } = useAlertFormsProviderContext();

  const { alertType } = alert.alertDetails.alertInfo;

  const formik = useFormikContext();
  useMakeFormikField({
    fieldName: 'alertTypeField',
    fieldValue: alertType,
    triggerValidation: true,
  });

  return (
    <DropdownWrapper alertType>
      <NewFilteredDropdown
        title={'Condition'}
        placeholderLabel="Select a Condition..."
        styleOptions={{
          disabled: formik.isSubmitting,
          hideClearX: true,
          required: true,
        }}
        setup={{
          dropdownOptions: alertTypeDropdownOptions,
          initialValue: alertType,
          onSelect: (selectedOption) => {
            updateAlertTypeAction({ alertType: selectedOption.value });
          },
        }}
      />

      <FieldError>{formik.touched.alertTypeField && formik.errors.alertTypeField}</FieldError>
    </DropdownWrapper>
  );
};

export default AlertTypeDropdown;
