import { InvalidReducerPayloadError } from 'legacy/core/error/classes/InvalidReducerPayloadError';
import { alertRecipientMapping } from 'legacy/features/alerts/services/mapping/definitions/alertRecipientMapping';
import { findRecipientRowSelector } from 'legacy/features/alerts/services/providers/AlertFormsProvider/utilities/findRecipientRowSelector';
import { mapToFrontend } from 'legacy/shared/utilities/helpers/mapping/mapper';
import { getValueOrFallbackIfUndefined } from 'legacy/shared/utilities/helpers/reducers/getValueOrFallbackIfUndefined';
import { validateAndExtractPayload } from 'legacy/shared/utilities/helpers/reducers/validateAndExtractPayload';

// updateAlertRecipientAction → updateAlertRecipientReducer
export const updateAlertRecipientReducer = (state, payload) => {
  const { alertNotificationGuid, recipientRowGuid, selectedRecipientValue } =
    validateAndExtractPayload({
      payload,
      mustInclude: ['recipientRowGuid', 'alertNotificationGuid', 'selectedRecipientValue'],
    });

  // get recipient row
  let recipientRow = findRecipientRowSelector(state, alertNotificationGuid, recipientRowGuid);

  // first delete any empty recipient rows in this alert notification
  const alertNotification = state.alert.alertNotifications.find(
    (n) => n.alertNotificationGuid === alertNotificationGuid,
  );

  alertNotification.alertRecipientRows = alertNotification.alertRecipientRows.filter(
    (r) => r.recipientRowGuid === recipientRowGuid || r.recipientGroup || r.recipientUser,
  );

  // this will happen when clearing a recipient row explicity
  if (selectedRecipientValue === null) {
    delete recipientRow.recipientUser;
    delete recipientRow.recipientGroup;
  } else {
    // find the recipient group
    const recipientGroup = state.allRecipientGroups.find(
      (g) => g.recipientGroupName === selectedRecipientValue,
    );
    // if recipient group is found, update recipient group
    if (recipientGroup) {
      recipientRow.recipientGroup = recipientGroup;
      delete recipientRow.recipientUser;
    }
    // otherwise, this is an individual user
    else {
      // find the user
      const user = state.organizationUsers.find((u) => u.username === selectedRecipientValue);

      let mappedUser = mapToFrontend(
        {
          username: user.username,
          given_name: user.firstName,
          family_name: user.lastName,
          // newly fetched group members do not have alert methods yet
          email: null,
          sms: null,
        },
        alertRecipientMapping,
      );

      // update recipient user
      recipientRow.recipientUser = mappedUser;

      // delete recipient group if it exists
      delete recipientRow.recipientGroup;
    }
  }
  recipientRow.recipientRowAlertMethod = null;

  // return updated state
  return {
    ...state,
  };
};
