import { alertTypeConstants } from 'legacy/features/alerts/data/enums/alertTypeConstants';
import { activeInactiveDropdownOptions } from 'legacy/features/alerts/data/ui/dropdown/activeInactiveDropdownOptions';
import { getValueOrFallbackIfUndefined } from 'legacy/shared/utilities/helpers/reducers/getValueOrFallbackIfUndefined';
import { validateAndExtractPayload } from 'legacy/shared/utilities/helpers/reducers/validateAndExtractPayload';

// updateAlertTriggerValueAction  →  updateAlertTriggerValueReducer
export const updateAlertTriggerValueReducer = (state, payload) => {
  const { triggerValue } = validateAndExtractPayload({ payload, mustInclude: ['triggerValue'] });

  let currentAlertType = state.alert.alertDetails.alertInfo.alertType;

  let newTriggerValueObject = {
    ...state.alert.alertDetails.alertInfo.triggerValueObject,
    value: triggerValue,
  };

  let newActiveInactiveDropdownOption =
    currentAlertType === alertTypeConstants.ADDON_ACTIVE ||
    currentAlertType === alertTypeConstants.CHECK_ENGINE_LIGHT
      ? {
          value: triggerValue,
          label: activeInactiveDropdownOptions.getLabelByValue(triggerValue),
        }
      : state.alert.alertDetails.decorated.activeInactiveDropdownOption;

  return {
    ...state,
    alert: {
      ...state.alert,
      alertDetails: {
        ...state.alert.alertDetails,
        alertInfo: {
          ...state.alert.alertDetails.alertInfo,
          triggerValueObject: newTriggerValueObject,
        },
        decorated: {
          ...state.alert.alertDetails.decorated,
          activeInactiveDropdownOption: newActiveInactiveDropdownOption,
        },
      },
    },
  };
};
