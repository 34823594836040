import { FieldError, FormAsterisk, FormLabelAnimated } from 'legacy/shared/styles/custom/Form';
import { IntervalInputDiv } from 'legacy/shared/styles/custom/Maintenance';
import StyledDayPickerInput from 'legacy/shared/controls/WcpDayPicker/StyledDayPickerInput';
import { getShortDateFromDatePicker } from 'legacy/shared/utilities/misc/time';

const DueDateField = ({ formikProps: { values, errors, setFieldValue } }) => {
  return (
    <IntervalInputDiv date>
      <FormLabelAnimated animated>
        Due Date (MM/DD/YYYY)<FormAsterisk>*</FormAsterisk>
      </FormLabelAnimated>
      <StyledDayPickerInput
        name="dueDate"
        placeholder={values.dueDate || 'Select a Date'}
        value={values.dueDate || 'Select a Date'}
        onDayChange={(date) => {
          const formattedDate = getShortDateFromDatePicker(date);
          setFieldValue('dueDate', formattedDate);
        }}
      />
      <FieldError maintenanceEvents>{errors.dueDate}</FieldError>
    </IntervalInputDiv>
  );
};

export default DueDateField;
