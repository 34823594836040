import { alertsMapping } from 'legacy/features/alerts/services/mapping/definitions/alertsMapping';
import { mapToBackend } from 'legacy/shared/utilities/helpers/mapping/mapper';
import { apiCall } from 'legacy/core/api/API';

export const putAlertsApi = async ({ organizationId, alert }) => {
  const mappedAlert = mapToBackend(alert, alertsMapping);
  const apiResponse = await apiCall({
    method: 'put',
    path: `/alerts/${alert.alertId}`,
    body: {
      organization_id: organizationId,
      ...mappedAlert,
    },
  });

  return apiResponse;
};
