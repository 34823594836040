import { vehiclePlaybackDateValidator } from 'legacy/features/playback/state/formik/validation/vehiclePlaybackDateValidator';
import { vehiclePlaybackEndDateValidator } from 'legacy/features/playback/state/formik/validation/vehiclePlaybackEndDateValidator';
import {
  analyticsLaunchDateValidator,
  endDateBeforeStartDateValidator,
  regexValidator,
  requiredValidator,
} from 'legacy/shared/utilities/helpers/validation/validators';
import { dateRegex } from 'legacy/shared/utilities/helpers/validation/validationRegularExpressions';
import { dateRegexError } from 'legacy/shared/utilities/helpers/validation/validationErrorMessages';

export const vehiclePlaybackDateRangeValidators = {
  startDate: requiredValidator(
    vehiclePlaybackDateValidator(regexValidator(dateRegex, dateRegexError('start date'))),
  ),
  endDate: requiredValidator(
    vehiclePlaybackDateValidator(regexValidator(dateRegex, dateRegexError('end date'))),
  ),
  endDateBeforeStartDate: endDateBeforeStartDateValidator,
  endDateWithinOneDayOfStartDate: vehiclePlaybackEndDateValidator,
  startDateAfterAnalyticsLaunch: analyticsLaunchDateValidator,
};
