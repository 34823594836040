import { alertMethodConstants } from 'legacy/features/alerts/data/enums/alertMethodConstants';
import { alertTypeConstants } from 'legacy/features/alerts/data/enums/alertTypeConstants';
import { alertRecipientMapping } from 'legacy/features/alerts/services/mapping/definitions/alertRecipientMapping';
import { mapRecipientUserAlertMethod } from 'legacy/features/alerts/services/mapping/helpers/mapRecipientUserAlertMethod';
import { findRecipientGroupSelector } from 'legacy/features/alerts/services/providers/AlertFormsProvider/utilities/findRecipientGroupSelector';
import { findRecipientRowSelector } from 'legacy/features/alerts/services/providers/AlertFormsProvider/utilities/findRecipientRowSelector';
import { mapToFrontend } from 'legacy/shared/utilities/helpers/mapping/mapper';
import { validateAndExtractPayload } from 'legacy/shared/utilities/helpers/reducers/validateAndExtractPayload';

// setGroupMembersAction → setGroupMembersReducer
export const setGroupMembersReducer = (state, payload) => {
  const { alertNotificationGuid, recipientRowGuid, fetchedRecipientGroupMembers } =
    validateAndExtractPayload({
      payload,
      mustInclude: ['alertNotificationGuid', 'recipientRowGuid', 'fetchedRecipientGroupMembers'],
    });

  const recipientRow = findRecipientRowSelector(state, alertNotificationGuid, recipientRowGuid);

  const recipientGroup = recipientRow.recipientGroup;

  if (!recipientGroup) {
    throw new Error(
      `Recipient row does not have a recipient group. Recipient row guid: ${recipientRowGuid}`,
    );
  }

  // set recipientGroupMembers to fetchedRecipientGroupMembers
  if (recipientGroup.recipientGroupMembers) {
    recipientGroup.recipientGroupMembers = recipientGroup.recipientGroupMembers.map((rgm, i) => {
      return {
        ...fetchedRecipientGroupMembers[i],
        ...rgm,
      };
    });
  } else {
    recipientGroup.recipientGroupMembers = fetchedRecipientGroupMembers;
  }
  recipientRow.recipientRowAlertMethod = recipientRow.recipientRowAlertMethod || null;

  // console.log('setGroupMembersReducer recipient Row: ', recipientRow);
  return {
    ...state,
  };
};
