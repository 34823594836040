import useAlertFormsProviderContext from 'legacy/features/alerts/services/providers/AlertFormsProvider/hooks/useAlertFormsProviderContext';
import React from 'react';
import { RemoveButton, TextButton } from 'legacy/shared/styles/custom/Button';
import { AlertRecipientRemoveButtonWrapper } from 'legacy/shared/styles/custom/ManageAlerts';
import { MobileRemoveLineItemButtonWrapper } from 'legacy/shared/styles/custom/VehicleAddons';
import IconSvgComponent from 'legacy/shared/controls/WcpIcon/IconSvgComponent';

const RemoveAlertRecipientButton = ({ alertNotificationGuid, recipientRowGuid }) => {
  const {
    actions: { removeAlertRecipientAction },
  } = useAlertFormsProviderContext();

  return (
    <AlertRecipientRemoveButtonWrapper>
      <RemoveButton
        type="button"
        lineItem
        title="Remove"
        onClick={() => removeAlertRecipientAction({ alertNotificationGuid, recipientRowGuid })}
      >
        <IconSvgComponent svgFileName={'remove-feature'} alt="Remove" />
      </RemoveButton>
      <MobileRemoveLineItemButtonWrapper>
        <TextButton
          type="button"
          alt="Remove Recipient"
          largeFont
          onClick={() => removeAlertRecipientAction({ alertNotificationGuid, recipientRowGuid })}
        >
          Remove Recipient
        </TextButton>
      </MobileRemoveLineItemButtonWrapper>
    </AlertRecipientRemoveButtonWrapper>
  );
};

export default RemoveAlertRecipientButton;
