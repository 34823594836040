import colors from 'legacy/shared/constants/colors';

export const findStartingIndex = (searchText, fullText) => {
  return fullText.toLowerCase().indexOf(searchText);
};

export const highlightSearchText = (searchText, fullText) => {
  //find where the match starts
  const startingIndex = findStartingIndex(searchText, fullText);

  //does that section start at the beginning? if not use a blank string
  const beginningOfWord = startingIndex === 0 ? '' : fullText.substring(0, startingIndex);

  //find the section to highlight
  const matchingLetters = fullText.substring(startingIndex, startingIndex + searchText.length);

  //find the remainder of the search result
  const restOfWord = fullText.substring(startingIndex + searchText.length, fullText.length);

  //return a new result with bolded matched letters
  return (
    <>
      {beginningOfWord}
      <span style={{ fontWeight: 'bolder', color: `${colors.midnight}` }}>{matchingLetters}</span>
      {restOfWord}
    </>
  );
};
