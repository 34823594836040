import { StyledModalsWrapper } from 'legacy/shared/controls/WcpModal/v2/styles/WcpModalStyles';
import WcpModal from 'legacy/shared/controls/WcpModal/v2/WcpModal';
import { createContext, useContext, useEffect, useState } from 'react';

export const WcpModalContext = createContext(null);

export const useWcpModalContext = (...modalContractKeys) => {
  // console.log('modalContractKeys', modalContractKeys);
  if (!modalContractKeys) {
    throw new Error(`A 'modalContractKey' is required in the modalContract`);
  }

  const context = useContext(WcpModalContext);
  if (!context) {
    throw new Error(`'useWcpModalContext' must be used within a 
  'WcpModalProvider'`);
  }

  const contextSlices = [];

  modalContractKeys.forEach((modalContractKey) => {
    const contextSlice = context[modalContractKey];

    if (!contextSlice) {
      throw new Error(
        `No modal contract found with the key '${modalContractKey}' in the instance of WcpModalProvider. `,
      );
    }

    contextSlices.push(contextSlice);
  });

  return contextSlices.length === 1 ? contextSlices[0] : contextSlices;
};

const generateModalContextValue = ({ modalContracts, modalProps, setModalProps }) =>
  modalContracts.reduce(
    (acc, { modalKey }) => ({
      ...acc,
      [modalKey]: {
        // state
        modalState: {
          ...modalProps[modalKey],
        },
        modalActions: {
          setModalState: (payload) => {
            setModalProps({
              ...modalProps,
              [modalKey]: {
                ...modalProps[modalKey],
                ...payload,
              },
            });
          },
          openModal: async (payload) => {
            console.log('open modal', modalKey);
            setModalProps({
              ...modalProps,
              [modalKey]: {
                ...modalProps[modalKey],
                isOpen: true,
                ...payload,
              },
            });
          },
          closeModal: () => {
            console.log('close modal', modalKey);
            setModalProps({
              ...modalProps,
              [modalKey]: {
                isOpen: false,
              },
            });
          },
        },
      },
    }),
    {},
  );

export const WcpModalProvider = ({ children, modalContracts }) => {
  const [modalProps, setModalProps] = useState({});
  return (
    <WcpModalContext.Provider
      value={generateModalContextValue({ modalContracts, modalProps, setModalProps })}
    >
      {children}
      <StyledModalsWrapper>
        {modalContracts.map((props) => {
          const ModalComponent = props.modalContentComponent;
          return (
            <WcpModal key={props.modalKey} {...props}>
              {({ modalState, modalActions }) => (
                <>
                  <ModalComponent modalState={modalState} modalActions={modalActions} />
                </>
              )}
            </WcpModal>
          );
        })}
      </StyledModalsWrapper>
    </WcpModalContext.Provider>
  );
};

export default WcpModalProvider;
