import styled from 'styled-components';
import colors from 'legacy/shared/constants/colors';
import device from 'legacy/shared/constants/sizing';
import alertIcon from 'assets/svg/maintenance-alert.svg';

/* Maintenance Control */
export const MaintenanceTypeControlDiv = styled.div`
  margin-top: 30px;
  margin-bottom: 30px;
`;

export const MaintenanceTypeItemDiv = styled.div`
  display: grid;
  margin-bottom: 45px;
  grid-template-areas:
    'Row'
    'Row'
    'Row'
    'Row';
  grid-template-columns: 1fr;
  grid-template-rows: 60px 1fr 15px 35px;
  @media ${device.large} {
    grid-template-areas: 'Col Col';
    grid-template-columns: 200px 1fr;
    grid-template-rows: 60px;
    margin-bottom: 5px;
  }
  ${(props) =>
    props.checkEngine &&
    `
      grid-template-areas:
    'Row'
    'Row';
    grid-template-rows: 60px 1fr ;
    margin-bottom: 25px;

      @media ${device.large} {
        grid-template-rows: 40px;
  }
  `}
`;
export const TypeDropDownDiv = styled.div`
  margin-top: 30px;
  margin-bottom: 50px;

  @media ${device.large} {
    margin-top: 20px;
    margin-bottom: 30px;
  }
`;
export const IntervalTypeSelectionDiv = styled.div`
  width: 200px;
  @media ${device.large} {
    width: 120px;
  }
`;
export const IntervalInputDiv = styled.div`
  width: 200px;
  @media ${device.large} {
    width: 140px;

    ${(props) =>
      props.date &&
      `
    margin-top: -1px;
  `}
  }
`;

export const NotesInputDiv = styled.div`
  width: 100%;
  height: 50px;
  padding-right: 50px;
  @media ${device.large} {
    width: 300px;
    padding-right: 0px;
  }
`;

export const MaintenanceControlOuterWrapperDiv = styled.div`
  display: grid;
  grid-gap: 0px;
  margin-bottom: 10px;
  grid-template-areas:
    'Row'
    'Row'
    'Row';
  grid-template-columns: 1fr;
  grid-template-rows: 70px 70px 30px;
  ${(props) =>
    props.checkEngine &&
    `
      margin-bottom: 0px;
      grid-template-areas:
        'Row'
        'Row';
      grid-template-columns: 1fr;
      grid-template-rows: 60px ;
  `}
  @media ${device.large} {
    grid-template-areas: 'Col Col Col Col';
    grid-template-columns: 120px 140px 300px 30px;
    grid-template-rows: 70px;
    grid-gap: 10px;
  }
`;

/* End Maintenance Control */

export const MaintenanceFilters = styled.div`
  margin-bottom: 20px;
  margin-top: 35px;
  &:last-child {
    margin-bottom: 0;
  }
  display: grid;
  grid-gap: 20px;

  grid-template-areas:
    'Col'
    'Col'
    'Col'
    'Col';
  grid-template-columns: 200px;
  grid-template-rows: 70px 70px 70px 60px;

  @media ${device.largeAnalytics} {
    grid-template-areas: 'Col Col Col Col';
    grid-template-columns: 200px 200px 220px 300px;
    grid-template-rows: 50px;
    grid-gap: 20px;
  }
`;

export const ScheduleMaintenanceFiltersDiv = styled.div`
  margin-top: 35px;
  &:last-child {
    margin-bottom: 0;
  }
  display: grid;
  grid-gap: 20px;

  grid-template-areas:
    'Col'
    'Col';
  grid-template-columns: 200px;
  grid-template-rows: 50px 50px;

  @media ${device.large} {
    grid-template-areas: 'Col Col';
    grid-template-columns: 200px 200px;
    grid-template-rows: 30px;
    margin-bottom: 20px;
    grid-gap: 20px;
  }
`;

export const MaintenanceActionButtonsDiv = styled.div`
  display: grid;
  grid-gap: 10px;

  grid-template-areas:
    'Col'
    'Col';
  grid-template-columns: 232px;
  grid-template-rows: 38px 38px;
  @media ${device.medium} {
    display: flex;
    align-items: baseline;
    grid-gap: 0px;
  }
`;

export const MaintenanceAlertWrapper = styled.div`
  cursor: pointer;
  box-sizing: border-box;
  height: fit-content;
  width: fit-content;
  padding-right: 20px;
  border: 3px solid ${colors.midnight};
  border-radius: 8px;
  font-size: 12px;
  font-weight: 600;
  margin-bottom: 50px;
  margin-top: 10px;
  display: grid;
  grid-gap: 10px;
  grid-template-areas: 'Col' 'Col';
  grid-template-columns: 40px 1fr;
  grid-template-rows: 1fr;

  @media ${device.medium} {
    box-sizing: border-box;
    width: fit-content;
    padding-right: 20px;
    border: 3px solid ${colors.midnight};
    border-radius: 8px;
    font-size: 12px;
    font-weight: 600;
    margin-bottom: 50px;
    display: grid;
    grid-gap: 10px;

    grid-template-areas: 'Col' 'Col';
    grid-template-columns: 50px 1fr;
    grid-template-rows: 50px;
  }
`;

export const AlertIcon = styled.div`
  margin-top: 10px;
  margin-left: 5px;
  width: 38px;
  height: 33px;
  border: none;
  background-image: url(${alertIcon});
  background-repeat: no-repeat;
  background-size: cover;

  @media ${device.medium} {
    width: 48px;
    height: 42px;
  }
`;

export const NeedAttentionText = styled.div`
  text-transform: uppercase;
  color: ${colors.midnight};
  font-size: 16px;
  font-weight: 800;
  margin-top: 7px;
  @media ${device.medium} {
    font-size: 20px;
    margin-top: 7px;
  }
`;

export const AlertCount = styled.span`
  font-weight: 800;
`;

export const AlertTextWrapper = styled.div`
  color: ${colors.midnight};
  font-weight: 500;
  font-size: 16px;
  display: flex;

  @media ${device.medium} {
    font-size: 20px;
  }
`;

export const AlertItem = styled.div`
  margin-right: 10px;
`;
export const AlertText = styled.div`
  display: grid;
  grid-template-areas:
    'Col Col'
    'Col Col ';
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 20px 1fr;

  @media ${device.medium} {
    display: flex;
  }
`;

export const VehicleCountDiv = styled.div`
  color: ${colors.midnight};
  font-weight: 500;
  font-size: 18px;
  text-align: right;
  margin-bottom: 10px;
  margin-right: 2px;
`;

export const ManageRemindersFieldsWrapperDiv = styled.div`
  display: grid;
  grid-template-areas:
    'Col'
    'Col'
    'Col';
  grid-template-columns: 170px;
  grid-template-rows: 60px 60px 60px;
  grid-gap: 15px;

  @media ${device.medium} {
    grid-template-areas: 'FormCol  FormCol FormCol';
    grid-template-columns: 170px 170px 170px;
    grid-template-rows: 50px;
  }
  ${(props) =>
    props.enhancedTelematics &&
    `
    grid-template-areas:
    'Col'
    'Col'
    'Col'
    'Col';
  grid-template-columns: 170px;
  grid-template-rows: 60px 60px 60px;
  grid-gap: 15px;

  @media ${device.medium} {
    grid-template-areas: 'FormCol  FormCol FormCol FormCol';
    grid-template-columns: 125px 125px 125px 125px;
    grid-template-rows: 50px;
  }
  `}
`;

export const VehicleMakeModelTitle = styled.div`
  color: ${colors.midnight};
  font-weight: 500;
  font-size: 14px;
  margin-top: 5px;
`;

export const EventColumn = styled.div`
  font-weight: 500;

  ${(props) =>
    props.due &&
    `
    color: ${colors.midnight};
      font-weight: 800;
  `}
`;
export const AddButtonWrapper = styled.div`
  margin-top: -16px;
  float: right;
`;

export const CheckIconWrapper = styled.div`
  margin-top: 15px;
`;

export const AddedText = styled.span`
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
`;

export const SuppressedWrapper = styled.div`
  display: flex;
`;

export const MarkCompletedFieldsWrapperDiv = styled.div`
  display: grid;
  grid-template-areas:
    'Col'
    'Col'
    'Col'
    'Col';
  grid-template-columns: 350px;
  grid-template-rows: 60px 60px 60px;
  grid-gap: 15px;

  @media ${device.large} {
    grid-template-areas: 'FormCol  FormCol FormCol FormCol';
    grid-template-columns: 150px 150px 1fr 1fr;
    grid-template-rows: 50px;
  }
`;

export const ReoccuringEventDiv = styled.div`
  margin-top: 30px;
  display: grid;
  grid-template-areas:
    'Col'
    'Col';
  grid-template-columns: 180px;
  grid-template-rows: 60px 60px;
  grid-gap: 5px;

  @media ${device.large} {
    grid-template-areas: 'FormCol  FormCol ';
    grid-template-columns: 150px 150px;
    grid-template-rows: 50px;
  }
`;

export const CompletedDateWrapperDiv = styled.div`
  width: 250px;
  margin-top: 15px;
  margin-bottom: 35px;
`;

export const NotesWrapperDiv = styled.div`
  margin-right: 20px;
  width: 100%;
`;

export const OccuredAtDiv = styled.div`
  width: 350px;
  @media ${device.large} {
    width: 270px;
    grid-column: span 2;
  }
`;

export const UnsupportedIconWrapper = styled.div`
  height: 25px;
  margin-right: 1px;
`;

export const UnsupportedTextWrapper = styled.div`
  color: ${colors.haze};
  margin-top: 0px;
  margin-bottom: 20px;
`;

export const UnsupportedTextCardWrapper = styled.div`
  margin-top: 10px;
`;

export const VehicleCommunicationMessageWrapper = styled.div`
  margin-top: 20px;
  margin-bottom: 35px;
  color: ${colors.midnight};
  @media ${device.large} {
  }
`;

export const CheckEngineOccuredAtWrapper = styled.div`
  @media ${device.large} {
    grid-column: span 2;
  }
`;

export const ReadOnlySpacer = styled.div`
  margin-top: 4px;
  margin-bottom: 12px;
`;

export const UpToDate = styled.span`
  margin-left: 5px;
`;

export const CheckEngineReadOnlyDiv = styled.div`
  margin-bottom: 45px;

  height: 16px;
  font-size: 16px;
  font-weight: 700;
  color: ${colors.midnight};
  width: 350px;

  @media ${device.medium} {
    font-size: 22px;
  }
  @media ${device.large} {
    margin-top: 0px;
    font-size: 14px;
    margin-bottom: 25px;
    width: 335px;
  }
`;
