import colors from 'legacy/shared/constants/colors';
import { vehicleTypeViewData } from 'legacy/shared/constants/vehicle';
import useDynamicSvgImport from 'legacy/shared/utilities/hooks/useDynamicSvgImport';
import { ActionButtonText, TextButton } from 'legacy/shared/styles/custom/Button';
import { AddonItem } from 'legacy/shared/styles/custom/VehicleDetailCard';
import {
  DetailColumn,
  DetailColumnsContainer,
  ModalSection,
  SectionLabel,
  VehicleDetailLabel,
  VehicleDetailValue,
  VehicleLabel,
  VehicleStatus,
  VehicleType,
  CloseButton,
} from 'legacy/shared/styles/custom/VehicleDetailCard';
import {
  convertAltitude,
  formatISOTimestampToDescriptiveLog,
} from 'legacy/shared/utilities/misc/time';
import { getHeading } from 'legacy/shared/utilities/misc/vehicle';
import { DateTime } from 'luxon';
import useVehiclePlaybackProviderContext from 'legacy/features/playback/state/provider/hooks/useVehiclePlaybackProviderContext';
import { vehicleAddonDictionary } from 'legacy/features/vehicles/constants/vehicleAddonDictionary';

const DetailField = ({ label, value }) => (
  <div style={{ marginTop: '6px' }}>
    <VehicleDetailLabel>{label}:</VehicleDetailLabel>
    <VehicleDetailValue> {value}</VehicleDetailValue>
  </div>
);

const displayVehicleStatus = (status) => {
  if (status === 'responding') {
    return colors.cherry;
  } else if (status === 'active') {
    return colors.midnight;
  } else return colors.haze;
};

const PlaybackVehicleDetailCard = ({ handleRequestClose, selectedVehicle }) => {
  const {
    state: {
      playbackData: { locationData },
      playbackAnimationControls: { selectedPlaybackDataIndex },
    },
    actions: { setPlaybackAnimationControlsAction },
  } = useVehiclePlaybackProviderContext();

  const vehicleLocationData = locationData[selectedPlaybackDataIndex];

  const vehicleImage = useDynamicSvgImport({
    importPath: vehicleTypeViewData[selectedVehicle?.meta?.vehicle_type]?.icon,
  });

  const DetermineShowAlert = (addon) => {
    /* when we have the laert level dictionary we can replace the hard coded alert level in the next line */
    return addon.alertLevel === 'High' && addon.isActive;
  };

  // we started storing elevation as of 10/5/2021

  const isBeforeElevationStorageDate =
    DateTime.fromISO(vehicleLocationData.time) < DateTime.fromFormat('2021-10-05', 'yyyy-MM-dd');

  return vehicleImage && vehicleLocationData ? (
    <>
      <CloseButton alt="Close" title="Close" onClick={handleRequestClose}></CloseButton>

      <ModalSection withBorder style={{ paddingTop: 20 }}>
        <DetailColumnsContainer>
          <div style={{ display: 'flex' }}>
            <img src={vehicleImage} style={{ width: '28px' }} />
            <div style={{ marginLeft: '12px' }}>
              <VehicleLabel title={selectedVehicle?.meta?.label}>
                {selectedVehicle?.meta?.label}
              </VehicleLabel>
              <VehicleType>
                {vehicleTypeViewData[selectedVehicle?.meta?.vehicle_type]?.title}
              </VehicleType>
              <VehicleStatus style={{ color: displayVehicleStatus(vehicleLocationData.status) }}>
                {vehicleLocationData.status === 'active' ? 'Online' : 'Responding'}
              </VehicleStatus>
            </div>
          </div>
        </DetailColumnsContainer>
      </ModalSection>
      <ModalSection playbackCard withBorder>
        <SectionLabel>Location Details</SectionLabel>
        <DetailColumnsContainer>
          <DetailColumn
            title={
              vehicleLocationData.speed_source === 'OBD'
                ? 'Speed determined by OBD2 Harness'
                : 'Speed determined by GPS'
            }
          >
            <DetailField
              label={'Speed'}
              value={`${vehicleLocationData.speed ? vehicleLocationData.speed : 0} MPH`}
            />
          </DetailColumn>
          <DetailColumn>
            <DetailField label={'Heading'} value={getHeading(vehicleLocationData.heading)} />
          </DetailColumn>
        </DetailColumnsContainer>
        <DetailField
          label={'Coordinates'}
          value={`${vehicleLocationData.lat}, ${vehicleLocationData.long}`}
        />
        {!isBeforeElevationStorageDate && (
          <DetailField
            label={'Elevation'}
            value={convertAltitude(vehicleLocationData.elevation) + ' FT'}
          />
        )}
        <DetailField
          label={'Timestamp'}
          value={formatISOTimestampToDescriptiveLog(vehicleLocationData.time)}
        />
      </ModalSection>

      <ModalSection withBorder playbackCard>
        <SectionLabel>Add-on Features</SectionLabel>
        {vehicleLocationData.addons.map((addon) => (
          <DetailColumnsContainer>
            <DetailColumn>
              <AddonItem alert={DetermineShowAlert(addon)} key={addon.type}>
                {vehicleAddonDictionary[addon.type]?.displayName}
              </AddonItem>
            </DetailColumn>
            <DetailColumn>
              <div>
                <VehicleDetailLabel>Status: </VehicleDetailLabel>
                <AddonItem alert={DetermineShowAlert(addon)}>
                  {addon.isActive ? ' Active' : ' Not Active'}
                </AddonItem>
              </div>
            </DetailColumn>
          </DetailColumnsContainer>
        ))}
      </ModalSection>
      <ModalSection playbackBottom>
        <TextButton
          type="button"
          largeFont
          onClick={() => {
            setPlaybackAnimationControlsAction({
              currentPlaybackDataIndex: selectedPlaybackDataIndex,
            });
          }}
        >
          <ActionButtonText>JUMP TO PLAYBACK POSITION</ActionButtonText>
        </TextButton>
      </ModalSection>
    </>
  ) : null;
};
export default PlaybackVehicleDetailCard;
