import { useEffect } from 'react';

import useFetchVehiclePlaybackAndRefreshProviderState from 'legacy/features/playback/state/formik/hooks/useFetchVehiclePlaybackAndRefreshProviderState';
import { mapFormikToVehiclePlaybackRequestFormParams } from 'legacy/features/playback/state/formik/mapping/mapFormikToVehiclePlaybackRequestFormParams';
import { mapVehiclePlaybackProviderStateToFormik } from 'legacy/features/playback/state/formik/mapping/mapVehiclePlaybackProviderStateToFormik ';
import { validateVehiclePlaybackRequestForm } from 'legacy/features/playback/state/formik/validation/validateVehiclePlaybackRequestForm';
import useVehiclePlaybackProviderContext from 'legacy/features/playback/state/provider/hooks/useVehiclePlaybackProviderContext';
import VehiclePlaybackRequestFormSubmitButton from 'legacy/features/playback/ui/components/VehiclePlaybackRequestForm/buttons/VehiclePlaybackRequestFormSubmitButton';
import VehiclePlaybackFormEndDateField from 'legacy/features/playback/ui/components/VehiclePlaybackRequestForm/datePickers/VehiclePlaybackFormEndDateField';
import VehiclePlaybackFormStartDateField from 'legacy/features/playback/ui/components/VehiclePlaybackRequestForm/datePickers/VehiclePlaybackFormStartDateField';
import VehiclePlaybackFormEndTimeDropdown from 'legacy/features/playback/ui/components/VehiclePlaybackRequestForm/dropdowns/VehiclePlaybackFormEndTimeDropdown';
import VehiclePlaybackFormGranularityDropdown from 'legacy/features/playback/ui/components/VehiclePlaybackRequestForm/dropdowns/VehiclePlaybackFormGranularityDropdown';
import VehiclePlaybackFormStartTimeDropdown from 'legacy/features/playback/ui/components/VehiclePlaybackRequestForm/dropdowns/VehiclePlaybackFormStartTimeDropdown';
import VehiclePlaybackFormVehicleDropdown from 'legacy/features/playback/ui/components/VehiclePlaybackRequestForm/dropdowns/VehiclePlaybackFormVehicleDropdown';
import VehiclePlaybackFormEventInfo from 'legacy/features/playback/ui/components/VehiclePlaybackRequestForm/widgets/VehiclePlaybackFormEventInfo';
import { WcpFormikProvider } from 'legacy/shared/controls/WcpForm/v1/WcpFormikProvider';
import { FormCol } from 'legacy/shared/styles/custom/Form';
import {
  DateTimeFormDiv,
  GenerateDiv,
  PlaybackOptionDiv,
  VehiclePlaybackFormDiv,
} from 'legacy/shared/styles/custom/VehiclePlayback';

const VehiclePlaybackRequestForm = () => {
  // get the initial fields from provider state
  const {
    state: { playbackRequestForm },
  } = useVehiclePlaybackProviderContext();
  const { readOnly } = playbackRequestForm;

  // refresh provider state
  const { isLoading, fetch } = useFetchVehiclePlaybackAndRefreshProviderState();

  useEffect(() => {
    if (readOnly) fetch(playbackRequestForm);
  }, [readOnly]);

  // define the form submit handler
  const submitHandler = async (formikValues, formikBag) => {
    // map formik values to request params
    const apiParams = mapFormikToVehiclePlaybackRequestFormParams(formikValues);

    // trigger fetch playback data and refresh provider state
    fetch(apiParams);
  };

  // render the formik provider
  return (
    <WcpFormikProvider
      providerValues={playbackRequestForm}
      providerToFormikMapper={mapVehiclePlaybackProviderStateToFormik}
      actions={{ submitHandler }}
      validation={validateVehiclePlaybackRequestForm}
    >
      <VehiclePlaybackFormDiv>
        <DateTimeFormDiv>
          <FormCol>
            <VehiclePlaybackFormStartDateField />
          </FormCol>
          {!readOnly && (
            <FormCol noMinWidth>
              <VehiclePlaybackFormStartTimeDropdown />
            </FormCol>
          )}
        </DateTimeFormDiv>
        <DateTimeFormDiv>
          <FormCol>
            <VehiclePlaybackFormEndDateField />
          </FormCol>
          {!readOnly && (
            <FormCol noMinWidth>
              <VehiclePlaybackFormEndTimeDropdown />
            </FormCol>
          )}
        </DateTimeFormDiv>
        <PlaybackOptionDiv granularity>
          <VehiclePlaybackFormGranularityDropdown />
        </PlaybackOptionDiv>
        <PlaybackOptionDiv>
          <VehiclePlaybackFormVehicleDropdown />
        </PlaybackOptionDiv>
        <GenerateDiv>
          <VehiclePlaybackRequestFormSubmitButton isLoading={isLoading} />
        </GenerateDiv>
        {readOnly && <VehiclePlaybackFormEventInfo />}
      </VehiclePlaybackFormDiv>
    </WcpFormikProvider>
  );
};

export default VehiclePlaybackRequestForm;
