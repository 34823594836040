import { useSelector } from 'react-redux';

import NavigationDropdownButton from 'legacy/core/navigation/components/NavigationDropdownButton';
import NavigationLink from 'legacy/core/navigation/components/NavigationLink';
import { getPermissions } from 'legacy/core/redux/user/selectors';
import { StyledNavigationListItem } from 'legacy/core/navigation/styles/NavigationStyles';
import { shouldRenderItem } from 'legacy/core/navigation/utilities/navigation';

const NavigationItem = ({
  item,
  itemKey,
  sidebarIsCollapsed,
  activatedItemKey,
  selectedItemKey,
  handleNavigationItemClick,
}) => {
  const userRole = useSelector((state) => state.user.user.userRole);
  const permissions = useSelector((state) => getPermissions(state.user, 'nav component'));
  const daysUntilExpiration = useSelector((state) => state.ui.daysUntilExpiration);

  if (
    !shouldRenderItem({
      item,
      userRole,
      permissions,
      daysUntilExpiration,
    })
  )
    return null;

  return (
    <StyledNavigationListItem>
      {item.link ? (
        <NavigationLink
          itemKey={itemKey}
          item={item}
          selectedItemKey={selectedItemKey}
          sidebarIsCollapsed={sidebarIsCollapsed}
          handleNavigationItemClick={handleNavigationItemClick}
        />
      ) : (
        <NavigationDropdownButton
          itemKey={itemKey}
          item={item}
          userRole={userRole}
          permissions={permissions}
          daysUntilExpiration={daysUntilExpiration}
          sidebarIsCollapsed={sidebarIsCollapsed}
          activatedItemKey={activatedItemKey}
          selectedItemKey={selectedItemKey}
          handleNavigationItemClick={handleNavigationItemClick}
        />
      )}
    </StyledNavigationListItem>
  );
};

export default NavigationItem;
