import { useQueryClient } from '@tanstack/react-query';
import { Formik } from 'formik';
import { useDispatch } from 'react-redux';

import { logWcpError } from 'legacy/core/error/utilities/ErrorLogger';
import ReminderDaysField from 'legacy/features/maintenance/components/MaintenanceSummary/fields/ReminderDaysField';
import ReminderEngineHoursField from 'legacy/features/maintenance/components/MaintenanceSummary/fields/ReminderEngineHoursField';
import ReminderMileageField from 'legacy/features/maintenance/components/MaintenanceSummary/fields/ReminderMileageField';
import ReminderOilLifeField from 'legacy/features/maintenance/components/MaintenanceSummary/fields/ReminderOilLifeField';
import { useFetchOrganizationQueryForMaintenance } from 'legacy/features/maintenance/hooks/queries/useFetchOrganizationQueryForMaintenance';
import MaintenanceSummaryContextProvider from 'legacy/features/maintenance/providers/MaintenanceSummaryContextProvider/MaintenanceSummaryContextProvider';
import {
  addDisplayCommas,
  stripCommas,
  stripTrailingPercentage,
} from 'legacy/features/maintenance/utilities/formatting';
import { editMaintenanceRemindersSuccess } from 'legacy/features/organizations/helpers/notifications';
import ButtonWithLoader from 'legacy/shared/controls/ButtonWithLoader/ButtonWithLoader';
import CustomModal from 'legacy/shared/controls/WcpModal/v1/CustomModal';
import Button from 'legacy/shared/styles/custom/Button';
import {
  Form,
  FormActions,
  FormAsterisk,
  FormBody,
  FormFoot,
  FormLegend,
  FormTitle,
} from 'legacy/shared/styles/custom/Form';
import { ManageRemindersFieldsWrapperDiv } from 'legacy/shared/styles/custom/Maintenance';
import { ActionsContainer, ModalText } from 'legacy/shared/styles/custom/Modal';
import { HaasIntegrationWrapperDiv } from 'legacy/shared/styles/custom/VehicleEdit';
import { validateMaintenanceReminders } from 'legacy/shared/utilities/helpers/validation/validators';
import { organizationPath } from 'legacy/shared/v1/constants/api';
import { apiCall } from 'legacy/core/api/API';
import { replaceWithEmptyString } from 'legacy/shared/utilities/misc/general';
import { formatDateToDynamoIsoString } from 'legacy/shared/utilities/misc/organizations';

// TODO - replace with mapper and react-query

export const ManageRemindersModal = ({ modalData: { show }, handleCloseModal }) => {
  const [fetchOrganizationQuery, organization] = useFetchOrganizationQueryForMaintenance({
    enableRefetchInterval: false,
  });

  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const confirmHandler = async (values, formikActions) => {
    organization.reminderMileage = +stripCommas(values.reminderMileage);
    organization.reminderEngineHours = +stripCommas(values.reminderEngineHours);
    organization.reminderDays = +values.reminderDays;
    organization.reminderOilLife = +stripTrailingPercentage(values.reminderOilLife);
    try {
      let success = false;
      success = await (async (organization) => {
        return await apiCall({
          endpoint: 'cloudfrontAPI',
          path: `${organizationPath}/${organization.organizationId}`,
          method: 'put',
          body: ((organization) => {
            if (organization.active === true) {
              organization.action = 'Activate';
            } else if (organization.active === false) {
              organization.action = 'Deactivate';
            } else {
              organization.action = 'Edit';
            }
            return {
              ...(organization.organizationId && { organization_id: organization.organizationId }),
              ...(organization.organizationName && {
                organization_name: organization.organizationName.trim(),
              }),

              ...(organization.activeUsers && { active_users: organization.activeUsers }),
              ...(organization.city && { address_city: organization.city }),
              ...(organization.state && { address_state: organization.state }),
              ...(organization.address1 && { address_1: organization.address1 }),
              ...(organization.address2 && { address_2: organization.address2 }),
              ...(organization.contactPhone && { contact_phone: organization.contactPhone }),
              ...(organization.contactPhoneExtension && {
                contact_phone_extension: replaceWithEmptyString(organization.contactPhoneExtension),
              }),
              ...(organization.contactEmail && { contact_email_id: organization.contactEmail }),
              ...(organization.contactName && { contact_name: organization.contactName }),
              ...(organization.customerType && { customer_type: organization.customerType }),
              ...(organization.timeZone && { timezone: organization.timeZone }),
              ...(organization.startDate && {
                start_date: formatDateToDynamoIsoString(organization.startDate),
              }),
              ...(organization.endDate && {
                end_date: formatDateToDynamoIsoString(organization.endDate),
              }),
              ...(organization.active !== undefined && {
                organization_active: organization.active,
              }),
              ...(organization.associatedFSE && { associated_fse: organization.associated_fse }),
              ...(organization.gttEnabled !== undefined && {
                gtt_enabled: organization.gttEnabled,
              }),
              ...(organization.gttSetup !== undefined && { gtt_setup: organization.gttSetup }),
              ...(organization.haasEnabled !== undefined && {
                haas_enabled: organization.haasEnabled,
              }),
              ...(organization.thruGreenEnabled !== undefined && {
                thrugreen_enabled: organization.thruGreenEnabled,
              }),
              ...(organization.appliedInformationEnabled !== undefined && {
                applied_information_enabled: organization.appliedInformationEnabled,
              }),
              ...(organization.appliedInformationBusinessId !== undefined && {
                applied_information_business_id: organization.appliedInformationBusinessId,
              }),
              ...(organization.alertsActive !== undefined && {
                alerts_active: organization.alertsActive,
              }),
              ...(organization.haasSetup !== undefined && { haas_setup: organization.haasSetup }),
              ...(organization.wimAccountId && { wim_account_id: organization.wimAccountId }),
              ...(organization.action && { action: organization.action }),
              ...(organization.vehicleCount && { vehicle_count: organization.vehicleCount }),
              ...(organization.reminderMileage && {
                maintenance_reminder_mileage: organization.reminderMileage,
              }),
              ...(organization.reminderEngineHours && {
                maintenance_reminder_engine_hours: organization.reminderEngineHours,
              }),
              ...(organization.reminderDays && {
                maintenance_reminder_days: organization.reminderDays,
              }),
              ...(organization.reminderOilLife && {
                maintenance_reminder_oil_life: organization.reminderOilLife,
              }),
            };
          })(organization),
        });
      })(organization);
      if (success) {
        dispatch(editMaintenanceRemindersSuccess());
        queryClient.invalidateQueries('fetchScheduledMaintenanceEventsQuery');
      }
    } catch (err) {
      logWcpError(err);
    }

    formikActions.setSubmitting(false);
    handleCloseModal();
  };
  return (
    <MaintenanceSummaryContextProvider>
      {({ state: { allVehicles } }) => {
        const atLeastOneVehicleWithEnahcedTelematics = allVehicles?.some((v) => {
          return v?.meta.enhanced_telematics_enabled === true;
        });
        return (
          show && (
            <CustomModal
              customWidth={650}
              title={
                <>
                  <FormTitle>{'Manage Reminders'}</FormTitle>
                </>
              }
              handleCloseModal={handleCloseModal}
            >
              <>
                <ModalText>
                  <HaasIntegrationWrapperDiv>
                    By setting the reminder threshold below, vehicles will be added to the
                    maintenance queue marked as “Due in Soon” once they pass the specified threshold
                    prior to reaching the actual maintenance interval.
                  </HaasIntegrationWrapperDiv>
                </ModalText>
                <Formik
                  initialValues={{
                    ...organization,
                    reminderMileage: organization.reminderMileage
                      ? addDisplayCommas(organization.reminderMileage)
                      : '',
                    reminderEngineHours: organization.reminderEngineHours
                      ? addDisplayCommas(organization.reminderEngineHours)
                      : '',
                    reminderDays: organization.reminderDays ? organization.reminderDays : '',
                    reminderOilLife: organization.reminderOilLife
                      ? organization.reminderOilLife
                      : '',
                  }}
                  validate={validateMaintenanceReminders}
                  validateOnChange={true}
                  validateOnBlur={false}
                  onSubmit={(values, formikActions) => confirmHandler(values, formikActions)}
                >
                  {(formikProps) => {
                    return (
                      <Form onSubmit={formikProps.submitForm}>
                        <FormBody>
                          <ManageRemindersFieldsWrapperDiv
                            enhancedTelematics={atLeastOneVehicleWithEnahcedTelematics}
                          >
                            <ReminderMileageField formikProps={formikProps} />
                            <ReminderEngineHoursField formikProps={formikProps} />
                            <ReminderDaysField formikProps={formikProps} />
                            {atLeastOneVehicleWithEnahcedTelematics && (
                              <ReminderOilLifeField formikProps={formikProps} />
                            )}
                          </ManageRemindersFieldsWrapperDiv>
                        </FormBody>
                        <FormFoot>
                          <FormLegend>
                            <FormAsterisk>*</FormAsterisk> required fields
                          </FormLegend>
                          <FormActions>
                            <ActionsContainer>
                              <Button
                                onClick={async () => {
                                  handleCloseModal();
                                }}
                                withRightSpacer
                                default
                                type="button"
                              >
                                Cancel
                              </Button>
                              <ButtonWithLoader
                                isLoading={formikProps.isSubmitting}
                                confirmText={'Submit'}
                                loadingStyleProp={'submittingWithSpinnerModal'}
                                notLoadingStyleProp={'mediumAlt'}
                                clickHandler={formikProps.submitForm}
                              />
                            </ActionsContainer>
                          </FormActions>
                        </FormFoot>
                      </Form>
                    );
                  }}
                </Formik>
              </>
            </CustomModal>
          )
        );
      }}
    </MaintenanceSummaryContextProvider>
  );
};

export default ManageRemindersModal;
