import { GenericButton } from 'legacy/shared/styles/v2/Button';

const RemoveAvatarImageButton = ({ handleRemoveImage }) => {
  return (
    <GenericButton onClick={() => handleRemoveImage()} noBackgroundColor default center>
      REMOVE IMAGE
    </GenericButton>
  );
};

export default RemoveAvatarImageButton;
