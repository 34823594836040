import CreateOrganizationForm from 'legacy/features/organizations/components/forms/OrganizationForm/CreateOrganizationForm';

const CreateOrganizationModalContent = ({ modalActions }) => (
  <CreateOrganizationForm
    handleCancel={modalActions.closeModal}
    handleSubmitCompleted={modalActions.closeModal}
  />
);

export default CreateOrganizationModalContent;
