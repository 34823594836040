import OnlyShowPendingUpdatesCheckbox from 'legacy/features/firmware/ui/controls/OnlyShowPendingUpdatesCheckbox';
import ExpandCollapseAll from 'legacy/shared/controls/Accordion/ExpandCollapseAll';
import Searchbox from 'legacy/shared/controls/Searchbox/Searchbox';
import {
  OnlyShowPendingUpdatesCheckboxWrapper,
  SearchAndCollapseAllWrapper,
  SeachAndFilterWrapper,
} from 'legacy/shared/styles/custom/OtaUpdates';
import { setOnlyShowPendingOtaUpdatesByDevicePreferenceInLocal } from 'legacy/shared/utilities/misc/localStore';

const FirmwareUpdatesSummaryByVehicleHeader = ({
  filteredVehicleCount,
  searchText,
  setSearchText,
  allExpanded,
  setAllExpanded,
  setCollapsedIndividualItems,
  onlyShowPendingUpdates,
  setOnlyShowPendingUpdates,
}) => {
  return (
    <>
      <h5>{`Total of ${filteredVehicleCount} Vehicle${filteredVehicleCount !== 1 ? 's' : ''}`}</h5>
      <SearchAndCollapseAllWrapper>
        <SeachAndFilterWrapper>
          <Searchbox searchText={searchText} setSearchText={setSearchText} />
          <OnlyShowPendingUpdatesCheckboxWrapper>
            <OnlyShowPendingUpdatesCheckbox
              checked={onlyShowPendingUpdates}
              handleChecked={() => {
                setOnlyShowPendingOtaUpdatesByDevicePreferenceInLocal(!onlyShowPendingUpdates),
                  setOnlyShowPendingUpdates(!onlyShowPendingUpdates);
              }}
            />
          </OnlyShowPendingUpdatesCheckboxWrapper>
        </SeachAndFilterWrapper>
        <ExpandCollapseAll
          allExpanded={allExpanded}
          setAllExpanded={setAllExpanded}
          setCollapsedIndividualItems={setCollapsedIndividualItems}
          totalListingCount={filteredVehicleCount}
        />
      </SearchAndCollapseAllWrapper>
    </>
  );
};

export default FirmwareUpdatesSummaryByVehicleHeader;
