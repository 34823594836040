import { set } from 'lodash';
import { createContext, useContext } from 'react';

// context to store the form state
export const WcpFormContext = createContext({
  setFormValues: () => {},
  formValues: {},
  requiredFields: [],
  setValidationErrors: () => {},
  validationErrors: {},
  validate: () => {},
});

const useWcpFormContext = () => {
  const context = useContext(WcpFormContext);
  return context;
};

export default useWcpFormContext;
