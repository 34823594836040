import { addressStatesDropdownOptions } from 'legacy/shared/constants/states';
import WcpFilteredDropdownField from 'legacy/shared/controls/WcpForm/v2/components/fields/WcpFilteredDropdownField';

const AddressStateDropdown = () => {
  return (
    <WcpFilteredDropdownField
      dataKey={'addressState'}
      fieldLabel={'State'}
      placeholderLabel={'Select a State...'}
      behaviourOptions={{ disableSearch: true }}
      setup={{
        dropdownOptions: addressStatesDropdownOptions,
      }}
      styleOptions={{
        fullWidth: true,
        required: true,
        showClearSelectionButton: false,
      }}
    />
  );
};

export default AddressStateDropdown;
