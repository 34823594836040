import Table from 'rc-table';
import { useEffect, useState } from 'react';
import { v4 as uuid } from 'uuid';

import Searchbox from 'legacy/shared/controls/Searchbox/Searchbox';
import { PAGINATION_OPTIONS } from 'legacy/shared/constants/pagination';
import { SORT_ORDER } from 'legacy/shared/constants/table';
import { PaginationStyles, TableFilters } from 'legacy/shared/styles/custom/PageList';
import { SortArrowSpan } from 'legacy/shared/styles/custom/Table';
import PaginationControls from 'legacy/shared/controls/WcpTable/v2/PaginationControls';
import { tableActions } from 'legacy/shared/controls/WcpTable/v2/constants/tableActions';
import useModifyColumnProperties from 'legacy/shared/controls/WcpTable/v2/useModifyColumnProperties';
import useTableReducer from 'legacy/shared/controls/WcpTable/v2/useTableReducer';
import WcpTableHeaderCell from 'legacy/shared/controls/WcpTable/v2/WcpTableHeaderCell';
import PropTypes from 'prop-types';
import ListingCount from 'legacy/shared/controls/ListingCount/ListingCount';

// custom header cell component that wraps/overwrites default table header cell (see: Table -> components prop line 56)

const WcpTable = ({
  columns,
  tableSourceData = [],
  emptyText,
  defaultPageSize = PAGINATION_OPTIONS[2].value,
  defaultSortProperty = columns[0].sortProperty || columns[0].displayProperty,
  defaultSortOrder = SORT_ORDER.ASC,
  showSearch = true,
  showPager = true,
  showListingCountHeader = false, // TODO - default to true once all tables are updated
  listingCountItemName = 'Record',
  setListingCount: handleSetListingCount, // not needed if showListingCountHeader is true
  disablePageSize = false,
}) => {
  console.log('default sort property', defaultSortProperty);
  if (!showPager) {
    defaultPageSize = tableSourceData?.length;
  }

  const state = useTableReducer({
    tableSourceData,
    searchEnabledColumns: columns
      .filter((column) => (column.searchEnabled === undefined ? true : column.searchEnabled))
      .map((column) => column.displayProperty),

    defaultPageSize,
    defaultSortProperty,
    defaultSortOrder,
  });

  let {
    currentSortProperty,
    currentSortOrder,
    currentPageSize,
    currentPageIndex,
    totalPageCount,
    searchResults,
    pagedResults,
    tableDispatch,
  } = state;

  const modifiedColumns = useModifyColumnProperties({
    columns,
    currentSortProperty,
    currentSortOrder,
  });

  const [searchText, setSearchText] = useState('');
  const [listingCount, setListingCount] = useState(0);

  useEffect(() => {
    tableDispatch({
      type: tableActions.RESET_DISPLAY_DATA,
      payload: { tableSourceData, searchText },
    });
  }, [tableSourceData]);

  useEffect(() => {
    if (handleSetListingCount) {
      handleSetListingCount(searchResults?.length);
    }

    setListingCount(searchResults?.length);
  }, [searchResults?.length]);

  useEffect(() => {
    tableDispatch({
      type: tableActions.SEARCH,
      payload: { searchText },
    });
  }, [searchText]);

  const handlePageChange = (e) => {
    tableDispatch({
      type: tableActions.SELECT_PAGE,
      payload: { selectedPageIndex: e.selected },
    });
  };

  const handlePageSizeChange = (e) => {
    tableDispatch({
      type: tableActions.SELECT_PAGE_SIZE,
      payload: { pageSize: e.value },
    });
  };

  return (
    modifiedColumns && (
      <>
        {showListingCountHeader && (
          <ListingCount
            listingCount={listingCount}
            totalCount={tableSourceData?.length}
            dataLabel={listingCountItemName}
          />
        )}
        <PaginationStyles>
          <TableFilters>
            {showSearch ? (
              <Searchbox
                smallSearch={false}
                setSearchText={setSearchText}
                searchText={searchText}
              />
            ) : (
              <div className="spacer" />
            )}
            {showPager && (
              <PaginationControls
                currentPageIndex={currentPageIndex}
                totalPageCount={totalPageCount}
                handlePageChange={handlePageChange}
                handlePageSizeChange={handlePageSizeChange}
                currentPageSize={currentPageSize}
                disablePageSize={disablePageSize}
              />
            )}
          </TableFilters>
          <Table
            className="table-simple"
            columns={modifiedColumns}
            data={pagedResults}
            rowClassName={(_, i) => `row-${i}`}
            rowKey={() => uuid()}
            emptyText={searchText ? 'No records match the current search criteria' : emptyText}
            components={{
              header: {
                cell: (props) => (
                  <WcpTableHeaderCell
                    currentSortOrder={currentSortOrder}
                    currentSortProperty={currentSortProperty}
                    tableDispatch={tableDispatch}
                    {...props}
                  />
                ),
              },
            }}
          />
          <TableFilters>
            <div className="spacer" />
            {showPager && (
              <PaginationControls
                currentPageIndex={currentPageIndex}
                totalPageCount={totalPageCount}
                handlePageChange={handlePageChange}
                handlePageSizeChange={handlePageSizeChange}
                currentPageSize={currentPageSize}
                disablePageSize={disablePageSize}
              />
            )}
          </TableFilters>
        </PaginationStyles>
      </>
    )
  );
};

WcpTable.propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      displayProperty: PropTypes.string,
      sortProperty: PropTypes.string,
      searchEnabled: PropTypes.bool,
    }),
  ).isRequired,
  tableSourceData: PropTypes.array,
  emptyText: PropTypes.string,
  defaultPageSize: PropTypes.number,
  defaultSortProperty: PropTypes.string,
  defaultSortOrder: PropTypes.oneOf([SORT_ORDER.ASC, SORT_ORDER.DESC]),
  displaySearch: PropTypes.bool,
  showPager: PropTypes.bool,
  displayPaging: PropTypes.bool,
  setListingCount: PropTypes.func,
};

export default WcpTable;
