import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';

import { apiCall } from 'legacy/core/api/API';
import { logWcpError } from 'legacy/core/error/utilities/ErrorLogger';
import { organizationMapping } from 'legacy/features/organizations/helpers/organizationMapping';
import { mapToBackend } from 'legacy/shared/utilities/helpers/mapping/mapper';
import { addNotification } from 'legacy/core/redux/ui/actions';
import { createNotification, LEVELS } from 'legacy/shared/utilities/misc/notification';

const useUpdateOrganizationMutation = () => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  const mutation = useMutation({
    mutationFn: async ({ organization }) => {
      return await apiCall({
        path: `/organization/${organization.organizationId}`,
        method: 'put',
        body: mapToBackend(organization, organizationMapping),
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['fetchOrganizationsQuery']);
    },
    onError: (err) => {
      logWcpError(err);
      dispatch(
        addNotification({
          notification: createNotification(LEVELS.ERROR, err),
        }),
      );
    },
  });

  return mutation;
};

export default useUpdateOrganizationMutation;
