import React, { useCallback } from 'react';
import { connect } from 'react-redux';

import IconSvgComponent from 'legacy/shared/controls/WcpIcon/IconSvgComponent';
import XIcon from 'legacy/features/configurations/components/icons/XIcon';

import { deleteConfiguration } from 'legacy/core/redux/configurations/actions';

/**
 * Styled Components
 */
import {
  Container,
  Title,
  CloseButton,
  ActionsContainer,
  ModalText,
} from 'legacy/shared/styles/custom/Modal';
import { Button } from 'legacy/shared/styles/custom/Button';

export default connect(null, (dispatch) => ({
  deleteConfiguration: (configurationId) => dispatch(deleteConfiguration(configurationId)),
}))(({ onSuccess, handleRequestClose, deleteConfiguration, configurationId }) => {
  return (
    <Container maxWidth={474} autoHeight>
      <CloseButton onClick={handleRequestClose}>
        <IconSvgComponent svgFileName={'x'} alt="Close" title="Close" />
      </CloseButton>
      <Title style={styles.title}>
        <XIcon width={20} /> Delete Configuration
      </Title>
      <ModalText>
        Deleting this Configuration will remove it as a selectable option for Vehicles. This action
        does not remove this Configuration from Vehicles. Would you like to continue deleting?
      </ModalText>
      <ActionsContainer>
        <Button
          formConfirm
          onClick={async () => {
            const success = await deleteConfiguration(configurationId);
            if (success) {
              onSuccess();
            }
          }}
        >
          Confirm
        </Button>
      </ActionsContainer>
    </Container>
  );
});

const styles = {
  title: {
    marginBottom: '30px',
  },
};
