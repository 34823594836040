import { TruncatedTextWithWrap } from 'legacy/shared/styles/custom/TruncatedText';

export const deviceLogsTableColumns = [
  {
    title: 'Timestamp',
    displayProperty: 'formattedTime',
    sortProperty: 'timestamp',
    width: '25%',
  },
  {
    displayProperty: 'actionDisplay',
    width: '15%',
    render({ cellJsx, cellValue }) {
      return (
        <span title={`Action: ${cellValue} `}>
          <TruncatedTextWithWrap>{cellJsx}</TruncatedTextWithWrap>
        </span>
      );
    },
  },
  {
    title: 'Associated Vehicle',
    displayProperty: 'associatedVehicle',
    width: '15%',
  },
  {
    title: 'Details',
    displayProperty: 'details',
    render({ cellJsx, cellValue }) {
      return (
        <span title={`Detail: ${cellValue} `}>
          <TruncatedTextWithWrap>{cellJsx}</TruncatedTextWithWrap>
        </span>
      );
    },
  },
];
