import { timeZoneDropdownOptions } from 'legacy/features/geofences/geofences/constants/dropdownOptions';
import WcpFilteredDropdownField from 'legacy/shared/controls/WcpForm/v2/components/fields/WcpFilteredDropdownField';

const TimezoneDropdown = () => {
  return (
    <WcpFilteredDropdownField
      dataKey={'timeZone'}
      fieldLabel={'Time Zone'}
      placeholderLabel={'Select a Time Zone...'}
      setup={{
        dropdownOptions: timeZoneDropdownOptions,
      }}
      styleOptions={{
        fullWidth: true,
        required: true,
        showClearSelectionButton: false,
      }}
    />
  );
};

export default TimezoneDropdown;
