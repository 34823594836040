import {
  alphanumericExtendedRegex,
  usernameRegex,
  phoneRegex,
} from 'legacy/shared/utilities/helpers/validation/validationRegularExpressions';
import {
  alphanumericExtendedRegexError,
  usernameRegexError,
  emailRegexError,
  requiredError,
  phoneRegexError,
} from 'legacy/shared/utilities/helpers/validation/validationErrorMessages';
import { object, string } from 'yup';

export const userValidationSchema = object({
  username: string()
    .required(requiredError)
    .matches(usernameRegex, usernameRegexError)
    .max(128, 'Please enter 128 characters or less'),
  firstName: string()
    .required(requiredError)
    .max(128, 'Please enter 128 characters or less')
    .matches(alphanumericExtendedRegex, alphanumericExtendedRegexError),
  lastName: string()
    .required(requiredError)
    .max(128, 'Please enter 128 characters or less')
    .matches(alphanumericExtendedRegex, alphanumericExtendedRegexError),
  email: string()
    .email(emailRegexError)
    .max(256, 'Please enter 256 characters or less')
    .required(requiredError),
  phoneNumber: string().matches(phoneRegex, phoneRegexError).required(requiredError),
  userTitle: string()
    .nullable()
    .max(128, 'Please enter 128 characters or less')
    .matches(alphanumericExtendedRegex, alphanumericExtendedRegexError),
  userRole: string().required(requiredError),
});
