import { SimpleDropdownBtn, SimpleDropdownItem } from 'legacy/shared/styles/custom/DropdownMenu';

const MarkCompletedMenuItem = ({ onMenuItemClick }) => {
  return (
    <SimpleDropdownItem>
      <SimpleDropdownBtn onClick={onMenuItemClick}>Mark Completed</SimpleDropdownBtn>
    </SimpleDropdownItem>
  );
};

export default MarkCompletedMenuItem;
