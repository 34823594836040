import React from 'react';

const ListingCount = ({
  listingCount,
  dataLabel,
  totalCount = listingCount,
  includeTotalText = true,
}) => {
  return (
    <h5>
      {listingCount === totalCount
        ? `${includeTotalText ? 'Total of ' : ''} ${listingCount} ${dataLabel}${
            listingCount !== 1 ? 's' : ''
          }`
        : `${listingCount} of ${totalCount} ${dataLabel}${totalCount !== 1 ? 's' : ''}`}
    </h5>
  );
};

export default ListingCount;
