import ModalCancelButton from 'legacy/shared/controls/WcpModal/v2/buttons/ModalCancelButton';
import ModalConfirmButton from 'legacy/shared/controls/WcpModal/v2/buttons/ModalConfirmButton';
import ModalSubmitButton from 'legacy/shared/controls/WcpModal/v2/buttons/ModalSubmitButton';
import React from 'react';

const GenericConfirmationModalControls = ({
  disableCancel = false,
  confirmButtonText = 'Ok',
  cancelButtonText = 'Cancel',
  submitOnConfirm = false,
  handleConfirm,
  handleCancel,
}) => {
  return (
    <>
      {!disableCancel && (
        <ModalCancelButton buttonText={cancelButtonText} handleClick={handleCancel} />
      )}
      {submitOnConfirm ? (
        // Use submit button if submitOnConfirm is true (i.e. loading spinner on button)
        <ModalSubmitButton buttonText={confirmButtonText} handleSubmit={handleConfirm} />
      ) : (
        // otherwise use confirm button
        <ModalConfirmButton buttonText={confirmButtonText} handleClick={handleConfirm} />
      )}
    </>
  );
};

export default GenericConfirmationModalControls;
