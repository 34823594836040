import { useQuery } from '@tanstack/react-query';

import { apiCall } from 'legacy/core/api/API';
import { logWcpError } from 'legacy/core/error/utilities/ErrorLogger';
import { organizationFetchError } from 'legacy/features/organizations/helpers/notifications';
import { organizationMapping } from 'legacy/features/organizations/helpers/organizationMapping';
import { mapToFrontend } from 'legacy/shared/utilities/helpers/mapping/mapper';
import { transformPhoneNumberToDisplay } from 'legacy/shared/utilities/helpers/transformers/transformPhoneNumberToDisplay';

export const useFetchOrganizationsQuery = () => {
  return useQuery({
    queryKey: ['fetchOrganizationsQuery'],
    queryFn: async () =>
      await apiCall({
        endpoint: 'cloudfrontAPI',
        path: '/organization',
        method: 'get',
      }),
    select: (data) =>
      data.body.message.map((organization) => {
        let mappedOrganization = mapToFrontend(organization, organizationMapping);

        // TODO add to mapping transform
        mappedOrganization.isWhelen = mappedOrganization.organizationName === 'Whelen';
        mappedOrganization.contactPhoneDisplay = transformPhoneNumberToDisplay(
          mappedOrganization.contactPhone,
        );

        return mappedOrganization;
      }),
    onError: (err) => {
      logWcpError(err);
      organizationFetchError({ err });
    },
  });
};
