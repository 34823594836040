import { useEffect, useState } from 'react';

import { SimpleDropdownItem } from 'legacy/shared/styles/custom/DropdownMenu';
import ActionMenu from 'legacy/shared/controls/WcpTable/v1/ActionMenu';
import EditAlertMenuItem from 'legacy/features/alerts/ui/components/AlertsSummary/menus/EditAlertMenuItem';
import DeleteAlertMenuItem from 'legacy/features/alerts/ui/components/AlertsSummary/menus/DeleteAlertMenuItem';
import DeleteAlertModal from 'legacy/features/alerts/ui/components/AlertsSummary/modals/DeleteAlertModal';
import ViewAlertLogsMenuItem from 'legacy/features/alerts/ui/components/AlertsSummary/menus/ViewAlertLogsMenuItem';
import DeactivateAlertMenuItem from 'legacy/features/alerts/ui/components/AlertsSummary/menus/DeactivateAlertMenuItem';
import DeactivateAlertModal from 'legacy/features/alerts/ui/components/AlertsSummary/modals/DeactivateAlertModal';
import EnableTestModeMenuItem from 'legacy/features/alerts/ui/components/AlertsSummary/menus/EnableTestModeMenuItem';
import EnableTestModeModal from 'legacy/features/alerts/ui/components/AlertsSummary/modals/EnableTestModeModal';

const ManageAlertsActionMenu = ({ alert }) => {
  const [deactivateAlertModalData, setDeactivateAlertModalData] = useState({
    show: false,
  });
  const [deleteAlertModalData, setDeleteAlertModalData] = useState({
    show: false,
  });
  const [enableTestModeModalData, setEnableTestModeModalData] = useState({
    show: false,
  });
  useEffect(() => {
    setDeactivateAlertModalData({
      show: false,
      alert: alert,
      isActive: alert.alertDetails.alertInfo.isActive,
    });
    setDeleteAlertModalData({
      show: false,
      alertId: alert.alertId,
      alertName: alert.alertDetails.alertInfo.alertName,
      isActive: alert.alertDetails.alertInfo.isActive,
    });
    setEnableTestModeModalData({
      show: false,
      alert: alert,
      testModeEnabled: alert.alertDetails.alertInfo.testModeEnabled,
      testModeMuteAlertsOption: alert.alertDetails.alertInfo.testModeMuteAlerts,
    });
  }, [alert]);
  return (
    <>
      <ActionMenu>
        <SimpleDropdownItem>
          <EditAlertMenuItem alertId={alert.alertId} />
        </SimpleDropdownItem>
        <SimpleDropdownItem>
          <ViewAlertLogsMenuItem alertId={alert.alertId} />
        </SimpleDropdownItem>
        <SimpleDropdownItem>
          <DeactivateAlertMenuItem
            onMenuItemClick={() =>
              setDeactivateAlertModalData((prevState) => ({ ...prevState, show: true }))
            }
            isActive={alert.alertDetails.alertInfo.isActive}
          />
        </SimpleDropdownItem>
        <SimpleDropdownItem>
          <DeleteAlertMenuItem
            onMenuItemClick={() =>
              setDeleteAlertModalData((prevState) => ({ ...prevState, show: true }))
            }
          />
        </SimpleDropdownItem>
        <SimpleDropdownItem>
          <EnableTestModeMenuItem
            onMenuItemClick={() =>
              setEnableTestModeModalData((prevState) => ({ ...prevState, show: true }))
            }
            isTestModeEnabled={alert.alertDetails.alertInfo.testModeEnabled}
          />
        </SimpleDropdownItem>
      </ActionMenu>
      <>
        <DeactivateAlertModal
          modalData={deactivateAlertModalData}
          handleCloseModal={() =>
            setDeactivateAlertModalData((prevState) => ({ ...prevState, show: false }))
          }
        />
        <EnableTestModeModal
          modalData={enableTestModeModalData}
          handleCloseModal={() =>
            setEnableTestModeModalData((prevState) => ({ ...prevState, show: false }))
          }
        />
        <DeleteAlertModal
          modalData={deleteAlertModalData}
          handleCloseModal={() =>
            setDeleteAlertModalData((prevState) => ({ ...prevState, show: false }))
          }
        />
      </>
    </>
  );
};

export default ManageAlertsActionMenu;
