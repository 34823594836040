import React, { useState } from 'react';
import SimpleDropdown from 'legacy/shared/controls/WcpTable/v1/SimpleDropdownComponent';

export default class ActionMenu extends React.Component {
  state = { active: false };

  toggleDropdown = (event) => {
    event.preventDefault();
    const component = this;
    if (!component.state.active) {
      this.outsideClickHandler = (innerEvent) => {
        component.dropdownStateToggler();
      };

      this.dropdownStateToggler();
    }
  };

  dropdownStateToggler = () => {
    const { active } = this.state;
    // setTimeout sends this call to the bottom of the stack
    // so that event can propagate and handler can fire before
    // the action menu is unmounted
    setTimeout(() => {
      this.setState({ active: !this.state.active }, () => {
        if (!this.state.active) {
          document.removeEventListener('mouseup', this.outsideClickHandler);
        } else {
          document.addEventListener('mouseup', this.outsideClickHandler);
        }
      });
    }, 0);
  };

  render() {
    const hasAtLeastOneMenuItem = Array.isArray(this.props.children)
      ? this.props.children.length > 0
      : this.props.children.props.children;
    return (
      <SimpleDropdown
        onClick={hasAtLeastOneMenuItem ? this.toggleDropdown : (e) => e.preventDefault()}
        active={this.state.active}
        innerRef={(dropdown) => (this.dropdown = dropdown)}
        toggleRef={(toggle) => (this.toggle = toggle)}
        disabled={!hasAtLeastOneMenuItem}
      >
        {this.props.children}
      </SimpleDropdown>
    );
  }
}
