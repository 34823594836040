import { SimpleDropdownBtn, SimpleDropdownItem } from 'legacy/shared/styles/custom/DropdownMenu';

const DeleteRecipientGroupMenuItem = ({ onMenuItemClick }) => {
  return (
    <SimpleDropdownItem>
      <SimpleDropdownBtn onClick={onMenuItemClick}>Delete Recipient Group</SimpleDropdownBtn>
    </SimpleDropdownItem>
  );
};

export default DeleteRecipientGroupMenuItem;
