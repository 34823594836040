import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';

import { apiCall } from 'legacy/core/api/API';
import { logWcpError } from 'legacy/core/error/utilities/ErrorLogger';
import { addNotification } from 'legacy/core/redux/ui/actions';
import { createNotification, LEVELS } from 'legacy/shared/utilities/misc/notification';

const useOrganizationActivationMutation = () => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  return useMutation({
    mutationFn: async ({ activate = true, organization }) => {
      return await apiCall({
        endpoint: 'cloudfrontAPI',
        path: `/organization/${organization.organizationId}`,
        method: 'put',
        body: {
          organization_active: activate,
        },
      });
    },
    onSuccess: (_, vars) => {
      // invalidate fetch users
      queryClient.invalidateQueries('fetchOrganizationsQuery');

      dispatch(
        addNotification({
          notification: createNotification(
            LEVELS.SUCCESS,
            `Organization ${vars.activate ? 'Activated' : 'Deactivated'} Successfully`,
          ),
        }),
      );
    },
    onError: (err, vars) => {
      logWcpError(err);
      dispatch(
        addNotification({
          notification: createNotification(LEVELS.ERROR, 'Error Activating Organization', err),
        }),
      );
    },
  });
};

export default useOrganizationActivationMutation;
