import { createContext, useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';

import { addMaintenanceItemReducer } from 'legacy/features/maintenance/providers/reducers/addMaintenanceItemReducer';
import { removeMaintenanceItemReducer } from 'legacy/features/maintenance/providers/reducers/removeMaintenanceItemReducer';
import { updateMaintenanceItemReducer } from 'legacy/features/maintenance/providers/reducers/updateMaintenanceItemReducer';
import useProviderState from 'legacy/shared/utilities/hooks/useProviderState';
import { isMaintenanceItemValidReducer } from 'legacy/features/maintenance/providers/reducers/isMaintenanceItemValidReducer';
import { useCallback } from 'react';

export const VehicleMaintenanceContext = createContext(null);

const maintenanceBuilderInitialState = {
  maintenanceItems: [],
  isDirty: false,
  isValid: true,
  rowValidStates: [],
  selectedIntervalTypes: [],
};

const maintenanceBuilderActionReducers = {
  // addMaintenaceItemAction
  ADD_MAINTENANCE_ITEM: addMaintenanceItemReducer,
  // updateMaintenanceItemAction
  UPDATE_MAINTENANCE_ITEM: updateMaintenanceItemReducer,
  // removeMaintenanceItemAction
  REMOVE_MAINTENANCE_ITEM: removeMaintenanceItemReducer,
  // isMaintenanceItemValidAction
  IS_MAINTENANCE_ITEM_VALID: isMaintenanceItemValidReducer,
};

const MaintenanceBuilderContextProvider = ({ children, initialState, onStateChanged }) => {
  const vehicleMaintenanceItems = initialState?.vehicleMaintenanceItems?.map((mi) => ({
    id: uuidv4(),
    ...mi,
  }));

  const [state, actions] = useProviderState({
    actionReducerMap: maintenanceBuilderActionReducers,
    initialState: vehicleMaintenanceItems
      ? {
          ...maintenanceBuilderInitialState,
          maintenanceItems: vehicleMaintenanceItems,
        }
      : maintenanceBuilderInitialState,
  });

  useEffect(() => {
    if (onStateChanged) onStateChanged(state);
  }, [state]);

  return (
    <VehicleMaintenanceContext.Provider
      value={{
        actions,
        state,
      }}
    >
      {children(actions, state)}
    </VehicleMaintenanceContext.Provider>
  );
};
export default MaintenanceBuilderContextProvider;
