import DropdownOptions from 'legacy/shared/utilities/classes/dropdown/DropdownOptions';

export const Alabama = 'Alabama';
export const Alaska = 'Alaska';
export const Arizona = 'Arizona';
export const Arkansas = 'Arkansas';
export const California = 'California';
export const Colorado = 'Colorado';
export const Connecticut = 'Connecticut';
export const Delaware = 'Delaware';
export const DistrictOfColumbia = 'DistrictOfColumbia';
export const Florida = 'Florida';
export const Georgia = 'Georgia';
export const Hawaii = 'Hawaii';
export const Idaho = 'Idaho';
export const Illinois = 'Illinois';
export const Indiana = 'Indiana';
export const Iowa = 'Iowa';
export const Kansas = 'Kansas';
export const Kentucky = 'Kentucky';
export const Louisiana = 'Louisiana';
export const Maine = 'Maine';
export const Maryland = 'Maryland';
export const Massachusetts = 'Massachusetts';
export const Michigan = 'Michigan';
export const Minnesota = 'Minnesota';
export const Mississippi = 'Mississippi';
export const Missouri = 'Missouri';
export const Montana = 'Montana';
export const Nebraska = 'Nebraska';
export const Nevada = 'Nevada';
export const NewHampshire = 'NewHampshire';
export const NewJersey = 'NewJersey';
export const NewMexico = 'NewMexico';
export const NewYork = 'NewYork';
export const NorthCarolina = 'NorthCarolina';
export const NorthDakota = 'NorthDakota';
export const Ohio = 'Ohio';
export const Oklahoma = 'Oklahoma';
export const Oregon = 'Oregon';
export const Pennsylvania = 'Pennsylvania';
export const RhodeIsland = 'RhodeIsland';
export const SouthCarolina = 'SouthCarolina';
export const SouthDakota = 'SouthDakota';
export const Tennessee = 'Tennessee';
export const Texas = 'Texas';
export const Utah = 'Utah';
export const Vermont = 'Vermont';
export const Virginia = 'Virginia';
export const Washington = 'Washington';
export const WestVirginia = 'WestVirginia';
export const Wisconsin = 'Wisconsin';
export const Wyoming = 'Wyoming';

export const stateOptions = {
  [Alaska]: {
    label: 'AK',
    value: 'AK',
  },
  [Alabama]: {
    label: 'AL',
    value: 'AL',
  },

  [Arkansas]: {
    label: 'AR',
    value: 'AR',
  },
  [Arizona]: {
    label: 'AZ',
    value: 'AZ',
  },
  [California]: {
    label: 'CA',
    value: 'CA',
  },
  [Colorado]: {
    label: 'CO',
    value: 'CO',
  },
  [Connecticut]: {
    label: 'CT',
    value: 'CT',
  },
  [DistrictOfColumbia]: {
    label: 'DC',
    value: 'DC',
  },
  [Delaware]: {
    label: 'DE',
    value: 'DE',
  },
  [Florida]: {
    label: 'FL',
    value: 'FL',
  },
  [Georgia]: {
    label: 'GA',
    value: 'GA',
  },
  [Hawaii]: {
    label: 'HI',
    value: 'HI',
  },
  [Iowa]: {
    label: 'IA',
    value: 'IA',
  },
  [Idaho]: {
    label: 'ID',
    value: 'ID',
  },
  [Illinois]: {
    label: 'IL',
    value: 'IL',
  },
  [Indiana]: {
    label: 'IN',
    value: 'IN',
  },

  [Kansas]: {
    label: 'KS',
    value: 'KS',
  },
  [Kentucky]: {
    label: 'KY',
    value: 'KY',
  },
  [Louisiana]: {
    label: 'LA',
    value: 'LA',
  },
  [Massachusetts]: {
    label: 'MA',
    value: 'MA',
  },
  [Maryland]: {
    label: 'MD',
    value: 'MD',
  },
  [Maine]: {
    label: 'ME',
    value: 'ME',
  },

  [Michigan]: {
    label: 'MI',
    value: 'MI',
  },
  [Minnesota]: {
    label: 'MN',
    value: 'MN',
  },
  [Missouri]: {
    label: 'MO',
    value: 'MO',
  },
  [Mississippi]: {
    label: 'MS',
    value: 'MS',
  },
  [Montana]: {
    label: 'MT',
    value: 'MT',
  },
  [NorthCarolina]: {
    label: 'NC',
    value: 'NC',
  },
  [NorthDakota]: {
    label: 'ND',
    value: 'ND',
  },
  [Nebraska]: {
    label: 'NE',
    value: 'NE',
  },
  [NewHampshire]: {
    label: 'NH',
    value: 'NH',
  },
  [NewJersey]: {
    label: 'NJ',
    value: 'NJ',
  },
  [NewMexico]: {
    label: 'NM',
    value: 'NM',
  },
  [Nevada]: {
    label: 'NV',
    value: 'NV',
  },
  [NewYork]: {
    label: 'NY',
    value: 'NY',
  },
  [Ohio]: {
    label: 'OH',
    value: 'OH',
  },
  [Oklahoma]: {
    label: 'OK',
    value: 'OK',
  },
  [Oregon]: {
    label: 'OR',
    value: 'OR',
  },
  [Pennsylvania]: {
    label: 'PA',
    value: 'PA',
  },
  [RhodeIsland]: {
    label: 'RI',
    value: 'RI',
  },
  [SouthDakota]: {
    label: 'SD',
    value: 'SD',
  },
  [SouthCarolina]: {
    label: 'SC',
    value: 'SC',
  },
  [Tennessee]: {
    label: 'TN',
    value: 'TN',
  },
  [Texas]: {
    label: 'TX',
    value: 'TX',
  },
  [Utah]: {
    label: 'UT',
    value: 'UT',
  },
  [Vermont]: {
    label: 'VT',
    value: 'VT',
  },
  [Virginia]: {
    label: 'VA',
    value: 'VA',
  },
  [Washington]: {
    label: 'WA',
    value: 'WA',
  },
  [Wisconsin]: {
    label: 'WI',
    value: 'WI',
  },
  [WestVirginia]: {
    label: 'WV',
    value: 'WV',
  },
  [Wyoming]: {
    label: 'WY',
    value: 'WY',
  },
};
export const stateOptionsArray = Object.values(stateOptions).sort((a, b) => a.label - b.label);

export const addressStatesDropdownOptions = new DropdownOptions(stateOptionsArray);
