import FseUserTableActionMenu from 'legacy/features/organizations/components/menus/FseUserTableActionMenu';
import { TableSpinner } from 'legacy/shared/styles/custom/Spinner';
import { TableAvatar } from 'legacy/shared/styles/custom/TableAvatar';

const useOrganizationFseTableColumns = ({ organization }) => {
  return [
    {
      title: ' ',
      displayProperty: 'avatar',
      searchEnabled: false,
      width: '50px',
      render({ rowData }) {
        return (
          <span className="userNameAndIcon">
            {rowData.loadingStatus ? (
              <TableSpinner className="spinner-margin" />
            ) : (
              <TableAvatar profilePicture={rowData.avatar} />
            )}
          </span>
        );
      },
    },
    {
      displayProperty: 'username',
    },
    {
      displayProperty: 'firstName',
    },
    {
      displayProperty: 'lastName',
    },
    {
      title: 'Contact Email',
      displayProperty: 'email',
    },
    {
      title: '',
      className: 'action',
      searchEnabled: false,
      render: ({ rowData }) => {
        return <FseUserTableActionMenu user={rowData} organization={organization} />;
      },
    },
  ];
};

export default useOrganizationFseTableColumns;
