import styled from 'styled-components';

import colors from 'legacy/shared/constants/colors';

const { haze, cotton, midnight } = colors;
export const GroupTagDiv = styled.div`
  display: inline-flex;
  padding: 8px 12px;
  border-radius: 15px;
  font-size: 12px;
  background-color: ${haze};
  color: ${cotton};
  transition: 1s translate;
  margin-right: 10px;
  margin-top: 5px;
  word-break: break-all;

  ${(props) =>
    props.isNew &&
    `
  background-color: ${midnight};
      `}
`;
