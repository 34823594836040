import { StyledBoldedText } from 'legacy/shared/styles/v2/Formatting';

const DeactivateOrganizationConfirmationModalContent = ({ modalState: { organization } }) => {
  return (
    <span>
      Are you sure you want to deactivate:
      <StyledBoldedText> {organization.organizationName}?</StyledBoldedText>
    </span>
  );
};

export default DeactivateOrganizationConfirmationModalContent;
