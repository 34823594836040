import { useState } from 'react';

import { getLogsAnalytics } from 'legacy/core/api/analytics';
import { NoDataDiv } from 'legacy/shared/styles/custom/Analytics';
import { FormCol } from 'legacy/shared/styles/custom/Form';
import FilteredDropdown from 'legacy/shared/controls/WcpFilteredDropdown/v2/FilteredDropdown';
import {
  formatISOTimestampToShortDate,
  getAWeekAgo,
  getYesterday,
} from 'legacy/shared/utilities/misc/time';
import { validateAnalyticsDateRange } from 'legacy/shared/utilities/helpers/validation/validators';
import { useSelector } from 'react-redux';
import DateRangeSubmitForm from 'legacy/shared/controls/DateRangeSubmitForm/DateRangeSubmitForm';
import { useFlags } from 'launchdarkly-react-client-sdk';

const AnalyticsLogsQueryFilter = ({
  groups,
  vehicles,
  setLogsQueryResults,
  selectedGroup,
  selectedVehicle,
  setSelectedVehicle,
  setSelectedGroup,
}) => {
  let { ldAnalyticsQueryVersion } = useFlags();
  let version = ldAnalyticsQueryVersion ? 2 : 1;
  const filteredOrganizationId = useSelector((state) => state.user.filteredOrganizationId);
  const [serverError, setServerError] = useState(false);
  const onSubmit = async ({ startDate, endDate }) => {
    try {
      let data = await getLogsAnalytics({
        startDate,
        endDate,
        groupId: selectedGroup ? selectedGroup.group_id : null,
        vehicleId: selectedVehicle ? selectedVehicle.vehicle_id : null,
        organizationId: filteredOrganizationId,
        version: version,
      });
      setLogsQueryResults(data.response.message);
      setSelectedVehicle(selectedVehicle);
      setServerError(false);
    } catch (e) {
      console.log('Analytics query failed:' + e);
      setServerError(true);
    }
  };

  const defaultStartDate = formatISOTimestampToShortDate(getAWeekAgo());
  const defaultEndDate = formatISOTimestampToShortDate(getYesterday());

  const groupOptions = groups?.map((group) => ({
    label: group.group_name,
    value: group.group_name,
  }));
  const vehicleOptions = vehicles
    ?.filter((v) => {
      return selectedGroup ? selectedGroup.vehicle_id.includes(v.meta.vehicle_id) : true;
    })
    .map((v) => ({ label: v.meta.label, value: v.meta.label }));

  return (
    vehicles && (
      <>
        <DateRangeSubmitForm
          defaultStartDate={defaultStartDate}
          defaultEndDate={defaultEndDate}
          submitHandler={onSubmit}
          validationFunction={validateAnalyticsDateRange}
          disabled={!selectedVehicle}
          analyticsQuery
        >
          <FormCol noMinWidth>
            <FilteredDropdown
              title={'Vehicle Group'}
              placeholderLabel={'All Vehicle Groups'}
              dropdownOptions={groupOptions}
              handleItemSelect={(selectedGroup) => {
                setSelectedGroup(
                  selectedGroup ? groups.find((g) => g.group_name === selectedGroup.label) : null,
                );
              }}
              defaultItem={null}
              styleOptions={{ restrictHeight: false }}
            />
          </FormCol>
          <FormCol noMinWidth>
            <FilteredDropdown
              title={'Vehicle'}
              placeholderLabel={'Select a Vehicle'}
              dropdownOptions={vehicleOptions}
              handleItemSelect={(selectedVehicle) => {
                setSelectedVehicle(
                  selectedVehicle
                    ? vehicles.find((v) => v.meta.label === selectedVehicle.label)
                    : null,
                );
              }}
              defaultItem={
                selectedVehicle
                  ? { label: selectedVehicle.meta.label, value: selectedVehicle.meta.label }
                  : null
              }
              styleOptions={{ restrictHeight: false }}
            />
          </FormCol>
        </DateRangeSubmitForm>
        {serverError && (
          <NoDataDiv analytics>Server error: No message was returned from the server</NoDataDiv>
        )}
      </>
    )
  );
};

export default AnalyticsLogsQueryFilter;
