import { getFirmwareOtaLogs } from 'legacy/core/api/firmware';

import { firmwareOtaLogsFetchError } from 'legacy/features/firmware/helpers/notifications';
import { useGenericFetchQuery } from 'legacy/shared/utilities/hooks/useGenericFetchQuery';

export const useFetchFirmwareOtaLogsQuery = (initialQueryParams) => {
  const { fetch, isLoading, data } = useGenericFetchQuery({
    apiFn: getFirmwareOtaLogs,
    hookName: useFetchFirmwareOtaLogsQuery.name,
    initialQueryParams,
    errorCallback: firmwareOtaLogsFetchError,
  });

  return {
    fetch,
    isLoading,
    data,
  };
};
