import InputMask from 'react-input-mask';
import { v4 as uuid } from 'uuid';

import WcpReadOnlyFormLabel from 'legacy/shared/controls/WcpForm/v2/components/fields/WcpReadOnlyFormLabel';
import useWcpFormContext from 'legacy/shared/controls/WcpForm/v2/hooks/useWcpFormContext';
import {
  StyledDivFieldError,
  StyledDivFormField,
  StyledInputText,
  StyledLabelAnimated,
} from 'legacy/shared/controls/WcpForm/v2/styles/WcpFieldStyles';
import { StyledSpanFormRequiredAsterisk } from 'legacy/shared/controls/WcpForm/v2/styles/WcpFormStyles';

export const WcpTextInputField = ({
  dataKey,
  label,
  disabled = false,
  freezeLabel = false,
  handleOnChange: handleOnChangeForField,
  handleOnBlur: handleOnBlurForField,
  handleOnFocus: handleOnFocusForField,
  showReadOnlyLabel = false,
  transformInputFn,
  mask,
  styleOptions = {
    halfWidth: false,
  },
}) => {
  if (!dataKey) {
    throw new Error(`dataKey prop is required for the field with label "${label}"`);
  }

  const {
    formValues,
    // initialFormValues,
    // setFormValues,
    requiredFields,
    focusedField,
    validationErrors,

    handleOnFocus: handleOnFocusForForm,
    handleOnBlur: handleOnBlurForForm,
    handleOnChange: handleOnChangeForForm,
  } = useWcpFormContext();

  const isFocused = focusedField === dataKey;
  const value = formValues?.[dataKey] || '';
  const required = requiredFields?.includes(dataKey);
  const validationErrorMessage =
    validationErrors?.find((error) => error.path === dataKey)?.message || '';

  const id = uuid();
  const dataCyId = `${dataKey}TextInput`;

  const onChange = ({ target: { value: input } }) => {
    // clean input if a sanitize function is provided
    if (transformInputFn) {
      input = transformInputFn(input);
    }

    // trim whitepsace if it is the only thing entered
    if (input === ' ') {
      input = input.trim();
    }

    handleOnChangeForField && handleOnChangeForField({ input });
    handleOnChangeForForm && handleOnChangeForForm({ dataKey, input });
  };

  const onFocus = () => {
    handleOnFocusForField && handleOnFocusForField();
    handleOnFocusForForm && handleOnFocusForForm({ dataKey });
  };

  const onBlur = () => {
    // setIsFocused(false);
    handleOnBlurForField && handleOnBlurForField();
    handleOnBlurForForm && handleOnBlurForForm({ dataKey });
  };

  return showReadOnlyLabel ? (
    <WcpReadOnlyFormLabel label={label} value={value} dataCyId={dataCyId} />
  ) : (
    <StyledDivFormField halfWidth={styleOptions?.halfWidth}>
      <StyledLabelAnimated
        performLabelTransition={isFocused || !!value || freezeLabel}
        htmlFor={id}
      >
        {label}
        {required && <StyledSpanFormRequiredAsterisk />}
      </StyledLabelAnimated>
      {mask ? (
        <InputMask
          mask={mask}
          value={value}
          required={required}
          disabled={disabled}
          onChange={onChange}
          onFocus={onFocus}
          onBlur={onBlur}
        >
          {() => <StyledInputText id={id} dataCyId={dataCyId} />}
        </InputMask>
      ) : (
        <StyledInputText
          data-cy={dataCyId}
          id={id}
          disabled={disabled}
          value={value}
          halfWidth={styleOptions?.halfWidth}
          onChange={onChange}
          onFocus={onFocus}
          onBlur={onBlur}
        />
      )}
      <StyledDivFieldError>{validationErrorMessage}</StyledDivFieldError>
    </StyledDivFormField>
  );
};
