import {
  validationRestrictionEnum,
  whatToValidateEnum,
  whenToValidateEnum,
} from 'legacy/shared/controls/WcpForm/v2/constants/enums';

export const defaultValidationSettings = {
  whenToValidate: [whenToValidateEnum.ON_CHANGE, whenToValidateEnum.ON_BLUR],
  whatToValidate: [whatToValidateEnum.TOUCHED_FIELDS],
  validationRestrictions: [validationRestrictionEnum.FORM_MUST_HAVE_BEEN_DIRTY],
  showFormValidationMessage: false,
  formValidationMessage: 'Please correct the errors above and try again.',
};
