import EditOrganizationForm from 'legacy/features/organizations/components/forms/OrganizationForm/EditOrganizationForm';

const EditOrganizationModalContent = ({ modalState, modalActions }) => {
  return (
    <EditOrganizationForm
      handleCancel={modalActions.closeModal}
      handleSubmitCompleted={modalActions.closeModal}
      organization={modalState.organization}
    />
  );
};

export default EditOrganizationModalContent;
