import { manageAlertsLogsTableColumns } from 'legacy/features/alerts/ui/components/AlertLogsSummary/tables/ManageAlertsLogsTableColumns';
import { addDisplayCommas } from 'legacy/features/maintenance/utilities/formatting';
import { SORT_ORDER } from 'legacy/shared/constants/table';
import WcpTable from 'legacy/shared/controls/WcpTable/v2/WcpTable';

const ManageAlertsLogsTable = ({ alertLogs, handleUpdateListingCount }) => {
  let alertLogsTableData = alertLogs?.map((alertLog) => {
    return {
      timestamp: alertLog.timestamp,
      formattedTime: alertLog.formattedTime,
      vehicleName: alertLog.vehicleName,
      alertTypeDisplay: alertLog.alertTypeDisplay,
      alertType: alertLog.alertType,
      vehicleId: alertLog.vehicleId,
      triggerValueDisplay: alertLog.triggerValueDisplay,
      measuredValueWithCommas: addDisplayCommas(alertLog.measuredValueDisplay),
      measuredValue: alertLog.measuredValueDisplay,
      testModeEnabled: alertLog.testModeEnabled,
      formattedUserDisplayNames: alertLog.formattedUserDisplayNames,
      aggregateAlertMethod: alertLog.aggregateAlertMethod,
    };
  });
  return (
    <WcpTable
      tableClassName="table-simple"
      columns={manageAlertsLogsTableColumns}
      tableSourceData={alertLogsTableData ? alertLogsTableData : []}
      emptyText="No alert logs are currently associated with this alert"
      setListingCount={handleUpdateListingCount}
      defaultSortOrder={SORT_ORDER.DESC}
    />
  );
};

export default ManageAlertsLogsTable;
