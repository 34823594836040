import { EDIT_ORGANIZATION_MODAL_KEY } from 'legacy/features/organizations/providers/modal/constants/organizationFeatureModalKeys';
import { useWcpModalContext } from 'legacy/shared/controls/WcpModal/v2/WcpModalProvider';
import { SimpleDropdownBtn, SimpleDropdownItem } from 'legacy/shared/styles/custom/DropdownMenu';
import React from 'react';

const EditOrganizationMenuItem = ({ organization }) => {
  const editOrganizationModalContext = useWcpModalContext(EDIT_ORGANIZATION_MODAL_KEY);

  const openEditOrganizationModal = () => {
    editOrganizationModalContext.modalActions.openModal({ organization });
  };

  return (
    <SimpleDropdownItem>
      <SimpleDropdownBtn onClick={openEditOrganizationModal}>Edit</SimpleDropdownBtn>
    </SimpleDropdownItem>
  );
};

export default EditOrganizationMenuItem;
