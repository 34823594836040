import { Cards } from 'legacy/shared/styles/custom/MobileCard';
import UniversalApiSummaryCard from 'legacy/features/integrations/ui/components/mobile/UniversalApiSummaryCard';
import UniversalApiActionMenu from 'legacy/features/integrations/ui/menus/UniversalApiActionMenu';

const UniversalApiSummaryCardContainer = ({ universalApiKeys }) => {
  const getActionMenu = (universalApiKey) => {
    return <UniversalApiActionMenu universalApiKey={universalApiKey} />;
  };
  return (
    <div>
      <Cards topMargin>
        {universalApiKeys.map((u) => (
          <UniversalApiSummaryCard
            universalApiKey={u}
            key={u.id}
            actionMenuComponent={() => getActionMenu(u)}
          />
        ))}
      </Cards>
    </div>
  );
};

export default UniversalApiSummaryCardContainer;
