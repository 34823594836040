import { WcpTextInputField } from 'legacy/shared/controls/WcpForm/v2/components/fields/WcpTextInputField';
import { transformMaskedPhoneNumberInput } from 'legacy/shared/utilities/helpers/transformers/transformMaskedPhoneNumberInput';

const ContactPhoneNumberField = () => {
  return (
    <>
      <WcpTextInputField
        label="Contact Phone"
        dataKey={'contactPhone'}
        transformInputFn={transformMaskedPhoneNumberInput}
        mask="+9 (999) 999-9999"
        styleOptions={{ halfWidth: true }}
      />
      <WcpTextInputField
        label="Extension"
        dataKey={'contactPhoneExtension'}
        mask="9999"
        styleOptions={{ halfWidth: true }}
      />
    </>
  );
};

export default ContactPhoneNumberField;
