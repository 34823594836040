import { partnershipsModalContracts } from 'legacy/features/integrations/providers/modal/constants/partnershipsModalContracts';
import WcpModalProvider from 'legacy/shared/controls/WcpModal/v2/WcpModalProvider';

const PartnershipsModalProvider = ({ children }) => {
  return (
    <WcpModalProvider modalContracts={partnershipsModalContracts}>{children}</WcpModalProvider>
  );
};

export default PartnershipsModalProvider;
