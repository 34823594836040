import { alertTypeConstants } from 'legacy/features/alerts/data/enums/alertTypeConstants';
import { activeInactiveDropdownOptions } from 'legacy/features/alerts/data/ui/dropdown/activeInactiveDropdownOptions';
import { alertTypeDropdownOptions } from 'legacy/features/alerts/data/ui/dropdown/alertTypeDropdownOptions';
import { decorateFromTriggerValue } from 'legacy/features/alerts/services/mapping/helpers/decorateFromTriggerValue';
import { validateAndExtractPayload } from 'legacy/shared/utilities/helpers/reducers/validateAndExtractPayload';
import { vehicleAddonOptions } from 'legacy/features/vehicles/constants/vehicleAddonDictionary';

// updateAlertTypeAction → updateAlertTypeReducer
export const updateAlertTypeReducer = (state, payload) => {
  // validate the payload
  const { alertType } = validateAndExtractPayload({
    payload,
    mustInclude: ['alertType'],
  });

  let newState = {
    ...state,
    alert: {
      ...state.alert,
      alertDetails: {
        ...state.alert.alertDetails,
        alertInfo: {
          ...state.alert.alertDetails.alertInfo,
          alertType, // update alert type
          triggerValueObject: {
            ...state.alert.alertDetails.alertInfo.triggerValueObject,
            ...(alertType === alertTypeConstants.ADDON_ACTIVE
              ? {
                  addon: null,
                  value: activeInactiveDropdownOptions.getAllOptions()[0]?.value,
                }
              : { addon: null, value: null }),
          },
          unitOfMeasure: null, // reset unit of measure
        },
      },
    },
  };

  // decorate alert details based on the new alert type
  decorateFromTriggerValue({ alertDetails: newState.alert.alertDetails });

  // return the new state
  return newState;
};
