import Downshift from 'downshift';
import { useNavigate } from 'react-router-dom';

import colors from 'legacy/shared/constants/colors';
import * as D from 'legacy/shared/styles/custom/DropdownMenu';
import { SearchResultsList } from 'legacy/shared/styles/custom/DropdownMenu';
import { TruncatedTextWithWrap } from 'legacy/shared/styles/custom/TruncatedText';
import IconSvgComponent from 'legacy/shared/controls/WcpIcon/IconSvgComponent';
import { createSearchResultItemName } from 'legacy/shared/utilities/misc/search';

const OrganizationSearch = ({
  organizations,
  searchInput,
  setFilteredOrganizationId,
  toggleSearchControl,
  userRole,
  username,
}) => {
  const navigate = useNavigate();
  return (
    <D.DropdownMenuContent $organizationFilterDropdown>
      <D.DropdownMenuBody style={styles.filterDropdownBody}>
        <IconSvgComponent svgStyle={styles.searchIcon} svgFileName={'search'} alt="Search" />
        <Downshift
          onSelect={(selectedItem, { clearSelection }) => {
            // clearing the selection will update selection to null triggering a state change internally
            // check here that selectedItem isn't null before executing these
            if (selectedItem) {
              setFilteredOrganizationId({
                organizationId: selectedItem.organizationId,
                userRole: userRole,
                username,
              });
              toggleSearchControl();
              clearSelection();
            }
            navigate('/livemap');
          }}
          itemToString={(item) => (item ? item.organizationName : '')}
        >
          {({
            getInputProps,
            getItemProps,
            getMenuProps,
            isOpen,
            inputValue,
            highlightedIndex,
          }) => {
            const inputValueLowerCased = inputValue.toLowerCase();

            return (
              <div style={styles.searchContainer}>
                <input
                  ref={searchInput}
                  {...getInputProps({
                    style: styles.searchInput,
                    placeholder: 'Search for an Org',
                    onKeyUp: (event) => {
                      if (event.keyCode === 27) toggleSearchControl();
                    },
                  })}
                />
                <SearchResultsList {...getMenuProps({})}>
                  {isOpen
                    ? organizations
                        .filter((a) => {
                          // search for matching substring in organization names
                          return a.organizationName.toLowerCase().includes(inputValueLowerCased);
                        })
                        .map((item, index) => (
                          <li
                            {...getItemProps({
                              key: item.organizationId,
                              index,
                              item,
                              style: styles.searchResultItem(index, highlightedIndex),
                            })}
                          >
                            <TruncatedTextWithWrap title={item.organizationName}>
                              {createSearchResultItemName(
                                inputValueLowerCased,
                                item.organizationName,
                              )}
                            </TruncatedTextWithWrap>
                          </li>
                        ))
                    : null}
                </SearchResultsList>
              </div>
            );
          }}
        </Downshift>
      </D.DropdownMenuBody>
    </D.DropdownMenuContent>
  );
};

export default OrganizationSearch;

const styles = {
  currentOrganizationContainerSkeleton: {
    display: 'flex',
    alignItems: 'center',
    marginRight: '15px',
  },
  iconSkeleton: {
    width: '32px',
    height: '32px',
    marginRight: '6px',
    borderRadius: '100%',
    backgroundColor: '#EAEAEA',
    animation: 'loadingSkeleton 1.2s infinite',
  },
  organizationNameSkeleton: {
    width: '125px',
    height: '20px',
    backgroundColor: '#EAEAEA',
    animation: 'loadingSkeleton 1.2s infinite',
  },
  organizationsIcon: (isAdmin) => ({
    width: '22px',
    height: '22px',
    ...(isAdmin ? { cursor: 'pointer' } : {}),
  }),
  organizationName: (isAdmin) => ({
    marginLeft: '6px',
    marginRight: '6px',
    minWidth: '0',
    ...(isAdmin ? { cursor: 'pointer' } : {}),
  }),
  searchToggleButton: {
    cursor: 'pointer',
  },
  searchIcon: {
    height: '22px',
    width: '22px',
    marginRight: '10px',
  },
  filterDropdownBody: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    padding: '5px 15px 5px 15px',
    width: '300px',
    height: '70px',
  },
  searchContainer: {
    height: '100%',
    width: '100%',
  },
  searchInput: {
    border: 'none',
    height: '100%',
    width: '100%',
    color: `${colors.midnight}`,
    fontSize: '1.1rem',
  },
  searchResultItem: (index, highlightedIndex) => ({
    listStyleType: 'none',
    color: '#8a909c',
    fontSize: '1.1rem',
    backgroundColor: 'white',
    fontWeight: 'normal',
    borderTop: index === 0 ? '1px solid #EEEFF0' : 'none',
    borderBottom: '1px solid #EEEFF0',
    padding: '18px 10px 18px 20px',
    backgroundColor: highlightedIndex === index ? 'lightgray' : 'white',
  }),
};
