import React from 'react';
import { stateOptionsArray } from 'legacy/shared/constants/states';
import { FieldError, VehicleLicenseWrapper } from 'legacy/shared/styles/custom/Form';
import AnimatedField from 'legacy/shared/controls/AnimatedField/v1/AnimatedField';
import SelectField from 'legacy/shared/controls/SelectField/SelectField';
import { handleSelectBlur, handleSelectChange } from 'legacy/shared/utilities/misc/form';

const VehicleLicenseStateSelect = ({
  formikProps: { values, touched, errors, setFieldValue, setTouched },
  options,
}) => {
  return (
    <VehicleLicenseWrapper licenseState>
      <SelectField
        name="licenseState"
        placeholder={'State'}
        defaultValue={values.licenseState}
        options={stateOptionsArray}
        onChange={(selectedOption) => {
          setFieldValue('licenseState', selectedOption.value);
        }}
        onBlur={handleSelectBlur(setTouched, 'licenseState', touched)}
        disabled={false}
        isClearable={false}
        isSearchable={false}
        field="true"
        required
      />
      <FieldError>{touched.licenseState && errors.licenseState}</FieldError>
    </VehicleLicenseWrapper>
  );
};

export default VehicleLicenseStateSelect;
