import { mapDeviceLogsDataToCsv } from 'legacy/features/billing/services/mapping/deviceLogsExportMapping';
import ExportCsvButton from 'legacy/shared/controls/ExportCsvButton/ExportCsvButton';

const DeviceLogsExportButton = ({ deviceLogs }) => {
  const exportData = mapDeviceLogsDataToCsv({ deviceLogs });

  return (
    <ExportCsvButton
      exportData={exportData}
      fileNamePrefix={'wcp_device_logs_export'}
      id="deviceLogsExportButton"
    />
  );
};

export default DeviceLogsExportButton;
