import { DropdownLink } from 'legacy/shared/styles/custom/DropdownMenu';

const ViewMaintenanceLogsMenuItem = ({ vehicleId }) => {
  return (
    <DropdownLink to={`/maintenancelogs/${encodeURIComponent(vehicleId)}`}>
      View Maintenance Logs
    </DropdownLink>
  );
};

export default ViewMaintenanceLogsMenuItem;
