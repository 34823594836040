import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { Navigate, useParams } from 'react-router-dom';
import { Formik } from 'formik';

import { resetForgottenPassword, submitLogout } from 'legacy/core/redux/user/actions';

import LoggedOutForm from 'legacy/core/components/authentication/components/LoggedOutForm';
import AnimatedField from 'legacy/shared/controls/AnimatedField/v1/AnimatedField';
import { StyledFormRow } from 'legacy/shared/styles/custom/LoginSignup';
import { validatePasswordResetForm } from 'legacy/shared/utilities/helpers/validation/validators';

const ResetPassword = ({ loginFormMessage, dispatchResetForgottenPassword, submitLogout }) => {
  const { token } = useParams();

  const formikRef = useRef(null);
  const refResetPasswordInput = useRef(null);
  const refConfirmPasswordInput = useRef(null);
  const triggerFormSubmit = () => formikRef.current && formikRef.current.handleSubmit();

  const [resetToken] = useState(token);
  const [redirectToLogin, setRedirectToLogin] = useState(false);

  const onSubmit = async (values, formikActions) => {
    console.log(resetToken);
    if (refResetPasswordInput.current.value === refConfirmPasswordInput.current.value) {
      try {
        const { response } = await dispatchResetForgottenPassword({
          resetToken,
          newPassword: refResetPasswordInput.current.value,
        });
        formikActions.setSubmitting(false);
        if (response && response.message) {
          submitLogout({ isTimedOut: false });
          setRedirectToLogin(true);
        }
      } catch (e) {
        console.log('error resetting password from confirmation submit handler', e);
      }
    }
  };

  return redirectToLogin ? (
    <Navigate to="/login" />
  ) : (
    <Formik
      validateOnChange={true}
      validateOnBlur={false}
      initialValues={{
        resetPassword: '',
        confirmPassword: '',
      }}
      validate={validatePasswordResetForm}
      innerRef={formikRef}
      onSubmit={onSubmit}
    >
      {({ values, errors, touched, dirty, handleChange, handleBlur, submitForm, isSubmitting }) => (
        <LoggedOutForm
          formTitle={'Please enter a new password'}
          formMessage={loginFormMessage}
          submitHandler={triggerFormSubmit}
          submitText={'Reset Password'}
          isSubmitting={isSubmitting}
        >
          <StyledFormRow>
            <AnimatedField
              name="resetPassword"
              value={values.resetPassword}
              touched={touched.resetPassword}
              validationError={errors.resetPassword}
              handleChange={handleChange}
              handleBlur={handleBlur}
              innerRef={refResetPasswordInput}
              placeholder="New Password"
              type="password"
              id="resetPassword"
              required
            />
          </StyledFormRow>
          <StyledFormRow>
            <AnimatedField
              name="confirmPassword"
              value={values.confirmPassword}
              touched={touched.confirmPassword}
              validationError={errors.confirmPassword}
              handleChange={handleChange}
              handleBlur={handleBlur}
              handleKeyUp={(e) => {
                if (e.keyCode === 13) {
                  e.preventDefault();
                  triggerFormSubmit();
                }
              }}
              innerRef={refConfirmPasswordInput}
              placeholder="Confirm New Password"
              type="password"
              id="confirmPassword"
              required
            />
          </StyledFormRow>
        </LoggedOutForm>
      )}
    </Formik>
  );
};

export default connect(
  (state) => ({
    loginFormMessage: state.ui.loginMessage,
  }),
  (dispatch) => ({
    submitLogout: (props) => dispatch(submitLogout(props)),
    dispatchResetForgottenPassword: async ({ username, resetToken, newPassword }) => {
      return dispatch(await resetForgottenPassword({ username, resetToken, newPassword }));
    },
  }),
)(ResetPassword);
