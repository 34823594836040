import colors from 'legacy/shared/constants/colors';
import checkIcon from 'assets/images/check@2x.png';

export const styles = {
  /* Organizations */
  organizations: {
    vehiclesBody: {
      padding: '0 4px',
    },
    container: {
      height: 'auto',
      maxHeight: 'none',
    },
    header: {
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
    },
    icon: {
      marginRight: '10px',
    },
    title: {
      paddingLeft: 10,
    },
    searchContainer: {
      marginBottom: 20,
    },
    search: {
      display: 'block',
      width: '100%',
      height: '35px',
      padding: '0 38px',
      background: 'white',
      borderRadius: '5px',
      border: `1px solid ${colors.haze}`,
      fontSize: '12px',
      fontWeight: 600,
      opacity: '0.43',
      color: 'rgba(33, 31, 80, 0.43)',
    },
    searchIcon: {
      left: '13px',
      transform: 'translateY(150%) translateX(100%)',
    },
    actionsContainer: {
      marginTop: 34,
    },

    phoneNumber: {
      width: '50%',
      display: 'inline-block',
      verticalAlign: 'top',
      marginBottom: '10px',
    },

    backIcon: {
      marginBottom: 7,
      marginRight: 15,
    },
    buttonPlusIcon: {
      position: 'absolute',
      right: 15,
      top: '50%',
      transform: 'translateY(-50%)',
    },
    form: (editToggled) => ({
      marginTop: 20,
      flexDirection: 'row',
      display: 'flex',
      paddingRight: '10%',
    }),
    organizationId: {
      display: 'inline-block',
      color: colors.midnight,
      flexBasis: '225px',
      minWidth: '225px',
    },
    organizationIdLabel: {
      display: 'block',
      fontSize: '9px',
    },
    contactName: (editToggled) => ({
      display: editToggled ? 'inline-block' : 'none',
      marginLeft: 10,
    }),
    outerContainer: {
      marginBottom: '57px',
    },
    innerContainer: {
      display: 'flex',
      justifyContent: 'space-between',
    },
  },
};
