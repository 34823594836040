import { Formik } from 'formik';
import { useDispatch } from 'react-redux';

import AnimatedField from 'legacy/shared/controls/AnimatedField/v1/AnimatedField';
import ButtonWithLoader from 'legacy/shared/controls/ButtonWithLoader/ButtonWithLoader';
import IconSvgComponent from 'legacy/shared/controls/WcpIcon/IconSvgComponent';
import Button from 'legacy/shared/styles/custom/Button';
import { ChangePasswordFormRow, NonTextFieldContainer } from 'legacy/shared/styles/custom/Form';
import { ActionsContainer, CloseButton, Container, Title } from 'legacy/shared/styles/custom/Modal';
import { validateUserPassword } from 'legacy/shared/utilities/helpers/validation/validators';
import { updateUserPassword } from 'legacy/core/redux/user/actions';

const ChangePasswordModal = ({ handleRequestClose }) => {
  const dispatch = useDispatch();

  return (
    <Container autoHeight>
      <CloseButton onClick={handleRequestClose}>
        <IconSvgComponent svgFileName={'x'} alt="Close" />
      </CloseButton>
      <Title style={styles.title}>
        <IconSvgComponent style={styles.editIcon} svgFileName={'edit-pencil-blue'} alt="Edit" />
        Change Password
      </Title>
      <Formik
        validateOnChange={true}
        validateOnBlur={false}
        initialValues={{
          oldPassword: '',
          newPassword: '',
          confirmNewPassword: '',
        }}
        validate={validateUserPassword}
        onSubmit={async (values, actions) => {
          let success;
          success = dispatch(
            updateUserPassword({
              oldPassword: values.oldPassword,
              newPassword: values.newPassword,
            }),
          );
          actions.setSubmitting(false);
          if (success) {
            handleRequestClose();
          }
        }}
      >
        {({ values, errors, touched, isSubmitting, handleChange, handleBlur, submitForm }) => (
          <form style={styles.formBody} onSubmit={submitForm}>
            <NonTextFieldContainer>
              <ChangePasswordFormRow>
                <AnimatedField
                  name="oldPassword"
                  value={values.oldPassword}
                  touched={touched.oldPassword}
                  validationError={errors.oldPassword}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  placeholder="Current Password"
                  type="password"
                  id="oldPassword"
                  required
                />
              </ChangePasswordFormRow>
              <ChangePasswordFormRow>
                <AnimatedField
                  name="newPassword"
                  value={values.newPassword}
                  touched={touched.newPassword}
                  validationError={errors.newPassword}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  placeholder="New Password"
                  type="password"
                  id="newPassword"
                  required
                />
              </ChangePasswordFormRow>
              <ChangePasswordFormRow>
                <AnimatedField
                  name="confirmNewPassword"
                  value={values.confirmNewPassword}
                  touched={touched.confirmNewPassword}
                  validationError={errors.confirmNewPassword}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  placeholder="Confirm New Password"
                  type="password"
                  id="confirmNewPassword"
                  required
                />
              </ChangePasswordFormRow>
            </NonTextFieldContainer>
            <ActionsContainer>
              <Button onClick={handleRequestClose} withRightSpacer default type="button">
                Cancel
              </Button>
              <ButtonWithLoader
                isLoading={isSubmitting}
                confirmText={'Save'}
                loadingStyleProp={'submittingWithSpinner'}
                notLoadingStyleProp={'mediumAlt'}
                clickHandler={submitForm}
              />
            </ActionsContainer>
          </form>
        )}
      </Formik>
    </Container>
  );
};

export default ChangePasswordModal;

const styles = {
  title: {
    marginBottom: '42px',
  },
  editIcon: {
    marginRight: '10px',
  },
  formBody: {
    padding: '0px 20px 0px 20px',
  },
};
