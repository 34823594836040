import { useSelector } from 'react-redux';

import OptOutOfEmailsCheckbox from 'legacy/features/organizations/components/forms/UserForm/checkboxes/OptOutOfEmailsCheckbox';
import UserAvatarField from 'legacy/features/organizations/components/forms/UserForm/custom/UserAvatarField';
import UserRoleField from 'legacy/features/organizations/components/forms/UserForm/dropdowns/UserRoleField';
import FirstNameField from 'legacy/features/organizations/components/forms/UserForm/inputs/FirstNameField';
import LastNameField from 'legacy/features/organizations/components/forms/UserForm/inputs/LastNameField';
import UserEmailField from 'legacy/features/organizations/components/forms/UserForm/inputs/UserEmailField';
import UsernameField from 'legacy/features/organizations/components/forms/UserForm/inputs/UsernameField';
import UserPhoneNumberField from 'legacy/features/organizations/components/forms/UserForm/inputs/UserPhoneNumberField';
import UserTitleField from 'legacy/features/organizations/components/forms/UserForm/inputs/UserTitleField';
import { userValidationSchema } from 'legacy/features/organizations/components/forms/UserForm/validation/userValidationSchema';
import useUpdateUserMutation from 'legacy/features/users/api/mutations/useUpdateUserMutation';
import useFetchUserQuery from 'legacy/features/users/api/queries/useFetchUserQuery';
import Change2faButton from 'legacy/features/users/components/Change2faButton';
import ChangePasswordButton from 'legacy/features/users/components/ChangePasswordButton';
import {
  StyledDivFormCol,
  StyledDivFormGridRow,
  StyledDivFormRow,
} from 'legacy/shared/controls/WcpForm/v2/styles/WcpFormStyles';
import WcpForm from 'legacy/shared/controls/WcpForm/v2/WcpForm';
import LoadingOverlay from 'legacy/shared/controls/WcpSpinner/LoadingOverlay';

const UserProfileEditForm = () => {
  const username = useSelector((state) => state.user.user.username);

  const fetchUserQuery = useFetchUserQuery({ username });
  const updateUserMutation = useUpdateUserMutation();

  const handleSubmit = async ({ formValues: user }) => {
    await updateUserMutation.mutateAsync({ user });
  };

  return fetchUserQuery.data && !fetchUserQuery.isFetching ? (
    <WcpForm
      formData={fetchUserQuery.data}
      handleSubmit={handleSubmit}
      validationSchema={userValidationSchema}
      submitButtonText="Save Changes"
      showCancelButton={false}
      formTitle={`${fetchUserQuery.data.firstName} ${fetchUserQuery.data.lastName} (${fetchUserQuery.data.userRole})`}
    >
      <StyledDivFormGridRow>
        <StyledDivFormCol>
          <StyledDivFormRow>
            <UserAvatarField />
          </StyledDivFormRow>
        </StyledDivFormCol>

        <StyledDivFormCol>
          <UsernameField readOnly={true} />
          <FirstNameField />
          <LastNameField />
          <UserEmailField />
          <UserPhoneNumberField />
          <UserTitleField />
          <UserRoleField readOnly={true} />
          <OptOutOfEmailsCheckbox />
          <Change2faButton />
          <ChangePasswordButton />
        </StyledDivFormCol>
      </StyledDivFormGridRow>
    </WcpForm>
  ) : (
    <LoadingOverlay />
  );
};

export default UserProfileEditForm;
