import useAlertFormsProviderContext from 'legacy/features/alerts/services/providers/AlertFormsProvider/hooks/useAlertFormsProviderContext';
import { useFormikContext } from 'formik';

import ButtonWithLoader from 'legacy/shared/controls/ButtonWithLoader/ButtonWithLoader';

// TODO
const SubmitButton = ({ title }) => {
  const formik = useFormikContext();

  return (
    <>
      <ButtonWithLoader
        isLoading={formik.isSubmitting}
        disabled={false}
        confirmText={title}
        loadingStyleProp={'submittingWithSpinnerModal'}
        notLoadingStyleProp={'mediumAlt'}
      />
    </>
  );
};

export default SubmitButton;
