import { findDuplicateValues } from 'legacy/shared/utilities/helpers/general/findDuplicateValues';
import {
  requiredValidator,
  regexValidator,
  characterLimitValidator,
} from 'legacy/shared/utilities/helpers/validation/validators';

import { alphanumericExtendedRegex } from 'legacy/shared/utilities/helpers/validation/validationRegularExpressions';
import { alphanumericExtendedRegexError } from 'legacy/shared/utilities/helpers/validation/validationErrorMessages';

const recipientGroupValidators = {
  recipientGroupNameField: requiredValidator(
    characterLimitValidator(
      regexValidator(alphanumericExtendedRegex, alphanumericExtendedRegexError),
    ),
  ),
  recipientGroupMembersField: requiredValidator(),
};

export const validateRecipientGroup = (values) => {
  const recipientGroupNameErrors = recipientGroupValidators.recipientGroupNameField(
    values.recipientGroupNameField,
    36,
  );
  let recipientGroupMembersErrors = [];
  let usersToValidate = values?.recipientGroupMembersField?.filter((u) => u);

  if (usersToValidate) {
    if (usersToValidate.length < 1) {
      recipientGroupMembersErrors.push('Please choose at least one recipient');
    }
    for (let u of usersToValidate) {
      const error = recipientGroupValidators.recipientGroupMembersField(u.username);
      if (error) {
        recipientGroupMembersErrors.push(error);
      }
    }
  }

  let duplicateUserNameErrors = 'Please choose unique recipients';
  let userNames = [];
  if (usersToValidate) {
    for (let u of usersToValidate) {
      userNames.push(u.username);
    }
  }

  let duplicates = findDuplicateValues(userNames);
  let validationErrors = {
    ...(recipientGroupNameErrors ? { recipientGroupNameField: recipientGroupNameErrors } : {}),
    ...(recipientGroupMembersErrors.length > 0
      ? { recipientGroupMembersField: recipientGroupMembersErrors[0] }
      : {}),
    ...(duplicates.length > 0 ? { duplicateUserNameErrors: duplicateUserNameErrors } : {}),
  };

  return validationErrors;
};
