import { useNavigate } from 'react-router-dom';

import useDispatchErrorNotification from 'legacy/shared/utilities/hooks/useDispatchErrorNotification';
import useGenericMutation from 'legacy/shared/utilities/hooks/useGenericMutation';
import { unsubscribeFromEmail } from 'legacy/core/api/unsubscribe';

const useUnsubscribeMutation = () => {
  const [errorToast] = useDispatchErrorNotification();
  const navigate = useNavigate();
  const errorDetail = "Please try again or unsubscribe from your user profile's edit page.";
  const mutation = useGenericMutation({
    apiFn: unsubscribeFromEmail,
    onSuccessCallback: () => {},

    onErrorCallback: (err) => {
      errorToast({ showError: true, message: 'Error Unsubscribing', err: errorDetail });
      navigate('/login');
    },
  });

  return mutation;
};

export default useUnsubscribeMutation;
