import { useState } from 'react';

import { TAB_NAMES } from 'legacy/features/firmware/constants/constants';
import useCheckForUpdatesMutation from 'legacy/features/firmware/hooks/useCheckForUpdatesMutation';
import { ViewLogsButton } from 'legacy/shared/styles/custom/LinkButton';
import { UpdatesSummaryActionButtonsDiv } from 'legacy/shared/styles/custom/OtaUpdates';
import { TabRadioWrapper } from 'legacy/shared/styles/custom/Tabs';
import PageListWrapper, { PageListHead, PageListTitle } from 'legacy/shared/styles/custom/PageList';
import { SectionHead } from 'legacy/shared/styles/custom/Section';
import ButtonWithLoader from 'legacy/shared/controls/ButtonWithLoader/ButtonWithLoader';
import {
  getDisplayOtaUpdatesPreferenceInLocal,
  setDisplayOtaUpdatesPreferenceInLocal,
} from 'legacy/shared/utilities/misc/localStore';
import FirmwareUpdatesSummaryByVehicle from 'legacy/features/firmware/ui/byVehicle/FirmwareUpdatesSummaryByVehicle';
import FirmwareUpdatesSummaryByDevice from 'legacy/features/firmware/ui/byDevice/FirmwareUpdateSummaryByDevice';

const FirmwareUpdatesSummaryPage = () => {
  const [checkForUpdatesMutation, setCheckUpdatesRunning, checkUpdatesRunning] =
    useCheckForUpdatesMutation();

  let tabPreference = getDisplayOtaUpdatesPreferenceInLocal();
  const [tabHeaderSelection, setTabHeaderSelection] = useState(
    tabPreference ? tabPreference : TAB_NAMES.BY_DEVICE,
  );

  return (
    <PageListWrapper>
      <PageListHead stackable otaSummary>
        <SectionHead otaUpdates>
          <PageListTitle>Firmware Updates</PageListTitle>
          <TabRadioWrapper>
            <input
              type="radio"
              id="radioByDevice"
              name="radioOtaType"
              value="device"
              checked={tabHeaderSelection === TAB_NAMES.BY_DEVICE}
              onClick={() => {
                console.log('HI!');
                setTabHeaderSelection(TAB_NAMES.BY_DEVICE);
                setDisplayOtaUpdatesPreferenceInLocal(TAB_NAMES.BY_DEVICE);
              }}
            />
            <label for="radioByDevice" title="View updates by device">
              BY DEVICE
            </label>

            <input
              type="radio"
              id="radioByVehicle"
              name="radioOtaType"
              value="vehicle"
              checked={tabHeaderSelection === TAB_NAMES.BY_VEHICLE}
              onClick={() => {
                setTabHeaderSelection(TAB_NAMES.BY_VEHICLE);
                setDisplayOtaUpdatesPreferenceInLocal(TAB_NAMES.BY_VEHICLE);
              }}
            />
            <label for="radioByVehicle" title="View updates by vehicle">
              BY VEHICLE
            </label>
          </TabRadioWrapper>
        </SectionHead>
        <UpdatesSummaryActionButtonsDiv>
          <ViewLogsButton to={`/firmware/updates/logs`}>View Update Logs</ViewLogsButton>
          <ButtonWithLoader
            isLoading={checkUpdatesRunning}
            confirmText={'Check for Updates'}
            loadingStyleProp={'submittingWithSpinner'}
            notLoadingStyleProp={'updates'}
            containerProp={'inherit'}
            clickHandler={() => {
              checkForUpdatesMutation.mutate();
              setCheckUpdatesRunning(true);
            }}
          />
        </UpdatesSummaryActionButtonsDiv>
      </PageListHead>

      {tabHeaderSelection === TAB_NAMES.BY_DEVICE ? (
        <FirmwareUpdatesSummaryByDevice />
      ) : (
        <FirmwareUpdatesSummaryByVehicle />
      )}
    </PageListWrapper>
  );
};

export default FirmwareUpdatesSummaryPage;
