import {
  StyledDivModalContent,
  StyledModalText,
} from 'legacy/shared/controls/WcpModal/v2/styles/WcpModalStyles';
import { CopyToClipboardButton } from 'legacy/shared/styles/custom/Button';
import { StyledKeyAndCopyToClipboardWrapper } from 'legacy/shared/styles/custom/Integrations';
import { StyledBoldedText } from 'legacy/shared/styles/v2/Formatting';

const ReceiveGttAccessKeyModalContent = ({ modalState: { gttAccessKey } }) => {
  return (
    <StyledDivModalContent>
      <StyledModalText>
        This is your one time access key. Please make sure to copy it for use in the Miovision cloud
      </StyledModalText>
      <StyledKeyAndCopyToClipboardWrapper>
        <StyledBoldedText>{gttAccessKey}</StyledBoldedText>
        <CopyToClipboardButton
          title="Copy to Clipboard"
          onClick={() => {
            navigator.clipboard.writeText(gttAccessKey);
          }}
        />
      </StyledKeyAndCopyToClipboardWrapper>
    </StyledDivModalContent>
  );
};

export default ReceiveGttAccessKeyModalContent;
