import colors from 'legacy/shared/constants/colors';
import WcpTabItem from 'legacy/shared/controls/WcpTabView/v2/WcpTabItem';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

const StyledTabUl = styled.ul`
  display: flex;
  justify-content: space-around;

  ${(props) =>
    props.vehicleDeviceTable &&
    `
    margin-top:10px;
    border: solid 1px ${colors.haze};
  `};
  ${(props) =>
    props.configurationsTableNav &&
    `
    width: 33.334%;
    margin: 0 auto 32.5px;
  `};
`;

const StyledTabLi = styled.li`
  display: block;
  flex: 1 1 25%;
  padding-bottom: 12px;
  font-family: 'Muli', sans-serif;
  font-size: 16px;
  text-align: center;
  color: ${colors.haze};
  cursor: pointer;

  ${(props) =>
    props.vehicleDeviceTable &&
    `
    padding-top: 15px;
     &:hover {
			background-color: ${colors.concrete};
      color: ${colors.midnight};
    }
  `};

  ${(props) =>
    props.active &&
    `
    color: ${colors.midnight};
    border-bottom: solid 2px ${colors.charcoal};
  `};

  ${(props) =>
    props.vehicleDeviceTable &&
    props.active &&
    `
    border: solid 1px ${colors.charcoal};
    background-color:${colors.cloud};
  `};
`;

const WcpTabList = ({ tabs, configurationsTableNav, vehicleDeviceTable }) => {
  if (tabs.length === 0) {
    throw new Error('Tabs must have at least one tab');
  }

  const [activeTabIndex, setActiveTabIndex] = useState(
    tabs.findIndex((tab) => tab.initialTab) || 0,
  );
  const activeTab = tabs[activeTabIndex];
  activeTab.handleTabChange = activeTab.handleTabChange || (() => {});

  useEffect(() => {
    activeTab.handleTabChange();
  }, [activeTabIndex]);

  return (
    <>
      <StyledTabUl
        configurationsTableNav={configurationsTableNav}
        vehicleDeviceTable={vehicleDeviceTable}
      >
        {tabs.map((tab, index) => {
          return (
            <StyledTabLi
              vehicleDeviceTable={vehicleDeviceTable}
              key={index}
              active={activeTabIndex === index}
              onClick={() => {
                setActiveTabIndex(index);
              }}
            >
              {tab.label}
            </StyledTabLi>
          );
        })}
      </StyledTabUl>
      <WcpTabItem>{activeTab.content}</WcpTabItem>
    </>
  );
};

export default WcpTabList;
