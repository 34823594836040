import { getStatusMessage } from 'legacy/features/integrations/utilities/getStatusMessage';
import WcpCheckbox from 'legacy/shared/controls/WcpCheckbox/v1/WcpCheckbox';
import WcpCheckboxField from 'legacy/shared/controls/WcpForm/v2/components/fields/WcpCheckboxField';
import useWcpFormContext from 'legacy/shared/controls/WcpForm/v2/hooks/useWcpFormContext';
import {
  FormLegend,
  FormSectionLabel,
  HaasStatus,
  HassIntegrationWrapperDiv,
} from 'legacy/shared/styles/custom/Form';
import { useCheckUserRole, userRoleKeys } from 'legacy/shared/utilities/hooks/useCheckUserRole';

const HaasIntegrationCheckbox = () => {
  const isUserRoleAuthorized = useCheckUserRole(
    userRoleKeys.ORGANIZATION_ADMIN,
    userRoleKeys.FIELD_SOLUTION_ENGINEER,
  );

  const {
    formValues: { haasSetup, haasEnabled },
  } = useWcpFormContext();

  return (
    <>
      <HassIntegrationWrapperDiv>
        <FormSectionLabel gtt>HAAS Integration</FormSectionLabel>

        <WcpCheckboxField
          dataKey={'haasEnabled'}
          label="Enable HAAS Integration"
          disabled={!isUserRoleAuthorized}
        />

        <FormLegend>
          <HaasStatus>{getStatusMessage(haasEnabled, haasSetup)}</HaasStatus>
        </FormLegend>
      </HassIntegrationWrapperDiv>
    </>
  );
};

export default HaasIntegrationCheckbox;
