import { DEACTIVATE_USER_CONFIRMATION_MODAL_KEY } from 'legacy/features/organizations/providers/modal/constants/organizationFeatureModalKeys';
import { useWcpModalContext } from 'legacy/shared/controls/WcpModal/v2/WcpModalProvider';
import { SimpleDropdownBtn, SimpleDropdownItem } from 'legacy/shared/styles/custom/DropdownMenu';

const DeactivateUserMenuItem = ({ user }) => {
  const deactivateUserConfirmationModalContext = useWcpModalContext(
    DEACTIVATE_USER_CONFIRMATION_MODAL_KEY,
  );
  const openDeactivateUserConfirmationModal = () => {
    deactivateUserConfirmationModalContext.modalActions.openModal({ user });
  };
  return (
    <SimpleDropdownItem>
      <SimpleDropdownBtn onClick={openDeactivateUserConfirmationModal}>
        Deactivate
      </SimpleDropdownBtn>
    </SimpleDropdownItem>
  );
};
export default DeactivateUserMenuItem;
