import React from 'react';
import { ActionButtonWithIcon } from 'legacy/shared/styles/custom/Button';
import { createExportDownload, getCsvFileName } from 'legacy/shared/utilities/misc/csvExport';
import { ExportWrapper } from 'legacy/shared/styles/custom/Analytics';
import { ExportDiv } from 'legacy/shared/styles/custom/PageList';

const ExportCsvButton = ({ exportData, fileNamePrefix }) => {
  return (
    <ExportWrapper>
      <ActionButtonWithIcon
        onClick={() => {
          const fileName = getCsvFileName({ fileNamePrefix });
          createExportDownload(exportData, fileName);
        }}
        export
      >
        Export to .csv
      </ActionButtonWithIcon>
    </ExportWrapper>
  );
};

export default ExportCsvButton;
