/**
 * @ External Dependecies
 */
import styled from 'styled-components';
import colors from 'legacy/shared/constants/colors';
import device from 'legacy/shared/constants/sizing';

export const UniversalApiWrapper = styled.div`
  margin-top: 20px;
  @media ${device.largeAnalytics} {
    margin-top: 30px;
  }
`;

export const StyledKeyAndCopyToClipboardWrapper = styled.div`
  height: 40px;
  display: grid;
  grid-gap: 5px;
  grid-template-areas: 'Col Col';
  grid-template-columns: max-content 35px;
`;
