import { ReactSVG } from 'react-svg';

const JSToCSS = (JS) => {
  let cssString = '';
  for (let objectKey in JS) {
    cssString +=
      objectKey.replace(/([A-Z])/g, (g) => `-${g[0].toLowerCase()}`) + ': ' + JS[objectKey] + ';\n';
  }

  return cssString;
};

const IconSvgComponent = ({ svgFileName, svgStyle, ...props }) => {
  return (
    <ReactSVG
      src={require(`assets/svg/${svgFileName}.svg`)}
      className="svg-icon"
      {...(svgStyle && {
        beforeInjection: (svg) => {
          svg.setAttribute('style', JSToCSS(svgStyle));
        },
      })}
      {...props}
    />
  );
};

export default IconSvgComponent;
