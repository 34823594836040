export const organizationMapping = {
  organization_id: {
    frontendProperty: 'organizationId',
  },
  organization_name: {
    frontendProperty: 'organizationName',
  },
  active_users: {
    frontendProperty: 'activeUsers',
  },
  address_city: {
    frontendProperty: 'city',
  },
  address_state: {
    frontendProperty: 'addressState',
  },
  address_1: {
    frontendProperty: 'address1',
  },
  address_2: {
    frontendProperty: 'address2',
  },
  contact_phone: {
    frontendProperty: 'contactPhone',
  },
  contact_phone_extension: {
    frontendProperty: 'contactPhoneExtension',
  },
  contact_email_id: {
    frontendProperty: 'contactEmail',
  },
  contact_name: {
    frontendProperty: 'contactName',
  },
  customer_type: {
    frontendProperty: 'customerType',
  },
  timezone: {
    frontendProperty: 'timeZone',
  },
  start_date: {
    frontendProperty: 'startDate',
  },
  end_date: {
    frontendProperty: 'endDate',
  },
  organization_active: {
    frontendProperty: 'isActive',
  },
  associated_fse: {
    frontendProperty: 'associatedFSE',
  },
  gtt_enabled: {
    frontendProperty: 'gttEnabled',
  },
  gtt_setup: {
    frontendProperty: 'gttSetup',
  },
  haas_enabled: {
    frontendProperty: 'haasEnabled',
  },
  alerts_active: {
    frontendProperty: 'alertsActive',
  },
  haas_setup: {
    frontendProperty: 'haasSetup',
  },
  wim_account_id: {
    frontendProperty: 'wimAccountId',
  },
  vehicle_count: {
    frontendProperty: 'vehicleCount',
  },
  maintenance_reminder_mileage: {
    frontendProperty: 'reminderMileage',
  },
  maintenance_reminder_engine_hours: {
    frontendProperty: 'reminderEngineHours',
  },
  maintenance_reminder_days: {
    frontendProperty: 'reminderDays',
  },
  maintenance_reminder_oil_life: {
    frontendProperty: 'reminderOilLife',
  },
  thrugreen_enabled: {
    frontendProperty: 'thruGreenEnabled',
  },
  applied_information_enabled: {
    frontendProperty: 'appliedInformationEnabled',
  },
  applied_information_business_id: {
    frontendProperty: 'appliedInformationBusinessId',
  },
  subscription_grace_period_end_date: {
    frontendProperty: 'subscriptionGracePeriodEndDate',
  },
  subscription_end_date: {
    frontendProperty: 'subscriptionEndDate',
  },
  subscription_status: {
    frontendProperty: 'subscriptionStatus',
  },
};
