import styled from 'styled-components';
import colors from 'legacy/shared/constants/colors';

export const Spinner = styled.div`
  display: inline-block;
  width: 100%;
  height: 100%;
  vertical-align: text-bottom;
  border: 0.25em solid;
  border-color: ${colors.midnight};
  border-right: 0.25em solid transparent;
  border-radius: 50%;
  animation: spinner 0.75s linear infinite;
`;

export const TableSpinner = styled.div`
  min-width: 20px;
  min-height: 20px;
  max-width: 20px;
  max-height: 20px;
  vertical-align: text-bottom;
  border: 2px solid;
  border-color: ${colors.midnight};
  border-right: 2px solid transparent;
  border-radius: 50%;
  animation: spinner 0.75s linear infinite;
  margin-right: 13px;
`;

export const ButtonSpinner = styled.div`
  width: 25px;
  height: 25px;
  vertical-align: text-bottom;
  border: 2px solid;
  border-color: ${colors.fog};
  border-right: 2px solid transparent;
  border-radius: 50%;
  animation: spinner 0.75s linear infinite;
  position: absolute;
  overflow: visible;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  cursor: not-allowed;
`;

export const InlineSpinnerWithMessageWrapper = styled.div`
  display: flex;
  margin-bottom: 20px;
`;

export const SpinnerSpacer = styled.div`
  margin-right: 20px;
`;
