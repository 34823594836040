import RemoveAvatarImageButton from 'legacy/shared/controls/WcpAvatarControl/v1/components/buttons/RemoveAvatarImageButton';
import { StyledAvatarImage } from 'legacy/shared/controls/WcpAvatarControl/v1/styles/AvatarControlStyles';

const AvatarImagePreviewControl = ({ avatarImage, handleRemoveImage }) => {
  return (
    <>
      <StyledAvatarImage image={avatarImage} />
      <RemoveAvatarImageButton handleRemoveImage={handleRemoveImage} />
    </>
  );
};

export default AvatarImagePreviewControl;
