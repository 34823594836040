import useVehiclePlaybackProviderContext from 'legacy/features/playback/state/provider/hooks/useVehiclePlaybackProviderContext';
import {
  DateRangeValidationDiv,
  FormAsterisk,
  FormLabelAnimated,
} from 'legacy/shared/styles/custom/Form';
import { PlaybackDateDiv } from 'legacy/shared/styles/custom/VehiclePlayback';
import StyledDayPickerInput from 'legacy/shared/controls/WcpDayPicker/StyledDayPickerInput';
import {
  formatISOTimestampToShortDate,
  getLongDateInIsoFormat,
  getShortDateFromDatePicker,
} from 'legacy/shared/utilities/misc/time';
import { useFormikContext } from 'formik';
import useMakeFormikField from 'legacy/shared/controls/WcpForm/v1/hooks/useMakeFormikField';

const VehiclePlaybackFormEndDateField = () => {
  const {
    actions: { setPlaybackRequestFormAction },
    state: {
      playbackRequestForm: { readOnly, endDate },
    },
  } = useVehiclePlaybackProviderContext();

  const { validationResult } = useMakeFormikField({
    fieldName: 'endDateField',
    fieldValue: endDate,
    triggerValidation: true,
  });

  return (
    <PlaybackDateDiv>
      <FormLabelAnimated animated>
        End Date (MM/DD/YYYY)<FormAsterisk>*</FormAsterisk>
      </FormLabelAnimated>
      <StyledDayPickerInput
        placeholder={formatISOTimestampToShortDate(endDate)}
        name="endDateField"
        value={formatISOTimestampToShortDate(endDate)}
        onDayChange={(date) => {
          if (date) {
            setPlaybackRequestFormAction({ endDate: getLongDateInIsoFormat(date) });
          }
        }}
        disabled={readOnly}
      />

      <DateRangeValidationDiv>{validationResult.errorMessage}</DateRangeValidationDiv>
    </PlaybackDateDiv>
  );
};

export default VehiclePlaybackFormEndDateField;
