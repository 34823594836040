import { createAction } from 'redux-actions';

import {
  associateFSEsToOrganization as associateFSEsToOrganizationAPI,
  disassociateFSEFromOrganization as disassociateFSEFromOrganizationAPI,
  fetchOrganizationFSEs as fetchOrganizationFSEsAPI,
  getAssociatedFSEData as getAssociatedFSEDataAPI,
  getOrganizations,
} from 'legacy/core/api/organizations';
import { decorateOrganization } from 'legacy/core/decorators/organization';
import { decorateFseUser } from 'legacy/core/decorators/user';
import { addNotification } from 'legacy/core/redux/ui/actions';
import { setSubscriptionExpiryAlertBar } from 'legacy/core/redux/ui/actions';
import { getSubscriptionExpiryAlertMessage } from 'legacy/shared/constants/alerts';
import { getDebouncedThunk } from 'legacy/shared/utilities/misc/debounce';
import { createNotification, LEVELS } from 'legacy/shared/utilities/misc/notification';

export const requestOrganizations = createAction('REQUEST_ORGANIZATIONS');
export const receiveOrganizations = createAction('RECEIVE_ORGANIZATIONS');
export const rejectOrganizations = createAction('REJECT_ORGANIZATIONS');
export const rejectCreateOrganization = createAction('REJECT_CREATE_ORGANIZATION');
export const requestDeactivateOrganization = createAction('REQUEST_DEACTIVATE_ORGANIZATION');
export const rejectDeactivateOrganization = createAction('REJECT_DEACTIVATE_ORGANIZATION');
export const requestActivateOrganization = createAction('REQUEST_ACTIVATE_ORGANIZATION');
export const rejectActivateOrganization = createAction('REJECT_ACTIVATE_ORGANIZATION');
export const requestUpdateOrganization = createAction('REQUEST_UPDATE_ORGANIZATION');
export const rejectUpdateOrganization = createAction('REJECT_UPDATE_ORGANIZATION');

export const requestAssociateFSEData = createAction('REQUEST_ASSOCIATED_FSE_DATA');
export const successAssociateFSEData = createAction('SUCCESS_ASSOCIATED_FSE_DATA');
export const rejectAssociateFSEData = createAction('REJECT_ASSOCIATED_FSE_DATA');

export const requestOrganizationFSEs = createAction('REQUEST_ORGANIZATION_FSES');
export const receiveOrganizationFSEs = createAction('SUCCESS_ORGANIZATION_FSES');
export const rejectOrganizationFSEs = createAction('REJECT_ORGANIZATION_FSES');

export const fetchOrganizations = ({ organizationIds } = {}) => {
  return async (dispatch, getState) => {
    dispatch(requestOrganizations());
    try {
      const userState = getState().user;
      const { response } = await getOrganizations({ organizationIds });
      dispatch(
        receiveOrganizations(
          response.message.map((organization) => decorateOrganization(organization)),
        ),
      );
      const filteredOrganization = decorateOrganization(
        response.message.find(
          (organization) => organization.organization_id === userState.filteredOrganizationId,
        ),
      );
      if (filteredOrganization.subscriptionGracePeriodEndDate) {
        dispatch(
          setSubscriptionExpiryAlertBar({
            ...getSubscriptionExpiryAlertMessage(filteredOrganization),
            ...{
              dismissed: false,
            },
          }),
        );
      }
      return true;
    } catch (e) {
      console.log(e);
      dispatch(rejectOrganizations(e));
      dispatch(
        addNotification({
          notification: createNotification(LEVELS.ERROR, 'Error Getting Organizations', e),
        }),
      );
      return false;
    }
  };
};

export const debouncedFetchOrganizations = getDebouncedThunk(fetchOrganizations, 1000);

export const getAssociatedFSEData = ({ organizationId, username }) => {
  return async (dispatch) => {
    dispatch(requestAssociateFSEData());
    try {
      const { response } = await getAssociatedFSEDataAPI({
        organizationId,
        username,
      });

      let fseData = response.message.associated_fse[0];

      if (fseData) {
        dispatch(
          successAssociateFSEData({ orgId: organizationId, permissions: fseData.permissions }),
        );
        return true;
      } else {
        dispatch(successAssociateFSEData({}));
      }
      return false;
    } catch (e) {
      console.log(e);
      dispatch(rejectAssociateFSEData());

      return false;
    }
  };
};

export const fetchOrganizationFSEs = (organizationId) => {
  console.log('fetching organization FSEs');
  return async (dispatch, getState) => {
    dispatch(requestOrganizationFSEs());
    try {
      const userState = getState().user;
      const guardedOrganizationId = organizationId ? organizationId : userState.user.organizationId;
      const { response } = await fetchOrganizationFSEsAPI(guardedOrganizationId);
      if (response && response.message) {
        dispatch(
          receiveOrganizationFSEs({
            organizationId: guardedOrganizationId,
            associated_fse: response.message.associated_fse.map((FSE) => decorateFseUser(FSE)),
          }),
        );
      }
      return true;
    } catch (e) {
      console.log(e);
      dispatch(rejectOrganizationFSEs());
      dispatch(
        addNotification({
          notification: createNotification(
            LEVELS.ERROR,
            'Error getting Field Solution Engineers for Organization',
            e,
          ),
        }),
      );
      return { error: e };
    }
  };
};
