import PartnershipsActionMenu from 'legacy/features/integrations/ui/menus/PartnershipsActionMenu';
import IconSvgComponent from 'legacy/shared/controls/WcpIcon/IconSvgComponent';

export const partnershipsTableColumns = [
  {
    title: 'Name',
    displayProperty: 'name',
    className: 'name',
    width: '35%',
  },
  {
    title: 'Status',
    displayProperty: 'status',
    width: '20%',
    render({ rowData, cellValue }) {
      return (
        <span className="vehicleNameAndIcon" title={'Status: ' + cellValue}>
          {rowData.status === 'Enabled' && (
            <IconSvgComponent title={'Enabled'} svgFileName={'check-success'} />
          )}
          {rowData.status}
        </span>
      );
    },
  },

  {
    title: 'Detail',
    displayProperty: 'detail',
    width: '40%',
  },

  {
    title: '',
    className: 'action',
    searchEnabled: false,
    key: 'a',
    render({ rowData }) {
      return (
        <PartnershipsActionMenu
          partnership={rowData}
          id={'partnershipsActionMenu' + rowData.rowClassName}
        />
      );
    },
  },
];
