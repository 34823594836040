import { WcpTextInputField } from 'legacy/shared/controls/WcpForm/v2/components/fields/WcpTextInputField';
import { useCheckUserRole, userRoleKeys } from 'legacy/shared/utilities/hooks/useCheckUserRole';

const WimAccountField = () => {
  const isUserRoleAuthorized = useCheckUserRole(
    userRoleKeys.SYSTEM_ADMIN,
    userRoleKeys.FIELD_SOLUTION_ENGINEER,
  );

  return (
    <WcpTextInputField
      label="WIM Account ID"
      dataKey="wimAccountId"
      required={true}
      disabled={!isUserRoleAuthorized}
    />
  );
};

export default WimAccountField;
