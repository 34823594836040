import ManageAlertsLogsActionMenu from 'legacy/features/alerts/ui/components/AlertLogsSummary/menus/ManageAlertsLogsActionMenu';
import { TruncatedTextWithWrap } from 'legacy/shared/styles/custom/TruncatedText';
import { RedText } from 'legacy/shared/styles/custom/ManageAlerts';

export const getRecipientTooltip = (alertLog) => {
  return `Recipients: ${alertLog.testModeEnabled ? 'TEST MODE - ' : ''}${
    alertLog.formattedUserDisplayNames
  }
    `;
};

export const manageAlertsLogsTableColumns = [
  {
    title: 'Timestamp',
    displayProperty: 'formattedTime',
    sortProperty: 'timestamp',
    width: '25%',
    render({ rowData, cellJsx }) {
      return <span title={'Timestamp: ' + rowData.formattedTime}>{cellJsx}</span>;
    },
  },
  {
    title: 'Vehicle',
    displayProperty: 'vehicleName',
    width: '10%',
    render({ rowData, cellJsx }) {
      return (
        <span title={`Vehicle: ${rowData.vehicleName} `}>
          <TruncatedTextWithWrap>{cellJsx}</TruncatedTextWithWrap>
        </span>
      );
    },
  },
  {
    title: 'Condition',
    displayProperty: 'alertTypeDisplay',
    width: '15%',
  },

  {
    title: 'Alert Value',
    displayProperty: 'triggerValueDisplay',
    width: '10%',
  },
  {
    title: 'Measured Value',
    displayProperty: 'measuredValueWithCommas',
    sortProperty: 'measuredValue',
    width: '10%',
    render({ rowData, cellJsx }) {
      return (
        <RedText title={`Measured Value: ${rowData.measuredValueWithCommas}`}>{cellJsx}</RedText>
      );
    },
  },

  {
    title: 'Recipients',
    displayProperty: 'formattedUserDisplayNames',
    render({ rowData, cellJsx }) {
      return (
        <span title={getRecipientTooltip(rowData)}>
          <TruncatedTextWithWrap>
            {rowData.testModeEnabled ? 'TEST MODE - ' : ''}
            {cellJsx}
          </TruncatedTextWithWrap>
        </span>
      );
    },
  },
  {
    title: 'Method',
    displayProperty: 'aggregateAlertMethod',
  },
  {
    title: '',
    className: 'action',
    searchEnabled: false,
    render({ rowData }) {
      return <ManageAlertsLogsActionMenu log={rowData} />;
    },
  },
];
