/**
 * @ External Dependecies
 */
import styled from 'styled-components';

import colors from 'legacy/shared/constants/colors';

export const TableTabs = styled.ul`
  display: flex;
  justify-content: space-around;
  ${(props) =>
    props.vehicleDeviceTable &&
    `
    margin-top:10px;
    border: solid 1px ${colors.haze};
  `};

  ${(props) =>
    props.configurationsTableNav &&
    `
    width: 33.334%;
    margin: 0 auto 32.5px;
  `};
`;

export const TableTab = styled.li`
  display: block;
  flex: 1 1 25%;
  padding-bottom: 12px;
  font-family: 'Muli', sans-serif;
  font-size: 16px;
  text-align: center;
  color: ${colors.haze};
  cursor: pointer;

  ${(props) =>
    props.vehicleDeviceTable &&
    `
    padding-top: 15px;
     &:hover {
			background-color: ${colors.concrete};
      color: ${colors.midnight};
    }
  `};

  ${(props) =>
    props.active &&
    `
    color: ${colors.midnight};
    border-bottom: solid 2px ${colors.charcoal};
  `};

  ${(props) =>
    props.vehicleDeviceTable &&
    props.active &&
    `
    border: solid 1px ${colors.charcoal};
    background-color:${colors.cloud};
  `};
`;

export const ConfigurationsTableNav = styled.ul`
  display: flex;
  justify-content: space-around;
  width: 33.334%;
  margin: 0 auto 32.5px;
`;

export const TabRadioWrapper = styled.div`
  min-width: 275px;
  input[type='radio'] {
    opacity: 0;
    position: fixed;
    width: 0;
  }

  label {
    display: inline-block;
    background-color: ${colors.cotton};
    color: ${colors.midnight};
    padding: 10px 30px 8px 30px;
    font-size: 13px;
    font-weight: 600;
    border-width: 1px;
    border-style: solid;
    border-color: ${colors.haze};
    border-radius: 10px 0px 0px 10px;
  }
  label:last-child {
    border-radius: 0px 10px 10px 0px;
    border-left-width: 0px;
  }
  label:hover {
    cursor: pointer;
  }
  input[type='radio']:checked + label {
    background-color: ${colors.midnight};
    border-color: ${colors.midnight};
    color: ${colors.cotton};
    font-weight: 700;
  }
`;
