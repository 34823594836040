import { SimpleDropdownItem } from 'legacy/shared/styles/custom/DropdownMenu';
import ActionMenu from 'legacy/shared/controls/WcpTable/v1/ActionMenu';
import EnableDisablePartnershipMenuItem from 'legacy/features/integrations/ui/menus/EnableDisablePartnershipMenuItem';
import GenerateGttKeyMenuItem from 'legacy/features/integrations/ui/menus/GenerateGttKeyMenuItem';
import EditAppliedInformationBusinessIdMenuItem from 'legacy/features/integrations/ui/menus/EditAppliedInformationBusinessIdMenuItem';
import { useCheckUserRole, userRoleKeys } from 'legacy/shared/utilities/hooks/useCheckUserRole';

const PartnershipsActionMenu = ({ partnership }) => {
  const isUserRoleAuthorized = useCheckUserRole(
    userRoleKeys.ORGANIZATION_ADMIN,
    userRoleKeys.FIELD_SOLUTION_ENGINEER,
  );
  return (
    <>
      <ActionMenu>
        <SimpleDropdownItem>
          <EnableDisablePartnershipMenuItem
            partnership={partnership}
            onMenuItemClick={null}
            id="enableDisablePartnershipMenuItem"
          />
        </SimpleDropdownItem>
        {isUserRoleAuthorized && partnership.name === 'Miovision' && (
          <SimpleDropdownItem>
            <GenerateGttKeyMenuItem
              partnership={partnership}
              onMenuItemClick={null}
              id="generateGttKeyMenuItem"
            />
          </SimpleDropdownItem>
        )}
        {partnership.name === 'Applied Information' && (
          <SimpleDropdownItem>
            <EditAppliedInformationBusinessIdMenuItem
              partnership={partnership}
              onMenuItemClick={null}
              id="editAppliedInformationBusinessIdMenuItem"
            />
          </SimpleDropdownItem>
        )}
      </ActionMenu>
    </>
  );
};

export default PartnershipsActionMenu;
