import useOrganizationActivationMutation from 'legacy/features/organizations/api/mutations/useOrganizationActivationMutation';
import GenericConfirmationModalControls from 'legacy/shared/controls/WcpModal/v2/controls/GenericConfirmationModalControls';

const DeactivateOrganizationConfirmationModalControls = ({
  modalState: { organization },
  modalActions,
}) => {
  const organzationActivationMutation = useOrganizationActivationMutation();

  const submitDeactivateOrganization = async () => {
    await organzationActivationMutation.mutateAsync({
      organization,
      activate: false,
    });

    modalActions.closeModal();
  };

  return (
    <GenericConfirmationModalControls
      confirmButtonText="Deactivate"
      submitOnConfirm={true}
      handleConfirm={submitDeactivateOrganization}
      handleCancel={modalActions.closeModal}
    />
  );
};

export default DeactivateOrganizationConfirmationModalControls;
