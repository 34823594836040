import { maintenanceTypeDropdownData } from 'legacy/features/maintenance/data/maintenanceTypeDropdownData';
import { useDeleteMaintenanceEventMutation } from 'legacy/features/maintenance/hooks/mutations/useDeleteMaintenanceEventMutation';
import { logWcpError } from 'legacy/core/error/utilities/ErrorLogger';
import SimpleConfirmationModal from 'legacy/shared/controls/WcpModal/v1/SimpleConfirmationModal';
import { maintenanceTypeConstants } from 'legacy/features/maintenance/data/maintenanceTypeConstants';

const DeleteMaintenanceEventModal = ({ modalData: { show, event }, handleCloseModal }) => {
  const deleteMaintenanceEventMutation = useDeleteMaintenanceEventMutation();
  const dueInText =
    event?.maintenanceType.value !== maintenanceTypeConstants.DIAGNOSTIC
      ? ` due in ${event?.dueIn}`
      : '';
  return (
    show && (
      <SimpleConfirmationModal
        handleCloseModal={handleCloseModal}
        isLoading={deleteMaintenanceEventMutation.isPending}
        submitHandler={async () => {
          try {
            let result = await deleteMaintenanceEventMutation.mutateAsync({
              maintenanceEventId: event.eventId,
            });
          } catch (err) {
            logWcpError(err);
          } finally {
            handleCloseModal();
          }
        }}
        bodyText={`Do you really want to delete this maintenance event: `}
        secondaryLineBoldedItem={`${event.vehicleNameDisplay}:  ${
          maintenanceTypeDropdownData.find((t) => t.label === event?.maintenanceType.label)?.label
        }${dueInText}?`}
        title={`Delete Maintenance Event`}
        confirmText="Delete"
        cancelText="Cancel"
      />
    )
  );
};

export default DeleteMaintenanceEventModal;
