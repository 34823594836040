import AnimatedField from 'legacy/shared/controls/AnimatedField/v1/AnimatedField';

const ReminderDaysField = ({
  formikProps: { values, touched, errors, handleChange, handleBlur },
}) => {
  return (
    <div>
      <AnimatedField
        name="reminderDays"
        placeholder="Days"
        value={values.reminderDays}
        touched={touched.reminderDays}
        validationError={errors.reminderDays}
        handleChange={handleChange}
        handleBlur={handleBlur}
        disabled={false}
        required
      />
    </div>
  );
};

export default ReminderDaysField;
