import React from 'react';
import {
  CardLabel,
  CardValue,
  CardRow,
  CardName,
  CardContainer,
  CardOuterWrapper,
  CardExpandedInfo,
} from 'legacy/shared/styles/custom/MobileCard';
import { firmwareStatus } from 'legacy/shared/constants/firmwareStatus';
import ProgressBar from 'legacy/shared/controls/ProgressBar/ProgressBar';
import ButtonWithLoader from 'legacy/shared/controls/ButtonWithLoader/ButtonWithLoader';
import PermissionProtectedElement from 'legacy/core/components/authorization/PermissionProtectedElement';
import { permissionData } from 'legacy/shared/constants/users';

export default function OtaUpdateCardByVehicle({
  device,
  vehicle,
  handleUpdateFirmware,
  updatePostLoading,
  updatesInProgress,
}) {
  return (
    <CardOuterWrapper>
      <CardContainer otaByVehicleExpanded>
        <CardRow>
          <CardName>{device.product_name}</CardName>
        </CardRow>
        <CardExpandedInfo expanded>
          <CardRow potentialLongValue>
            <CardLabel>Device ID:</CardLabel>
            <CardValue>{device.device_id}</CardValue>
          </CardRow>
          <CardRow>
            <CardLabel>Initial Firmware Version:</CardLabel>
            <CardValue>{device.version_initial}</CardValue>
          </CardRow>
          <CardRow>
            <CardLabel>Target Firmware Version:</CardLabel>
            <CardValue>{device.version}</CardValue>
          </CardRow>
          <CardRow>
            <CardLabel>Firmware Status:</CardLabel>
            <CardValue>{firmwareStatus[device.firmware_status].displayName}</CardValue>
          </CardRow>
          <CardRow>
            <CardLabel>Update Progress:</CardLabel>
            <CardValue>
              <ProgressBar progress={firmwareStatus[device.firmware_status].progress} />
            </CardValue>
          </CardRow>
          <CardRow>
            {firmwareStatus[device.firmware_status].hasOwnProperty('buttonText') && (
              <PermissionProtectedElement requiredPermissions={[permissionData.editotafirmware]}>
                <ButtonWithLoader
                  disabled={
                    !vehicle?.meta.online ||
                    vehicle?.gps.fr_mode_enabled === 1 ||
                    updatesInProgress ||
                    updatePostLoading
                  }
                  loadingStyleProp={'otaUpdateSubmittingWithSpinner'}
                  notLoadingStyleProp={'otaUpdate'}
                  confirmText={firmwareStatus[device.firmware_status].buttonText}
                  isLoading={device.device_id === updatePostLoading}
                  clickHandler={async () => {
                    await handleUpdateFirmware(device);
                  }}
                />
              </PermissionProtectedElement>
            )}
          </CardRow>
        </CardExpandedInfo>
      </CardContainer>
    </CardOuterWrapper>
  );
}
