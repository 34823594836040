import { manageAlertsSummaryTableColumns } from 'legacy/features/alerts/ui/components/AlertsSummary/tables/ManageAlertsSummaryTableColumns';
import WcpTable from 'legacy/shared/controls/WcpTable/v2/WcpTable';

const ManageAlertsSummaryTable = ({ alerts, handleUpdateListingCount }) => {
  const getAlertNameClasses = (alert) => {
    return `vehicleNameAndIcon ${
      alert.alertDetails.alertInfo.isActive && alert.alertDetails.alertInfo.testModeEnabled !== true
        ? ''
        : 'deactivated'
    }`;
  };
  const getAlertNameTooltip = (alert) => {
    return `Alert Name: ${alert.alertDetails.decorated.summaryTable.alertName} ${
      alert.alertDetails.alertInfo.testModeEnabled ? ' (TEST MODE) ' : ''
    }
    ${alert.alertDetails.alertInfo.isActive ? '' : '(Deactivated)'}`;
  };
  let alertsTableData = alerts?.map((alert) => {
    return {
      alertId: alert.alertId,
      alertName: alert.alertDetails.decorated.summaryTable.alertName,
      condition: alert.alertDetails.decorated.summaryTable.conditionColumn,
      testModeEnabled: alert.alertDetails.alertInfo.testModeEnabled,
      isActive: alert.alertDetails.alertInfo.isActive,
      alertNameTooltip: getAlertNameTooltip(alert),
      alertNameClasses: getAlertNameClasses(alert),
      alertValue: alert.alertDetails.decorated.summaryTable.valueColumn,
      targetVehicleOrGroupName: alert.alertDetails.decorated.targetVehicleOrGroupName,
      recipient: alert.alertDetails.decorated.summaryTable.recipientColumn,
      alertMethod: alert.alertDetails.decorated.summaryTable.methodColumn,
      multiLevel: alert.alertNotifications.length > 1,
      // used for action menu, see comment on action menu below
      rawAlert: alert,
    };
  });

  return (
    <WcpTable
      columns={manageAlertsSummaryTableColumns}
      tableSourceData={alertsTableData}
      emptyText="No alerts are currently associated with this organization"
      setListingCount={handleUpdateListingCount}
    />
  );
};

export default ManageAlertsSummaryTable;
