import AddVehiclesToGroupForm from 'legacy/features/vehicleGroups/ui/form/AddVehiclesToGroupForm';
import { useAddVehiclesToGroupMutation } from 'legacy/features/vehicleGroups/hooks/v1/useAddVehiclesToGroupMutation';
import { useFetchVehiclesQuery } from 'legacy/features/vehicles/hooks/useFetchVehiclesQuery';
import IconSvgComponent from 'legacy/shared/controls/WcpIcon/IconSvgComponent';
import GenericFormModal from 'legacy/shared/controls/WcpModal/v1/GenericFormModal';
import LoadingOverlay from 'legacy/shared/controls/WcpSpinner/LoadingOverlay';

export const AddVehiclesToGroupModal = ({
  modalData: { show, groupId, groupVehicles },
  handleCloseModal,
}) => {
  // react-query
  const addVehiclesToGroupMutation = useAddVehiclesToGroupMutation();
  const [fetchVehiclesQuery, vehicles] = useFetchVehiclesQuery({
    autoRun: show,
    apiFlags: {
      addons: false,
      cell: true,
      device: true,
      gps: true,
      meta: true,
      maintenance: false,
      obd: false,
    },
  });

  return (
    show &&
    (fetchVehiclesQuery.isFetching ? (
      <LoadingOverlay />
    ) : (
      <GenericFormModal
        customWidth={675}
        icon={<IconSvgComponent svgFileName="plus" alt="Add Vehicle" />}
        title={'Add Vehicles'}
        initialValues={{
          vehicles: '',
        }}
        submitHandler={async (values, formikActions) => {
          formikActions.setSubmitting(true);
          await addVehiclesToGroupMutation.mutateAsync({
            groupId,
            vehicleIds: values.vehicles.split(','),
          });

          formikActions.setSubmitting(false);
          handleCloseModal();
        }}
        //validator={validateGroup}
        handleCloseModal={handleCloseModal}
        // errorMessage={createGroupMutation.isError ? createGroupMutation.error : null}
        errorMessage={null}
        submitButtonText={'Add'}
      >
        {(formikProps) => (
          <AddVehiclesToGroupForm
            formikProps={formikProps}
            vehicles={vehicles}
            groupVehicles={groupVehicles}
          />
        )}
      </GenericFormModal>
    ))
  );
};

export default AddVehiclesToGroupModal;
