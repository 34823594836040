import { getAlertByIdApi } from 'legacy/features/alerts/services/api/get/getAlertByIdApi';
import useGenericQuery from 'legacy/shared/utilities/hooks/useGenericQuery';

const useFetchAlertByIdQuery = ({ alertId }) => {
  const [queryResult, queryKey] = useGenericQuery({
    queryKeyPrefix: 'getAlertByIdApi',
    apiFn: getAlertByIdApi,
    autoRun: !!alertId,
    params: { alertId },
  });

  return [queryResult.data, queryResult.isFetching, queryKey];
};

export default useFetchAlertByIdQuery;
