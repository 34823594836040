import MapZoomControls from 'legacy/features/livemap/ui/mapControls/MapZoomControls';
import useMapboxCustomControl from 'legacy/shared/utilities/hooks/useMapboxCustomControl';

const useMapZoomControls = ({ mapboxApi, isMapboxLoaded }) => {
  useMapboxCustomControl({
    renderComponent: <MapZoomControls mapboxApi={mapboxApi} />,
    mapboxApi,
    isMapboxLoaded,
    position: 'bottom-right',
  });
};

export default useMapZoomControls;
