import { FormikProvider } from 'formik';

import useCreateAlertForm from 'legacy/features/alerts/services/formik/hooks/useCreateAlertForm';
import useAlertFormsProviderContext from 'legacy/features/alerts/services/providers/AlertFormsProvider/hooks/useAlertFormsProviderContext';
import CancelButton from 'legacy/features/alerts/ui/components/AlertForms/shared/buttons/CancelButton';
import SubmitButton from 'legacy/features/alerts/ui/components/AlertForms/shared/buttons/SubmitButton';
import AlertDetailsContainer from 'legacy/features/alerts/ui/components/AlertForms/shared/containers/AlertDetailsContainer';
import AlertNotificationBuilder from 'legacy/features/alerts/ui/components/AlertNotificationBuilder/AlertNotificationBuilder';
import { WcpForm } from 'legacy/shared/controls/WcpForm/v1/WcpForm';
import IsFetchingWrapper from 'legacy/shared/controls/WcpLoadingWrapper/v2/IsFetchingWrapper';
import { AssignCancelDiv, FieldError } from 'legacy/shared/styles/custom/Form';
import {
  BottomOfFormValidationMessage,
  CreateAlertFormWrapperDiv,
} from 'legacy/shared/styles/custom/ManageAlerts';
import { SectionDivider } from 'legacy/shared/styles/custom/SectionDivider';
import AddAdditionalNotificationButton from 'legacy/features/alerts/ui/components/AlertNotificationBuilder/buttons/AddAdditionalNotificationButton';

const CreateAlertForm = () => {
  const {
    state: { alert },
    queryKeys: { recipientGroupsQueryKey },
  } = useAlertFormsProviderContext();
  let formik = useCreateAlertForm();

  return (
    alert && (
      <FormikProvider value={formik}>
        <CreateAlertFormWrapperDiv>
          <WcpForm
            formik={formik}
            customOnSubmit={(e) => {
              formik.values.alert = alert;
              return formik.handleSubmit(e);
            }}
          >
            <AlertDetailsContainer />
            <SectionDivider mobileOnly />
            <AlertNotificationBuilder />
            <AddAdditionalNotificationButton dataCyId={'addAdditionalNotificationButton'} />
            <SectionDivider mobileOnly />
            <BottomOfFormValidationMessage>
              {(formik.errors.alertRecipientField || formik.errors.alertMethodField) &&
              alert?.alertNotifications[0]?.alertRecipientRows.length > 0 ? (
                <FieldError>
                  Please select all required recipients and associated alert methods
                </FieldError>
              ) : null}
            </BottomOfFormValidationMessage>
            <AssignCancelDiv withTopSpacing>
              <CancelButton />
              <SubmitButton title={'Create'} />
            </AssignCancelDiv>
          </WcpForm>
          <IsFetchingWrapper queryKeys={[recipientGroupsQueryKey]}></IsFetchingWrapper>
        </CreateAlertFormWrapperDiv>
      </FormikProvider>
    )
  );
};

export default CreateAlertForm;
