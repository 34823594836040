import { CreateOrganizationButton } from 'legacy/features/organizations/components/buttons/CreateOrganizationButton';
import OrganizationListingContainer from 'legacy/features/organizations/containers/OrganizationListingContainer';
import OrganizationListingModalProvider from 'legacy/features/organizations/providers/modal/OrganizationListingModalProvider/OrganizationListingModalProvider';
import PageListWrapper, { PageListHead, PageListTitle } from 'legacy/shared/styles/custom/PageList';

const OrganizationListingPage = () => {
  return (
    <OrganizationListingModalProvider>
      <PageListWrapper>
        <PageListHead>
          <PageListTitle>Organizations</PageListTitle>
          <CreateOrganizationButton />
        </PageListHead>
        <OrganizationListingContainer />
      </PageListWrapper>
    </OrganizationListingModalProvider>
  );
};

export default OrganizationListingPage;
