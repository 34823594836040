import React from 'react';

import { SearchBox } from 'legacy/shared/styles/custom/PageList';
import IconSvgComponent from 'legacy/shared/controls/WcpIcon/IconSvgComponent';

const Searchbox = ({ setSearchText, searchText, smallSearch }) => {
  return (
    <SearchBox smallSearch={smallSearch}>
      <input
        type="text"
        className="search"
        placeholder="Search"
        onChange={(e) => setSearchText(e.target.value)}
        value={searchText}
      />
      {searchText ? (
        <button className="Search-box-button" onClick={(e) => setSearchText('')}>
          <IconSvgComponent svgFileName="searbox-close" title="Reset" alt="SearboxClose" />
        </button>
      ) : null}
      <button type="button" className="searchButton">
        <IconSvgComponent
          className="search-icon"
          svgFileName="searchbox"
          title="Search"
          alt="Search"
        />
      </button>
    </SearchBox>
  );
};

export default Searchbox;
