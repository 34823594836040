import { FieldError, FormAsterisk, FormLabelAnimated } from 'legacy/shared/styles/custom/Form';
import { IntervalInputDiv } from 'legacy/shared/styles/custom/Maintenance';
import StyledDayPickerInput from 'legacy/shared/controls/WcpDayPicker/StyledDayPickerInput';
import { getShortDateFromDatePicker } from 'legacy/shared/utilities/misc/time';

const NextDueDateField = ({ formikProps: { values, errors, setFieldValue, touched } }) => {
  return (
    <IntervalInputDiv date>
      <FormLabelAnimated animated>
        Due Date (MM/DD/YYYY)<FormAsterisk>*</FormAsterisk>
      </FormLabelAnimated>
      <StyledDayPickerInput
        name="nextDueDate"
        placeholder={values.nextDueDate || 'Select a Date'}
        value={values.nextDueDate || 'Select a Date'}
        onDayChange={(date) => {
          const formattedDate = getShortDateFromDatePicker(date);
          setFieldValue('nextDueDate', formattedDate);
        }}
      />
      <FieldError maintenanceEvents>{touched.nextDueDate && errors.nextDueDate}</FieldError>
    </IntervalInputDiv>
  );
};

export default NextDueDateField;
