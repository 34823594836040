import MapStyleToggleButton from 'legacy/features/livemap/ui/mapControls/MapStyleToggleButton';
import useMapboxCustomControl from 'legacy/shared/utilities/hooks/useMapboxCustomControl';

const useMapStyleToggleButton = ({ mapboxApi, isMapboxLoaded }) => {
  useMapboxCustomControl({
    renderComponent: (
      <MapStyleToggleButton
        setMapStyle={(mapStyle) => {
          console.log(isMapboxLoaded);
          mapboxApi.setStyle(mapStyle);
        }}
      />
    ),
    mapboxApi,
    isMapboxLoaded,
    position: 'bottom-right',
    triggerRerender: true,
  });
};

export default useMapStyleToggleButton;
