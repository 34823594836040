import {
  requiredValidator,
  regexValidator,
  characterLimitValidator,
} from 'legacy/shared/utilities/helpers/validation/validators';

import { alphanumericExtendedRegex } from 'legacy/shared/utilities/helpers/validation/validationRegularExpressions';
import { alphanumericExtendedRegexError } from 'legacy/shared/utilities/helpers/validation/validationErrorMessages';

export const validateRecipientGroupFromAlert = (values) => {
  const recipientGroupNameErrors = recipientGroupValidators.recipientGroupNameField(
    values.recipientGroupNameField,
    36,
  );
  return {
    ...(recipientGroupNameErrors ? { recipientGroupNameField: recipientGroupNameErrors } : {}),
  };
};

const recipientGroupValidators = {
  recipientGroupNameField: requiredValidator(
    characterLimitValidator(
      regexValidator(alphanumericExtendedRegex, alphanumericExtendedRegexError),
    ),
  ),
};
