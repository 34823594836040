import ScheduleMaintenanceVehicleNameColumn from 'legacy/features/maintenance/components/MaintenanceScheduler/table/ScheduleMaintenanceVehicleNameColumn';
import { getLastIn, getObdDisplayValue } from 'legacy/features/maintenance/utilities/formatting';
import { PAGINATION_OPTIONS } from 'legacy/shared/constants/pagination';
import { SmallTableWrapper } from 'legacy/shared/styles/custom/Table';
import WcpTable from 'legacy/shared/controls/WcpTable/v2/WcpTable';

const ScheduleMaintenanceTable = ({
  vehicleRecords,
  selectableVehicles,
  selectedVehicles,
  setSelectedVehicles,
  handleUpdateListingCount,
}) => {
  const vehicleTableData = vehicleRecords.map((vehicle) => {
    return {
      vehicleId: vehicle.vehicleId,
      vehicleName: vehicle.meta.label,
      make: vehicle.meta.make,
      odometer: vehicle.obd.odometer,
      odometerDisplayValue: getObdDisplayValue(vehicle.obd.odometer),
      engineHours: vehicle.obd.engine_hours,
      engineHoursDisplayValue: getObdDisplayValue(vehicle.obd.engine_hours),
      lastIn: vehicle.obd.last_in,
      lastInDisplayValue: getLastIn(vehicle.obd.last_in),
      oilLifeDisplayValue: getObdDisplayValue(vehicle.obd.oil_life, true),
    };
  });

  const columns = [
    {
      title: 'Vehicle Name',
      displayProperty: 'vehicleName',
      className: 'name',
      render({ rowData }) {
        return (
          <ScheduleMaintenanceVehicleNameColumn
            vehicle={rowData}
            selectableVehicles={selectableVehicles}
            selectedVehicles={selectedVehicles}
            setSelectedVehicles={setSelectedVehicles}
          />
        );
      },
    },
    {
      title: 'Make',
      displayProperty: 'make',
      width: '25%',
    },

    {
      title: 'Odometer',
      displayProperty: 'odometerDisplayValue',
    },
    {
      title: 'Engine Hours',
      displayProperty: 'engineHoursDisplayValue',
    },
    {
      title: 'Oil Life',
      displayProperty: 'oilLifeDisplayValue',
    },
    {
      title: 'Last In',
      displayProperty: 'lastInDisplayValue',
      width: '20%',
    },
  ];

  return (
    <SmallTableWrapper>
      <WcpTable
        defaultPageSize={PAGINATION_OPTIONS[0].value}
        columns={columns}
        tableSourceData={vehicleTableData || []}
        emptyText="No Vehicles are present in this organization"
        setListingCount={handleUpdateListingCount}
      />
    </SmallTableWrapper>
  );
};

export default ScheduleMaintenanceTable;
