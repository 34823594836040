import WcpCheckboxField from 'legacy/shared/controls/WcpForm/v2/components/fields/WcpCheckboxField';
import { NonTextFieldContainer } from 'legacy/shared/styles/custom/Form';
import React from 'react';

const ResetUserCheckbox = () => {
  return (
    <NonTextFieldContainer>
      <WcpCheckboxField dataKey={'resetUser'} label="Reset password and two-factor option" />
    </NonTextFieldContainer>
  );
};

export default ResetUserCheckbox;
