import { HeaderLinkButton } from 'legacy/shared/styles/custom/LinkButton';
import { AlertsActionButtonsDiv } from 'legacy/shared/styles/custom/ManageAlerts';
import PageListWrapper, {
  PageListActionButtonContainer,
  PageListHead,
  PageListTitle,
} from 'legacy/shared/styles/custom/PageList';
import { Link } from 'react-router-dom';
import { ActionButtonText, ActionButtonWithIcon } from 'legacy/shared/styles/custom/Button';
import IconSvgComponent from 'legacy/shared/controls/WcpIcon/IconSvgComponent';
import AlertsSummary from 'legacy/features/alerts/ui/components/AlertsSummary/AlertsSummary';
import AlertsSummaryProvider from 'legacy/features/alerts/services/providers/AlertsSummaryProvider/AlertsSummaryProvider';

const ManageAlertsPage = () => {
  return (
    <AlertsSummaryProvider>
      <PageListWrapper>
        <PageListHead stackable>
          <PageListTitle>Manage Alerts</PageListTitle>
          <PageListActionButtonContainer>
            <AlertsActionButtonsDiv>
              <HeaderLinkButton to={`/managerecipientgroups`}>
                Manage Recipient Groups
              </HeaderLinkButton>
              <Link
                to={{
                  pathname: `/createalert`,
                }}
                style={{ textDecoration: 'none' }}
              >
                <ActionButtonWithIcon create>
                  <IconSvgComponent svgFileName="plus-simple" alt="Create" />

                  <ActionButtonText>Create New Alert </ActionButtonText>
                </ActionButtonWithIcon>
              </Link>
            </AlertsActionButtonsDiv>
          </PageListActionButtonContainer>
        </PageListHead>
        <AlertsSummary />
      </PageListWrapper>
    </AlertsSummaryProvider>
  );
};

export default ManageAlertsPage;
