import useSetGttAccessKeyMutation from 'legacy/features/organizations/api/mutations/useSetGttAccessKeyMutation';
import {
  RECEIVE_GTT_ACCESS_KEY_MODAL_KEY,
  REGENERATE_GTT_ACCESS_KEY_MODAL_KEY,
} from 'legacy/features/organizations/providers/modal/constants/organizationFeatureModalKeys';
import useWcpFormContext from 'legacy/shared/controls/WcpForm/v2/hooks/useWcpFormContext';
import { useWcpModalContext } from 'legacy/shared/controls/WcpModal/v2/WcpModalProvider';
import { GenericButton } from 'legacy/shared/styles/v2/Button';

const GenerateGttAccessKeyButton = () => {
  const setGttAccessKeyMutation = useSetGttAccessKeyMutation();

  const {
    setFormValues,
    formValues: { organizationId, gttSetup },
  } = useWcpFormContext();

  // confirmation modal to regenerate the key and modal for receiving the key
  const [regenerateGttAccessKeyModalContext, receiveGttAccessKeyModalContext] = useWcpModalContext(
    REGENERATE_GTT_ACCESS_KEY_MODAL_KEY,
    RECEIVE_GTT_ACCESS_KEY_MODAL_KEY,
  );

  const handleClick = async () => {
    // if the GTT setup is already complete, open the modal to confirm key regeneration
    if (gttSetup) {
      regenerateGttAccessKeyModalContext.modalActions.openModal({
        organizationId,
        handleSubmitCompleted: async ({ gttAccessKey }) => {
          setFormValues({ gttAccessKey });
        },
      });
    }
    // otherwise, generate a new access key
    else {
      const gttAccessKeyMutation = await setGttAccessKeyMutation.mutateAsync({ organizationId });
      const gttAccessKey = gttAccessKeyMutation.data.message.gtt_access_id;

      receiveGttAccessKeyModalContext.modalActions.openModal({
        gttAccessKey,
        handleConfirmed: receiveGttAccessKeyModalContext.modalActions.closeModal,
      });

      // update the form values with the new access key
      setFormValues({ gttAccessKey });
    }
  };

  return (
    <GenericButton onClick={async () => await handleClick()}>Generate Access Key</GenericButton>
  );
};

export default GenerateGttAccessKeyButton;
