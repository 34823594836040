import { useNavigate } from 'react-router-dom';

import { postAlertsApi } from 'legacy/features/alerts/services/api/post/postAlertsApi';
import useDispatchErrorNotification from 'legacy/shared/utilities/hooks/useDispatchErrorNotification';
import useDispatchSuccessNotification from 'legacy/shared/utilities/hooks/useDispatchSuccessNotification';
import useGenericMutation from 'legacy/shared/utilities/hooks/useGenericMutation';

const useCreateAlertMutation = () => {
  const navigate = useNavigate();
  const [successToast] = useDispatchSuccessNotification();
  const [errorToast] = useDispatchErrorNotification();

  const mutation = useGenericMutation({
    apiFn: postAlertsApi,
    onSuccessCallback: () => {
      successToast({ message: 'Successfully Created Alert' });
      navigate('/managealerts');
    },
    onErrorCallback: (err) => {
      errorToast({ message: 'Error Creating Alert' });
    },
  });

  return mutation;
};

export default useCreateAlertMutation;
