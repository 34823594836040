import {
  CardContainer,
  CardIconWrapper,
  CardLabel,
  CardName,
  CardOuterWrapper,
  CardRow,
  CardValue,
} from 'legacy/shared/styles/custom/MobileCard';
import { ActionMenuBtnWrapper } from 'legacy/shared/styles/custom/MobileCard';

const UniversalApiSummaryCard = ({ universalApiKey, actionMenuComponent }) => {
  console.log(universalApiKey);
  return (
    <CardOuterWrapper>
      <CardContainer univseralApi>
        <ActionMenuBtnWrapper>{actionMenuComponent(universalApiKey)}</ActionMenuBtnWrapper>
        <CardIconWrapper>
          <CardRow header>
            <CardName potentialLongValue title={universalApiKey.keyName}>
              {universalApiKey.keyName}
            </CardName>
          </CardRow>
        </CardIconWrapper>
        <CardRow potentialLongValue>
          <CardLabel>Key Type:</CardLabel>
          <CardValue title={universalApiKey.keyType}>{universalApiKey.keyType}</CardValue>
        </CardRow>
        <CardRow potentialLongValue>
          <CardLabel>Permissions:</CardLabel>
          <CardValue title={universalApiKey.permissions}>{universalApiKey.permissions}</CardValue>
        </CardRow>
        <CardRow potentialLongValue>
          <CardLabel>Created By:</CardLabel>
          <CardValue title={universalApiKey.createdBy}>{universalApiKey.createdBy}</CardValue>
        </CardRow>
        <CardRow potentialLongValue>
          <CardLabel>Created On:</CardLabel>
          <CardValue title={universalApiKey.formattedTime}>
            {universalApiKey.formattedTime}
          </CardValue>
        </CardRow>
      </CardContainer>
    </CardOuterWrapper>
  );
};

export default UniversalApiSummaryCard;
