import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';
import { apiCall, getAuthToken } from 'legacy/core/api/API';
import { userMapping } from 'legacy/features/users/utilities/userMapping';
import { mapToBackend } from 'legacy/shared/utilities/helpers/mapping/mapper';
import { logWcpError } from 'legacy/core/error/utilities/ErrorLogger';
import { addNotification } from 'legacy/core/redux/ui/actions';
import { createNotification, LEVELS } from 'legacy/shared/utilities/misc/notification';

const useUpdateUserMutation = () => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  const mutation = useMutation({
    mutationFn: async ({ user }) => {
      let mappedUser = mapToBackend(user, userMapping);
      delete mappedUser.permissions;

      var userRequestPayload = new FormData();

      for (var prop in mappedUser) {
        userRequestPayload.append(prop, mappedUser[prop]);
      }

      console.log('userRequestPayload', userRequestPayload);

      return await apiCall({
        path: `/user/${user.username}`,
        method: 'put',
        headers: {
          Authorization: getAuthToken(),
        },
        queryStringParameters: {
          // TODO remove when using provider
          targetOrganizationId: userRequestPayload.organization_id,
        },
        body: userRequestPayload,
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['fetchUser']);
    },

    onError: (err) => {
      logWcpError(err);
      dispatch(
        addNotification({
          notification: createNotification(LEVELS.ERROR, err),
        }),
      );
    },
  });

  return mutation;
};

export default useUpdateUserMutation;
