import useAlertsSummaryProviderContext from 'legacy/features/alerts/services/providers/AlertsSummaryProvider/hooks/useAlertsSummaryProviderContext';
import AlertsExportButton from 'legacy/features/alerts/ui/components/AlertsSummary/buttons/AlertsExportButton';
import ManageAlertsSummaryCardContainer from 'legacy/features/alerts/ui/components/AlertsSummary/mobile/ManageAlertsSummaryCardContainer';
import ManageAlertsSummaryTable from 'legacy/features/alerts/ui/components/AlertsSummary/tables/ManageAlertsSummaryTable';
import ListingCount from 'legacy/shared/controls/ListingCount/ListingCount';
import MediaWrapper from 'legacy/shared/controls/WcpMediaWrapper/v1/MediaWrapper';
import { ExportDiv, ExportWrapper } from 'legacy/shared/styles/custom/PageList';

const AlertsSummary = () => {
  const {
    state: { alerts, listingCount },
    actions: { updateAlertsListingCountAction },
  } = useAlertsSummaryProviderContext();

  // derived state
  const alertsCount = alerts?.length;
  return (
    <>
      {alertsCount > 0 && (
        <ExportWrapper>
          <ExportDiv>
            <AlertsExportButton alerts={alerts} />
          </ExportDiv>
        </ExportWrapper>
      )}
      {alerts && (
        <>
          <ListingCount listingCount={listingCount} totalCount={alertsCount} dataLabel={'Alert'} />

          <MediaWrapper
            mobileComponent={<ManageAlertsSummaryCardContainer alerts={alerts} />}
            nonMobileComponent={
              <ManageAlertsSummaryTable
                alerts={alerts}
                handleUpdateListingCount={(listingCount) =>
                  updateAlertsListingCountAction({ listingCount })
                }
              />
            }
          />
        </>
      )}
    </>
  );
};

export default AlertsSummary;
