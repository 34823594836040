import { VehicleCountDiv } from 'legacy/shared/styles/custom/Maintenance';

const ScheduledMaintenanceSelectedVehicleCount = ({ selectedVehicles }) => {
  return (
    <VehicleCountDiv
      title={
        selectedVehicles?.length > 0
          ? selectedVehicles?.map((v) => v.meta?.label).join(', ')
          : 'No Vehicles Selected for Maintenance'
      }
    >{`${
      selectedVehicles?.length > 0 ? selectedVehicles?.length : 'No'
    } Vehicles Selected for Maintenance`}</VehicleCountDiv>
  );
};

export default ScheduledMaintenanceSelectedVehicleCount;
