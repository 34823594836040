import DeleteKeyMenuItem from 'legacy/features/integrations/ui/menus/DeleteKeyMenuItem';
import { SimpleDropdownItem } from 'legacy/shared/styles/custom/DropdownMenu';
import ActionMenu from 'legacy/shared/controls/WcpTable/v1/ActionMenu';

const UniversalApiActionMenu = () => {
  return (
    <>
      <ActionMenu>
        <SimpleDropdownItem>
          <DeleteKeyMenuItem onMenuItemClick={null} id="deleteKeyMenuItem" />
        </SimpleDropdownItem>
      </ActionMenu>
    </>
  );
};

export default UniversalApiActionMenu;
