import React from 'react';
import {
  CardLabel,
  CardValue,
  CardRow,
  CardName,
  CardContainer,
  CardOuterWrapper,
} from 'legacy/shared/styles/custom/MobileCard';

export default function GeofenceLogCard({ log }) {
  return (
    <CardOuterWrapper>
      <CardContainer geofenceLogs>
        <CardRow>
          <CardName>{log.vehicle_label}</CardName>
        </CardRow>
        <CardRow potentialLongValue>
          <CardLabel>Timestamp:</CardLabel>
          <CardValue>{log.formattedTime}</CardValue>
        </CardRow>

        <CardRow potentialLongValue>
          <CardLabel>Action:</CardLabel>
          <CardValue>{log.action} Geofence</CardValue>
        </CardRow>

        <CardRow>
          <CardLabel>Geofence Status:</CardLabel>
          <CardValue>{log.while_active ? 'Active' : 'Not Active'}</CardValue>
        </CardRow>
      </CardContainer>
    </CardOuterWrapper>
  );
}
