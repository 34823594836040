import useSetGttAccessKeyMutation from 'legacy/features/organizations/api/mutations/useSetGttAccessKeyMutation';
import { RECEIVE_GTT_ACCESS_KEY_MODAL_KEY } from 'legacy/features/organizations/providers/modal/constants/organizationFeatureModalKeys';
import GenericConfirmationModalControls from 'legacy/shared/controls/WcpModal/v2/controls/GenericConfirmationModalControls';
import { useWcpModalContext } from 'legacy/shared/controls/WcpModal/v2/WcpModalProvider';

const RegenerateGttAccessKeyModalControls = ({
  modalState: { organizationId, handleSubmitCompleted },
  modalActions,
}) => {
  const setGttAccessKeyMutation = useSetGttAccessKeyMutation();
  const receiveGttAccessKeyModalContext = useWcpModalContext(RECEIVE_GTT_ACCESS_KEY_MODAL_KEY);

  const generateNewAccessKey = async () => {
    // get the new access key
    const gttAccessKeyMutation = await setGttAccessKeyMutation.mutateAsync({ organizationId });
    const gttAccessKey = gttAccessKeyMutation.data.message.gtt_access_id;

    // open the receive gtt access key confirmation modal with the new access key and a callback to handle the confirmation from the second modal
    receiveGttAccessKeyModalContext.modalActions.openModal({
      gttAccessKey,
      handleConfirmed: async () => {
        await handleSubmitCompleted({ gttAccessKey });

        console.log('RegenerateGttAccessKeyModalControls: handleConfirmed - closing modals');
        // close this modal
        modalActions.closeModal();
      },
    });
  };

  return (
    <GenericConfirmationModalControls
      confirmButtonText="Confirm and Generate"
      submitOnConfirm={true}
      handleConfirm={generateNewAccessKey}
      handleCancel={modalActions.closeModal}
    />
  );
};
export default RegenerateGttAccessKeyModalControls;
