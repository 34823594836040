import { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import AnalyticsLogsQueryFilter from 'legacy/features/analyticsLogs/components/AnalyticsLogsQueryFilter';
import AnalyticsLogsResultsSummary from 'legacy/features/analyticsLogs/components/AnalyticsLogsResultsSummary';
import AnalyticsLogsResultsTable from 'legacy/features/analyticsLogs/components/AnalyticsLogsResultsTable';
import { useFetchGroupsQuery } from 'legacy/features/vehicleGroups/hooks/v1/useFetchGroupsQuery';
import { useFetchVehiclesQuery } from 'legacy/features/vehicles/hooks/useFetchVehiclesQuery';
import PageListWrapper, { PageListHead, PageListTitle } from 'legacy/shared/styles/custom/PageList';
import LoadingOverlay from 'legacy/shared/controls/WcpSpinner/LoadingOverlay';
import { useParams } from 'react-router';

const AnalyticsLogsPage = () => {
  const { vehicleId } = useParams();
  const [fetchVehiclesQuery, vehicles] = useFetchVehiclesQuery({
    key: 'AnalyticsLogsPage',
    apiFlags: {
      addons: false,
      cell: true,
      device: false,
      gps: false,
      meta: true,
      maintenance: false,
      obd: false,
    },
  }); // TODO - optimize for just META
  const [fetchGroupsQuery, groups] = useFetchGroupsQuery();
  let selectedVehicleFromUrl = vehicles?.find((v) => v.vehicle_id === vehicleId);

  const [logsQueryResults, setLogsQueryResults] = useState(null);
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [selectedVehicle, setSelectedVehicle] = useState(selectedVehicleFromUrl);

  useEffect(() => {
    setSelectedVehicle(selectedVehicleFromUrl);
  }, [selectedVehicleFromUrl]);

  return fetchVehiclesQuery.isFetching || fetchGroupsQuery.isFetching ? (
    <LoadingOverlay />
  ) : (
    <>
      <PageListWrapper>
        <PageListHead>
          <PageListTitle>Analytics Logs</PageListTitle>
        </PageListHead>
        <AnalyticsLogsQueryFilter
          groups={groups}
          vehicles={vehicles}
          setLogsQueryResults={setLogsQueryResults}
          selectedGroup={selectedGroup}
          selectedVehicle={selectedVehicle}
          setSelectedVehicle={setSelectedVehicle}
          setSelectedGroup={setSelectedGroup}
        />
        {!logsQueryResults ? (
          <div>{/* no results message here*/}</div>
        ) : (
          <>
            <AnalyticsLogsResultsSummary
              logsQueryResults={logsQueryResults}
              selectedVehicle={selectedVehicle}
            />
            <AnalyticsLogsResultsTable
              selectedVehicle={selectedVehicle}
              logsQueryResults={logsQueryResults}
            ></AnalyticsLogsResultsTable>
          </>
        )}
      </PageListWrapper>
    </>
  );
};

export default AnalyticsLogsPage;
