import { determineStatusDisplay } from 'legacy/features/maintenance/utilities/formatting';
import { displayVehicleIcon } from 'legacy/features/vehicles/helpers/utilities';
import {
  ActionMenuBtnWrapper,
  CardCollapsedInfo,
  CardContainer,
  CardIconWrapper,
  CardLabel,
  CardName,
  CardOuterWrapper,
  CardRow,
  CardType,
  CardValue,
  VehicleIcon,
} from 'legacy/shared/styles/custom/MobileCard';

{
  /* <VehicleIcon svgFileName={displayVehicleIcon(vehicle.meta.vehicle_type)} alt="Vehicle" /> */
}
const MaintenanceSummaryCard = ({ maintenanceEvent, actionMenuComponent }) => {
  const dueInDisplayString = `${
    maintenanceEvent.dueInValue
      ? maintenanceEvent.dueInValueDisplay + maintenanceEvent.dueInUnit
      : 'Now'
  }`;
  const percentageSuffix = maintenanceEvent.dueInUnit === '%' ? ' oil life remaning' : '';
  return (
    <CardOuterWrapper>
      <CardContainer maintenanceEvent>
        <ActionMenuBtnWrapper>{actionMenuComponent(maintenanceEvent)}</ActionMenuBtnWrapper>
        <CardIconWrapper>
          <VehicleIcon
            svgFileName={displayVehicleIcon(maintenanceEvent.vehicleType)}
            alt="Vehicle"
          />
          <CardCollapsedInfo>
            <CardRow header>
              <CardName potentialLongValue title={maintenanceEvent.vehicleLabel}>
                {maintenanceEvent.vehicleLabel}
              </CardName>
            </CardRow>
            <CardRow header>
              <CardType>{maintenanceEvent.vehicleMake}</CardType>
            </CardRow>
            <CardRow maintenanceStatus>{determineStatusDisplay(maintenanceEvent)}</CardRow>
          </CardCollapsedInfo>
        </CardIconWrapper>

        <CardRow title={maintenanceEvent.odometer} potentialLongValue>
          <CardLabel>Odometer:</CardLabel>
          <CardValue>{maintenanceEvent.odometerDisplay}</CardValue>
        </CardRow>
        <CardRow>
          <CardLabel>Engine Hours:</CardLabel>
          <CardValue>{maintenanceEvent.engineHoursDisplay}</CardValue>
        </CardRow>
        <CardRow>
          <CardLabel>Due in:</CardLabel>
          <CardValue>{dueInDisplayString + percentageSuffix}</CardValue>
        </CardRow>
        <CardRow potentialLongValue>
          <CardLabel>Type:</CardLabel>
          <CardValue>{maintenanceEvent.maintenanceType.label}</CardValue>
        </CardRow>
        <CardRow potentialLongValue>
          <CardLabel>Last in:</CardLabel>
          <CardValue>{maintenanceEvent.lastIn}</CardValue>
        </CardRow>
      </CardContainer>
    </CardOuterWrapper>
  );
};

export default MaintenanceSummaryCard;
