/**
 * @ External Dependecies
 */
import styled from 'styled-components';
import colors from 'legacy/shared/constants/colors';
import device from 'legacy/shared/constants/sizing';
import IconSvgComponent from 'legacy/shared/controls/WcpIcon/IconSvgComponent';
import theme from 'legacy/shared/styles/global/theme';
import alertIcon from 'assets/svg/maintenance-alert.svg';
import highAlertIcon from 'assets/svg/maintenance-alert-high-alert.svg';

export const AlertIcon = styled.div`
  margin-top: 10px;
  margin-left: 5px;
  width: 38px;
  height: 33px;
  border: none;
  background-image: url(${alertIcon});
  background-repeat: no-repeat;
  background-size: cover;

  @media ${device.medium} {
    width: 48px;
    height: 42px;
  }

  ${(props) =>
    props.highAlert &&
    `

    background-image: url(${highAlertIcon});

  `}
`;

export const NeedAttentionText = styled.div`
  text-transform: uppercase;
  color: ${colors.midnight};
  font-size: 16px;
  font-weight: 800;
  margin-top: 7px;
  @media ${device.medium} {
    font-size: 20px;
    margin-top: 7px;
  }

  ${(props) =>
    props.highAlert &&
    `
    color: ${colors.cherry};

  `}
`;

export const AlertCount = styled.span`
  font-weight: 800;
`;

export const AlertTextWrapper = styled.div`
  color: ${colors.midnight};
  font-weight: 500;
  font-size: 16px;
  display: flex;

  @media ${device.medium} {
    font-size: 20px;
  }

  ${(props) =>
    props.highAlert &&
    `
    color: ${colors.cherry};

  `}
`;

export const AlertItem = styled.div`
  margin-right: 10px;
  ${(props) =>
    props.highAlert &&
    `
    color: ${colors.cherry};

  `}
`;
export const AlertText = styled.div`
  display: grid;
  grid-template-areas:
    'Col Col'
    'Col Col ';
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;

  @media ${device.medium} {
    display: flex;
  }
`;
