import { maintenanceIntervalConstants } from 'legacy/features/maintenance/data/maintenanceIntervalConstants';
import { stripTrailingPercentage } from 'legacy/features/maintenance/utilities/formatting';
import { IntervalInputDiv } from 'legacy/shared/styles/custom/Maintenance';
import AnimatedField from 'legacy/shared/controls/AnimatedField/v1/AnimatedField';

const NextIntervalNumberField = ({
  formikProps: { values, touched, handleBlur, errors, handleChange },
}) => {
  const oilLifeInterval = values.nextIntervalType?.value === maintenanceIntervalConstants.OIL_LIFE;
  const reminderOilLifeWithPercentage = values.nextIntervalValue
    ? stripTrailingPercentage(values.nextIntervalValue) + '%'
    : values.nextIntervalValue;
  return (
    <IntervalInputDiv>
      <AnimatedField
        name="nextIntervalValue"
        placeholder="Interval"
        value={oilLifeInterval ? reminderOilLifeWithPercentage : values.nextIntervalValue}
        handleChange={handleChange}
        touched={touched.nextIntervalValue}
        handleBlur={handleBlur}
        forceAnimated
        validationError={errors.nextIntervalValue}
        required
      />
    </IntervalInputDiv>
  );
};

export default NextIntervalNumberField;
