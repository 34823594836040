import ReceiveGttAccessKeyModalContent from 'legacy/features/organizations/components/modals/ReceiveGttAccessKeyModal/ReceiveGttAccessKeyModalContent';
import ReceiveGttAccessKeyModalControls from 'legacy/features/organizations/components/modals/ReceiveGttAccessKeyModal/ReceiveGttAccessKeyModalControls';
import RegenerateGttAccessKeyModalContent from 'legacy/features/organizations/components/modals/RegenerateGttAccessKeyModal/RegenerateGttAccessKeyModalContent';
import RegenerateGttAccessKeyModalControls from 'legacy/features/organizations/components/modals/RegenerateGttAccessKeyModal/RegenerateGttAccessKeyModalControls';

import {
  RECEIVE_GTT_ACCESS_KEY_MODAL_KEY,
  REGENERATE_GTT_ACCESS_KEY_MODAL_KEY,
  ENABLE_DISABLE_PARTNERSHIP_MODAL_KEY,
} from 'legacy/features/integrations/providers/modal/constants/partnershipsModalKeys';
import { EnableDisablePartnershipModalContent } from 'legacy/features/integrations/ui/components/modals/ActivateDeactivatePartnershipModal/EnableDisablePartnershipModalContent';
import EnableDisablePartnershipModalControls from 'legacy/features/integrations/ui/components/modals/ActivateDeactivatePartnershipModal/EnableDisablePartnershipModalControls';
import {
  EnableDisableModalTitle,
  getEnableDisableModalTitle,
} from 'legacy/features/integrations/ui/components/modals/ActivateDeactivatePartnershipModal/getEnableDisableModalTitle';

export const partnershipsModalContracts = [
  // GTT modals
  {
    modalKey: RECEIVE_GTT_ACCESS_KEY_MODAL_KEY,
    modalTitle: 'Miovision Access Key',
    modalContentComponent: ReceiveGttAccessKeyModalContent,
    modalControlsComponent: ReceiveGttAccessKeyModalControls,
    modalStyleOptions: {
      width: '520px',
    },
  },
  {
    modalKey: REGENERATE_GTT_ACCESS_KEY_MODAL_KEY,
    modalTitle: 'Generate New Key',
    modalContentComponent: RegenerateGttAccessKeyModalContent,
    modalControlsComponent: RegenerateGttAccessKeyModalControls,
    modalStyleOptions: {
      width: '520px',
    },
  },
  // ENABLE/DISABLE PARTNERSHIP
  {
    modalKey: ENABLE_DISABLE_PARTNERSHIP_MODAL_KEY,
    modalTitle: getEnableDisableModalTitle,
    modalContentComponent: EnableDisablePartnershipModalContent,
    modalControlsComponent: EnableDisablePartnershipModalControls,
    modalStyleOptions: {
      width: '520px',
    },
  },
];
