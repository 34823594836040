import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

import { setSubscriptionExpiryAlertBar } from 'legacy/core/redux/ui/actions';
import { AlertBarWrapper, AlertMain, ButtonClose } from 'legacy/shared/styles/custom/AlertBar';
import IconSvgComponent from 'legacy/shared/controls/WcpIcon/IconSvgComponent';

const AlertBarNotifier = ({ alertBarData }) => {
  const dispatch = useDispatch();

  return (
    <AlertBarWrapper>
      <div style={{ position: 'relative', paddingTop: 5, paddingBottom: 5 }}>
        <AlertMain>
          {alertBarData.message}
          {alertBarData.link && (
            <Link
              onClick={() => dispatch(setSubscriptionExpiryAlertBar({ dismissed: true }))}
              to={alertBarData.link.to}
            >
              {alertBarData.link.text}
            </Link>
          )}
        </AlertMain>
        <ButtonClose onClick={() => dispatch(setSubscriptionExpiryAlertBar({ dismissed: true }))}>
          <span>Dismiss</span>
          <IconSvgComponent svgFileName="close" title="Close" alt="Close" />
        </ButtonClose>
      </div>
    </AlertBarWrapper>
  );
};
export default AlertBarNotifier;
