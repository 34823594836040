import ButtonWithLoader from 'legacy/shared/controls/ButtonWithLoader/ButtonWithLoader';

const WcpFormSubmitButton = ({
  handleClick,
  isSubmitting,
  buttonText = 'Submit',
  disabled = false,
  dataCyId,
}) => (
  <ButtonWithLoader
    dataCyId={dataCyId}
    isLoading={isSubmitting}
    confirmText={buttonText}
    clickHandler={handleClick}
    disabled={disabled}
    notLoadingStyleProp={'mediumAlt'}
    loadingStyleProp={'submittingWithSpinnerModal'}
    withRightSpacer={true}
  />
);
export default WcpFormSubmitButton;
