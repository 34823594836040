import GenericConfirmationModalControls from 'legacy/shared/controls/WcpModal/v2/controls/GenericConfirmationModalControls';

const ReceiveGttAccessKeyModalControls = ({ modalState, modalActions }) => {
  const handleConfirmation = async () => {
    await modalState.handleConfirmed();
  };

  return (
    <GenericConfirmationModalControls
      disableCancel={true}
      confirmButtonText="Got it"
      handleConfirm={handleConfirmation}
    />
  );
};

export default ReceiveGttAccessKeyModalControls;
