import DuplicateGroupModal from 'legacy/features/vehicleGroups/ui/modals/DuplicateGroupModal';
import React from 'react';
import { useState } from 'react';
import { SimpleDropdownBtn, SimpleDropdownItem } from 'legacy/shared/styles/custom/DropdownMenu';

const DuplicateGroupMenuItem = ({ onMenuItemClick }) => {
  return (
    <>
      <SimpleDropdownItem>
        <SimpleDropdownBtn onClick={onMenuItemClick}>Duplicate</SimpleDropdownBtn>
      </SimpleDropdownItem>
    </>
  );
};

export default DuplicateGroupMenuItem;
