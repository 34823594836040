import { getRecipientGroupsApi } from 'legacy/features/recipientGroups/api/get/getRecipientGroupsApi';
import useGenericQuery from 'legacy/shared/utilities/hooks/useGenericQuery';
import { logWcpError } from 'legacy/core/error/utilities/ErrorLogger';

const useFetchRecipientGroupsQuery = () => {
  const [queryResult, queryKey] = useGenericQuery({
    queryKeyPrefix: 'getRecipientGroupsApi',
    apiFn: getRecipientGroupsApi,
    onErrorCallback: (err) => {
      logWcpError(err);
    },
  });

  return [queryResult.data, queryKey];
};

export default useFetchRecipientGroupsQuery;
