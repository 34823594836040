import { useSelector } from 'react-redux';

import RemoveUserMenuItem from 'legacy/features/organizations/components/menus/RemoveUserMenuItem';
import {
  FIELD_SOLUTION_ENGINEER,
  ORGANIZATION_ADMIN,
  SYSTEM_ADMIN,
  userRoleOptions as userRoles,
} from 'legacy/shared/constants/users';
import ViewActivityLogsMenuItem from 'legacy/features/organizations/components/menus/ViewActivityLogsMenuItem';
import ActionMenu from 'legacy/shared/controls/WcpTable/v1/ActionMenu';

const FseUserTableActionMenu = ({ user, organization }) => {
  const userRole = useSelector((state) => state.user.user.userRole);

  return (
    <ActionMenu>
      {/* Only System Admin can remove */}
      {userRole === userRoles[SYSTEM_ADMIN].value && (
        <RemoveUserMenuItem user={user} organization={organization} />
      )}
      <ViewActivityLogsMenuItem username={user.username} />
    </ActionMenu>
  );
};

export default FseUserTableActionMenu;
