import { maintenanceIntervalConstants } from 'legacy/features/maintenance/data/maintenanceIntervalConstants';
import { getDaysSince, getNow, getShortDateInIsoFormat } from 'legacy/shared/utilities/misc/time';
import {
  dateRegex,
  passwordRegex,
  deviceIdRegex,
  vinRegex,
  positiveIntegerRegex,
  alphanumericExtendedRegex,
  asciiRegex,
  numericRegex,
  wimAccountIdRegex,
} from 'legacy/shared/utilities/helpers/validation/validationRegularExpressions';

export const dateRegexError = (dateFieldLabel) =>
  `Please enter a ${dateFieldLabel} in the format MM/DD/YYYY`;
const passwordRegexError =
  'Enter a password with at least 8 characters, uppercase, lowercase, and numeric characters';
const passwordMatchError = 'Please enter matching passwords';
export const deviceIdRegexError = 'Please enter a valid 32 character VSG ID';
export const vinRegexError =
  'Please enter a valid VIN (note that O,I, and Q are not valid characters)';
export const alphanumericExtendedRegexError = 'Please enter only alphanumeric characters';
export const asciiRegexError = 'Please enter only ASCII characters';
export const numericRegexError = 'Please enter numbers only';
export const wimAccountIdRegexError = 'Please enter a valid six digit account ID';

export const positiveIntegerRegexError = 'Please enter a positive whole number';
import {
  stripCommas,
  stripTrailingPercentage,
} from 'legacy/features/maintenance/utilities/formatting';
import { maintenanceStatusConstants } from 'legacy/features/maintenance/data/maintenanceStatusConstants';

export const positiveIntegerOneHundredOrLess = (nextValidator) => (value) => {
  const valueAsNumber = Number(stripTrailingPercentage(value));
  if (!Number.isInteger(valueAsNumber) || valueAsNumber > 99 || valueAsNumber < 1) {
    return 'Please enter a positive whole number less than 100';
  }
  if (typeof nextValidator === 'function') {
    return nextValidator(value);
  }
};

const analyticsDateValidator =
  (nextValidator) =>
  (value, ...rest) => {
    const difference = getDaysSince({
      fromDateTime: getShortDateInIsoFormat(value),
      toDateTime: getNow(),
    });
    if (difference < 0) {
      return 'Please enter a date today or earlier';
    }
    if (difference > 1096) {
      return 'Please enter a date less than 3 years ago';
    }
    if (typeof nextValidator === 'function') {
      return nextValidator(...[value, ...rest]);
    }
  };

const otaLogsDateValidator =
  (nextValidator) =>
  (value, ...rest) => {
    const difference = getDaysSince({
      fromDateTime: getShortDateInIsoFormat(value),
      toDateTime: getNow(),
    });
    if (difference < 0) {
      return 'Please enter a date today or earlier';
    }
    if (difference > 366) {
      return 'Please enter a date less than a year ago';
    }
    if (typeof nextValidator === 'function') {
      return nextValidator(...[value, ...rest]);
    }
  };
export const endDateBeforeStartDateValidator = (startDate, endDate) => {
  if (
    getDaysSince({
      fromDateTime: getShortDateInIsoFormat(startDate),
      toDateTime: getShortDateInIsoFormat(endDate),
    }) < 0
  ) {
    return 'Please choose a date that is after the start date';
  }
};

export const differenceBetweenDatesGreaterThan3Months = (startDate, endDate) => {
  const daysDifference = getDaysSince({
    fromDateTime: getShortDateInIsoFormat(startDate),
    toDateTime: getShortDateInIsoFormat(endDate),
  });

  if (daysDifference > 90) {
    return 'Start and End Date must be within a 90 day range';
  }
};

export const analyticsLaunchDateValidator = (startDate) => {
  if (
    getDaysSince({
      fromDateTime: getShortDateInIsoFormat('07/20/2021'),
      toDateTime: getShortDateInIsoFormat(startDate),
    }) < 0
  ) {
    return 'Please choose a date that is 07/20/21 or later';
  }
};

const otaLogsLaunchDateValidator = (startDate) => {
  if (
    //change this closer to release
    getDaysSince({
      fromDateTime: getShortDateInIsoFormat('12/05/2022'),
      toDateTime: getShortDateInIsoFormat(startDate),
    }) < 0
  ) {
    return 'Please choose a date that is 12/05/22 or later';
  }
};

export const isEmptyOrWhitespaceOnly = (value) => {
  //some values come back as ints and we can't use the trim method on non-strings
  let stringValue = value ? value.toString() : '';
  if (stringValue.trim() === '') {
    return 'This field is required';
  }
};

export const requiredValidator =
  (nextValidator) =>
  (value, ...rest) => {
    const requiredMessage = isEmptyOrWhitespaceOnly(value);
    if (requiredMessage) {
      return requiredMessage;
    }
    if (typeof nextValidator === 'function') {
      return nextValidator(...[value, ...rest]);
    }

    return null;
  };
const geofenceLogsLaunchDateValidator = (startDate) => {
  if (
    getDaysSince({
      fromDateTime: getShortDateInIsoFormat('05/23/2022'),
      toDateTime: getShortDateInIsoFormat(startDate),
    }) < 0
  ) {
    return 'Please choose a date that is 05/23/22 or later';
  }
};
export const characterLimitValidator =
  (nextValidator) =>
  (value, characterLimit, ...rest) => {
    if (value?.length > characterLimit) {
      return `Please enter ${characterLimit} characters or less`;
    }

    if (typeof nextValidator === 'function') {
      return nextValidator(...[value, ...rest]);
    }
    return null;
  };

export const regexValidator = (regex, errorMessage) => (value) => {
  if (regex instanceof RegExp) {
    if (value && !regex.test(String(value))) {
      return errorMessage;
    }
  }
};

const matchValidator = (errorMessage) => (matchString, value) => {
  if (value !== matchString) {
    return errorMessage;
  }
};

const dateRangeValidators = {
  startDate: requiredValidator(
    analyticsDateValidator(regexValidator(dateRegex, dateRegexError('start date'))),
  ),
  endDate: requiredValidator(
    analyticsDateValidator(regexValidator(dateRegex, dateRegexError('end date'))),
  ),
  endDateBeforeStartDate: endDateBeforeStartDateValidator,
  startDateAfterAnalyticsLaunch: analyticsLaunchDateValidator,
  startDateAfterOtaLogsLaunch: otaLogsLaunchDateValidator,
  startDateAfterGeofenceLaunch: geofenceLogsLaunchDateValidator,
  greaterThanThreeMonthRange: differenceBetweenDatesGreaterThan3Months,
  otaLogsStartDate: requiredValidator(
    otaLogsDateValidator(regexValidator(dateRegex, dateRegexError('start date'))),
  ),
  otaLogsEndDate: requiredValidator(
    otaLogsDateValidator(regexValidator(dateRegex, dateRegexError('end date'))),
  ),
};

const configurationValidators = {
  configName: requiredValidator(
    characterLimitValidator(
      regexValidator(alphanumericExtendedRegex, alphanumericExtendedRegexError),
    ),
  ),
  description: requiredValidator(
    characterLimitValidator(
      regexValidator(alphanumericExtendedRegex, alphanumericExtendedRegexError),
    ),
  ),
  archived: requiredValidator(),
};

const groupValidators = {
  groupName: requiredValidator(
    regexValidator(alphanumericExtendedRegex, alphanumericExtendedRegexError),
  ),
};

const selectedConfigurationValidators = {
  selectedConfiguration: requiredValidator(),
};

const maintenanceRemindersValidators = {
  reminderMileage: regexValidator(positiveIntegerRegex, positiveIntegerRegexError),
  reminderEngineHours: regexValidator(positiveIntegerRegex, positiveIntegerRegexError),
  reminderDays: regexValidator(positiveIntegerRegex, positiveIntegerRegexError),
  reminderOilLife: requiredValidator(positiveIntegerOneHundredOrLess()),
};

const markMaintenanceCompletedValidators = {
  completedDate: requiredValidator(
    otaLogsDateValidator(regexValidator(dateRegex, dateRegexError('completed date'))),
  ),
  notes: characterLimitValidator(regexValidator(asciiRegex, asciiRegexError)),
  nextIntervalValue: requiredValidator(
    regexValidator(positiveIntegerRegex, positiveIntegerRegexError),
  ),
  nextOilLifeInterval: requiredValidator(positiveIntegerOneHundredOrLess()),
  nextDueDateRequired: requiredValidator(),
};

const helpValidators = {
  type: requiredValidator(),
  description: requiredValidator(
    characterLimitValidator(regexValidator(asciiRegex, asciiRegexError)),
  ),
};

const passwordValidators = {
  oldPassword: requiredValidator(),
  password: requiredValidator(regexValidator(passwordRegex, passwordRegexError)),
  confirmPassword: requiredValidator(matchValidator(passwordMatchError)),
  username: requiredValidator(),
  resetCode: requiredValidator(),
};

export const validateMaintenanceReminders = (values) => {
  const reminderMileageErrors = maintenanceRemindersValidators.reminderMileage(
    stripCommas(values.reminderMileage),
  );
  const reminderEngineHoursErrors = maintenanceRemindersValidators.reminderEngineHours(
    stripCommas(values.reminderEngineHours),
  );
  const reminderDaysErrors = maintenanceRemindersValidators.reminderDays(values.reminderDays);

  const reminderOilLifeErrors = maintenanceRemindersValidators.reminderOilLife(
    values.reminderOilLife,
  );

  return {
    ...(reminderMileageErrors ? { reminderMileage: reminderMileageErrors } : {}),
    ...(reminderEngineHoursErrors ? { reminderEngineHours: reminderEngineHoursErrors } : {}),
    ...(reminderDaysErrors ? { reminderDays: reminderDaysErrors } : {}),
    ...(reminderOilLifeErrors ? { reminderOilLife: reminderOilLifeErrors } : {}),
  };
};

export const findNextIntervalValidator = (intervalType, nextIntervalValue) => {
  if (intervalType === maintenanceIntervalConstants.DATE) {
    return;
  } else if (intervalType === maintenanceIntervalConstants.OIL_LIFE) {
    const error = markMaintenanceCompletedValidators.nextOilLifeInterval(nextIntervalValue);
    return error;
  } else {
    const error = markMaintenanceCompletedValidators.nextIntervalValue(
      stripCommas(nextIntervalValue),
    );
    return error;
  }
};

export const validateMarkMaintenanceCompleted = (values) => {
  const completedDateErrors = markMaintenanceCompletedValidators.completedDate(
    values.completedDate,
  );
  const notesErrors = markMaintenanceCompletedValidators.notes(values.notes, 1000);
  const nextIntervalValueErrors =
    values.status !== maintenanceStatusConstants.CHECK_ENGINE &&
    findNextIntervalValidator(values.nextIntervalType?.value, values.nextIntervalValue);

  const nextDueDateErrors =
    values.nextIntervalType?.value === maintenanceIntervalConstants.DATE &&
    values.status !== maintenanceStatusConstants.CHECK_ENGINE &&
    markMaintenanceCompletedValidators.nextDueDateRequired(values.nextDueDate);
  return {
    ...(completedDateErrors ? { completedDate: completedDateErrors } : {}),
    ...(notesErrors ? { notes: notesErrors } : {}),
    ...(nextIntervalValueErrors ? { nextIntervalValue: nextIntervalValueErrors } : {}),
    ...(nextDueDateErrors ? { nextDueDate: nextDueDateErrors } : {}),
  };
};

export const validateHelp = (values) => {
  const typeErrors = helpValidators.type(values.type);
  const descriptionErrors = helpValidators.description(values.description, 1000);
  return {
    ...(typeErrors ? { type: typeErrors } : {}),
    ...(descriptionErrors ? { description: descriptionErrors } : {}),
  };
};

export const validateUserPassword = (values) => {
  const oldPasswordErrors = passwordValidators.oldPassword(values.oldPassword);
  const newPasswordErrors = passwordValidators.password(values.newPassword);
  const confirmNewPasswordErrors = passwordValidators.confirmPassword(
    values.newPassword,
    values.confirmNewPassword,
  );
  return {
    ...(oldPasswordErrors ? { oldPassword: oldPasswordErrors } : {}),
    ...(newPasswordErrors ? { newPassword: newPasswordErrors } : {}),
    ...(confirmNewPasswordErrors ? { confirmNewPassword: confirmNewPasswordErrors } : {}),
  };
};

export const validateConfiguration = (values) => {
  const configNameErrors = configurationValidators.configName(values.configName, 128);
  const descriptionErrors = configurationValidators.description(values.description, 256);
  const archivedErrors = configurationValidators.archived(values.archived);
  return {
    ...(configNameErrors ? { configName: configNameErrors } : {}),
    ...(descriptionErrors ? { description: descriptionErrors } : {}),
    ...(archivedErrors ? { archived: archivedErrors } : {}),
  };
};

export const validateGroup = (values) => {
  const groupNameErrors = groupValidators.groupName(values.groupName);
  return {
    ...(groupNameErrors ? { groupName: groupNameErrors } : {}),
  };
};

export const validateSelectedConfiguration = (values) => {
  const selectedConfigurationErrors = selectedConfigurationValidators.selectedConfiguration(
    values.selectedConfiguration,
  );
  return {
    ...(selectedConfigurationErrors ? { selectedConfiguration: selectedConfigurationErrors } : {}),
  };
};

export const validatePasswordResetForm = (values) => {
  const resetPasswordErrors = passwordValidators.password(values.resetPassword);
  const confirmPasswordErrors = passwordValidators.confirmPassword(
    values.resetPassword,
    values.confirmPassword,
  );
  return {
    ...(resetPasswordErrors ? { resetPassword: resetPasswordErrors } : {}),
    ...(confirmPasswordErrors ? { confirmPassword: confirmPasswordErrors } : {}),
  };
};

export const validateAnalyticsDateRange = (values) => {
  const startDateErrors = dateRangeValidators.startDate(values.startDate);
  const endDateErrors = dateRangeValidators.endDate(values.endDate);
  const endDateBeforeStartDateError = dateRangeValidators.endDateBeforeStartDate(
    values.startDate,
    values.endDate,
  );
  const startDateAfterAnalyticsLaunchError = dateRangeValidators.startDateAfterAnalyticsLaunch(
    values.startDate,
  );
  const greaterThanThreeMonthRangeError = dateRangeValidators.greaterThanThreeMonthRange(
    values.startDate,
    values.endDate,
  );
  return {
    ...(startDateErrors ? { startDate: startDateErrors } : {}),
    ...(endDateErrors ? { endDate: endDateErrors } : {}),
    ...(endDateBeforeStartDateError ? { endDate: endDateBeforeStartDateError } : {}),
    ...(startDateAfterAnalyticsLaunchError
      ? { startDate: startDateAfterAnalyticsLaunchError }
      : {}),
    ...(greaterThanThreeMonthRangeError ? { endDate: greaterThanThreeMonthRangeError } : {}),
  };
};

export const validateLogsRange = (values) => {
  const startDateErrors = dateRangeValidators.otaLogsStartDate(values.startDate);
  const endDateErrors = dateRangeValidators.otaLogsEndDate(values.endDate);
  const endDateBeforeStartDateError = dateRangeValidators.endDateBeforeStartDate(
    values.startDate,
    values.endDate,
  );
  const startDateAfterOtaLogsLaunchError = dateRangeValidators.startDateAfterOtaLogsLaunch(
    values.startDate,
  );
  return {
    ...(startDateErrors ? { startDate: startDateErrors } : {}),
    ...(endDateErrors ? { endDate: endDateErrors } : {}),
    ...(endDateBeforeStartDateError ? { endDate: endDateBeforeStartDateError } : {}),
    ...(startDateAfterOtaLogsLaunchError ? { startDate: startDateAfterOtaLogsLaunchError } : {}),
  };
};

export const validateGeofenceLogsRange = (values) => {
  const startDateErrors = dateRangeValidators.otaLogsStartDate(values.startDate);
  const endDateErrors = dateRangeValidators.otaLogsEndDate(values.endDate);
  const endDateBeforeStartDateError = dateRangeValidators.endDateBeforeStartDate(
    values.startDate,
    values.endDate,
  );
  const startDateAfterGeofenceLaunchError = dateRangeValidators.startDateAfterGeofenceLaunch(
    values.startDate,
  );
  return {
    ...(startDateErrors ? { startDate: startDateErrors } : {}),
    ...(endDateErrors ? { endDate: endDateErrors } : {}),
    ...(endDateBeforeStartDateError ? { endDate: endDateBeforeStartDateError } : {}),
    ...(startDateAfterGeofenceLaunchError ? { startDate: startDateAfterGeofenceLaunchError } : {}),
  };
};
