import { SORT_ORDER } from 'legacy/shared/constants/table';
import { SortArrowSpan } from 'legacy/shared/styles/custom/Table';
import { tableActions } from 'legacy/shared/controls/WcpTable/v2/constants/tableActions';

const WcpTableHeaderCell = ({
  currentSortProperty,
  currentSortOrder,
  sortingEnabled,
  sortProperty,
  tableDispatch,
  hideHeaderContent,
  children,
}) => {
  return (
    <th
      onClick={() => {
        if (sortingEnabled) {
          let selectedSortProperty = sortProperty;
          let newSortOrder = SORT_ORDER.ASC;

          if (selectedSortProperty === currentSortProperty) {
            newSortOrder = currentSortOrder === SORT_ORDER.ASC ? SORT_ORDER.DESC : SORT_ORDER.ASC;
          }

          tableDispatch({
            type: tableActions.SORT,
            payload: {
              sortProperty: selectedSortProperty,
              sortOrder: newSortOrder,
            },
          });
        }
      }}
      style={{ cursor: 'pointer' }}
    >
      {hideHeaderContent ? <></> : children}
      {sortingEnabled ? (
        <SortArrowSpan
          isVisible={currentSortOrder !== null && sortProperty === currentSortProperty}
        >
          {currentSortOrder === SORT_ORDER.ASC && sortProperty === currentSortProperty
            ? String.fromCharCode(9650)
            : String.fromCharCode(9660)}
        </SortArrowSpan>
      ) : (
        <></>
      )}
    </th>
  );
};

export default WcpTableHeaderCell;
