import styled from 'styled-components';
import colors from 'legacy/shared/constants/colors';
import device from 'legacy/shared/constants/sizing';

export const AnalyticsInfographics = styled.div`
  margin-bottom: 20px;

  &:last-child {
    margin-bottom: 0;
  }
  display: grid;
  grid-gap: 20px;

  grid-template-areas:
    'Col'
    'Col'
    'Col'
    'Col';
  grid-template-columns: 200px;
  grid-template-rows: 70px 70px 70px 60px;

  ${(props) =>
    props.logs &&
    `
    grid-template-columns: 200px;
  grid-template-rows: 70px 70px 0px 200px;
    grid-gap: 5px;

    @media ${device.largeAnalytics} {
    grid-template-areas:
      'Col Col Col Col'
      'Col Col Col Col';
    grid-template-columns: 150px 150px 220px 300px;
    grid-template-rows: 80px 100px;
    grid-gap: 20px;
  }
	`};

  @media ${device.largeAnalytics} {
    grid-template-areas:
      'Col Col Col Col'
      'Col Col Col Col';
    grid-template-columns: 200px 200px 220px 300px;
    grid-template-rows: 80px 100px;
    grid-gap: 20px;
  }
`;

export const LargeResult = styled.div`
  font-size: 36px;

  ${(props) =>
    props.online &&
    `
    color: ${colors.grass};
	`};

  ${(props) =>
    props.responding &&
    `
    color: ${colors.cherry};
	`};

  ${(props) =>
    props.offline &&
    `
    color: ${colors.haze};
	`};

  ${(props) =>
    props.charcoal &&
    `
    color: ${colors.charcoal};
	`};

  ${(props) =>
    props.bars &&
    `
    text-align:right;
	`};
`;

export const LargeResultLabel = styled.div`
  font-size: 18px;
  font-weight: 700;
  color: ${colors.charcoal};
`;

export const SmallLabel = styled.div`
  font-size: 12px;
  margin-top: 5px;
  color: ${colors.haze};

  ${(props) =>
    props.gps &&
    `
    margin-top: 15px;
	`};
`;

export const VehicleCount = styled.div`
  font-size: 24px;
  font-weight: 700;
  color: ${colors.charcoal};
  margin-bottom: 5px;
`;

export const BarResults = styled.div`
  display: grid;
  grid-template-areas: 'Col Col';
  grid-template-columns: 120px 200px;
  grid-gap: 20px;

  ${(props) =>
    props.logs &&
    `
      grid-template-columns: 100px 200px;
	`};

  @media ${device.large} {
    grid-template-columns: 120px 300px;
  }
`;

export const AnalyticsCheckboxFilterWrapper = styled.div`
  margin-bottom: 10px;
`;

export const Bar = styled.div`
  height: 10px;
  width: 100%;
  margin-top: 10px;

  ${(props) =>
    typeof props.barWidth === 'number' &&
    `
      width: ${props.barWidth}%;
    `};
  ${(props) =>
    props.online &&
    `
    background: ${colors.grass};
	`};

  ${(props) =>
    props.responding &&
    `
    background: ${colors.cherry};
	`};

  ${(props) =>
    props.offline &&
    `
    background: ${colors.haze};
	`};

  ${(props) =>
    props.totalHours &&
    `
    border: solid 1px ${colors.haze};
	`};
`;

export const BarResultsWrapper = styled.div`
  @media ${device.large} {
    grid-column-start: 4;
    grid-column-end: 4;
    grid-row-start: 1;
    grid-row-end: 2;
  }
`;
export const ExportWrapper = styled.div`
  @media ${device.large} {
    grid-column: span 2;
    grid-row-start: 2;
  }
`;

export const AnalyticsDropdown = styled.div`
  width: 85px;
  display: inline-block;
  vertical-align: top;
  margin-top: -10px;
  margin-left: 5px;
  margin-right: 5px;

  @media ${device.large} {
    width: 65px;
    margin-top: 12px;
  }
`;

export const NoDataDiv = styled.div`
  font-size: 18px;
  font-weight: 700;
  color: ${colors.cherry};
  margin-bottom: 10px;
  @media ${device.large} {
    margin-top: -20px;
    margin-bottom: 20px;

    ${(props) =>
      props.analytics &&
      `
    margin-top:10px;
	`};
  }
`;

export const AnalyticsDatesDiv = styled.div``;

export const AnalyticsDateRangeValidationDiv = styled.div`
  font-size: 12px;
  margin-top: 20px;
  color: ${colors.cherry};

  @media ${device.large} {
    margin-top: 5px;
  }
`;
