import { Form, Formik } from 'formik';
import { useContext, useEffect, useState } from 'react';

import AnalyticsHeatmapRespondingFilter from 'legacy/features/analyticsHeatmap/components/sidebar/AnalyticsHeatmapRespondingFilter';
import { activityFilterOptions } from 'legacy/features/analyticsHeatmap/constants/AnalyticsHeatmapConstants';
import { AnalyticsHeatmapApi } from 'legacy/features/analyticsHeatmap/helpers/AnalyticsHeatmapApiContext';
import FormWrapper, {
  CalculateDiv,
  FormCol,
  FormLabelAnimated,
} from 'legacy/shared/styles/custom/Form';
import {
  HeatmapDateDiv,
  HeatmapMonthRangeValidationDiv,
  MonthYearFormDiv,
  YearDiv,
} from 'legacy/shared/styles/custom/Heatmap';
import StyledReactSelect from 'legacy/shared/styles/custom/SelectField';
import {
  FilterLabel,
  HeatmapFilterDiv,
  LiveMapSidebarHead,
  SidebarHideToggle,
} from 'legacy/shared/styles/custom/SharedMapStyledComponents';
import ButtonWithLoader from 'legacy/shared/controls/ButtonWithLoader/ButtonWithLoader';
import IconSvgComponent from 'legacy/shared/controls/WcpIcon/IconSvgComponent';
import {
  getCurrentMonthAsInteger,
  getCurrentYearAsInteger,
  getDifferenceInMonths,
  getEndOfMonthDateStringForDateTimeFromObject,
  getLastNYears,
  getMonthDropdownOptions,
  getStartOfMonthDateStringForDateTimeFromObject,
} from 'legacy/shared/utilities/misc/time';

const AnalyticsHeatmapSidebarControls = () => {
  const currentYear = getCurrentYearAsInteger();
  const currentMonth = getCurrentMonthAsInteger({ offset: -1 });

  let monthOptions = getMonthDropdownOptions();

  const yearOptions = getLastNYears({
    beforeAndIncludingYear: currentYear,
    numYears: 4,
    minYear: 2021,
  }).map((y) => ({ label: y, value: y }));

  const {
    setHeatmapSidebarOpen,
    submitHeatmapDataQuery,
    setSelectedActivityFilterOption,
    setHeaderStartDate,
    setHeaderEndDate,
  } = useContext(AnalyticsHeatmapApi);

  const [startMonth, setStartMonth] = useState(currentMonth);
  const [endMonth, setEndMonth] = useState(currentMonth);
  const [startYear, setStartYear] = useState(currentMonth === 12 ? currentYear - 1 : currentYear);
  const [endYear, setEndYear] = useState(currentMonth === 12 ? currentYear - 1 : currentYear);

  const [validationMessage, setValidationMessage] = useState(null);

  const onSubmit = async (values, formikActions) => {
    let startDate = getStartOfMonthDateStringForDateTimeFromObject({ startMonth, startYear });
    let endDate = getEndOfMonthDateStringForDateTimeFromObject({ endMonth, endYear });
    await submitHeatmapDataQuery({
      startDate,
      endDate,
    });

    setHeaderStartDate(startDate);
    setHeaderEndDate(endDate);

    formikActions.setSubmitting(false);
    // default layer filter to all activity
    setSelectedActivityFilterOption(activityFilterOptions.ALL);
  };

  useEffect(() => {
    if (startMonth <= 6 && startYear < 2022) {
      setValidationMessage('Please choose a month that is July 2021 or later');
    } else if (startYear > endYear) {
      setValidationMessage('Start year must be before end year');
    } else if (startYear === endYear && endMonth < startMonth) {
      setValidationMessage('Start month must be before end month');
    } else if (
      getDifferenceInMonths({
        startMonth,
        startYear,
        currentMonth,
        currentYear,
      }) > 36
    ) {
      setValidationMessage('Please choose a start date within the last 36 months');
    } else if (
      getDifferenceInMonths({
        startMonth,
        startYear,
        endMonth,
        endYear,
      }) > 12
    ) {
      setValidationMessage('Please choose a range of 12 months or less');
    } else if (currentYear === endYear && endMonth > currentMonth) {
      setValidationMessage('Please choose end date that is on or before the current date');
    } else {
      setValidationMessage(null);
    }
  }, [startMonth, endMonth, startYear, endYear]);

  return (
    <LiveMapSidebarHead>
      <FilterLabel>Available Heatmap Layers</FilterLabel>

      <SidebarHideToggle onClick={() => setHeatmapSidebarOpen(false)}>
        <IconSvgComponent
          svgFileName="hideLiveMapFilter"
          title="Hide heatmap filter"
          alt="Hide heatmap filter"
        />
      </SidebarHideToggle>
      <HeatmapFilterDiv>
        <Formik
          initialValues={{
            startMonth,
            endMonth,
            startYear,
            endYear,
          }}
          onSubmit={onSubmit}
          validator={() => ({})}
        >
          {({ values, submitForm, handleChange, handleBlur, isSubmitting, errors, touched }) => (
            <Form>
              <FormWrapper>
                <MonthYearFormDiv>
                  <FormCol>
                    <HeatmapDateDiv>
                      <FormLabelAnimated animated>Start Month</FormLabelAnimated>
                      <StyledReactSelect
                        name="startMonth"
                        placeholder={'Start Month'}
                        defaultValue={{
                          label: monthOptions.find((m) => m.value === startMonth).label,
                        }}
                        onChange={(e) => setStartMonth(+e.value)}
                        disabled={false}
                        isClearable={false}
                        isSearchable={false}
                        options={monthOptions}
                      />
                    </HeatmapDateDiv>
                  </FormCol>
                  <FormCol>
                    <FormLabelAnimated animated>Year</FormLabelAnimated>
                    <YearDiv>
                      <StyledReactSelect
                        name="startYear"
                        placeholder={'Start Year'}
                        defaultValue={{
                          label: startYear,
                        }}
                        onChange={(e) => {
                          setStartYear(+e.value);
                        }}
                        disabled={false}
                        isClearable={false}
                        isSearchable={false}
                        options={yearOptions}
                      />
                    </YearDiv>
                  </FormCol>
                </MonthYearFormDiv>
                <MonthYearFormDiv>
                  <FormCol>
                    <HeatmapDateDiv>
                      <FormLabelAnimated animated>End Month</FormLabelAnimated>
                      <StyledReactSelect
                        name="endMonth"
                        placeholder={'End Month'}
                        defaultValue={{
                          label: monthOptions.find((m) => m.value === endMonth).label,
                        }}
                        onChange={(e) => setEndMonth(+e.value)}
                        isDisabled={false}
                        isClearable={false}
                        isSearchable={false}
                        options={monthOptions}
                      />
                    </HeatmapDateDiv>
                  </FormCol>
                  <FormCol>
                    <FormLabelAnimated animated>Year</FormLabelAnimated>
                    <YearDiv>
                      <StyledReactSelect
                        name="endYear"
                        placeholder={'End Year'}
                        defaultValue={{
                          label: endYear,
                        }}
                        onChange={(e) => {
                          setEndYear(+e.value);
                        }}
                        isDisabled={false}
                        isClearable={false}
                        isSearchable={false}
                        options={yearOptions}
                      />
                    </YearDiv>
                  </FormCol>
                </MonthYearFormDiv>
                {validationMessage !== null && (
                  <HeatmapMonthRangeValidationDiv>
                    {validationMessage}
                  </HeatmapMonthRangeValidationDiv>
                )}

                <CalculateDiv heatmap>
                  <ButtonWithLoader
                    isLoading={isSubmitting}
                    confirmText={'Calculate'}
                    loadingStyleProp={'submittingWithSpinner'}
                    notLoadingStyleProp={'query'}
                    clickHandler={submitForm}
                    disabled={validationMessage !== null} // disable if validation message exists
                  />
                </CalculateDiv>
              </FormWrapper>
            </Form>
          )}
        </Formik>
        <AnalyticsHeatmapRespondingFilter />
      </HeatmapFilterDiv>
    </LiveMapSidebarHead>
  );
};

export default AnalyticsHeatmapSidebarControls;
