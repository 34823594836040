import { useEffect, useState } from 'react';

import RecipientGroupUsersContainer from 'legacy/features/recipientGroups/components/ManageRecipientGroupsForms/containers/RecipientGroupUsersContainer';
import RecipientGroupNameField from 'legacy/features/recipientGroups/components/ManageRecipientGroupsForms/fields/RecipientGroupNameField';
import UpdateRecipientGroupFormSubmit from 'legacy/features/recipientGroups/components/ManageRecipientGroupsForms/submit/UpdateRecipientGroupFormSubmit';
import useFetchRecipientGroupByIdQuery from 'legacy/features/recipientGroups/hooks/queries/useFetchRecipientGroupByIdQuery';
import { RecipientsFormWrapper } from 'legacy/shared/styles/custom/RecipientGroups';
import IsFetchingWrapper from 'legacy/shared/controls/WcpLoadingWrapper/v2/IsFetchingWrapper';
import { WcpForm } from 'legacy/shared/controls/WcpForm/v1/WcpForm';

const EditRecipientGroupForm = ({ recipientGroupId, formik }) => {
  // get recipient group by id
  const [recipientGroup, _, queryKey] = useFetchRecipientGroupByIdQuery({
    recipientGroupId,
  });

  // render
  return (
    <IsFetchingWrapper queryKeys={[queryKey]}>
      {!!recipientGroup && (
        <WcpForm
          formik={formik}
          customOnSubmit={(e) => {
            formik.values.recipientGroupId = recipientGroupId;
            formik.values.recipientGroupName = formik.values.recipientGroupNameField;
            formik.values.recipientGroupMembers = formik.values.recipientGroupMembersField;
            return formik.handleSubmit(e);
          }}
        >
          <RecipientsFormWrapper>
            <RecipientGroupNameField
              formik={formik}
              forceAnimated
              recipientGroupName={recipientGroup.recipientGroupName}
            />
            <RecipientGroupUsersContainer
              formik={formik}
              recipients={recipientGroup.recipientGroupMembers}
            />
            <UpdateRecipientGroupFormSubmit formik={formik} />
          </RecipientsFormWrapper>
        </WcpForm>
      )}
    </IsFetchingWrapper>
  );
};

export default EditRecipientGroupForm;
