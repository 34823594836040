import styled from 'styled-components';
import device from 'legacy/shared/constants/sizing';
import colors from 'legacy/shared/constants/colors';

export const PlaybackSlider = styled.input.attrs({
  type: 'range',
})`
  width: 100%;
  margin-bottom: 20px;
`;

export const PlaybackSliderWrapperDiv = styled.div`
  padding: 0px 10px;
  margin-bottom: 5px;
`;

export const PlaybackControlsDiv = styled.div`
  margin: 50px auto;
  text-align: center;
`;

export const PlaybackSpeedDiv = styled.div`
  width: 100px;
  text-align: left;
  margin-bottom: 55px;

  @media ${device.large} {
    margin-bottom: 35px;
  }
`;

export const VehiclePlaybackDiv = styled.div`
  padding: 15px 20px;
`;

export const GenerateDiv = styled.div`
  margin-top: 20px;
`;

export const VehiclePlaybackFormDiv = styled.div`
  margin-top: 40px;
`;

export const DateTimeFormDiv = styled.div`
  display: grid;
  grid-template-areas: 'FormCol FormCol';
  grid-template-columns: 180px 80px;
  grid-gap: 20px;
  margin-bottom: 35px;
`;

export const PlaybackDateDiv = styled.div`
  width: 180px;
  margin-right: 20px;
  margin-top: -1px;
`;

export const HourDiv = styled.div`
  margin-top: -19px;
  width: 80px;

  @media ${device.large} {
    margin-top: 0px;
  }
`;

export const PlaybackOptionDiv = styled.div`
  margin-top: 10px;
  margin-bottom: 10px;

  ${(props) =>
    props.granularity &&
    `
      @media ${device.large} {
    margin-bottom: 45px;
  }
    `}
`;

export const SpecifiedEventDiv = styled.div`
  text-align: left;
  margin: 20px 0px;
`;

export const SpecifiedEventTextDiv = styled.div`
  font-size: 12px;
  font-weight: 700;
  margin-top: 5px;
`;

export const PlaybackToggleHeaderDiv = styled.div`
  display: block;

  @media ${device.large} {
    display: none;
  }
`;
export const NoPlaybackDiv = styled.div`
  font-size: 16px;
  font-weight: 700;
  margin-top: 20px;
  white-space: normal;
`;

export const SpecifiedEventHeaderDiv = styled.div`
  display: flex;
  font-size: 14px;
  font-weight: 700;
  margin-top: 5px;
`;

export const ExportButtonWrapper = styled.div`
  padding-left: 20px;
  padding-top: 20px;
  margin: 0 auto;
  text-align: -webkit-center;
`;

export const SpeedSummary = styled.div`
  font-size: 13px;
  font-weight: 700;
  margin-bottom: 5px;
`;

export const MatchDateButtonWrapperDiv = styled.div`
  z-index: 100;
  position: absolute;
  top: 97px;
  left: 150px;
  @media ${device.large} {
    top: 94px;
  }
`;
