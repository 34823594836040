import useWcpFormContext from 'legacy/shared/controls/WcpForm/v2/hooks/useWcpFormContext';
import Button from 'legacy/shared/styles/custom/Button';

const WcpFormResetButton = () => {
  const { initialFormValues, setFormValues } = useWcpFormContext();

  return (
    <Button
      type="button"
      onClick={() => {
        console.log('Resetting form values', initialFormValues);
        setFormValues(initialFormValues);
      }}
      default
      withRightSpacer
    >
      Reset
    </Button>
  );
};

export default WcpFormResetButton;
