import React, { useState } from 'react';
import { SimpleDropdownItem, SimpleDropdownBtn } from 'legacy/shared/styles/custom/DropdownMenu';
import { getGuardedObjectProp } from 'legacy/shared/utilities/misc/general';
import { vehicleLockStatusViewData } from 'legacy/shared/constants/vehicle';

const ToggleLockVehicleMenuItem = ({ vehicle, onClick }) => {
  const lockedStatus = getGuardedObjectProp('device.CenComCoreAccess', vehicle);
  let displayLockOption = lockedStatus && lockedStatus !== 'UNAVAILABLE' && lockedStatus !== 'NA';
  return displayLockOption ? (
    <SimpleDropdownItem>
      <SimpleDropdownBtn onClick={onClick}>
        {vehicleLockStatusViewData[lockedStatus].commandTitle}
      </SimpleDropdownBtn>
    </SimpleDropdownItem>
  ) : null;
};

export default ToggleLockVehicleMenuItem;
