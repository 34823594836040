import {
  findRecipientRowSelector,
  findRecipientRowSelectorV2,
} from 'legacy/features/alerts/services/providers/AlertFormsProvider/utilities/findRecipientRowSelector';
import { determineCustomAlertMethod } from 'legacy/features/alerts/services/utilities/determineCustomAlertMethod';
import { validateAndExtractPayload } from 'legacy/shared/utilities/helpers/reducers/validateAndExtractPayload';

// updateCustomAlertMethodAction → updateCustomAlertMethodReducer
export const updateCustomAlertMethodReducer = (state, payload) => {
  const { recipientRowGuid, recipientUserName, sms, email } = validateAndExtractPayload({
    payload,
    mustInclude: ['recipientRowGuid', 'recipientUserName'],
  });

  console.log('updateCustomAlertMethodReducer', payload);

  // find the alert recipient with the recipientRowGuid
  const recipientRow = findRecipientRowSelectorV2(state, recipientRowGuid);

  if (recipientRow?.recipientGroup?.recipientGroupMembers) {
    // update the specific recipient with the alert method
    const recipient = recipientRow.recipientGroup.recipientGroupMembers.find(
      (member) => member.recipientUserName === recipientUserName,
    );

    recipient.sms = sms;
    recipient.email = email;
    recipient.recipientUserAlertMethod = determineCustomAlertMethod(sms, email);
  }

  console.log('recipientRow', recipientRow);

  // return the state
  return { ...state };
};
