import SimpleConfirmationModal from 'legacy/shared/controls/WcpModal/v1/SimpleConfirmationModal';

const ExcessiveDurationPlaybackModal = ({ modalData: { show }, handleCloseModal }) => {
  return (
    show && (
      <SimpleConfirmationModal
        handleCloseModal={handleCloseModal}
        submitHandler={() => {
          handleCloseModal();
        }}
        bodyText={
          'The associated event spans longer than 24 hours and is not available for playback.'
        }
        title={`Playback Not Available`}
        confirmText="OK"
      />
    )
  );
};

export default ExcessiveDurationPlaybackModal;
