import { useSelector } from 'react-redux';

import EditUserMenuItem from 'legacy/features/organizations/components/menus/EditUserMenuItem';
import {
  FIELD_SOLUTION_ENGINEER,
  ORGANIZATION_ADMIN,
  SYSTEM_ADMIN,
  userRoleOptions as userRoles,
} from 'legacy/shared/constants/users';
import ViewActivityLogsMenuItem from 'legacy/features/organizations/components/menus/ViewActivityLogsMenuItem';
import ActionMenu from 'legacy/shared/controls/WcpTable/v1/ActionMenu';
import UserActivationToggleMenuItem from 'legacy/features/organizations/components/menus/UserActivationToggleMenuItem';

const OrganizationUserTableActionMenu = ({ user, organization }) => {
  const userRole = useSelector((state) => state.user.user.userRole);
  const currentUserUsername = useSelector((state) => state.user.user.username);

  switch (userRole) {
    case userRoles[SYSTEM_ADMIN].value:
      // System Admin can: edit, view activity logs, activate / deactivate
      return (
        <ActionMenu>
          <EditUserMenuItem user={user} organization={organization} />
          <ViewActivityLogsMenuItem username={user.username} />
          {user.username !== currentUserUsername && <UserActivationToggleMenuItem user={user} />}
        </ActionMenu>
      );
    case userRoles[ORGANIZATION_ADMIN].value:
      // Organization Admins can: edit, view activity logs, activate / deactivate
      return (
        <ActionMenu>
          <EditUserMenuItem user={user} organization={organization} />
          <ViewActivityLogsMenuItem username={user.username} />
          <UserActivationToggleMenuItem user={user} />
        </ActionMenu>
      );
    case userRoles[FIELD_SOLUTION_ENGINEER].value:
      // FSEs can: edit, activate / deactivate
      return (
        <ActionMenu>
          <EditUserMenuItem user={user} organization={organization} />
          <UserActivationToggleMenuItem user={user} />
        </ActionMenu>
      );
  }
};

export default OrganizationUserTableActionMenu;
