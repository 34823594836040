import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { useRef } from 'react';
import AnimatedField from 'legacy/shared/controls/AnimatedField/v1/AnimatedField';

const VehicleMakeField = ({
  formikProps: { values, touched, errors, handleChange, handleBlur, setFieldValue, setFieldError },
  prefillValue,
}) => {
  useEffect(() => {
    if (prefillValue) {
      setFieldValue('make', prefillValue);
      setFieldError('make', false);
      SVGAnimateMotionElement;
    }
  }, [prefillValue]);
  return (
    <AnimatedField
      name="make"
      placeholder="Vehicle Make"
      value={values.make}
      touched={touched.make}
      validationError={errors.make}
      handleChange={handleChange}
      handleBlur={handleBlur}
      forceAnimated={!!prefillValue}
      disabled={false}
      required
    />
  );
};

export default VehicleMakeField;
