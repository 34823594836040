/**
 * @ External Dependecies
 */
import { createGlobalStyle } from 'styled-components';

import { media } from 'legacy/shared/utilities/misc/media';
import colors from 'legacy/shared/constants/colors';

export const GlobalTableStyles = createGlobalStyle`

	/********** Rc Table Styles **********/

	.rc-table {
		font-size: 12px;
		color: #666;
		transition: opacity 0.3s ease;
		position: relative;
		line-height: 1.5;
		overflow: hidden;
		}
		.rc-table .rc-table-scroll {
		overflow: auto;
		}
		.rc-table .rc-table-scroll table {
		width: auto;
		min-width: 100%;
		}
		.rc-table .rc-table-header {
		overflow: hidden;
		background: #f7f7f7;
		}
		.rc-table-fixed-header .rc-table-body {
		background: #fff;
		position: relative;
		}
		.rc-table-fixed-header .rc-table-body-inner {
		height: 100%;
		overflow: scroll;
		}
		.rc-table-fixed-header .rc-table-scroll .rc-table-header {
		padding-bottom: 20px;
		margin-bottom: -20px;
		overflow: scroll;
		box-sizing: border-box;
		}
		.rc-table-hide-scrollbar {
		scrollbar-color: transparent transparent;
		}
		.rc-table-hide-scrollbar::-webkit-scrollbar {
		background-color: transparent;
		}
		.rc-table-fixed-columns-in-body {
		visibility: hidden;
		pointer-events: none;
		}
		.rc-table .rc-table-title {
		padding: 16px 8px;
		border-top: 1px solid #e9e9e9;
		}
		.rc-table .rc-table-content {
		position: relative;
		}
		.rc-table .rc-table-footer {
		padding: 16px 8px;
		border-bottom: 1px solid #e9e9e9;
		}
		.rc-table .rc-table-placeholder {
		padding: 16px 8px;
		background: #fff;
		border-bottom: 1px solid #e9e9e9;
		text-align: center;
		position: relative;
		}
		.rc-table .rc-table-placeholder-fixed-columns {
		position: absolute;
		bottom: 0;
		width: 100%;
		background: transparent;
		pointer-events: none;
		}
		.rc-table table {
		width: 100%;
		border-collapse: separate;
		text-align: left;
		}
		.rc-table th {
		background: #f7f7f7;
		font-weight: bold;
		transition: background 0.3s ease;
		}
		.rc-table td {
		border-bottom: 1px solid #e9e9e9;
		}
		.rc-table td:empty:after {
		content: '.';
		visibility: hidden;
		}

		}
		.rc-table tr {
		transition: all 0.3s ease;
		}
		.rc-table tr:hover {
		background: #eaf8fe;
		}
		.rc-table tr.rc-table-row-hover {
		background: #eaf8fe;
		}
		.rc-table th,
		.rc-table td {
		padding: 16px 8px;
		white-space: nowrap;
		}
		.rc-table-expand-icon-col {
		width: 34px;
		}
		.rc-table-row-expand-icon,
		.rc-table-expanded-row-expand-icon {
		cursor: pointer;
		display: inline-block;
		width: 16px;
		height: 16px;
		text-align: center;
		line-height: 16px;
		border: 1px solid #e9e9e9;
		user-select: none;
		background: #fff;
		}
		.rc-table-row-spaced,
		.rc-table-expanded-row-spaced {
		visibility: hidden;
		}
		.rc-table-row-spaced:after,
		.rc-table-expanded-row-spaced:after {
		content: '.';
		}
		.rc-table-row-expanded:after,
		.rc-table-expanded-row-expanded:after {
		content: '-';
		}
		.rc-table-row-collapsed:after,
		.rc-table-expanded-row-collapsed:after {
		content: '+';
		}
		tr.rc-table-expanded-row {
		background: #f7f7f7;
		}
		tr.rc-table-expanded-row:hover {
		background: #f7f7f7;
		}
		.rc-table-column-hidden {
		display: none;
		}
		.rc-table-prev-columns-page,
		.rc-table-next-columns-page {
		cursor: pointer;
		color: #666;
		z-index: 1;
		}
		.rc-table-prev-columns-page:hover,
		.rc-table-next-columns-page:hover {
		color: #2db7f5;
		}
		.rc-table-prev-columns-page-disabled,
		.rc-table-next-columns-page-disabled {
		cursor: not-allowed;
		color: #999;
		}
		.rc-table-prev-columns-page-disabled:hover,
		.rc-table-next-columns-page-disabled:hover {
		color: #999;
		}
		.rc-table-prev-columns-page {
		margin-right: 8px;
		}
		.rc-table-prev-columns-page:before {
		content: '<';
		}
		.rc-table-next-columns-page {
		float: right;
		}
		.rc-table-next-columns-page:before {
		content: '>';
		}
		.rc-table-fixed-left,
		.rc-table-fixed-right {
		position: absolute;
		top: 0;
		overflow: hidden;
		}
		.rc-table-fixed-left table,
		.rc-table-fixed-right table {
		width: auto;
		background: #fff;
		}
		.rc-table-fixed-left {
		left: 0;
		box-shadow: 4px 0 4px rgba(100, 100, 100, 0.1);
		}
		.rc-table-fixed-left .rc-table-body-inner {
		margin-right: -20px;
		padding-right: 20px;
		}
		.rc-table-fixed-header .rc-table-fixed-left .rc-table-body-inner {
		padding-right: 0;
		}
		.rc-table-fixed-right {
		right: 0;
		box-shadow: -4px 0 4px rgba(100, 100, 100, 0.1);
		}
		.rc-table-fixed-right .rc-table-expanded-row {
		color: transparent;
		pointer-events: none;
		}
		.rc-table.rc-table-scroll-position-left .rc-table-fixed-left {
		box-shadow: none;
		}
		.rc-table.rc-table-scroll-position-right .rc-table-fixed-right {
		box-shadow: none;
		}
		.rc-table-row-cell-ellipsis {
		text-overflow: ellipsis;
		white-space: nowrap;
		overflow: hidden;
		}
		.rc-table-row-cell-break-word {
		word-wrap: break-word;
		word-break: break-word;
		}
		.rc-table-layout-fixed table {
		table-layout: fixed;
		}


	/********** Table Styles **********/
//override last bottom border present on vehicle device table
.rc-table td {
  border-bottom: 0px solid #e9e9e9;
}
	.table-alt {
		color: ${colors.fog};
	}

	.table-alt th {
		padding: 10px 8px 5px;
		background: #fff;
		font-size: 10px;
		font-weight: 600;
		text-transform: uppercase;
		color: ${colors.haze};

		&:first-child {
			padding-left: 46px;
		}
	}

	.table-alt td {
		padding: 14px 8px;
		font-size: 14px;
		font-weight: 600;
		color: ${colors.midnight};

		&:first-child {
			padding-left: 46px;
		}

		.vehicleNameAndIcon {
			display: flex;
			justify-content: flex-start;
			align-items: center;

			.svg-icon {
				margin-right: 10px;
				font-size: 0;
			}
		}
	}

	.table-simple {
		padding-right: 60px;
		margin-right: -60px;
		overflow:inherit;

		${media.tablet_portrait`
			padding-right: 0;
			margin-right: 0;
		`}

		td,
		th {
			white-space: pre-wrap
		}

		th {
			vertical-align: bottom;
			padding: 0 7px 5px 7px;
			background: transparent;
			border-bottom: 1px solid rgba(138, 144, 156, 0.15);
			font-size: 10px;
			font-weight: 600;
			text-transform: uppercase;
			letter-spacing: 0.01em;
			color: ${colors.haze};

			&:first-child {
				padding-left: 0;
			}
		}

		td {
			padding: 24px 7px;
			border-bottom: 1px solid rgba(138, 144, 156, 0.15);
		}

		td.name {
			padding-left: 5;
			font-size: 14px;
			font-weight: 700;
			color: ${colors.midnight};
			min-width:100px;
			word-break: break-all;
		}

		td.tightRow {
			padding:15px;
			font-size: 14px;
		}

		td.connectivity {
			font-size: 14px;
			font-weight: 700;
			letter-spacing: 0.01em;
			max-width: 0;
			white-space: nowrap;
			text-overflow: ellipsis;
			overflow: hidden;

			.error {
				color: ${colors.cherry};
			}

			.success {
				color: ${colors.midnight};
			}
		}

		td.year,
		td.make,
		td.type,
		td.generic {
			font-size: 14px;
			font-weight: 600;
			letter-spacing: 0.01em;
			color: ${colors.haze};
		}

		td.vin {
			font-size: 10px;
			font-weight: 400;
			text-transform: uppercase;
			color: ${colors.haze};
		}

		td.plate {
			font-size: 12px;
			font-weight: 400;
			letter-spacing: 0.01em;
			color: ${colors.haze};
		}

		td.model {
			font-size: 14px;
			font-weight: 400;
			letter-spacing: 0.01em;
			color: ${colors.haze};
		}

		tr:hover {
			background: transparent;
		}

		.vehicleNameAndIcon {
			display: flex;
			justify-content: flex-start;
			align-items: center;

			.svg-icon {
				margin-right: 13px;
				font-size: 0;
			}
		}
		.deactivated{
			color: ${colors.haze};
		}
		.groupNameAndIcon {
			display: flex;
			justify-content: flex-start;
			align-items: left;
			overflow: hidden;
			word-break: break-all;
			text-overflow: ellipsis;
			-webkit-line-clamp: 2;
			-webkit-box-orient: vertical;
				.svg-icon {
				margin-right: 13px;
				font-size: 0;
			}
		}
		.userNameAndIcon {
			display: flex;
			justify-content: flex-start;
			align-items: left;
			overflow: hidden;
			word-break: break-all;
			text-overflow: ellipsis;
			-webkit-line-clamp: 2;
			-webkit-box-orient: vertical;
			.spinner-margin {
				margin: 1px !important;
			}
		}
	}
	.permission-table-simple {
		th {
			vertical-align: top !important;
		}
	}
`;
