import {
  CardContainer,
  CardLabel,
  CardName,
  CardOuterWrapper,
  CardRow,
  CardValue,
  ActionMenuBtnWrapper,
} from 'legacy/shared/styles/custom/MobileCard';

import { RespondingDot } from 'legacy/shared/styles/custom/SharedMapStyledComponents';
import {
  convertDuration,
  formatISOTimestampToLongDateWithTime,
} from 'legacy/shared/utilities/misc/time';

export default function AnalyticsLogsCard({ event, actionMenuComponent }) {
  return (
    <CardOuterWrapper>
      <CardContainer logsResults>
        <ActionMenuBtnWrapper>{actionMenuComponent(event)}</ActionMenuBtnWrapper>
        <CardRow>
          <CardName withRespondingStatus title={event.status}>
            {event.status}{' '}
            {event.responding ? (
              <RespondingDot logsCard logs>
                <CardLabel>Responding</CardLabel>
              </RespondingDot>
            ) : (
              <span title={'Responding: '}></span>
            )}
          </CardName>
        </CardRow>
        <CardRow></CardRow>
        <CardRow>
          <CardLabel>Timestamp:</CardLabel>
          <CardValue>{formatISOTimestampToLongDateWithTime(event.timestamp)}</CardValue>
        </CardRow>
        <CardRow>
          <CardLabel>Duration:</CardLabel>
          <CardValue>{convertDuration(event.duration)}</CardValue>
        </CardRow>
        <CardRow>
          <CardLabel>Distance Traveled:</CardLabel>
          <CardValue>
            {event.distance_traveled ? `${event.distance_traveled} Miles` : 'N/A'}
          </CardValue>
        </CardRow>
        <CardRow>
          <CardLabel>Top Speed:</CardLabel>
          <CardValue>{event.top_speed}</CardValue>
        </CardRow>
      </CardContainer>
    </CardOuterWrapper>
  );
}
