import { userRoleOptionsArray as userRoleOptions } from 'legacy/shared/constants/users';

export const decorateUserObject = ({ user }) => {
  return {
    username: user.Username,
    email: user.email,
    phoneNumber: user.phone_number,
    phoneExtension: user.phone_extension,
    givenName: user.given_name,
    familyName: user.family_name,
    userTitle: user.user_title,
    active: user.active,
    userRole: user.user_role,
    picture: user.picture,
    organizationId: user.organization_id,
    twoFactorAuthMethod: user.two_factor_auth_method,
    permissions: user.permissions,
    role: userRoleOptions.find((userRole) => userRole.value === user.user_role).label,
    status: user.active === true ? 'Enabled' : 'Disabled',
    optOutOfEmails: user.opt_out_of_emails,
  };
};

export const decorateFseUser = (fseUser) => ({
  ...fseUser,
  username: fseUser.Username,
  givenName: fseUser.given_name,
  familyName: fseUser.family_name,
});
