import useVehiclePlaybackProviderContext from 'legacy/features/playback/state/provider/hooks/useVehiclePlaybackProviderContext';
import {
  PlaybackSlider,
  PlaybackSliderWrapperDiv,
} from 'legacy/shared/styles/custom/VehiclePlayback';

const VehiclePlaybackSlider = () => {
  const {
    actions: { setPlaybackAnimationControlsAction },
    state: {
      playbackData: { locationData },
      playbackAnimationControls: { currentPlaybackDataIndex },
    },
  } = useVehiclePlaybackProviderContext();

  return (
    <PlaybackSliderWrapperDiv>
      <PlaybackSlider
        id="timeScrubber"
        step="1"
        min="0"
        max={locationData.length - 1}
        value={currentPlaybackDataIndex}
        onChange={(e) => {
          setPlaybackAnimationControlsAction({ currentPlaybackDataIndex: +e.target.value });
        }}
      />
    </PlaybackSliderWrapperDiv>
  );
};

export default VehiclePlaybackSlider;
