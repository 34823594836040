import { useQuery } from '@tanstack/react-query';

import { getOrganization } from 'legacy/core/api/organizations';
import { logWcpError } from 'legacy/core/error/utilities/ErrorLogger';
import { organizationFetchError } from 'legacy/features/organizations/helpers/notifications';
import { organizationMapping } from 'legacy/features/organizations/helpers/organizationMapping';
import { mapToFrontend } from 'legacy/shared/utilities/helpers/mapping/mapper';
import { transformPhoneNumberToDisplay } from 'legacy/shared/utilities/helpers/transformers/transformPhoneNumberToDisplay';

export const useFetchOrganizationQuery = ({ organizationId }) => {
  try {
    return useQuery({
      queryKey: ['fetchOrganizationQuery', organizationId],
      queryFn: () => getOrganization(organizationId),
      select: (data) => {
        let mappedOrganization = mapToFrontend(data.body.message[0], organizationMapping);

        // TODO add to mapping transform
        mappedOrganization.isWhelen = mappedOrganization.organizationName === 'Whelen';
        mappedOrganization.contactPhoneDisplay = transformPhoneNumberToDisplay(
          mappedOrganization.contactPhone,
        );

        return mappedOrganization;
      },
      onError: (err) => {
        logWcpError(err);
        organizationFetchError({ err });
      },
    });
  } catch (e) {
    console.log(e);
  }
};
