import { useQuery } from '@tanstack/react-query';
import { getFirmwares } from 'legacy/core/api/firmware';
import { updateFetchError } from 'legacy/features/firmware/helpers/notifications';
import { useEffect } from 'react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FIRMWARE_REFETCH_INTERVAL } from 'legacy/shared/constants/firmwares';

import { checkLogin } from 'legacy/shared/utilities/misc/user';

const useFetchFirmwareQuery = ({
  key = '',
  autoRun = true,
  enableRefetchInterval = false,
  cachingEnabled = true,
} = {}) => {
  const dispatch = useDispatch();

  const filteredOrganizationId = useSelector((state) => state.user.filteredOrganizationId);
  const [updates, setFirmwareUpdateData] = useState(null);

  // gets firmware updates
  const fetchUpdatesQuery = useQuery({
    queryKey: ['fetchFirmwareUpdatesQuery' + key],
    queryFn: () => {
      return getFirmwares({ organizationId: filteredOrganizationId });
    },
    enabled: autoRun && checkLogin(),
    select: ({
      data: {
        message: { updates },
      },
    }) => {
      return updates;
    },
    onError: (err) => dispatch(updateFetchError({ err })),
    refetchInterval: enableRefetchInterval === false ? false : FIRMWARE_REFETCH_INTERVAL,
    cacheTime: cachingEnabled ? 5 * 60 * 1000 : 0,
  });

  useEffect(() => {
    setFirmwareUpdateData(fetchUpdatesQuery.data);
  }, [fetchUpdatesQuery.data]);

  return [fetchUpdatesQuery, updates];
};

export default useFetchFirmwareQuery;
