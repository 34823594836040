import { useNavigate } from 'react-router-dom';

import Button from 'legacy/shared/styles/custom/Button';
import { AssignCancelDiv } from 'legacy/shared/styles/custom/Form';
import ButtonWithLoader from 'legacy/shared/controls/ButtonWithLoader/ButtonWithLoader';

const UpdateRecipientGroupFormSubmit = ({ formik }) => {
  const navigate = useNavigate();
  return (
    <AssignCancelDiv>
      <>
        <Button
          type="button"
          withRightSpacer
          mediumAlt
          default
          onClick={() => navigate('/managerecipientgroups')}
        >
          Cancel
        </Button>
        <ButtonWithLoader
          isLoading={formik.isSubmitting}
          confirmText={'Update Recipient Group'}
          loadingStyleProp={'submittingWithSpinnerModal'}
          notLoadingStyleProp={'mediumAlt'}
          disabled={false}
        />
      </>
    </AssignCancelDiv>
  );
};

export default UpdateRecipientGroupFormSubmit;
