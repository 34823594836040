import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';

import { apiCall } from 'legacy/core/api/API';
import { logWcpError } from 'legacy/core/error/utilities/ErrorLogger';
import { addNotification } from 'legacy/core/redux/ui/actions';
import { createNotification, LEVELS } from 'legacy/shared/utilities/misc/notification';

const useUserActivationMutation = () => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  return useMutation({
    mutationFn: async ({ activate = true, username }) =>
      await apiCall({
        endpoint: 'cloudfrontAPI',
        path: `/user/${username}/${activate ? 'activate' : 'deactivate'}`,
        method: 'put',
      }),

    onSuccess: (_, vars) => {
      // invalidate fetch users
      queryClient.invalidateQueries('fetchOrganizationUsers');

      dispatch(
        addNotification({
          notification: createNotification(
            LEVELS.SUCCESS,
            `User ${vars.activate ? 'Activated' : 'Deactivated'} Successfully`,
          ),
        }),
      );
    },
    onError: (err, vars) => {
      logWcpError(err);
      dispatch(
        addNotification({
          notification: createNotification(
            LEVELS.ERROR,
            `Error ${vars.activate ? 'Activating' : 'Deactivating'} User`,
            err,
          ),
        }),
      );
    },
  });
};

export default useUserActivationMutation;
