import { getAlertsApi } from 'legacy/features/alerts/services/api/get/getAlertsApi';
import useGenericQuery from 'legacy/shared/utilities/hooks/useGenericQuery';

const useFetchAlertsQuery = () => {
  const [queryResult, queryKey] = useGenericQuery({
    queryKeyPrefix: 'getAlertsApi',
    apiFn: getAlertsApi,
  });

  return [queryResult.data, queryResult.isFetching, queryKey];
};

export default useFetchAlertsQuery;
