import colors from 'legacy/shared/constants/colors';
// const values
const vehicleLockStatus = {
  LOCKED: 'LOCKED',
  UNLOCKED: 'UNLOCKED',
  NA: 'NA',
  UNAVAILABLE: 'UNAVAILABLE',
};

const vehicleOnlineStatus = {
  ACTIVE: 'ACTIVE',
  INACTIVE: 'INACTIVE',
};

const vehicleRespondingStatus = {
  RESPONDING: 'RESPONDING',
  NOT_RESPONDING: 'NOT_RESPONDING',
};

const vehicleType = {
  AMBULANCE: 'AMBULANCE',
  FIRE: 'FIRE',
  POLICE: 'POLICE',
  TOW: 'TOW',
  DOT: 'DOT',
};

// exported view models
const viewModelFunctions = {
  generateOptions() {
    return Object.keys(this)
      .filter((k) => this[k].title)
      .map((k) => ({ label: this[k].title, value: this[k].id }));
  },
  generateCheckboxes(initValue) {
    return {
      ...Object.keys(this)
        .filter((k) => this[k].title)
        .map((k) => ({
          id: k,
          dom_id: `cb_${this[k].id.toLowerCase()}`,
          title: this[k].title,
          checked: initValue,
        })),
    };
  },
};

export const vehicleOnlineStatusViewData = {
  ...viewModelFunctions,
  [vehicleOnlineStatus.ACTIVE]: {
    id: vehicleOnlineStatus.ACTIVE,
    title: 'Online',
    style: `
      text-transform: uppercase;
      color: ${colors.midnight};

      &:before {
        background: ${colors.midnight};
      }
    `,
  },
  [vehicleOnlineStatus.INACTIVE]: {
    id: vehicleOnlineStatus.INACTIVE,
    title: 'Offline',
    style: `
      text-transform: uppercase;
      color: #8a909c;

      &:before {
        border: 2px solid #8a909c;
        background: transparent;
      }
    `,
  },
};

export const vehicleRespondingStatusViewData = {
  ...viewModelFunctions,
  [vehicleRespondingStatus.RESPONDING]: {
    id: vehicleRespondingStatus.RESPONDING,
    title: 'Responding',
    style: `
      color: ${colors.cherry};
      text-transform: uppercase;

      &:before {
        background: ${colors.cherry};
      }
    `,
  },
  [vehicleRespondingStatus.NOT_RESPONDING]: {
    id: vehicleRespondingStatus.NOT_RESPONDING,
    title: 'Not Responding',
  },
};

export const vehicleLockStatusViewData = {
  ...viewModelFunctions,
  [vehicleLockStatus.LOCKED]: {
    id: vehicleLockStatus.LOCKED,
    title: 'Locked',
    commandTitle: 'Unlock',
  },
  [vehicleLockStatus.UNLOCKED]: {
    id: vehicleLockStatus.UNLOCKED,
    title: 'Unlocked',
    commandTitle: 'Lock',
  },

  [vehicleLockStatus.NA]: {
    id: vehicleLockStatus.NA,
    title: 'N/A',
  },
  [vehicleLockStatus.UNAVAILABLE]: {
    id: vehicleLockStatus.UNAVAILABLE,
    title: 'Unavailable',
  },
};

export const vehicleTypeViewData = {
  ...viewModelFunctions,
  [vehicleType.AMBULANCE]: {
    id: vehicleType.AMBULANCE,
    title: 'Ambulance',
    icon: 'car4',
  },
  [vehicleType.FIRE]: {
    id: vehicleType.FIRE,
    title: 'Fire Truck',
    icon: 'car5',
  },
  [vehicleType.POLICE]: {
    id: vehicleType.POLICE,
    title: 'Law Enforcement',
    icon: 'car3',
  },
  [vehicleType.TOW]: {
    id: vehicleType.TOW,
    title: 'Tow Truck',
    icon: 'car2',
  },
  [vehicleType.DOT]: {
    id: vehicleType.DOT,
    title: 'DOT',
    icon: 'car1',
  },
};

export const haasPublicAlertingData = {
  ALWAYS_ON: 'ALWAYS_ON',
  ALWAYS_OFF: 'ALWAYS_OFF',
  ADDON_CONFIGURED: 'ADDON_CONFIGURED',
};

export const haasPublicAlertingDropdownOptions = [
  { value: haasPublicAlertingData.ALWAYS_ON, label: 'Always On' },
  { value: haasPublicAlertingData.ALWAYS_OFF, label: 'Always Off' },
  { value: haasPublicAlertingData.ADDON_CONFIGURED, label: 'Add-on Configured' },
];
