export const vehiclePlaybackAddonsMapping = {
  alert_level: {
    frontendProperty: 'alertLevel',
  },
  type: {
    frontendProperty: 'type',
  },
  is_active: {
    frontendProperty: 'isActive',
  },

  decorateFrontend: (addon) => {
    // some data is missing the isActive property coming from the backend, in this case we need to default it to true based on CChevalier's account (when it was true it was
    addon.isActive = addon.isActive !== undefined ? addon.isActive : true;
    return addon;
  },
};
