export const mapDevicesAndBillingDataToCsv = ({ deviceSubscriptions }) => {
  const devicesForExport = deviceSubscriptions.map((ds) => ({
    Device_ID: ds.decorated.formattedDeviceId,
    Associated_Vehicle: ds.vehicleLabel,
    //Ship_Date: ds.decorated.formattedShipDate,
    Start_Date: ds.decorated.formattedStartDate,
    End_Date: ds.decorated.formattedEndDate,
    Subscription_Type: ds.subscriptionType,
    Cell_Network: ds.cellNetworkType,
  }));
  return devicesForExport;
};
