import { useEffect } from 'react';

import { alertRecipientMapping } from 'legacy/features/alerts/services/mapping/definitions/alertRecipientMapping';
import useAlertFormsProviderContext from 'legacy/features/alerts/services/providers/AlertFormsProvider/hooks/useAlertFormsProviderContext';
import useFetchRecipientGroupByIdQuery from 'legacy/features/recipientGroups/hooks/queries/useFetchRecipientGroupByIdQuery';
import { mapToFrontend } from 'legacy/shared/utilities/helpers/mapping/mapper';

const useFetchRecipientGroupMembers = ({ alertNotification, recipientRow }) => {
  let { alertNotificationGuid } = alertNotification;
  let { recipientRowGuid } = recipientRow;

  let originalRecipientGroupId = alertNotification.recipientGroup?.recipientGroupId;
  let recipientRowRecipientGroupId = recipientRow?.recipientGroup?.recipientGroupId;

  const {
    actions: { setGroupMembersAction },
  } = useAlertFormsProviderContext();

  // set up query to get recipient group members
  const [groupRecipientQueryData, refetchGroupRecipients] = useFetchRecipientGroupByIdQuery({
    recipientRowGuid: recipientRowGuid,
    recipientGroupId: recipientRowRecipientGroupId,
    autoRun: false, // only runs on refetch (i.e., when useEffect triggers refetchGroupRecipients() below)
  });

  let fetchedRecipientGroupMembers = groupRecipientQueryData?.recipientGroupMembers.map((rgm, i) =>
    mapToFrontend(
      {
        username: rgm.username,
        given_name: rgm.firstName,
        family_name: rgm.lastName,
        // newly fetched group members do not have alert methods yet
        email: null,
        sms: null,
      },
      alertRecipientMapping,
    ),
  );

  // if and only if the recipient group id has changed, refetch the group members
  useEffect(() => {
    if (recipientRowRecipientGroupId && recipientRowRecipientGroupId !== originalRecipientGroupId) {
      console.log(`refetching with recipientRowRecipientGroupId: ${recipientRowRecipientGroupId}`);
      refetchGroupRecipients();
    }
  }, [recipientRowRecipientGroupId]);

  // if the recipient group members have been fetched, map them to the frontend
  useEffect(() => {
    // check if recipient group members have been fetched
    if (recipientRowRecipientGroupId && fetchedRecipientGroupMembers) {
      // update the recipient group members for this recipient row in the state
      setGroupMembersAction({
        alertNotificationGuid,
        recipientRowGuid,
        fetchedRecipientGroupMembers,
      });
    }
  }, [groupRecipientQueryData]);
};

export default useFetchRecipientGroupMembers;
