import PartnershipsActionMenu from 'legacy/features/integrations/ui/menus/PartnershipsActionMenu';
import UniversalApiActionMenu from 'legacy/features/integrations/ui/menus/UniversalApiActionMenu';

export const universalApiTableColumns = [
  {
    title: 'Key Name',
    displayProperty: 'keyName',
    className: 'name',
    width: '15%',
  },
  {
    title: 'Key Type',
    displayProperty: 'keyType',
    width: '15%',
  },

  {
    title: 'Permissions',
    displayProperty: 'permissions',
    width: '15%',
  },
  {
    title: 'Created By',
    displayProperty: 'createdBy',
    width: '15%',
  },
  {
    title: 'Created On',
    displayProperty: 'formattedTime',
    sortProperty: 'timestamp',
    width: '30%',
  },

  {
    title: '',
    className: 'action',
    searchEnabled: false,
    key: 'a',
    render({ rowData }) {
      return (
        <UniversalApiActionMenu
          apiKey={rowData}
          id={'universalApiActionMenu' + rowData.rowClassName}
        />
      );
    },
  },
];
