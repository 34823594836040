import WcpCheckbox from 'legacy/shared/controls/WcpCheckbox/v1/WcpCheckbox';
import WcpCheckboxField from 'legacy/shared/controls/WcpForm/v2/components/fields/WcpCheckboxField';
import useWcpFormContext from 'legacy/shared/controls/WcpForm/v2/hooks/useWcpFormContext';
import { OptInFeatureWrapper } from 'legacy/shared/styles/custom/Form';
import { useCheckUserRole, userRoleKeys } from 'legacy/shared/utilities/hooks/useCheckUserRole';

const ThruGreenIntegrationCheckbox = () => {
  const isUserRoleAuthorized = useCheckUserRole(userRoleKeys.FIELD_SOLUTION_ENGINEER);

  return (
    <>
      <OptInFeatureWrapper>
        <WcpCheckboxField
          dataKey={'thruGreenEnabled'}
          label="Enable ThruGreen Integration"
          disabled={!isUserRoleAuthorized}
        />
      </OptInFeatureWrapper>
    </>
  );
};

export default ThruGreenIntegrationCheckbox;
