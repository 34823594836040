import { useEffect, useState } from 'react';

import PermissionProtectedElement from 'legacy/core/components/authorization/PermissionProtectedElement';
import { useFetchGroupsQuery } from 'legacy/features/vehicleGroups/hooks/v1/useFetchGroupsQuery';
import CreateGroupButton from 'legacy/features/vehicleGroups/ui/buttons/CreateGroupButton';
import { groupsListComponentTableColumns } from 'legacy/features/vehicleGroups/ui/GroupsListComponentTableColumns';
import ListingCount from 'legacy/shared/controls/ListingCount/ListingCount';
import { permissionData } from 'legacy/shared/constants/users';
import PageListWrapper, { PageListHead, PageListTitle } from 'legacy/shared/styles/custom/PageList';
import LoadingOverlay from 'legacy/shared/controls/WcpSpinner/LoadingOverlay';
import WcpTable from 'legacy/shared/controls/WcpTable/v2/WcpTable';

const GroupsListComponent = (props) => {
  const [fetchGroupsQuery, groups] = useFetchGroupsQuery();
  const [listingCount, setListingCount] = useState(groups?.length);

  useEffect(() => {
    setListingCount(groups?.length);
  }, [groups]);
  let groupTableData = groups?.map((group) => {
    return {
      groupName: group.group_name,
      groupId: group.group_id,
      vehicleCount: group.vehicle_count.toString(),
      organizationId: group.organization_id,
      vehicleId: group.vehicle_id,
    };
  });

  return fetchGroupsQuery.isFetched && groups ? (
    <>
      <PageListWrapper>
        <PageListHead>
          <PageListTitle>Vehicle Groups</PageListTitle>
          <PermissionProtectedElement requiredPermissions={[permissionData.editfleet]}>
            <CreateGroupButton />
          </PermissionProtectedElement>
        </PageListHead>

        <ListingCount
          listingCount={listingCount}
          totalCount={groups?.length}
          dataLabel={'Vehicle Group'}
        />

        <WcpTable
          tableClassName="table-simple"
          rowClassName={(record, i) => `row-${i}`}
          tableSourceData={groupTableData ? groupTableData : []}
          columns={groupsListComponentTableColumns}
          rowKey={(record, i) => i}
          setListingCount={setListingCount}
          emptyText="No groups are currently associated with this organization"
        />
      </PageListWrapper>
    </>
  ) : (
    <LoadingOverlay />
  );
};

export default GroupsListComponent;
