import { useEffect, useState } from 'react';
import Media from 'react-media';
import { connect, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { getUsers } from 'legacy/core/api/users';
import { decorateUserObject } from 'legacy/core/decorators/user';
import { fetchOrganizationFSEs, fetchOrganizations } from 'legacy/core/redux/organizations/actions';
import { restorePermissions, savePermissions } from 'legacy/core/redux/permissions/actions';
import { addNotification } from 'legacy/core/redux/ui/actions';
import { receiveUsers, requestUsers } from 'legacy/core/redux/users/actions';
import { OrgUserPermissionsContextApi } from 'legacy/features/organizations/helpers/context';
import PermissionsCard from 'legacy/features/permissions/components/PermissionsCard';
import PermissionsHeader from 'legacy/features/permissions/components/PermissionsHeader';
import PermissionsTable from 'legacy/features/permissions/components/PermissionsTable';
import { sortAscendingAlphaUserName } from 'legacy/features/permissions/utilities/permissions';
import {
  SYSTEM_ADMIN,
  userRoleOptions as userRoles,
  variableScopeAllowedUserRoles,
} from 'legacy/shared/constants/users';
import ConfirmationModal from 'legacy/shared/controls/WcpModal/v1/ConfirmationModal';
import ModalPortal from 'legacy/shared/controls/WcpModal/v1/ModalPortal';
import LoadingOverlay from 'legacy/shared/controls/WcpSpinner/LoadingOverlay';
import { Cards } from 'legacy/shared/styles/custom/MobileCard';
import PageListWrapper, { PageListHead } from 'legacy/shared/styles/custom/PageList';
import useAPIData from 'legacy/shared/utilities/hooks/useAPIData';
import { sizes } from 'legacy/shared/utilities/misc/media';
import { createNotification, LEVELS } from 'legacy/shared/utilities/misc/notification';

const OrgUserPermissionsPage = ({
  fetchOrganizations,
  fetchOrganizationFSEs,
  fetchUsers,
  savePermissions,
  restorePermissions,
  users,
  userRole,
}) => {
  const organizations = useSelector((state) => state.organizations.organizations);
  const { organizationId } = useParams();

  const organization = organizations.find(
    (organization) => organization.organizationId === decodeURIComponent(organizationId),
  );

  const organizationsLoaded = useAPIData({ fetchAPIData: fetchOrganizations });

  const [allUsers, setAllUsers] = useState([]);
  const [localUserPermissions, setLocalUserPermissions] = useState(new Map());
  const [submittingSave, setSubmittingSave] = useState(false);
  const [submittingRestoreDefaults, setSubmittingRestoreDefaults] = useState(false);
  const [confirmRestoreDefaultsModalOpen, setConfirmRestoreDefaultsModalOpen] = useState(false);

  const fetchAllUsers = async () => {
    if (organizationsLoaded) {
      const organizationId = variableScopeAllowedUserRoles.includes(userRole)
        ? organization.organizationId
        : '';
      const usersLoaded = await fetchUsers(organizationId);
      const fsesLoaded = await fetchOrganizationFSEs(organizationId);
      return usersLoaded & fsesLoaded;
    }
  };
  const initializePermissionState = () => {
    if (organization && organization.associatedFSE) {
      const organizationUsers = users.filter(
        (user) => user.organizationId === organization.organizationId,
      );

      const fses = organization.associatedFSE.map((fse) => ({ ...fse, ...{ userRole: 'FSE' } }));

      let orgUsersAndFses = organizationUsers.concat(fses);

      const filteredUsers = orgUsersAndFses.filter(
        (user) => user.userRole !== userRoles[SYSTEM_ADMIN].value,
      );
      setAllUsers(filteredUsers);

      // set local permissions state
      const localPermissionsMap = new Map();
      JSON.parse(JSON.stringify(filteredUsers)).forEach(({ username, permissions }) => {
        localPermissionsMap.set(username, permissions);
      });

      setLocalUserPermissions(new Map(localPermissionsMap));
    }
  };

  let allUsersLoaded = useAPIData({
    fetchAPIData: fetchAllUsers,
    dependencies: [organizationsLoaded],
  });

  useEffect(() => {
    initializePermissionState();
  }, [organization, users]);

  const updatelocalUserPermissions = (newPermissions) => {
    setLocalUserPermissions(new Map(newPermissions));
  };

  const handleCancelPermissionsChangesmissions = () => {
    initializePermissionState();
  };

  const handleSavePermissions = async () => {
    let userPerms = [];

    for (let [username, permissions] of localUserPermissions.entries()) {
      userPerms.push({ username, permissions });
    }

    setSubmittingSave(true);
    await savePermissions({
      organizationId: organization.organizationId,
      permissions: userPerms,
    });

    await fetchAllUsers();
    setSubmittingSave(false);
    // reload page to refresh route.js and navigation for current session
    location.reload();
  };

  const handleRestorePermissionsConfirmation = () => {
    setConfirmRestoreDefaultsModalOpen(true);
  };

  const handleRestoreDefaultPermissions = async () => {
    setSubmittingRestoreDefaults(true);
    setConfirmRestoreDefaultsModalOpen(false);
    await restorePermissions({
      organizationId: organization.organizationId,
    });
    await fetchAllUsers();
    setSubmittingRestoreDefaults(false);
    // reload page to refresh route.js and navigation for current session
    location.reload();
  };

  const onChangeViewCheckbox = (username, vPermission, ePermission) => {
    if (localUserPermissions.has(username)) {
      let permissions = localUserPermissions.get(username);
      let vEnabled = permissions.some((pName) => pName === vPermission);
      let eEnabled = permissions.some((pName) => pName === ePermission);
      if (vEnabled && eEnabled) {
        permissions = [...permissions.filter((p) => p !== ePermission)];
      }
      permissions = vEnabled
        ? [...permissions.filter((p) => p !== vPermission)]
        : [...permissions, vPermission];
      localUserPermissions.set(username, permissions);
      updatelocalUserPermissions(localUserPermissions);
    }
  };
  const onChangeEditCheckbox = (username, vPermission, ePermission) => {
    if (localUserPermissions.has(username)) {
      let permissions = localUserPermissions.get(username);
      let eEnabled = permissions.some((pName) => pName === ePermission);
      let vEnabled = permissions.some((pName) => pName === vPermission);
      permissions = eEnabled
        ? [...permissions.filter((p) => p !== ePermission)]
        : [...permissions, ePermission];
      if (!eEnabled && !vEnabled) {
        permissions = [...permissions, vPermission];
      }
      localUserPermissions.set(username, permissions);
      updatelocalUserPermissions(localUserPermissions);
    }
  };

  return organizationsLoaded && allUsersLoaded ? (
    <OrgUserPermissionsContextApi.Provider
      value={{
        updatelocalUserPermissions,
        handleSavePermissions,
        handleCancelPermissionsChangesmissions,
        handleRestoreDefaultPermissions,
        handleRestorePermissionsConfirmation,
        onChangeViewCheckbox,
        onChangeEditCheckbox,
      }}
    >
      <>
        {confirmRestoreDefaultsModalOpen && (
          <ModalPortal
            onRequestClose={() => {
              setConfirmRestoreDefaultsModalOpen(false);
            }}
          >
            <ConfirmationModal
              bodyText={<p>Are you sure you want to restore all permissions to default?</p>}
              cancelHandler={() => {
                setConfirmRestoreDefaultsModalOpen(false);
              }}
              confirmHandler={async () => {
                handleRestoreDefaultPermissions();
              }}
              cancelText={'Cancel'}
              confirmText={'Restore Permissions'}
              title={'Restore Default Permissions?'}
            />
          </ModalPortal>
        )}
        <PageListWrapper>
          <PageListHead>
            <PermissionsHeader
              organization={organization}
              submittingSave={submittingSave}
              submittingRestoreDefaults={submittingRestoreDefaults}
            />
          </PageListHead>
          <Media
            queries={{
              tablet: { maxWidth: sizes.tablet },
              mobile: { maxWidth: sizes.mobile },
            }}
          >
            {(matches) =>
              matches.tablet ? (
                <div>
                  <Cards>
                    {allUsers.sort(sortAscendingAlphaUserName).map((user) => (
                      <PermissionsCard
                        user={user}
                        userRole={userRole}
                        localUserPermissions={localUserPermissions}
                      />
                    ))}
                  </Cards>
                </div>
              ) : (
                <PermissionsTable
                  organizationUsers={allUsers}
                  localUserPermissions={localUserPermissions}
                />
              )
            }
          </Media>
        </PageListWrapper>
      </>
    </OrgUserPermissionsContextApi.Provider>
  ) : (
    <LoadingOverlay />
  );
};

// TODO - react query and react redux hooks
export default connect(
  (state) => ({
    users: state.users.users,
    userRole: state.user.user ? state.user.user.userRole : null,
  }),
  (dispatch) => ({
    fetchOrganizations: () => dispatch(fetchOrganizations()),
    fetchOrganizationFSEs: (organizationId) => dispatch(fetchOrganizationFSEs(organizationId)),
    fetchUsers: (organizationId) =>
      dispatch(
        (({ organizationId }) => {
          return async (dispatch, getState) => {
            dispatch(requestUsers());

            try {
              const { response } = await getUsers({ organizationId });
              if (response.errorMessage) throw response.errorMessage;
              dispatch(receiveUsers(response.message.map((user) => decorateUserObject({ user }))));
              return true;
            } catch (e) {
              console.log(e);
              dispatch(rejectUsers(e));
              dispatch(
                addNotification({
                  notification: createNotification(LEVELS.ERROR, 'Error Getting Users', e),
                }),
              );
              return false;
            }
          };
        })({ organizationId }),
      ),
    savePermissions: ({ organizationId, permissions }) =>
      dispatch(savePermissions({ organizationId, permissions })),
    restorePermissions: ({ organizationId }) => dispatch(restorePermissions({ organizationId })),
  }),
)(OrgUserPermissionsPage);
