import { useEffect, useState } from 'react';

import useFetchOrganizationUsersQuery from 'legacy/features/alerts/services/reactQuery/queries/useFetchOrganizationUsersQuery';
import { RecipientItemBlockDiv } from 'legacy/shared/styles/custom/RecipientGroups';
import RecipientSelectDropdown from 'legacy/features/recipientGroups/components/ManageRecipientGroupsForms/dropdowns/RecipientSelectDropdown';
import AddRecipientButton from 'legacy/features/recipientGroups/components/ManageRecipientGroupsForms/buttons/AddRecipientButton';
import RemoveRecipientUserButton from 'legacy/features/recipientGroups/components/ManageRecipientGroupsForms/buttons/RemoveRecipientUserButton';
import useMakeFormikField from 'legacy/shared/controls/WcpForm/v1/hooks/useMakeFormikField';
import IsFetchingWrapper from 'legacy/shared/controls/WcpLoadingWrapper/v2/IsFetchingWrapper';
import { FieldError } from 'legacy/shared/styles/custom/Form';

const RecipientGroupUsersContainer = ({ recipients, formik }) => {
  // get all users from organization
  const [organizationUsers, organizationUsersQueryKey] = useFetchOrganizationUsersQuery();
  const [unsavedRecipients, setUnsavedRecipients] = useState(null);

  useEffect(() => {
    setUnsavedRecipients(recipients || [null]);
  }, [recipients]);

  useMakeFormikField({
    fieldName: 'recipientGroupMembersField',
    fieldValue: unsavedRecipients,
    triggerValidation: true,
  });

  return (
    <IsFetchingWrapper queryKeys={[organizationUsersQueryKey]}>
      <>
        {unsavedRecipients?.map((user, i) => (
          <RecipientItemBlockDiv>
            <RecipientSelectDropdown
              key={i}
              index={i}
              availableUsers={
                organizationUsers?.filter(
                  (u) =>
                    u.isActive && !unsavedRecipients.map((r) => r?.username).includes(u.username),
                ) || []
              }
              selectedUser={user}
              handleUserSelect={(selectedUser) => {
                // add selected user to updatedGroupUsers if not already in list
                if (selectedUser) {
                  const userNotInList =
                    unsavedRecipients.findIndex((u) => u?.username === selectedUser.username) < 0;

                  if (userNotInList) {
                    const userFromAllUsers = organizationUsers?.find(
                      (u) => u.username === selectedUser.username,
                    );

                    unsavedRecipients[i] = userFromAllUsers;
                    setUnsavedRecipients([...unsavedRecipients]);
                  }
                }
              }}
            />
            {unsavedRecipients.some((user) => user?.username !== undefined) &&
              i === unsavedRecipients.length - 1 && (
                <AddRecipientButton
                  handleAddRecipient={() => setUnsavedRecipients([...unsavedRecipients, null])}
                />
              )}
            {unsavedRecipients.length > 1 && (
              <RemoveRecipientUserButton
                recipientUserName={user?.username}
                handleRemoveRecipientUserFromGroup={(username) => {
                  setUnsavedRecipients(unsavedRecipients?.filter((u) => u?.username !== username));
                }}
              />
            )}
          </RecipientItemBlockDiv>
        ))}
        {/* TEMP CATCH ALL VALIDATION UNTIL PRE INSTANCE VALIDATION IS REWRITTEN */}
        <FieldError>
          {(formik.touched.recipientGroupMembersField &&
            formik.errors.recipientGroupMembersField) ||
            formik.errors.duplicateUserNameErrors}
        </FieldError>
      </>
    </IsFetchingWrapper>
  );
};

export default RecipientGroupUsersContainer;
