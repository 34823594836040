import { SubscriptionAlertIconWrapper } from 'legacy/shared/styles/custom/DevicesAndBilling';
import IconSvgComponent from 'legacy/shared/controls/WcpIcon/IconSvgComponent';

export const getSubscriptionWarningIcon = (device) => {
  if (device.decorated.isExpired) {
    return (
      <SubscriptionAlertIconWrapper id="subscriptionWarningIcon">
        <IconSvgComponent
          svgFileName={'warning'}
          alt="The subscription for this device has expired"
          title="The subscription for this device has expired"
        />
      </SubscriptionAlertIconWrapper>
    );
  } else if (device.decorated.aboutToExpire) {
    return (
      <SubscriptionAlertIconWrapper id="subscriptionWarningIcon">
        <IconSvgComponent
          svgFileName={'warning-black'}
          alt="The subscription for this device will expire soon"
          title={`The subscription for this device will expire in ${
            device.decorated.monthsLeftInSubscription
          } month${device.decorated.monthsLeftInSubscription === 1 ? '' : 's'} or less`}
        />
      </SubscriptionAlertIconWrapper>
    );
  } else {
    return;
  }
};
