import CreateOrganizationUserForm from 'legacy/features/organizations/components/forms/UserForm/CreateOrganizationUserForm';

const CreateUserModalContent = ({ modalState: { organization }, modalActions: { closeModal } }) => (
  <CreateOrganizationUserForm
    organization={organization}
    handleCancel={closeModal}
    handleSubmitCompleted={closeModal}
  />
);
export default CreateUserModalContent;
