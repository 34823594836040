import { useEffect, useState } from 'react';

import { apiCall } from 'legacy/core/api/API';
import { userMapping } from 'legacy/features/users/utilities/userMapping';
import { mapToFrontend } from 'legacy/shared/utilities/helpers/mapping/mapper';
import useGenericQuery from 'legacy/shared/utilities/hooks/useGenericQuery';

// TODO replace with the one from the organization feature
const useFetchOrganizationUsersQuery = () => {
  const [queryResultData, setQueryResultData] = useState(null);

  const [queryResult, queryKey] = useGenericQuery({
    queryKeyPrefix: 'getOrganizationUsersApi',
    apiFn: async ({ organizationId }) =>
      await apiCall({
        method: 'get',
        path: `/user`,
        queryStringParameters: {
          organization_id: `${organizationId}`,
        },
      }),
  });

  useEffect(() => {
    if (!queryResult.data) return;

    setQueryResultData(
      queryResult.data?.body?.message?.map((user) => mapToFrontend(user, userMapping)),
    );
  }, [queryResult.data]);

  return [queryResultData, queryKey];
};

export default useFetchOrganizationUsersQuery;
