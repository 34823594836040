import { useQueryClient } from '@tanstack/react-query';

import useGenericMutation from 'legacy/shared/utilities/hooks/useGenericMutation';
import useDispatchErrorNotification, {
  dispatchErrorNotification,
} from 'legacy/shared/utilities/hooks/useDispatchErrorNotification';
import useDispatchSuccessNotification, {
  dispatchSuccessNotification,
} from 'legacy/shared/utilities/hooks/useDispatchSuccessNotification';
import { deleteVsgFromSubscriptionApi } from 'legacy/features/billing/api/delete/deleteVsgFromSubscriptionApi';

export const useDeleteVsgFromSubscriptionMutation = () => {
  const queryClient = useQueryClient();

  const [successToast] = useDispatchSuccessNotification();
  const [errorToast] = useDispatchErrorNotification();

  const mutation = useGenericMutation({
    apiFn: deleteVsgFromSubscriptionApi,
    onSuccessCallback: () => {
      queryClient.invalidateQueries('getSubscriptionDevicesApi');
      successToast({ message: 'Successfully Deleted VSG' });
    },

    onErrorCallback: (err) => {
      errorToast({ message: 'Error Deleting VSG' });
    },
  });

  return mutation;
};
