import { GROUP_SUFFIX } from 'legacy/features/alerts/data/enums/alertRecipientDropdown';
import useAlertFormsProviderContext from 'legacy/features/alerts/services/providers/AlertFormsProvider/hooks/useAlertFormsProviderContext';
import DropdownOptions from 'legacy/shared/utilities/classes/dropdown/DropdownOptionsLegacy';

const getDropdownOptionsForAlertRecipientDropdown = ({
  organizationUsers,
  includeRecipientGroupsInDropdown,
  allRecipientGroups,
}) => {
  let dropdownOptions =
    organizationUsers
      ?.filter((u) => u.isActive)
      ?.map((u) => ({
        label: `${u.firstName} ${u.lastName}`,
        value: u.username,
      })) || [];

  // if includeRecipientGroupsInDropdown is true, add recipient groups to the dropdown
  if (includeRecipientGroupsInDropdown && allRecipientGroups) {
    dropdownOptions.unshift(
      ...allRecipientGroups.map((g) => ({
        label: `${g.recipientGroupName}${GROUP_SUFFIX}`,
        value: g.recipientGroupName,
      })),
    );
  }

  // create new dropdown options object
  return new DropdownOptions(dropdownOptions);
};

const useAlertRecipientDropdown = ({ recipientRow }) => {
  let { isFirstRecipientRow } = recipientRow;

  const {
    state: { organizationUsers, allRecipientGroups },
  } = useAlertFormsProviderContext();

  // only include recipient groups in the dropdown if this is the first recipient row
  const includeRecipientGroupsInDropdown = isFirstRecipientRow;

  // set up dropdown options with organization users
  let dropdownOptions = getDropdownOptionsForAlertRecipientDropdown({
    organizationUsers,
    includeRecipientGroupsInDropdown,
    allRecipientGroups,
  });

  return dropdownOptions;
};

export default useAlertRecipientDropdown;
