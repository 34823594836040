import styled from 'styled-components';
import device from 'legacy/shared/constants/sizing';
import colors from 'legacy/shared/constants/colors';
import { media } from 'legacy/shared/utilities/misc/media';
import Button from 'legacy/shared/styles/custom/Button';

export const ProgressPercentageDiv = styled.div`
  display: inline;
  justify-content: flex-start;
  color: ${colors.haze};
  font-size: 13px;
`;

export const ProgressBarIcon = styled.img`
  height: 19px;
  margin-left: 5px;
  margin-right: 5px;
`;

export const Wrapper = styled.div`
  margin: 0 -13px 0px;
  padding: 10px 0 5px 0;
  font-family: Muli, sans-serif;
  font-size: 14px;
  color: #8a909c;

  &:last-child {
    border-bottom: 0 none;
  }

  .col {
    padding: 0 13px;
  }

  ${media.tablet_portrait`
		display: block;
		margin: 0 0 0px 5px;

		.col {
			padding: 0;
		}
	`};
`;
export const Content = styled.div`
  flex: 0 0 370px;

  ${media.tablet_portrait`
		flex: 1 1 100%;
	`};
`;
export const ContentInner = styled.div`
  position: relative;
  min-height: 38px;
  margin-bottom: 0px;
  @media ${device.medium} {
    margin-bottom: 10px;
  }

  ${(props) =>
    props.offsetLeft &&
    `
		padding-left: 25px;
	`}
`;
export const Actions = styled.div`
  margin-left: 25px;
  margin-top: 10px;
  flex: 0 0 auto;

  @media ${device.medium} {
    margin-left: auto;
  }
`;
export const Title = styled.h6`
  margin-bottom: 2px;
  color: ${colors.midnight};
  ${(props) =>
    props.productName &&
    `
		text-transform: uppercase;
	`}
`;
export const Text = styled.p`
  margin-bottom: 0px;
  margin-top: 2px;
  &:last-child {
    margin-bottom: 0;
  }

  ${(props) =>
    props.success &&
    `
		color: ${colors.midnight};
	`}
  ${(props) =>
    props.failed &&
    `
		color: ${colors.cherry};
    font-weight:700;
	`}
  ${(props) =>
    props.status &&
    `
    margin-top: 20px;
  `};
  ${(props) =>
    props.updateComplete &&
    `
		color: ${colors.grass};
    font-weight:700;
	`}
`;
export const Icon = styled.div`
  position: absolute;
  left: 0;
  top: 0;
`;

export const EntryButton = styled(Button)`
  ${(props) =>
    props.link &&
    `
    margin-right: 20px;
		padding: 0;
		border: 0 none;
		text-transform: none;
		font-weight: normal;
		color: #8A909C;

		&:hover {
			background: none;
			text-decoration: underline;
			color: #8A909C;
		}
	`}
  ${(props) =>
    typeof props.enabled !== 'undefined' &&
    !props.enabled &&
    `
  border-color: ${colors.haze};
  color: ${colors.haze};

  &:hover {
    background-color: transparent;
    color: ${colors.haze};
    cursor: default;
  }
  `};
`;

export const OtaSummaryItemNotes = styled.div`
  margin-right: 50px;
  margin-left: 24px;
  max-width: 700px;

  @media ${device.large} {
    margin-left: 37px;
  }
`;

export const OtaSummaryItemWrapperDiv = styled.div`
  display: flex;
  align-items: flex-start;
  margin-bottom: 10px;
  flex-direction: column;

  @media ${device.medium} {
    flex-direction: unset;
  }

  ${(props) =>
    props.firmware &&
    `
		margin-bottom: 0px;
	`}
`;

export const OtaSummaryItemByVehicleWrapperDiv = styled.div`
  display: flex;
  align-items: flex-start;
  margin-bottom: 0px;
  flex-direction: column;

  @media ${device.medium} {
    flex-direction: unset;
    margin-bottom: 10px;
  }

  ${(props) =>
    props.firmware &&
    `
		margin-bottom: 0px;
	`}
`;

export const UpdateAllWrapperDiv = styled.div`
  float: right;
`;

export const UploadDateDiv = styled.div`
  margin-bottom: 10px;
`;

export const SupportedDeviceName = styled.div`
  color: ${colors.midnight};
  font-size: 16px;
  font-weight: 700;
  margin-top: 15px;

  @media ${device.large} {
    font-size: 14px;
  }
`;

export const TableCount = styled.div`
  display: inline-block;
  margin-left: 16px;
  padding: 4px 12px;
  border-radius: 9px;
  background-color: ${colors.midnight};
  font-size: 11px;
  color: ${colors.cotton};
`;

export const TableTitle = styled.div`
  display: flex;
  align-items: center;
  font-size: 20px;
  font-weight: 700;
  color: ${colors.midnight};
  @media ${device.large} {
    font-size: 16px;
  }
`;

export const SupportedDeviceWrapperDiv = styled.div`
  max-height: 200px;
  overflow-y: scroll;
  overflow-x: hidden;
`;

export const UpdatesSummaryActionButtonsDiv = styled.div`
  display: grid;
  grid-gap: 10px;

  grid-template-areas:
    'Col'
    'Col';
  grid-template-columns: 210px;
  grid-template-rows: 38px 38px;
  @media ${device.medium} {
    display: flex;
    align-items: baseline;
    grid-gap: 0px;
    margin-top: 40px;
  }
`;

export const LogsFiltersDiv = styled.div`
  margin-bottom: 0px;
  display: grid;
  grid-template-rows: 65px 65px 65px;
  grid-template-areas:
    'FormCol'
    'FormCol'
    'FormCol';

  ${(props) =>
    props.configs &&
    `
      grid-template-rows: 65px 65px;
      grid-template-areas:
      'FormCol'
      'FormCol';
	`}

  @media ${device.large} {
    grid-template-areas: 'FormCol FormCol FormCol ';
    grid-template-columns: 200px 200px 200px;
    grid-template-rows: 30px;
    grid-gap: 20px;
    margin-bottom: 30px;
  }
`;

export const QueryAndExportDiv = styled.div`
  margin-bottom: 70px;
  display: grid;
  grid-template-rows: 150px 20px;
  grid-template-areas:
    'FormCol'
    'FormCol';

  @media ${device.largeAnalytics} {
    margin-bottom: 0px;
    grid-template-areas: 'FormCol FormCol ';
    grid-template-columns: 580px fr;
    grid-template-rows: 100px;
    grid-gap: 20px;
  }
`;

export const BoldedCount = styled.span`
  font-weight: 700;
`;

export const IconAndTitleWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  cursor: pointer;
  color: ${colors.midnight};
`;

export const CompletedCount = styled.div`
  font-weight: 500;
  text-transform: none;
`;

export const VehicleStatusDisplay = styled.span`
  font-size: 12px;
  color: ${colors.haze};

  ${(props) =>
    props.online &&
    `
      font-weight:800;
      color: ${colors.midnight};
	`}

  ${(props) =>
    props.responding &&
    `
    color: ${colors.cherry};
	`}
`;

export const ByVehicleSummaryItemWrapper = styled.div`
  display: flex;
  flex-direction: column-reverse;
  margin-bottom: 40px;

  @media ${device.medium} {
    flex-direction: column;
  }
`;

export const ByVehicleSummaryItems = styled.div`
  margin-top: 0px;
  max-width: 80%;

  @media ${device.medium} {
    margin-top: -50px;
  }
`;

export const SearchAndCollapseAllWrapper = styled.div`
  margin-bottom: 40px;
  display: flex;
  justify-content: space-between;

  @media ${device.medium} {
    flex-direction: unset;
  }
`;
export const SeachAndFilterWrapper = styled.div`
  width: 250px;
  margin-bottom: 0px;
  display: grid;
  grid-template-rows: max-content max-content;
  grid-template-areas:
    'FormCol'
    'FormCol';

  @media ${device.large} {
    width: 600px;
    grid-template-areas: 'FormCol FormCol';
    grid-template-columns: max-content max-content;
    grid-template-rows: 25px;
    grid-gap: 20px;
  }
`;

export const OnlyShowPendingUpdatesCheckboxWrapper = styled.div`
  margin-top: 10px;
`;

export const UpdateCount = styled.div`
  margin-bottom: 40px;
  display: flex;
  justify-content: space-between;

  @media ${device.medium} {
    flex-direction: unset;
  }
`;

export const NonMobileDetail = styled.div`
  display: none;

  @media ${device.medium} {
    display: block;
  }
`;

export const ConfigurationActionButtonsDiv = styled.div`
  display: grid;
  grid-gap: 10px;
  margin-bottom: 20px;

  grid-template-areas:
    'Col'
    'Col';
  grid-template-columns: 252px;
  grid-template-rows: 38px 38px;
  @media ${device.medium} {
    display: flex;
    align-items: baseline;
    grid-gap: 10px;
    margin-bottom: 40px;
  }
`;
