import { useSelector } from 'react-redux';

import { getAvailableRoleOptionsForUserCreation } from 'legacy/shared/constants/users';
import WcpFilteredDropdownField from 'legacy/shared/controls/WcpForm/v2/components/fields/WcpFilteredDropdownField';
import WcpReadOnlyFormLabel from 'legacy/shared/controls/WcpForm/v2/components/fields/WcpReadOnlyFormLabel';
import useWcpFormContext from 'legacy/shared/controls/WcpForm/v2/hooks/useWcpFormContext';
import DropdownOptions from 'legacy/shared/utilities/classes/dropdown/DropdownOptions';

const UserRoleField = ({ organization, readOnly = false }) => {
  if (!readOnly && !organization) {
    throw new Error('Organization is required when readOnly is false');
  }

  const { formValues } = useWcpFormContext();

  const sessionUserRole = useSelector((state) => state.user.user.userRole);

  const dropdownOptions = readOnly
    ? null
    : new DropdownOptions(
        getAvailableRoleOptionsForUserCreation({
          organization,
          sessionUserRole,
        }),
      );

  return readOnly ? (
    <WcpReadOnlyFormLabel label={'Role'} value={formValues.userRole} />
  ) : (
    <WcpFilteredDropdownField
      dataKey={'userRole'}
      fieldLabel={'Role'}
      placeholderLabel={'Select a Role...'}
      setup={{
        dropdownOptions,
      }}
      behaviorOptions={{ disableSearch: true }}
      styleOptions={{
        fullWidth: true,
        required: true,
        showClearSelectionButton: false,
      }}
    />
  );
};

export default UserRoleField;
