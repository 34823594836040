import { customerTypeDropdownOptions } from 'legacy/shared/constants/organizations';
import WcpFilteredDropdownField from 'legacy/shared/controls/WcpForm/v2/components/fields/WcpFilteredDropdownField';
import useWcpFormContext from 'legacy/shared/controls/WcpForm/v2/hooks/useWcpFormContext';

const CustomerTypeDropdown = () => {
  const { setFormValues } = useWcpFormContext();

  return (
    <WcpFilteredDropdownField
      dataKey={'customerType'}
      fieldLabel={'Customer Type'}
      placeholderLabel={'Select a Customer Type...'}
      setup={{
        dropdownOptions: customerTypeDropdownOptions,
      }}
      eventHandlers={{
        handleSelect: (selectedOption) => {
          setFormValues({ customerType: selectedOption.value });
        },
      }}
      styleOptions={{
        fullWidth: true,
        required: true,
        showClearSelectionButton: false,
      }}
    />
  );
};

export default CustomerTypeDropdown;
