import { apiCall } from 'legacy/core/api/API';
import { mapRecipientDataToFrontend } from 'legacy/features/recipientGroups/utilities/recipientGroupMapping';
import useGenericQuery from 'legacy/shared/utilities/hooks/useGenericQuery';

const useFetchRecipientGroupByIdQuery = ({ recipientGroupId, autoRun = true }) => {
  const [queryResult, queryKey] = useGenericQuery({
    queryKeyPrefix: 'getRecipientGroupByIdApi',
    autoRun,
    apiFn: async ({ organizationId, recipientGroupId }) => {
      const apiResponse = await apiCall({
        method: 'get',
        path: `/user_group/${recipientGroupId}`,
        queryStringParameters: {
          organization_id: `${organizationId}`,
        },
      });

      const recipientGroup = mapRecipientDataToFrontend(apiResponse.data.message);

      return recipientGroup;
    },
    params: { recipientGroupId },
  });

  return [queryResult.data, queryResult.refetch, queryKey];
};

export default useFetchRecipientGroupByIdQuery;
