import React, { createContext, useState } from 'react';

import GeofencesMapboxComponent from 'legacy/features/geofences/geofences/components/map/GeofencesMapboxComponent';
import GeofencesSidebarContainer from 'legacy/features/geofences/geofences/components/sidebar/GeofencesSidebarContainer';
import { DrawMode, GeofencesDrawHint, GotItButton } from 'legacy/shared/styles/custom/Geofences';
import {
  HeaderDiv,
  HeaderText,
  MapHeaderItem,
  MapHeaderItemsDiv,
  SharedMapDiv,
  SidebarAndContentDiv,
} from 'legacy/shared/styles/custom/SharedMapStyledComponents';
import {
  getHideGeofencesDrawHintInLocal,
  setHideGeofencesDrawHintInLocal,
} from 'legacy/shared/utilities/misc/localStore';

export const GeofencesApi = createContext(null);

const GeofencesContainer = ({
  currentOrganizationId,
  geofencesData,
  intersectionsData,
  reloadGeofencesData,
}) => {
  const [geofencesSidebarOpen, setGeofencesSidebarOpen] = useState(true);
  const [hideGeofenceHint, setHideGeofenceHint] = useState(getHideGeofencesDrawHintInLocal());
  const [selectedGeofenceId, setSelectedGeofenceId] = useState({ id: null, selectOrigin: null });

  const dismissHint = () => {
    setHideGeofencesDrawHintInLocal(true);
    setHideGeofenceHint(true);
  };
  const [drawPolygon, setDrawPolygon] = useState(false);

  const handleSelectGeofence = ({ id, selectOrigin }) => {
    setSelectedGeofenceId((p) => ({ id, selectOrigin }));
  };

  return (
    <GeofencesApi.Provider
      value={{
        setGeofencesSidebarOpen,
        reloadGeofencesData,
        handleSelectGeofence,
        selectedGeofenceId,
        drawPolygon,
        setDrawPolygon,
      }}
    >
      <SharedMapDiv>
        <HeaderDiv>
          <MapHeaderItemsDiv>
            <MapHeaderItem textCentered>
              <HeaderText>
                {geofencesData && <>{geofencesData.length} of 200 Geofences</>}
              </HeaderText>
            </MapHeaderItem>
          </MapHeaderItemsDiv>
        </HeaderDiv>
        <SidebarAndContentDiv>
          <GeofencesSidebarContainer
            isOpen={geofencesSidebarOpen}
            geofencesData={geofencesData}
            intersectionsData={intersectionsData}
          />
          <GeofencesMapboxComponent
            currentOrganizationId={currentOrganizationId}
            geofencesData={geofencesData}
          />
          <GeofencesDrawHint hideGeofenceHint={hideGeofenceHint}>
            Create New Geofences Here -&gt;
            <GotItButton
              title="Don't show me this hint again"
              type="button"
              playbackCard
              onClick={() => {
                dismissHint();
              }}
            >
              Got it!
            </GotItButton>
          </GeofencesDrawHint>
          {drawPolygon && <DrawMode>GEOFENCE DRAW MODE</DrawMode>}
        </SidebarAndContentDiv>
      </SharedMapDiv>
    </GeofencesApi.Provider>
  );
};

export default GeofencesContainer;
