import styled from 'styled-components';

import colors from 'legacy/shared/constants/colors';
import device from 'legacy/shared/constants/sizing';
import addonLowAlert from 'assets/svg/addonLowAlert.svg';
import addonMediumAlert from 'assets/svg/addonMediumAlert.svg';
import addonHighAlert from 'assets/svg/addonHighAlert.svg';

export const AddonFeatureDiv = styled.div`
  display: grid;
  margin-bottom: 65px;
  grid-template-areas:
    'Row'
    'Row'
    'Row'
    'Row';
  grid-template-columns: 1fr;
  grid-template-rows: 80px 80px 50px 30px;
  @media ${device.large} {
    height: 25px;
    margin-bottom: 25px;
    grid-template-areas: 'Col Col Col';
    grid-template-columns: 280px 150px 220px;
  }
`;

export const FeatureTypeDiv = styled.div`
  width: 330px;
  @media ${device.medium} {
    width: 500px;
  }
  @media ${device.large} {
    width: 280px;
  }
`;

export const FeatureDropDownDiv = styled.div`
  margin-top: 30px;
  margin-bottom: 50px;

  @media ${device.large} {
    margin-top: 20px;
    margin-bottom: 30px;
  }
`;

export const AddonFeaturesDiv = styled.div`
  margin-top: 30px;
  margin-bottom: 50px;
`;

export const VsgInputSelectionDiv = styled.div`
  width: 200px;
  @media ${device.large} {
    width: 120px;
  }
`;

export const CheckboxTextSpan = styled.span`
  margin-left: 10px;
  font-size: 22px;

  ${(props) =>
    props.addonsFilter &&
    `
    font-size: 16px;
    margin-left: 5px;
    `};
  @media ${device.large} {
    font-size: 16px;
  }
`;

export const VehicleListDiv = styled.div`
  margin-top: 10px;
  margin-left: 5px;
`;

export const VehicleListingDiv = styled.div`
  margin-bottom: 3px;
  margin-left: 5px;
`;

export const BaseVehicleDiv = styled.div`
  margin-top: 30px;
  margin-bottom: 30px;
`;

export const SelectAllVehiclesDiv = styled.div`
  display: flex;
`;
export const SelectAllCheckboxDiv = styled.div`
  margin-left: 15px;
  margin-top: -3px;
  @media ${device.large} {
    margin-left: 15px;
    margin-top: 3px;
  }
`;

export const ServerErrorDiv = styled.div`
  font-size: 18px;
  font-weight: 700;
  color: ${colors.cherry};
  margin-bottom: 10px;
`;

export const AlertSelectionDiv = styled.div`
  width: 200px;
  @media ${device.large} {
    width: 120px;
  }
`;

export const AlertLevelOuterWrapperDiv = styled.div`
  width: 300px;
  height: 60px;
  display: grid;
  grid-template-areas: 'Col Col Col';
  grid-template-columns: 200px 30px 30px;
  grid-gap: 10px;
  @media ${device.large} {
    grid-template-columns: 120px 30px 30px;
    width: 220px;
  }
`;

export const AlertIcon = styled.div`
  width: 25px;
  height: 25px;
  margin-top: 7px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  @media ${device.large} {
    margin-top: 0px;
  }
  ${(props) =>
    props.Low &&
    `
    background-image: url(${addonLowAlert});
  `}
  ${(props) =>
    props.Medium &&
    `
    background-image: url(${addonMediumAlert});
  `}
   ${(props) =>
    props.High &&
    `
    background-image: url(${addonHighAlert});
  `}
`;

export const MobileRemoveLineItemButtonWrapper = styled.div`
  display: block;

  ${(props) =>
    props.recipientGroupPage &&
    `
    margin-bottom:30px;
  `}
  @media ${device.large} {
    display: none;
  }
`;
