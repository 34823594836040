import { vehiclePlaybackDateRangeValidators } from 'legacy/features/playback/state/formik/validation/vehiclePlaybackDateRangeValidators';
import {
  addHoursToDateTime,
  getHoursSince,
  getShortDateInIsoFormat,
} from 'legacy/shared/utilities/misc/time';

const isValidTimeRange = ({
  startDateField,
  endDateField,
  startTimeField,
  endTimeField,
  selectedGranularity,
}) => {
  // Perform validation on date range
  let errorMessage = null;

  const startDate = getShortDateInIsoFormat(startDateField);
  const endDate = getShortDateInIsoFormat(endDateField);

  if (startDateField && endDateField) {
    const startDateTime = addHoursToDateTime({
      dateTime: startDate,
      hours: startTimeField,
    });
    const endDateTime = addHoursToDateTime({
      dateTime: endDate,
      hours: endTimeField,
    });

    const differenceInHours = getHoursSince({ endDateTime, startDateTime });

    if (endDateTime < startDateTime) {
      errorMessage = 'End time must be after start time';
    } else if (selectedGranularity < 5 && differenceInHours > 6) {
      errorMessage = 'Please choose a time range of 6 hours or less';
    } else if (differenceInHours > 24) {
      errorMessage = 'Please choose a time range of 24 hours or less';
    }
  }

  return errorMessage;
};

// This function is used to validate the form for the vehicle playback request
export const validateVehiclePlaybackRequestForm = (values) => {
  const {
    startDateField,
    endDateField,
    startTimeField,
    endTimeField,
    selectedVehicleId,
    selectedGranularity,
  } = values;
  const timeRangeErrors = isValidTimeRange({
    startDateField,
    endDateField,
    startTimeField,
    endTimeField,
    selectedGranularity,
  });

  // Get other errors

  const startDateErrors = vehiclePlaybackDateRangeValidators.startDate(startDateField);
  const endDateErrors = vehiclePlaybackDateRangeValidators.endDate(endDateField);
  const endDateBeforeStartDateError = vehiclePlaybackDateRangeValidators.endDateBeforeStartDate(
    startDateField,
    endDateField,
  );
  const endDateWithinOneDayOfStartDateError =
    vehiclePlaybackDateRangeValidators.endDateWithinOneDayOfStartDate(startDateField, endDateField);
  const startDateAfterAnalyticsLaunchError =
    vehiclePlaybackDateRangeValidators.startDateAfterAnalyticsLaunch(startDateField);

  const selectedVehicleIdErrors = selectedVehicleId ? null : 'Vehicle ID is required';

  let errors = {
    ...(timeRangeErrors ? { selectedGranularity: timeRangeErrors } : {}),
    ...(startDateErrors ? { startDateField: startDateErrors } : {}),
    ...(endDateErrors ? { endDateField: endDateErrors } : {}),
    ...(endDateBeforeStartDateError ? { endDateField: endDateBeforeStartDateError } : {}),
    ...(endDateWithinOneDayOfStartDateError
      ? { endDateField: endDateWithinOneDayOfStartDateError }
      : {}),
    ...(startDateAfterAnalyticsLaunchError
      ? { startDateField: startDateAfterAnalyticsLaunchError }
      : {}),
    ...(selectedVehicleIdErrors ? { selectedVehicleId: selectedVehicleIdErrors } : {}),
  };

  return errors;
};
