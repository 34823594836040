import OptOutOfEmailsCheckbox from 'legacy/features/organizations/components/forms/UserForm/checkboxes/OptOutOfEmailsCheckbox';
import ResetUserCheckbox from 'legacy/features/organizations/components/forms/UserForm/checkboxes/ResetUserCheckbox';
import UserAvatarField from 'legacy/features/organizations/components/forms/UserForm/custom/UserAvatarField';
import UserRoleField from 'legacy/features/organizations/components/forms/UserForm/dropdowns/UserRoleField';
import FirstNameField from 'legacy/features/organizations/components/forms/UserForm/inputs/FirstNameField';
import LastNameField from 'legacy/features/organizations/components/forms/UserForm/inputs/LastNameField';
import UserEmailField from 'legacy/features/organizations/components/forms/UserForm/inputs/UserEmailField';
import UsernameField from 'legacy/features/organizations/components/forms/UserForm/inputs/UsernameField';
import UserPhoneNumberField from 'legacy/features/organizations/components/forms/UserForm/inputs/UserPhoneNumberField';
import UserTitleField from 'legacy/features/organizations/components/forms/UserForm/inputs/UserTitleField';
import { userValidationSchema } from 'legacy/features/organizations/components/forms/UserForm/validation/userValidationSchema';
import useUpdateUserMutation from 'legacy/features/users/api/mutations/useUpdateUserMutation';
import {
  StyledDivFormCol,
  StyledDivFormGridRow,
  StyledDivFormRow,
} from 'legacy/shared/controls/WcpForm/v2/styles/WcpFormStyles';
import WcpForm from 'legacy/shared/controls/WcpForm/v2/WcpForm';

const EditOrganizationUserForm = ({ user, handleCancel, handleSubmitCompleted }) => {
  const updateUserMutation = useUpdateUserMutation();

  const handleSubmit = async ({ formValues }) => {
    await updateUserMutation.mutateAsync({ user: formValues });
    handleSubmitCompleted();
  };

  return (
    <WcpForm
      formData={user}
      handleSubmit={handleSubmit}
      handleCancel={handleCancel}
      validationSchema={userValidationSchema}
      submitButtonText="Edit User"
      showFormHeader={false}
    >
      <StyledDivFormGridRow>
        <StyledDivFormCol>
          <StyledDivFormRow>
            <UserAvatarField />
          </StyledDivFormRow>
        </StyledDivFormCol>

        <StyledDivFormCol>
          <UsernameField readOnly={true} />
          <FirstNameField />
          <LastNameField />
          <UserEmailField />
          <UserPhoneNumberField />
          <UserTitleField />
          <UserRoleField readOnly={true} />
          <OptOutOfEmailsCheckbox />
          <ResetUserCheckbox />
        </StyledDivFormCol>
      </StyledDivFormGridRow>
    </WcpForm>
  );
};

export default EditOrganizationUserForm;
