import { useState, useEffect } from 'react';
import { SimpleDropdownItem } from 'legacy/shared/styles/custom/DropdownMenu';
import ActionMenu from 'legacy/shared/controls/WcpTable/v1/ActionMenu';
import ViewDeviceLogsMenuItem from 'legacy/features/billing/ui/components/DevicesAndBillingSummary/menus/ViewDeviceLogsMenuItem';
import { useCheckUserRole, userRoleKeys } from 'legacy/shared/utilities/hooks/useCheckUserRole';
import PermanentlyRemoveVsgMenuItem from 'legacy/features/billing/ui/components/DevicesAndBillingSummary/menus/PermanentlyRemoveVsgMenuItem';
import PermanentlyRemoveVsgModal from 'legacy/features/billing/ui/components/DevicesAndBillingSummary/modals/PermanentlyRemoveVsgModal';

const DevicesAndBillingActionMenu = ({ vsgDuid, formattedDeviceId }) => {
  const [permanentlyRemoveVsgModalData, setPermanentlyRemoveVsgModalData] = useState({
    show: false,
  });

  useEffect(() => {
    setPermanentlyRemoveVsgModalData({
      show: false,
      vsgDuid: vsgDuid,
      formattedDeviceId: formattedDeviceId,
    });
  }, [vsgDuid]);
  const isUserRoleAuthorized = useCheckUserRole(
    userRoleKeys.SYSTEM_ADMIN,
    userRoleKeys.FIELD_SOLUTION_ENGINEER,
  );
  return (
    <>
      <ActionMenu>
        <SimpleDropdownItem>
          <ViewDeviceLogsMenuItem vsgDuid={vsgDuid} id="viewDeviceLogsMenuItem" />
        </SimpleDropdownItem>
        {isUserRoleAuthorized && (
          <SimpleDropdownItem>
            <PermanentlyRemoveVsgMenuItem
              id="permanentlyRemoveVsgMenuItem"
              onMenuItemClick={() =>
                setPermanentlyRemoveVsgModalData((prevState) => ({ ...prevState, show: true }))
              }
            />
          </SimpleDropdownItem>
        )}
      </ActionMenu>
      <>
        <PermanentlyRemoveVsgModal
          modalData={permanentlyRemoveVsgModalData}
          handleCloseModal={() =>
            setPermanentlyRemoveVsgModalData((prevState) => ({ ...prevState, show: false }))
          }
        />
      </>
    </>
  );
};

export default DevicesAndBillingActionMenu;
