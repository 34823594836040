import { whelenGpsCoordinates } from 'legacy/shared/constants/map';

// gets the map bounds
const useGetVehicleMapBounds = ({ vehicles }) => {
  // if no vehicles presented, return the default coordinates
  if (!vehicles.length) return whelenGpsCoordinates;

  // extract latitude and longitude arrays from the vehicles array
  const [latitudes, longitudes] = vehicles.reduce(
    ([lat, long], v) => [
      [...lat, v.gps.latitude],
      [...long, v.gps.longitude],
    ],
    [[], []],
  );

  // get the min/max latitudes and longitudes
  return [
    [Math.min(...longitudes), Math.min(...latitudes)],
    [Math.max(...longitudes), Math.max(...latitudes)],
  ];
};

export default useGetVehicleMapBounds;
