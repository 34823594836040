import colors from 'legacy/shared/constants/colors';
import device from 'legacy/shared/constants/sizing';
import theme from 'legacy/shared/styles/global/theme';
import styled from 'styled-components';
export const StyledDivReadOnlyFormLabel = styled.div`
  margin-bottom: 45px;

  height: 16px;
  font-family: ${theme.font};
  font-size: 22px;
  font-weight: 700;
  color: ${colors.midnight};

  @media ${device.large} {
    margin-top: 12px;
    font-size: 14px;
    margin-bottom: 25px;
  }
`;
export const StyledLabelAnimated = styled.label`
  display: block;
  position: relative;
  left: 0;
  top: 0;
  margin-bottom: -14px;
  width: 100%;
  height: 16px;
  font-family: ${theme.font};
  font-size: 24px;
  font-weight: 400;
  color: ${colors.haze};
  pointer-events: none;
  transition:
    top 0.3s,
    font-size 0.3s;

  @media ${device.large} {
    font-size: 14px;
    ${(props) =>
      props.performLabelTransition &&
      `
    font-size: 11px;
    top: -${props.offset || 15}px;
	`};
  }

  ${(props) =>
    props.performLabelTransition &&
    `
    font-size: 15px;
    top: -${props.offset || 15}px;
	`};
`;

export const StyledDivFormField = styled.div`
  width: 100%;
  margin-bottom: 10px;
  ${(props) =>
    props.halfWidth &&
    `
    width: 50%;
    display: inline-block;
    vertical-align: top;
    margin-bottom: 10px;
    `};
`;

export const StyledInputText = styled.input.attrs(() => ({
  type: 'text',
}))`
  display: block;
  position: relative;
  width: 100%;
  padding: 5px 8px 2px 0px;
  border: none;
  border-bottom: 1px solid rgba(138, 144, 156, 0.25);
  background-color: transparent;
  font-size: 22px;
  font-weight: 700;
  color: ${colors.midnight};

  @media ${device.large} {
    font-size: 14px;
  }
`;

export const StyledDivFieldError = styled.div`
  color: ${colors.cherry};
  font-size: 12px;
  margin-top: 4px;
  min-height: 14px;
  margin-bottom: 20px;
  white-space: break-spaces;
  ${(props) =>
    props.maintenanceEvents &&
    `
    grid-column: span 1;
    margin-bottom:20px;
    @media ${device.large} {
      grid-column: span 2;
  }
    `};

  ${(props) =>
    props.topSpacing &&
    `
    margin-top:22px;
    `};
  ${(props) =>
    props.snugSpacing &&
    `
        margin-top:-15px;
        `};
`;
