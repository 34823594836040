import { useFormikContext } from 'formik';
import { useEffect } from 'react';

import { NameFieldDiv } from 'legacy/shared/styles/custom/ManageAlerts';
import AnimatedField from 'legacy/shared/controls/AnimatedField/v1/AnimatedField';
import useAlertFormsProviderContext from 'legacy/features/alerts/services/providers/AlertFormsProvider/hooks/useAlertFormsProviderContext';
import { FieldError } from 'legacy/shared/styles/custom/Form';

const AlertNameField = ({ forceAnimated }) => {
  const {
    actions: { updateAlertDetailsAction },
    state: { alert },
  } = useAlertFormsProviderContext();

  const { alertDetails } = alert;
  const { alertName } = alertDetails.alertInfo;

  const formik = useFormikContext();

  useEffect(() => {
    formik.setFieldValue('alertNameField', alertName, true);
  }, [alertName]);

  return (
    <NameFieldDiv>
      <AnimatedField
        name="alertNameField"
        required
        label={'Name'}
        placeholder={'Name'}
        touched={formik?.touched?.alertNameField}
        validationError={formik?.errors?.alertNameField}
        handleBlur={formik.handleBlur}
        value={formik.values.alertNameField}
        handleChange={(e) => {
          updateAlertDetailsAction({ alertName: e.target.value });
          formik.handleChange(e);
        }}
        forceAnimated={forceAnimated}
      />
    </NameFieldDiv>
  );
};

export default AlertNameField;
