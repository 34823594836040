import GroupListActionMenu from 'legacy/features/vehicleGroups/ui/menus/GroupListActionMenu';
import IconSvgComponent from 'legacy/shared/controls/WcpIcon/IconSvgComponent';
import { Link } from 'react-router-dom';

export const groupsListComponentTableColumns = [
  {
    title: 'Group Name',
    displayProperty: 'groupName',
    className: 'name',
    width: '45%',
    render({ rowData, cellJsx }) {
      return (
        <span className="vehicleNameAndIcon" title={`Group Name: ${rowData.groupName}`}>
          <IconSvgComponent svgFileName={'folder'} alt="Groups" />

          <Link to={`${encodeURIComponent(rowData.groupName)}`} style={{ textDecoration: 'none' }}>
            {cellJsx}
          </Link>
        </span>
      );
    },
  },

  {
    title: 'Total Number of Vehicles',
    displayProperty: 'vehicleCount',
    width: '45%',
    render({ rowData, cellJsx }) {
      return (
        <div title={'Total Number of Vehicles: ' + rowData.vehicleCount}>
          <IconSvgComponent
            svgFileName={'car-outline'}
            style={styles.totalVehiclesIcon}
            alt="Vehicle"
          />
          <span>{cellJsx}</span>
        </div>
      );
    },
  },
  {
    className: 'action',
    searchEnabled: false,
    width: '5%',
    render({ rowData }) {
      return <GroupListActionMenu group={rowData} />;
    },
  },
];

const styles = {
  totalVehiclesIcon: {
    marginBottom: 5,
    marginRight: 12,
  },
};
