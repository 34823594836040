import { useState } from 'react';

import useGenericQuery from 'legacy/shared/utilities/hooks/useGenericQuery';
import { getVehicleGroupsApi } from 'legacy/features/vehicleGroups/api/get/getVehicleGroupsApi';
import { sortAscendingAlpha } from 'legacy/shared/utilities/misc/general';

const useFetchVehicleGroupsQuery = () => {
  const [queryResult, queryKey] = useGenericQuery({
    queryKeyPrefix: 'getVehicleGroupsApi',
    apiFn: getVehicleGroupsApi,
  });
  const sortedResults = queryResult?.data?.data?.message?.sort((a1, a2) =>
    sortAscendingAlpha(a1.group_name, a2.group_name),
  );

  return [sortedResults, queryKey];
};

export default useFetchVehicleGroupsQuery;
