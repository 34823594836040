import React from 'react';
import { stateOptionsArray } from 'legacy/shared/constants/states';
import { vehicleTypeViewData } from 'legacy/shared/constants/vehicle';
import { FieldError, VehicleLicenseWrapper } from 'legacy/shared/styles/custom/Form';
import AnimatedField from 'legacy/shared/controls/AnimatedField/v1/AnimatedField';
import SelectField from 'legacy/shared/controls/SelectField/SelectField';
import { handleSelectBlur, handleSelectChange } from 'legacy/shared/utilities/misc/form';

const VehicleTypeSelect = ({
  formikProps: { values, touched, errors, options, setFieldValue, setTouched },
}) => {
  return (
    <>
      <SelectField
        name="type"
        placeholder={'Vehicle Type'}
        defaultValue={
          vehicleTypeViewData.generateOptions().find((v) => v.value === values.type)?.label
        }
        disabled={false}
        onChange={handleSelectChange(setFieldValue, 'type')}
        onBlur={handleSelectBlur(setTouched, 'type', touched)}
        isClearable={false}
        isSearchable={false}
        options={vehicleTypeViewData.generateOptions()}
        field="true"
        required
      />
      <FieldError>{touched.type && errors.type}</FieldError>
    </>
  );
};

export default VehicleTypeSelect;
