import AnimatedField from 'legacy/shared/controls/AnimatedField/v1/AnimatedField';

const ReminderEngineHoursField = ({
  formikProps: { values, touched, errors, handleChange, handleBlur },
}) => {
  return (
    <div>
      <AnimatedField
        name="reminderEngineHours"
        placeholder="Engine Hours"
        value={values.reminderEngineHours}
        touched={touched.reminderEngineHours}
        validationError={errors.reminderEngineHours}
        handleChange={handleChange}
        handleBlur={handleBlur}
        disabled={false}
        required
      />
    </div>
  );
};

export default ReminderEngineHoursField;
