import { AddVehiclesToGroupModal } from 'legacy/features/vehicleGroups/ui/modals/AddVehiclesToGroupModal';
import { useEffect } from 'react';
import { useState } from 'react';
import { ActionButtonText, ActionButtonWithIcon } from 'legacy/shared/styles/custom/Button';
import { PageListActionButtonContainer } from 'legacy/shared/styles/custom/PageList';
import IconSvgComponent from 'legacy/shared/controls/WcpIcon/IconSvgComponent';

const AddVehiclesButton = ({ groupId, groupVehicles }) => {
  const [addVehiclesToGroupModalData, setAddVehiclesToGroupModalData] = useState({
    show: false,
    groupId,
    groupVehicles,
  });

  useEffect(() => {
    setAddVehiclesToGroupModalData((prevState) => ({ ...prevState, groupVehicles }));
  }, [groupVehicles]);

  return (
    <>
      <PageListActionButtonContainer>
        <ActionButtonWithIcon
          groupDetail
          onClick={() =>
            setAddVehiclesToGroupModalData((prevState) => ({ ...prevState, show: true }))
          }
        >
          <ActionButtonText>Add Vehicles</ActionButtonText>
          <IconSvgComponent
            svgFileName={'plus-simple'}
            alt="Add Vehicles"
            style={styles.buttonPlusIcon}
          />
        </ActionButtonWithIcon>
      </PageListActionButtonContainer>

      <AddVehiclesToGroupModal
        modalData={addVehiclesToGroupModalData}
        handleCloseModal={() =>
          setAddVehiclesToGroupModalData((prevState) => ({ ...prevState, show: false }))
        }
      />
    </>
  );
};

export default AddVehiclesButton;

const styles = {
  buttonPlusIcon: {
    position: 'absolute',
    right: 15,
    top: '50%',
    transform: 'translateY(-50%)',
  },
};
