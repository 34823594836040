import { getUserNameDisplayFromUserList } from 'legacy/features/recipientGroups/utilities/getUserNameDisplayFromUserList';
import { mapToFrontend, mapToBackend } from 'legacy/shared/utilities/helpers/mapping/mapper';

export const recipientGroupMapping = {
  group_id: {
    frontendProperty: 'recipientGroupId',
  },
  organization_id: {
    frontendProperty: 'organizationId',
  },

  group_name: {
    frontendProperty: 'recipientGroupName',
  },

  usernames: {
    frontendProperty: 'recipientGroupMembers',
    transformValueToBackend: (recipientGroupMembers) => {
      const test = recipientGroupMembers.map((u) => u.username);
      return test;
    },
  },

  user_list: {
    frontendProperty: 'recipientGroupMembers',
    transformValueToFrontend: (user_list) => {
      return user_list.map((u) => ({
        username: u.username,
        firstName: u.given_name,
        lastName: u.family_name,
      }));
    },
  },
};

export const mapRecipientDataToFrontend = (recipient) => {
  const mappedRecipients = mapToFrontend(recipient, recipientGroupMapping);
  mappedRecipients.usernames = mappedRecipients.recipientGroupMembers.map((u) => u.username);
  return mappedRecipients;
};

export const mapRecipientDataToBackend = (recipient) => {
  const mappedRecipients = mapToBackend(recipient, recipientGroupMapping);
  delete mappedRecipients.user_list;

  return mappedRecipients;
};

export const mapRecipientGroupsDataToCsv = ({ recipientGroups }) => {
  return recipientGroups?.map((r) => {
    return {
      Recipient_Group_Name: r.recipientGroupName,
      Members: getUserNameDisplayFromUserList(r.recipientGroupMembers),
    };
  });
};
