import UserProfileEditForm from 'legacy/features/users/components/UserProfileEditForm/UserProfileEditForm';
import PageListWrapper from 'legacy/shared/styles/custom/PageList';

const UserProfilePage = () => {
  return (
    <PageListWrapper>
      <UserProfileEditForm />
    </PageListWrapper>
  );
};

export default UserProfilePage;
