import { useState } from 'react';
import Avatar from 'react-avatar-edit';

import colors from 'legacy/shared/constants/colors';
import fileSizes from 'legacy/shared/constants/fileSizes';
import { StyledAvatarControlContainer } from 'legacy/shared/controls/WcpAvatarControl/v1/styles/AvatarControlStyles';

const AvatarImageUploadControl = ({ handleChangeImage }) => {
  const [uploadedImage, setUploadedImage] = useState(null);

  return (
    <StyledAvatarControlContainer>
      <Avatar
        src={uploadedImage}
        imageWidth={300}
        width={300}
        height={300}
        borderStyle={{
          border: '1px solid #979797',
          textAlign: 'center',
          backgroundColor: colors.fog,
          height: '300px',
        }}
        label={'UPLOAD PROFILE PHOTO'}
        labelStyle={{
          color: colors.haze,
          fontSize: '14px',
          fontWeight: '800',
          letterSpacing: '0.59px',
          cursor: 'pointer',
        }}
        onImageLoad={(image) => {
          setUploadedImage(image);
        }}
        // this fires after uploading an image as well as when cropping an image
        onCrop={(croppedImage) => {
          handleChangeImage(croppedImage);
        }}
        onClose={() => {
          setUploadedImage(null);
          handleChangeImage(null);
        }}
        onBeforeFileLoad={(elem) => {
          //reject files over 6 mb
          if (elem?.target.files[0].size > fileSizes.userPhotoMax) {
            alert('The selected file is too large. Please try another.');
            elem.target.value = '';
          } else if (elem?.target.files[0].size < fileSizes.globalMin) {
            alert('The selected file is corrupt or invalid. Please try another.');
            elem.target.value = '';
          }
        }}
      />
    </StyledAvatarControlContainer>
  );
};

export default AvatarImageUploadControl;
