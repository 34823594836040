import { useQueryClient } from '@tanstack/react-query';

import { removeVehiclesFromGroupAPI } from 'legacy/core/api/groups';
import ConfirmationModalWithInnerModalPortal from 'legacy/shared/controls/WcpModal/v1/ConfirmationModalWithInnerModalPortal';

const RemoveVehicleFromGroupModal = ({ vehicle, group, onCloseModal }) => {
  const queryClient = useQueryClient();
  return (
    <ConfirmationModalWithInnerModalPortal
      onCloseModal={onCloseModal}
      onSuccess={onCloseModal}
      onFailure={onCloseModal}
      bodyText={`Do you really want to remove "${vehicle.vehicleName}" from the "${group.group_name}" group?`}
      title={`Remove Vehicle`}
      handleRequestClose={onCloseModal}
      submitHandler={async () => {
        await removeVehiclesFromGroupAPI({
          organizationId: group.organization_id,
          groupId: group.group_id,
          vehicleIds: [vehicle.vehicleId],
        });
        queryClient.invalidateQueries(['fetchGroupsQuery']);
      }}
      confirmText="Remove Vehicle"
      cancelHandler={onCloseModal}
      cancelText="Cancel"
    />
  );
};

export default RemoveVehicleFromGroupModal;
