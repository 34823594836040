import { Link } from 'react-router-dom';

import EditOrganizationMenuItem from 'legacy/features/organizations/components/menus/EditOrganizationMenuItem';
import ManageOrganizationUsersMenuItem from 'legacy/features/organizations/components/menus/ManageOrganizationUsersMenuItem';
import OrganizationActivationToggleMenuItem from 'legacy/features/organizations/components/menus/OrganizationActivationToggleMenuItem';
import { customerTypeDictionary } from 'legacy/shared/constants/organizations';
import IconSvgComponent from 'legacy/shared/controls/WcpIcon/IconSvgComponent';
import ActionMenu from 'legacy/shared/controls/WcpTable/v1/ActionMenu';
import { Badge } from 'legacy/shared/styles/custom/Badge';
import { TableSpinner } from 'legacy/shared/styles/custom/Spinner';
import { TruncatedTextWithWrap } from 'legacy/shared/styles/custom/TruncatedText';

export const useOrganizationTableColumns = () => {
  return [
    {
      title: 'Organization Name',
      displayProperty: 'organizationName',
      className: 'name',
      render({ rowData, cellJsx }) {
        return (
          <span
            className="vehicleNameAndIcon"
            title={'Organization Name: ' + rowData.organizationName}
          >
            {rowData.loading_status ? (
              <TableSpinner />
            ) : (
              <IconSvgComponent svgFileName={'organizations'} alt="Organizations" />
            )}

            <Link
              to={`${encodeURIComponent(rowData.organizationId)}`}
              style={{ textDecoration: 'none' }}
            >
              <TruncatedTextWithWrap orgTable>{cellJsx}</TruncatedTextWithWrap>
            </Link>
          </span>
        );
      },
    },
    {
      title: 'Contact Email',
      displayProperty: 'contactEmail',
      render({ rowData, cellJsx }) {
        return (
          <TruncatedTextWithWrap orgTable title={'Contact Email: ' + rowData.contactEmail}>
            {cellJsx}
          </TruncatedTextWithWrap>
        );
      },
    },
    {
      title: 'Customer Type',
      displayProperty: 'customerType',
      render({ rowData }) {
        const specifyType = rowData.customerType
          ? customerTypeDictionary.find((ct) => ct.value === rowData.customerType)?.label
          : 'Not Specified';
        return <span title={'Customer Type: ' + specifyType}>{specifyType}</span>;
      },
    },
    {
      title: 'Contact Phone',
      displayProperty: 'contactPhoneDisplay',
    },
    {
      title: 'Start Date',
      displayProperty: 'startDate',
    },
    {
      title: 'End Date',
      displayProperty: 'endDate',
    },
    {
      title: 'Vehicle Count',
      displayProperty: 'vehicleCount',
      width: '5%',
    },
    {
      title: 'Status',
      displayProperty: 'status',
      render: ({ rowData }) => (
        <Badge active={rowData.isActive}>
          <span title="Status">{rowData.isActive ? 'Enabled' : 'Disabled'}</span>
        </Badge>
      ),
    },
    {
      title: 'HAAS Integration',
      displayProperty: 'haasEnabled',
      render: ({ rowData }) =>
        rowData.haasEnabled &&
        (rowData.haasSetup ? (
          <IconSvgComponent
            svgFileName={'check-success'}
            alt="HAAS Enabled"
            title="HAAS Integration Enabled"
          />
        ) : (
          <IconSvgComponent
            svgFileName={'check-pending'}
            alt="HAAS Pending"
            title="HAAS Integration Pending"
          />
        )),
    },
    {
      title: 'Miovision Integration',
      displayProperty: 'gttEnabled',
      render: ({ rowData }) =>
        rowData.gttEnabled &&
        (rowData.gttSetup ? (
          <IconSvgComponent
            svgFileName={'check-success'}
            alt="Miovision Enabled"
            title="Miovision Integration Enabled"
          />
        ) : (
          <IconSvgComponent
            svgFileName={'check-pending'}
            alt="Miovision Pending"
            title="Miovision Integration Pending"
          />
        )),
    },
    {
      title: 'action',
      displayProperty: '',
      className: 'action',
      searchEnabled: false,
      render: ({ rowData }) => {
        return (
          <ActionMenu>
            <EditOrganizationMenuItem organization={rowData} />
            <ManageOrganizationUsersMenuItem organization={rowData} />
            <OrganizationActivationToggleMenuItem organization={rowData} />
          </ActionMenu>
        );
      },
    },
  ];
};
