import { DateTime } from 'luxon';
import { useContext, useEffect, useState } from 'react';
import { connect } from 'react-redux';

import {
  deleteGeofencesData,
  postDisableGeofence,
  postEnableGeofence,
} from 'legacy/core/api/geofences';
import { addNotification } from 'legacy/core/redux/ui/actions';
import { GeofencesApi } from 'legacy/features/geofences/geofences/components/GeofencesContainer';
import device from 'legacy/shared/constants/sizing';
import ConfirmationModal from 'legacy/shared/controls/WcpModal/v1/ConfirmationModal';
import ModalPortal from 'legacy/shared/controls/WcpModal/v1/ModalPortal';
import { SwitchCheckbox, SwitchCheckboxLabel } from 'legacy/shared/styles/custom/Checkbox';
import {
  DropdownLink,
  SimpleDropdownBtn,
  SimpleDropdownItem,
} from 'legacy/shared/styles/custom/DropdownMenu';
import {
  GeofenceDetailDiv,
  GeofenceLastEntered,
  GeofenceMiniCardContainerDiv,
  GeofenceName,
} from 'legacy/shared/styles/custom/Geofences';
import { ActionMenuBtnWrapper } from 'legacy/shared/styles/custom/MobileCard';
import LoadingOverlay from 'legacy/shared/controls/WcpSpinner/LoadingOverlay';
import ActionMenu from 'legacy/shared/controls/WcpTable/v1/ActionMenu';
import { createNotification, LEVELS } from 'legacy/shared/utilities/misc/notification';
import {
  formatISOTimestampToDescriptiveLog,
  formatISOTimestampToShortDate,
  formatISOTimestampToStandardTime,
} from 'legacy/shared/utilities/misc/time';
import { selectionMethod } from 'legacy/features/geofences/geofences/constants/enums';

const GeofenceMiniCard = ({
  dispatchNotification,
  currentOrganizationId,
  id,
  name,
  lastEntered,
  enabled,
  expirationTime,
}) => {
  const { handleSelectGeofence, selectedGeofenceId, reloadGeofencesData, setGeofencesSidebarOpen } =
    useContext(GeofencesApi);

  const [deleteConfirmation, setDeleteConfirmation] = useState(false);
  const [requestComplete, setRequestComplete] = useState(true);
  const [isExpired, setIsExpired] = useState(false);

  useEffect(() => {
    if (expirationTime) {
      let expiration = DateTime.fromISO(expirationTime);
      let now = DateTime.now().toUTC();
      if (now > expiration) {
        setIsExpired(true);
      } else {
        setIsExpired(false);
      }
    }
  }, [expirationTime]);

  const enableGeofence = async (geofenceId) => {
    try {
      let response = await postEnableGeofence({
        geofenceIds: [geofenceId],
        organizationId: currentOrganizationId,
      });
      if (response) {
        dispatchNotification.enableGeofenceSuccess({ label: name });
      }
    } catch (err) {
      dispatchNotification.enableGeofenceError({ err });
    }
  };

  const disableGeofence = async (geofenceId) => {
    try {
      let response = await postDisableGeofence({
        geofenceIds: [geofenceId],
        organizationId: currentOrganizationId,
      });
      if (response) {
        dispatchNotification.disableGeofenceSuccess({ label: name });
      }
    } catch (err) {
      dispatchNotification.disableGeofenceError({ err });
    }
  };

  return (
    <>
      <>
        {deleteConfirmation && (
          <ModalPortal
            onRequestClose={() => {
              setDeleteConfirmation(false);
            }}
          >
            <ConfirmationModal
              bodyText={<p>Are you sure you want to delete {name}?</p>}
              cancelHandler={() => {
                setDeleteConfirmation(false);
              }}
              confirmHandler={async () => {
                await deleteGeofencesData({
                  organizationId: currentOrganizationId,
                  geofenceId: id,
                });
                await reloadGeofencesData();
                setDeleteConfirmation(false);
                handleSelectGeofence({ id: null, selectOrigin: selectionMethod.SIDEBAR });
              }}
              cancelText={'Cancel'}
              confirmText={'Delete'}
              title={'Delete Geofence'}
            />
          </ModalPortal>
        )}
      </>
      <GeofenceMiniCardContainerDiv isSelected={selectedGeofenceId.id === id}>
        {!requestComplete && <LoadingOverlay />}
        <div>
          <SwitchCheckbox
            id={id}
            checked={enabled}
            disabled={isExpired}
            onChange={async (e) => {
              setRequestComplete(false);
              if (enabled) {
                await disableGeofence(id);
              } else {
                await enableGeofence(id);
              }
              e.stopPropagation();
              await reloadGeofencesData();
              setRequestComplete(true);
            }}
          />
          <SwitchCheckboxLabel htmlFor={id} title="Toggle On/Off" disabled={isExpired} />
        </div>

        <GeofenceDetailDiv
          onClick={() => {
            let mobile = !window.matchMedia(device.large).matches;
            mobile && setGeofencesSidebarOpen(false);
            handleSelectGeofence({ id, selectOrigin: selectionMethod.SIDEBAR });
          }}
        >
          <GeofenceName>{name}</GeofenceName>
          <GeofenceLastEntered>
            Last entered on:
            {lastEntered ? ` ${formatISOTimestampToDescriptiveLog(lastEntered.timestamp)}` : ' N/A'}
          </GeofenceLastEntered>
        </GeofenceDetailDiv>
        <ActionMenuBtnWrapper>
          <ActionMenu>
            <SimpleDropdownItem>
              <DropdownLink to={`/geofences/logs/${encodeURIComponent(id)}`}>
                View Logs
              </DropdownLink>
            </SimpleDropdownItem>
            <SimpleDropdownItem>
              <SimpleDropdownBtn
                onClick={(e) => {
                  e.preventDefault();
                  setDeleteConfirmation(true);
                }}
              >
                Delete
              </SimpleDropdownBtn>
            </SimpleDropdownItem>
          </ActionMenu>
        </ActionMenuBtnWrapper>
      </GeofenceMiniCardContainerDiv>
    </>
  );
};

export default connect(
  (state) => ({
    currentOrganizationId: state.user.filteredOrganizationId,
  }),
  (dispatch) => ({
    dispatchNotification: {
      enableGeofenceSuccess: ({ label }) =>
        dispatch(
          addNotification({
            notification: createNotification(
              LEVELS.SUCCESS,
              'Geofence Enabled Successfully',
              `Successfully enabled geofence named "${label}"`,
            ),
          }),
        ),
      disableGeofenceSuccess: ({ label }) =>
        dispatch(
          addNotification({
            notification: createNotification(
              LEVELS.SUCCESS,
              'Geofence Disabled Successfully',
              `Successfully disabled geofence named "${label}"`,
            ),
          }),
        ),
      enableGeofenceError: ({ err }) =>
        dispatch(
          addNotification({
            notification: createNotification(LEVELS.ERROR, `Error Enabling Geofence`, err),
          }),
        ),
      disableGeofenceError: ({ err }) =>
        dispatch(
          addNotification({
            notification: createNotification(LEVELS.ERROR, `Error Disabling Geofence`, err),
          }),
        ),
    },
  }),
)(GeofenceMiniCard);
