import ActivateUserMenuItem from 'legacy/features/organizations/components/menus/ActivateUserMenuItem';
import DeactivateUserMenuItem from 'legacy/features/organizations/components/menus/DeactivateUserMenuItem';
import { SimpleDropdownItem } from 'legacy/shared/styles/custom/DropdownMenu';

const UserActivationToggleMenuItem = ({ user }) => {
  return (
    <SimpleDropdownItem>
      {user.isActive === true ? (
        <DeactivateUserMenuItem user={user} />
      ) : (
        <ActivateUserMenuItem user={user} />
      )}
    </SimpleDropdownItem>
  );
};

export default UserActivationToggleMenuItem;
