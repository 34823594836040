import { DropdownLink } from 'legacy/shared/styles/custom/DropdownMenu';

const ViewDeviceLogsMenuItem = ({ vsgDuid, id }) => {
  return (
    <DropdownLink id={id} to={`/devicelogs/${encodeURIComponent(vsgDuid)}`}>
      View Device Logs
    </DropdownLink>
  );
};

export default ViewDeviceLogsMenuItem;
