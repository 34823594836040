import styled, { css } from 'styled-components';
import colors from 'legacy/shared/constants/colors';
import theme from 'legacy/shared/styles/global/theme';
import { Link } from 'react-router-dom';

export const Wrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  margin-top: ${theme.header_height};
  height: calc(100% - ${theme.header_height});
  width: 0;
  display: flex;
  flex-direction: column;
  background: #fff;
  box-shadow: 0 41px 51px 0 rgba(0, 0, 0, 0.27);
  border: 1px solid rgba(151, 151, 151, 0.15);
  border-right: 0 none;
  transition: width 0.3s;
  overflow: hidden;
  white-space: nowrap;
  ${(props) =>
    props.expanded &&
    `
     width: 330px;

     `}
`;

export const Head = styled.div`
  padding: 15px 34px;
  border-bottom: 1px solid rgba(138, 144, 156, 0.15);
`;

export const Title = styled.h5`
  font-family: Muli, sans-serif;
  font-weight: 600;
  color: #14101d;

  ${(props) =>
    props.altstyle &&
    css`
      margin-top: 10px;
      color: #4a4a4a;
    `}
`;

export const Body = styled.div`
  flex: 1 1 100%;

  ${(props) =>
    props.centerContent &&
    css`
      display: flex;
      justify-content: center;
      align-items: center;
    `}
`;

export const List = styled.ul`
  list-style-type: none;
`;

export const MessageWrapper = styled.div`
  text-align: center;
`;

export const AlertLink = styled(Link)`
  text-decoration: none;
`;

export const ListItem = styled.li`
  position: relative;
  padding: 20px 0 20px 50px;
  border-bottom: 1px solid rgba(138, 144, 156, 0.15);
  font-family: Muli, sans-serif;
  font-weight: 600;
  color: #4a4a4a;

  &:before {
    content: '';
    position: absolute;
    left: 24px;
    top: 50%;
    transform: translateY(-50%);
    width: 10px;
    height: 10px;
    background: ${colors.midnight};
    border-radius: 50%;
  }

  span {
    display: block;
    text-decoration: none;
  }

  .version {
  }

  .cta {
    color: #8a909c;
  }
`;

export const MaintenanceItem = styled.div`
  display: flex;
  position: relative;
  padding: 20px 0 20px 15px;
  border-bottom: 1px solid rgba(138, 144, 156, 0.15);
  font-family: Muli, sans-serif;
  font-weight: 600;
  color: #4a4a4a;
`;

export const DetailDiv = styled.div`
  color: #8a909c;
`;
export const IconWrapper = styled.div`
  margin-top: 8px;
  margin-right: 12px;
`;

export const AlertTitleWrapper = styled.div`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 250px;
`;
