import useFetchOrganizationFseUsersQuery from 'legacy/features/organizations/api/queries/useFetchOrganizationFseUsersQuery';
import FseUserListingCard from 'legacy/features/organizations/components/mobile/FseUserListingCard ';
import { TABS } from 'legacy/features/organizations/constants/constants';
import useOrganizationFseTableColumns from 'legacy/features/organizations/hooks/useOrganizationFseTableColumns';
import LoadingWrapper from 'legacy/shared/controls/WcpLoadingWrapper/v1/LoadingWrapper';
import MediaWrapper from 'legacy/shared/controls/WcpMediaWrapper/v1/MediaWrapper';
import WcpTable from 'legacy/shared/controls/WcpTable/v2/WcpTable';
import { Cards } from 'legacy/shared/styles/custom/MobileCard';
import { styles } from 'legacy/shared/styles/inline/inlineStyles';

const OrganizationFseListingTab = ({ organization }) => {
  const fetchOrganizationFseUsersQuery = useFetchOrganizationFseUsersQuery({
    organizationId: organization.organizationId,
  });

  const organizationUserTableColumns = useOrganizationFseTableColumns({
    organization,
  });

  return (
    <LoadingWrapper isLoading={!fetchOrganizationFseUsersQuery.data}>
      <MediaWrapper
        mobileComponent={
          <div>
            {fetchOrganizationFseUsersQuery.data && (
              <Cards>
                {fetchOrganizationFseUsersQuery.data.map((user) => (
                  <FseUserListingCard user={user} organization={organization} />
                ))}
              </Cards>
            )}
          </div>
        }
        nonMobileComponent={
          <div style={styles.organizations.vehiclesBody}>
            <WcpTable
              columns={organizationUserTableColumns.filter(
                (column) => !['Status', 'Role', 'Title'].includes(column.title), // exclude columns that are not relevant to FSEs
              )}
              tableSourceData={fetchOrganizationFseUsersQuery.data}
              defaultSortProperty="username"
              emptyText="No Field Solution Engineers are currently associated with this organization"
              showListingCountHeader={true}
              listingCountItemName="Field Solution Engineer"
            />
          </div>
        }
      />
    </LoadingWrapper>
  );
};

export default OrganizationFseListingTab;
