import {
  CardContainer,
  CardIconWrapper,
  CardLabel,
  CardName,
  CardOuterWrapper,
  CardRow,
  CardValue,
} from 'legacy/shared/styles/custom/MobileCard';
import { ActionMenuBtnWrapper } from 'legacy/shared/styles/custom/MobileCard';

const PartnershipsSummaryCard = ({ partnership, actionMenuComponent }) => {
  return (
    <CardOuterWrapper>
      <CardContainer>
        <ActionMenuBtnWrapper>{actionMenuComponent(partnership)}</ActionMenuBtnWrapper>
        <CardIconWrapper>
          <CardRow header>
            <CardName potentialLongValue title={partnership.name}>
              {partnership.name}
            </CardName>
          </CardRow>
        </CardIconWrapper>
        <CardRow potentialLongValue>
          <CardLabel>Status:</CardLabel>
          <CardValue title={partnership.status}>{partnership.status}</CardValue>
        </CardRow>
        <CardRow potentialLongValue>
          <CardLabel>Detail:</CardLabel>
          <CardValue title={partnership.detail}>{partnership.detail}</CardValue>
        </CardRow>
      </CardContainer>
    </CardOuterWrapper>
  );
};

export default PartnershipsSummaryCard;
