import React from 'react';

import Button, { ButtonWithSpinnerContainer } from 'legacy/shared/styles/custom/Button';
import { ButtonSpinner } from 'legacy/shared/styles/custom/Spinner';

export default ({
  isLoading,
  confirmText,
  loadingStyleProp,
  notLoadingStyleProp,
  clickHandler,
  disabled,
  title,
  autoFocus,
  containerProp,
  dataCyId,
}) =>
  isLoading ? (
    <ButtonWithSpinnerContainer {...(containerProp ? { [containerProp]: true } : {})}>
      <Button
        type="submit"
        data-cy={dataCyId}
        {...(loadingStyleProp ? { [loadingStyleProp]: true } : {})}
      >
        {confirmText}
      </Button>
      <ButtonSpinner />
    </ButtonWithSpinnerContainer>
  ) : (
    <div title={disabled && title}>
      <Button
        type="submit"
        data-cy={dataCyId}
        autoFocus={autoFocus}
        disabled={disabled}
        disabledStyleProp={disabled}
        {...(notLoadingStyleProp ? { [notLoadingStyleProp]: true } : {})}
        onClick={clickHandler}
        title={title}
      >
        {confirmText}
      </Button>
    </div>
  );
