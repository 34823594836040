import { Formik } from 'formik';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import TwoFactorSubtitle from 'legacy/core/components/authentication/components/TwoFactorSubtitle';
import { mfaTypes } from 'legacy/shared/constants/users';
import AnimatedField from 'legacy/shared/controls/AnimatedField/v1/AnimatedField';
import ButtonWithLoader from 'legacy/shared/controls/ButtonWithLoader/ButtonWithLoader';
import IconSvgComponent from 'legacy/shared/controls/WcpIcon/IconSvgComponent';
import Button from 'legacy/shared/styles/custom/Button';
import { ActionsContainer, CloseButton, Container, Title } from 'legacy/shared/styles/custom/Modal';
import {
  RadioButton,
  RadioButtonContainer,
  RadioButtonLabel,
} from 'legacy/shared/styles/custom/Radio';
import { setTwoFactorMethod, verifySmsMfa } from 'legacy/core/redux/user/actions';

const Change2faModal = ({ handleRequestClose }) => {
  const username = useSelector((state) => state.user.user.username);
  const mfaType = useSelector((state) => state.user.mfaType);
  const mfaDestination = useSelector((state) => state.user.mfaDestination);
  const authenticationAppSecretCode = useSelector(
    (state) => state.user.authenticationAppSecretCode,
  );
  const smsVerificationCode = useSelector((state) => state.user.smsVerificationCode);
  const redirectToVerifyMfa = useSelector((state) => state.user.redirectToVerifyMfa);

  const dispatch = useDispatch();

  const [selectedOption, selectOption] = useState(mfaTypes.SMS);

  const submitVerification = async (values, actions) => {
    const { userMfaCode } = values;
    let success;

    // user profile change 2fa login: Auth App
    if (authenticationAppSecretCode) {
      success = dispatch(
        await verifySoftwareTokenMfa({
          userMfaCode,
        }),
      );
    }

    // user profile change 2fa login: SMS
    else if (smsVerificationCode) {
      success = dispatch(await verifySmsMfa({ smsVerificationCode, userMfaCode, bypass: false }));
    } else {
      // smoke and mirrors
      dispatch(await verifySmsMfa({ smsVerificationCode, userMfaCode, bypass: true }));
    }

    // can use success variable here if needed for redirects or whatever
    // currently not using it for anything
    actions.setSubmitting(false);
    handleRequestClose();
  };
  const renderTwoFactorSelection = () => (
    <Formik
      initialValues={{}}
      onSubmit={(values, actions) => {
        dispatch(setTwoFactorMethod({ twoFactorMethod: selectedOption }));

        actions.setSubmitting(false);
      }}
    >
      {({ isSubmitting, submitForm }) => (
        <form style={styles.formBody}>
          <RadioButtonContainer onClick={() => selectOption(mfaTypes.SMS)}>
            <RadioButton
              type="radio"
              value={mfaTypes.SMS}
              checked={selectedOption === mfaTypes.SMS}
            />
            <RadioButtonLabel>Text me a code</RadioButtonLabel>
          </RadioButtonContainer>
          <RadioButtonContainer onClick={() => selectOption(mfaTypes.AUTHENTICATION_APP)}>
            <RadioButton
              type="radio"
              value={mfaTypes.AUTHENTICATION_APP}
              checked={selectedOption === mfaTypes.AUTHENTICATION_APP}
            />
            <RadioButtonLabel>Use an authenticator app</RadioButtonLabel>
          </RadioButtonContainer>
          <ActionsContainer>
            <Button onClick={handleRequestClose} withRightSpacer default>
              Cancel
            </Button>
            <ButtonWithLoader
              isLoading={isSubmitting}
              confirmText={'Continue'}
              loadingStyleProp={'submittingWithSpinner'}
              notLoadingStyleProp={'mediumAlt'}
              clickHandler={submitForm}
            />
          </ActionsContainer>
        </form>
      )}
    </Formik>
  );

  const renderTwoFactorVerification = () => (
    <Formik
      initialValues={{
        userMfaCode: '',
      }}
      onSubmit={submitVerification}
    >
      {({ values, errors, touched, dirty, isSubmitting, handleChange, handleBlur, submitForm }) => (
        <form style={styles.formBody}>
          <AnimatedField
            id="userMfaCode"
            name="userMfaCode"
            type="text"
            touched={touched.userMfaCode}
            validationError={errors.userMfaCode}
            handleChange={handleChange}
            handleBlur={handleBlur}
            placeholder="Enter Code"
            value={values.userMfaCode}
            required
          />
          <ActionsContainer>
            <Button onClick={handleRequestClose} withRightSpacer default type="button">
              Cancel
            </Button>
            <ButtonWithLoader
              isLoading={isSubmitting}
              confirmText={'Continue'}
              loadingStyleProp={'submittingWithSpinnerModal'}
              notLoadingStyleProp={'mediumAlt'}
              clickHandler={submitForm}
            />
          </ActionsContainer>
        </form>
      )}
    </Formik>
  );

  return (
    <Container autoHeight>
      <CloseButton onClick={handleRequestClose}>
        <IconSvgComponent svgFileName={'x'} alt="Close" />
      </CloseButton>
      <Title style={styles.title}>
        <IconSvgComponent style={styles.editIcon} svgFileName={'edit-pencil-blue'} alt="Edit" />
        Change Two-Factor Authentication
      </Title>

      {redirectToVerifyMfa === true ? (
        <>
          <TwoFactorSubtitle
            mfaType={mfaType}
            username={username}
            mfaDestination={mfaDestination}
            authenticationAppSecretCode={authenticationAppSecretCode}
          />
          {renderTwoFactorVerification()}
        </>
      ) : (
        <>{renderTwoFactorSelection()}</>
      )}
    </Container>
  );
};

export default Change2faModal;

const styles = {
  title: {
    marginBottom: '42px',
  },
  editIcon: {
    marginRight: '10px',
  },
  formBody: {
    padding: '0px 20px 0px 20px',
  },
};
