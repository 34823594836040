import { alertsMapping } from 'legacy/features/alerts/services/mapping/definitions/alertsMapping';
import { apiCall } from 'legacy/core/api/API';
import { mapToBackend } from 'legacy/shared/utilities/helpers/mapping/mapper';

export const postAlertsApi = async ({ organizationId, alert }) => {
  const mappedAlert = mapToBackend(alert, alertsMapping);
  const apiResponse = await apiCall({
    method: 'post',
    path: `/alerts`,
    queryStringParameters: {
      organization_id: `${organizationId}`,
    },
    body: {
      ...mappedAlert,
      organization_id: organizationId,
    },
  });

  return apiResponse;
};
