import { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

import SimpleAddon from 'legacy/features/vehicles/components/controls/addons/SimpleAddon';
import { vehicleAddonDictionary } from 'legacy/features/vehicles/constants/vehicleAddonDictionary';
import { FieldError, FormSectionLabel } from 'legacy/shared/styles/custom/Form';
import { AddonFeaturesDiv, FeatureDropDownDiv } from 'legacy/shared/styles/custom/VehicleAddons';
import FilteredDropdown from 'legacy/shared/controls/WcpFilteredDropdown/v2/FilteredDropdown';
import { sortAscendingAlpha } from 'legacy/shared/utilities/misc/general';

const VehicleAddonFeatures = ({
  vehicleAddons,
  updateVehicleAddons,
  groupAddons,
  preventSubmit,
}) => {
  const [localVehicleAddons, setLocalVehicleAddons] = useState([]);
  const [addonValidation, setAddonValidation] = useState(null);

  useEffect(() => {
    let inputDesignations = [];
    let undesignatedInputs = [];
    for (let i = 0; i < localVehicleAddons.length; i++) {
      const inputValue = localVehicleAddons[i].assigned_inputs;
      inputValue ? inputDesignations.push(inputValue) : undesignatedInputs.push('undefined');
    }
    inputDesignations = inputDesignations.flat();
    let duplicateInput = new Set(inputDesignations).size !== inputDesignations.length;
    if (duplicateInput) {
      setAddonValidation(
        'You may only use a VSG Input once. Please choose a unique input for each add-on feature.',
      );
    } else if (undesignatedInputs.length > 0) {
      setAddonValidation('Please choose a VSG Input');
    } else {
      setAddonValidation(null);
    }
  }, [localVehicleAddons]);

  useEffect(() => {
    addonValidation ? preventSubmit(true) : preventSubmit(false);
  }, [addonValidation]);

  useEffect(() => {
    updateVehicleAddons([...localVehicleAddons]);
  }, [localVehicleAddons]);

  const addonDropdownOptions = Object.values(vehicleAddonDictionary)
    .sort((a1, a2) => sortAscendingAlpha(a1.displayName, a2.displayName))
    .map((addon) => ({ label: addon.displayName, value: addon.displayName }));

  const handleAddonSelect = (addonTypeName) => {
    if (addonTypeName) {
      let addonType = Object.keys(vehicleAddonDictionary).find(
        (key) => vehicleAddonDictionary[key]?.displayName === addonTypeName.label,
      );
      let addonAlertLevel = Object.values(vehicleAddonDictionary).find(
        (value) => value?.displayName === addonTypeName.label,
      ).defaultAlertLevel;

      addonType &&
        setLocalVehicleAddons([
          ...vehicleAddons,
          {
            addon_id: uuidv4(),
            type: addonType,
            alert_level: addonAlertLevel,
          },
        ]);
    }
  };

  return (
    <AddonFeaturesDiv>
      {groupAddons ? (
        <h5>Add-ons To Apply</h5>
      ) : (
        <FormSectionLabel>Add-on Features</FormSectionLabel>
      )}
      <FeatureDropDownDiv>
        <FilteredDropdown
          dropdownOptions={addonDropdownOptions}
          title={'Additional Features'}
          placeholderLabel={'Search for features...'}
          handleItemSelect={(addonTypeName) => {
            handleAddonSelect(addonTypeName);
          }}
          defaultItem={null}
          clearOnSelection={true}
        />
      </FeatureDropDownDiv>
      <div>
        {vehicleAddons.map((addon) => (
          <SimpleAddon
            key={addon.addon_id}
            addon={addon}
            handleSelectVsgInput={(id, input) => {
              let i = vehicleAddons.findIndex((addon) => addon.addon_id === id);
              vehicleAddons[i].assigned_inputs = [input];
              setLocalVehicleAddons([...vehicleAddons]);
            }}
            handleSelectAlertLevel={(id, alertLevel) => {
              let i = vehicleAddons.findIndex((addon) => addon.addon_id === id);
              vehicleAddons[i].alert_level = alertLevel;
              setLocalVehicleAddons([...vehicleAddons]);
            }}
            removeAddon={(id) => {
              setLocalVehicleAddons(vehicleAddons.filter((a) => a.addon_id !== id));
            }}
          ></SimpleAddon>
        ))}
      </div>
      {addonValidation && <FieldError>{addonValidation}</FieldError>}
    </AddonFeaturesDiv>
  );
};

export default VehicleAddonFeatures;
