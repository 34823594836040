import WcpTable from 'legacy/shared/controls/WcpTable/v2/WcpTable';
import { deviceLogsTableColumns } from 'legacy/features/billing/ui/components/DeviceLogsSummary/tables/DeviceLogsTableColumns';
import { SORT_ORDER } from 'legacy/shared/constants/table';

const DeviceLogsTable = ({ deviceLogs, handleUpdateListingCount }) => {
  return (
    <WcpTable
      id="deviceLogsSummaryTable"
      columns={deviceLogsTableColumns}
      tableSourceData={deviceLogs ? deviceLogs : []}
      emptyText="No subscription activity associated with this device"
      setListingCount={handleUpdateListingCount}
      defaultSortOrder={SORT_ORDER.DESC}
    />
  );
};

export default DeviceLogsTable;
