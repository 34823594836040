import { SimpleDropdownBtn, SimpleDropdownItem } from 'legacy/shared/styles/custom/DropdownMenu';

const DeleteKeyMenuItem = ({ onMenuItemClick }) => {
  return (
    <SimpleDropdownItem>
      <SimpleDropdownBtn onClick={onMenuItemClick}>Delete Key</SimpleDropdownBtn>
    </SimpleDropdownItem>
  );
};

export default DeleteKeyMenuItem;
