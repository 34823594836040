import GroupNameField from 'legacy/features/vehicleGroups/ui/form/fields/text/GroupNameField';
import { useCreateGroupMutation } from 'legacy/features/vehicleGroups/hooks/v1/useCreateGroupMutation';
import { FormRow } from 'legacy/shared/styles/custom/Form';
import IconSvgComponent from 'legacy/shared/controls/WcpIcon/IconSvgComponent';
import GenericFormModal from 'legacy/shared/controls/WcpModal/v1/GenericFormModal';
import { validateGroup } from 'legacy/shared/utilities/helpers/validation/validators';

const CreateGroupModal = ({ modalData: { show }, handleCloseModal }) => {
  const createGroupMutation = useCreateGroupMutation();

  return (
    show && (
      <GenericFormModal
        icon={<IconSvgComponent svgFileName="plus" alt="Create" />}
        title={'Create Vehicle Group'}
        initialValues={{
          groupName: '',
        }}
        submitHandler={async (values, formikActions) => {
          let result = await createGroupMutation.mutateAsync({
            groupName: values.groupName,
          });

          formikActions.setSubmitting(false);
          handleCloseModal();
        }}
        validator={validateGroup}
        handleCloseModal={handleCloseModal}
        errorMessage={createGroupMutation.isError ? createGroupMutation.error : null}
        submitButtonText={'Create'}
        validateOnChange={true}
        validateOnBlur={false}
      >
        {(formikProps) => (
          <FormRow>
            <GroupNameField formikProps={formikProps} />
          </FormRow>
        )}
      </GenericFormModal>
    )
  );
};

export default CreateGroupModal;
