import useUserActivationMutation from 'legacy/features/organizations/api/mutations/useUserActivationMutation';
import { SimpleDropdownBtn, SimpleDropdownItem } from 'legacy/shared/styles/custom/DropdownMenu';

const ActivateUserMenuItem = ({ user }) => {
  const userActivationMutation = useUserActivationMutation();

  const activateUser = async () => {
    await userActivationMutation.mutateAsync({
      username: user.username,
      activate: true,
    });
  };

  return (
    <SimpleDropdownItem>
      <SimpleDropdownBtn onClick={activateUser}>Activate</SimpleDropdownBtn>
    </SimpleDropdownItem>
  );
};

export default ActivateUserMenuItem;
