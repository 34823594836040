import {
  requiredValidator,
  regexValidator,
  characterLimitValidator,
} from 'legacy/shared/utilities/helpers/validation/validators';

import {
  alphanumericExtendedRegex,
  positiveIntegerRegex,
} from 'legacy/shared/utilities/helpers/validation/validationRegularExpressions';

import {
  alphanumericExtendedRegexError,
  positiveIntegerRegexError,
} from 'legacy/shared/utilities/helpers/validation/validationErrorMessages';

export const alertValidators = {
  alertNameFieldValidation: requiredValidator(
    characterLimitValidator(
      regexValidator(alphanumericExtendedRegex, alphanumericExtendedRegexError),
    ),
  ),
  alertTypeFieldValidation: requiredValidator(),
  alertTriggerValueFieldValidation: requiredValidator(
    regexValidator(positiveIntegerRegex, positiveIntegerRegexError),
  ),
  unitOfMeasureFieldValidation: requiredValidator(),
  alertAddonFieldValidation: requiredValidator(),
  triggerDropdownValueValidation: requiredValidator(),
  alertMethodFieldValidation: requiredValidator(),
  alertRecipientFieldValidation: requiredValidator(),
};
