import GenerateGttAccessKeyButton from 'legacy/features/organizations/components/buttons/GenerateGttAccessKeyButton';
import WcpCheckboxField from 'legacy/shared/controls/WcpForm/v2/components/fields/WcpCheckboxField';
import useWcpFormContext from 'legacy/shared/controls/WcpForm/v2/hooks/useWcpFormContext';
import { FormSectionLabel } from 'legacy/shared/styles/custom/Form';
import { useCheckUserRole, userRoleKeys } from 'legacy/shared/utilities/hooks/useCheckUserRole';

const GttIntegrationCheckbox = () => {
  const isUserRoleAuthorized = useCheckUserRole(
    userRoleKeys.ORGANIZATION_ADMIN,
    userRoleKeys.FIELD_SOLUTION_ENGINEER,
  );

  const {
    formValues: { gttEnabled },
  } = useWcpFormContext();

  return (
    <div>
      <FormSectionLabel gtt>Miovision Integration</FormSectionLabel>
      <WcpCheckboxField
        dataKey={'gttEnabled'}
        label="Enable Miovision Integration"
        disabled={!isUserRoleAuthorized}
      />
      {isUserRoleAuthorized && gttEnabled && <GenerateGttAccessKeyButton />}
    </div>
  );
};

export default GttIntegrationCheckbox;
