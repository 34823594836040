import { useEffect, useState } from 'react';

import FirmwareUpdatesSummaryByVehicleGroupContainer from 'legacy/features/firmware/ui/byVehicle/FirmwareUpdatesSummaryByVehicleGroupContainer';
import FirmwareUpdatesSummaryByVehicleHeader from 'legacy/features/firmware/ui/byVehicle/FirmwareUpdatesSummaryByVehicleHeader';
import useAllExpanded from 'legacy/features/firmware/hooks/useAllExpanded';
import useFetchFirmwareByVehiclesQuery from 'legacy/features/firmware/hooks/useFetchFirmwareByVehiclesQuery';
import useSearchText from 'legacy/features/firmware/hooks/useSearchText';
import { SectionBody } from 'legacy/shared/styles/custom/Section';
import LoadingOverlay from 'legacy/shared/controls/WcpSpinner/LoadingOverlay';
import { formatISOTimestampToShortDate } from 'legacy/shared/utilities/misc/time';
import { getOnlyShowPendingOtaUpdatesByDevicePreferenceInLocal } from 'legacy/shared/utilities/misc/localStore';

const FirmwareUpdatesSummaryByVehicle = () => {
  let onlyShowPendingUpdatesPreference = getOnlyShowPendingOtaUpdatesByDevicePreferenceInLocal();
  const { fetchUpdatesQuery, updates } = useFetchFirmwareByVehiclesQuery();
  const [vehiclesWithUpdates, setVehiclesWithUpdates] = useState(null);
  const [onlyShowPendingUpdates, setOnlyShowPendingUpdates] = useState(
    onlyShowPendingUpdatesPreference ? onlyShowPendingUpdatesPreference : false,
  );
  const { searchText, setSearchText } = useSearchText();

  useEffect(() => {
    const filterPendingUpdates = (u) => {
      if (onlyShowPendingUpdates) {
        return u.firmwareStatus !== 'UP_TO_DATE' && u.firmwareStatus !== 'EXTERNAL_UPDATE';
      } else {
        return u;
      }
    };
    let vwu = updates?.vehicles
      ?.filter(({ products }) => products.length > 0)
      // TODO move to mapper
      .map(({ meta, products }) => ({
        vehicle: meta,
        // TODO move to mapper
        updates: products
          .map((p) => ({
            productId: p.product_id,
            version: p.version,
            channel: p.channel,
            productLine: p.product_line,
            deviceId: p.device_id,
            versionCurrent: p.version_current,
            versionInitial: p.version_initial,
            currentChannel: p.current_channel,
            error: p.error,
            firmwareStatus: p.firmware_status,
            vehicleLabel: meta.label,
            connectivityDeviceId: p.connectivity_device_id,
            productType: p.product_type,
            productName: p.product_name,
            releaseNotes: p.release_notes,
            releaseDate: p.release_date,
            time: p.time,
          }))
          .filter((u) => {
            return (
              u.vehicleLabel?.toLowerCase().includes(searchText.toLowerCase()) ||
              u.productName?.toLowerCase().includes(searchText.toLowerCase()) ||
              u.releaseNotes?.toLowerCase().includes(searchText.toLowerCase()) ||
              u.channel?.toLowerCase().includes(searchText.toLowerCase()) ||
              u.version?.toLowerCase().includes(searchText.toLowerCase()) ||
              formatISOTimestampToShortDate(u.releaseDate)?.includes(searchText.toLowerCase())
            );
          })
          .filter((u) => filterPendingUpdates(u))
          .sort((a, b) => a.productName.localeCompare(b.productName)),
      }))
      .sort((a, b) => a.vehicle.label.localeCompare(b.vehicle.label));

    setVehiclesWithUpdates(vwu);
  }, [searchText, updates, onlyShowPendingUpdates]);

  // count of all the vehicles with updates
  const filteredVehicleCount = vehiclesWithUpdates?.length || 0;

  // total updates in all the vehiclesWithUpdates
  const totalUpdates = vehiclesWithUpdates?.reduce(
    (acc, vehicle) => acc + vehicle.updates.length,
    0,
  );
  const { allExpanded, setAllExpanded, collapsedIndividualItems, setCollapsedIndividualItems } =
    useAllExpanded({
      filteredVehicleCount,
    });

  return !fetchUpdatesQuery.isFetchedAfterMount ? (
    <LoadingOverlay />
  ) : (
    <SectionBody>
      <FirmwareUpdatesSummaryByVehicleHeader
        allExpanded={allExpanded}
        setAllExpanded={setAllExpanded}
        setCollapsedIndividualItems={setCollapsedIndividualItems}
        collapsedIndividualItems={collapsedIndividualItems}
        filteredVehicleCount={filteredVehicleCount}
        searchText={searchText}
        setSearchText={setSearchText}
        onlyShowPendingUpdates={onlyShowPendingUpdates}
        setOnlyShowPendingUpdates={setOnlyShowPendingUpdates}
      />

      {_.isEmpty(vehiclesWithUpdates) || totalUpdates === 0 ? (
        <h4>No Updates Available</h4>
      ) : (
        vehiclesWithUpdates.map(({ vehicle, updates }) => {
          // only show there are updates
          if (updates.length === 0) return null;
          return (
            <FirmwareUpdatesSummaryByVehicleGroupContainer
              vehicle={vehicle}
              updates={updates}
              allExpanded={allExpanded}
              setAllExpanded={setAllExpanded}
              setCollapsedIndividualItems={setCollapsedIndividualItems}
              collapsedIndividualItems={collapsedIndividualItems}
            />
          );
        })
      )}
    </SectionBody>
  );
};
export default FirmwareUpdatesSummaryByVehicle;
