import { getDaysSince, getShortDateInIsoFormat } from 'legacy/shared/utilities/misc/time';

export const vehiclePlaybackEndDateValidator = (startDate, endDate) => {
  if (startDate === null || endDate === null) return 'Please Enter a valid date range';
  const difference = getDaysSince({
    fromDateTime: getShortDateInIsoFormat(startDate),
    toDateTime: getShortDateInIsoFormat(endDate),
  });
  if (difference > 1) {
    return 'Please enter a date within 1 day of the start date';
  }
  if (typeof nextValidator === 'function') {
    return nextValidator(...[value, ...rest]);
  }
};
