import React, { useState, useRef } from 'react';
import { connect } from 'react-redux';
import { Navigate } from 'react-router-dom';

import { getPasswordResetCode, getForgottenUsername } from 'legacy/core/redux/user/actions';

import AnimatedField from 'legacy/shared/controls/AnimatedField/v1/AnimatedField';
import {
  Wrapper,
  StyledFormWrapper,
  LoginWrapper,
  StyledHead,
  StyledBody,
  StyledTitle,
  StyledIconWrapper,
  StyledFoot,
  WhelenLogo,
  WCPLogo,
  PreLogoText,
} from 'legacy/shared/styles/custom/LoginSignup';
import {
  RadioButtonContainer,
  RadioButton,
  RadioButtonLabel,
} from 'legacy/shared/styles/custom/Radio';
import ButtonWithLoader from 'legacy/shared/controls/ButtonWithLoader/ButtonWithLoader';

import whelenLogo from 'assets/images/whelenlogo@2x.png';
import wcpLogo from 'assets/images/wcpLogo@2x.png';

const TroubleLoggingInForm = ({
  requestingForgottenCredentials,
  getPasswordResetCode,
  getForgottenUsername,
}) => {
  const refUsernameInput = useRef(null);
  const refEmailInput = useRef(null);

  const [redirectToCodeEntry, setRedirect] = useState(false);
  const [selectedOption, selectOption] = useState(null);
  const [redirectPath, setRedirectPath] = useState(null);

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  const handleOptionSelect = (e) => {
    // selectOption(e.target.value);
    selectOption(e);
  };

  const onSubmitUsername = async () => {
    try {
      const { response } = await getPasswordResetCode({
        username: refUsernameInput.current.value,
      });
      const redirectPath = '/login';

      if (response && response.message) {
        setRedirectPath(redirectPath);
        setRedirect(true);
      }
    } catch (e) {
      console.log('error getting password reset code', e);
    }
  };

  const onSubmitEmail = async () => {
    try {
      const { response } = await getForgottenUsername({
        email: refEmailInput.current.value,
      });

      if (response && response.message) {
        setRedirectPath('/login');
        setRedirect(true);
      }
    } catch (e) {
      console.log('error getting forgotten username', e);
    }
  };

  return (
    <Wrapper>
      {redirectToCodeEntry === true ? <Navigate to={redirectPath} /> : null}
      <LoginWrapper>
        <StyledFormWrapper>
          <StyledHead>
            <StyledIconWrapper>
              <WCPLogo src={wcpLogo} alt="Whelen Cloud Platform" />
            </StyledIconWrapper>

            <StyledTitle>Trouble Logging In?</StyledTitle>
          </StyledHead>

          <StyledBody style={{ marginTop: '-30px' }}>
            <form onSubmit={handleSubmit}>
              <RadioButtonContainer onClick={() => handleOptionSelect('forgotPassword')}>
                <RadioButton
                  type="radio"
                  value="forgotPassword"
                  checked={selectedOption === 'forgotPassword'}
                />
                <RadioButtonLabel>Forgot Password</RadioButtonLabel>
              </RadioButtonContainer>

              {selectedOption === 'forgotPassword' && (
                <>
                  <div style={descriptionStyles}>
                    To reset your password enter your Username and an email will be sent to the
                    email of the associated user.
                  </div>
                  <AnimatedField
                    containerStyles={inputStyles}
                    innerRef={refUsernameInput}
                    placeholder="Username"
                    type="text"
                    id="username"
                    required
                  />
                </>
              )}

              <RadioButtonContainer onClick={() => handleOptionSelect('forgotUsername')}>
                <RadioButton
                  type="radio"
                  value="forgotUsername"
                  checked={selectedOption === 'forgotUsername'}
                />
                <RadioButtonLabel>Forgot Username</RadioButtonLabel>
              </RadioButtonContainer>
              {selectedOption === 'forgotUsername' && (
                <>
                  <div style={descriptionStyles}>
                    Enter your email address and a list of your usernames and associated
                    organizations will be emailed to you.
                  </div>
                  <AnimatedField
                    containerStyles={inputStyles}
                    innerRef={refEmailInput}
                    placeholder="Email Address"
                    type="email"
                    id="email"
                    required
                  />
                </>
              )}
              <StyledFoot>
                <ButtonWithLoader
                  isLoading={requestingForgottenCredentials}
                  confirmText={'Continue'}
                  loadingStyleProp={'loginWithSpinner'}
                  notLoadingStyleProp={'loginButton'}
                  clickHandler={
                    selectedOption === 'forgotPassword' ? onSubmitUsername : onSubmitEmail
                  }
                />
                <PreLogoText>POWERED BY</PreLogoText>
                <WhelenLogo src={whelenLogo} alt="Whelen" />
              </StyledFoot>
            </form>
          </StyledBody>
        </StyledFormWrapper>
      </LoginWrapper>
    </Wrapper>
  );
};

export default connect(
  (state) => ({ requestingForgottenCredentials: state.user.requestingForgottenCredentials }),
  (dispatch) => ({
    getPasswordResetCode: async ({ username }) => {
      return dispatch(await getPasswordResetCode({ username }));
    },
    getForgottenUsername: async ({ email }) => {
      return dispatch(await getForgottenUsername({ email }));
    },
  }),
)(TroubleLoggingInForm);

const descriptionStyles = {
  marginTop: '-22px',
  marginBottom: '25px',
  paddingLeft: '25px',
  paddingRight: '10px',
  color: '#8a909c',
  fontSize: '13px',
};

const inputStyles = {
  paddingLeft: '25px',
  paddingRight: '10px',
};
