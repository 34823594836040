import mapboxgl from '!mapbox-gl';
import MapboxDraw from '@mapbox/mapbox-gl-draw';
import { useContext, useEffect, useRef, useState } from 'react';
import * as turf from '@turf/turf';

import { GeofencesApi } from 'legacy/features/geofences/geofences/components/GeofencesContainer';
import { useFlyToGeofence } from 'legacy/features/geofences/geofences/components/hooks/useFlyToGeofence';
import GeofenceMapCard from 'legacy/features/geofences/geofences/components/mapCard/GeofenceMapCard';
import { selectionMethod } from 'legacy/features/geofences/geofences/constants/enums';
import {
  mapboxAccessToken,
  mapboxStyleUrl,
  whelenGpsCoordinates,
} from 'legacy/shared/constants/map';
import ConfirmationModal from 'legacy/shared/controls/WcpModal/v1/ConfirmationModal';
import ModalPortal from 'legacy/shared/controls/WcpModal/v1/ModalPortal';
import { MapboxDiv, MapContentDiv } from 'legacy/shared/styles/custom/SharedMapStyledComponents';
import { getLastMapLocationInLocal } from 'legacy/shared/utilities/misc/localStore';
import MapControls from 'legacy/shared/controls/MapControls/v1/MapControls';
import { MapboxCustomControls } from 'legacy/shared/utilities/classes/mapbox/MapboxCustomControls';
import { toggleMapboxStyleUrl } from 'legacy/shared/constants/map';

import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css';

const GeofencesMapboxComponent = ({ currentOrganizationId, geofencesData }) => {
  const { handleSelectGeofence, selectedGeofenceId, drawPolygon, setDrawPolygon } =
    useContext(GeofencesApi);

  /* #region  State */

  const [updateConfirmation, setUpdateConfirmation] = useState(null);
  const [selectedGeofenceData, setSelectedGeofenceData] = useState(null);
  const [geofenceArea, setGeofenceArea] = useState(null);
  const [unsavedChanges, setUnsavedChanges] = useState(null);
  const [mapStyle, setMapStyle] = useState(mapboxStyleUrl);
  const [geofenceAreaWarning, setGeofenceAreaWarning] = useState(null);

  /* #endregion */

  /* #region  Refs */

  const mapboxRef = useRef(null);
  const mapboxDrawRef = useRef(null);
  const mapContainerRef = useRef(null);
  const previouslySelectedGeofenceIdRef = useRef(false);
  const mapControlsRef = useRef(null);

  const setPreviouslySelectedGeofenceId = (id) => {
    previouslySelectedGeofenceIdRef.current = id;
  };

  const getPreviouslySelectedGeofenceId = () => {
    return previouslySelectedGeofenceIdRef.current;
  };

  /* #endregion */

  /* #region  Helpers */
  const calculateGeofenceArea = ({ points }) => {
    var p = turf.polygon([points]);
    var a = turf.area(p);
    setGeofenceArea(a);
  };

  const getFeatureCollection = (newData) => {
    let features = newData
      .filter((gf) => !gf.new)
      .map((gf) => ({
        type: 'Feature',
        properties: {
          geofenceId: gf.geofenceId,
        },
        geometry: {
          type: 'Polygon',
          coordinates: [gf.points],
        },
      }));

    return {
      type: 'FeatureCollection',
      features,
    };
  };

  const initializeMap = ({ mapContainer }) => {
    mapboxgl.accessToken = mapboxAccessToken;
    const sessionBounds = getLastMapLocationInLocal();

    let map = new mapboxgl.Map({
      container: mapContainer.current,
      style: mapStyle,
      dragRotate: false,
      maxZoom: 25,
      bounds: sessionBounds ? sessionBounds : whelenGpsCoordinates,
      fitBoundsOptions: { padding: 50, maxZoom: 10 },
    });

    const mapLoadedEvent = new Event('mapLoaded', { bubbles: true });
    window.document.dispatchEvent(mapLoadedEvent);

    let mapboxDraw = new MapboxDraw({
      boxSelect: false,
      displayControlsDefault: false,
      // Select which mapbox-gl-draw control buttons to add to the map.
      controls: {
        polygon: true,
        trash: false,
      },
    });

    map.addControl(mapboxDraw);

    map.on('load', function () {});

    // set ref to mapbox object
    mapboxRef.current = map;
    mapboxDrawRef.current = mapboxDraw;
    document
      .querySelector('.mapbox-gl-draw_ctrl-draw-btn.mapbox-gl-draw_polygon')
      .setAttribute('title', 'Create Geofence');
  };

  // select the geofence data by id
  const getGeofenceDataFromPropsById = (id) => {
    return geofencesData.find((gf) => gf.geofenceId === id);
  };

  // refreshes the mapbox feature data
  const resetMapboxDataFromGeofenceData = () => {
    mapboxDrawRef.current?.set(getFeatureCollection(geofencesData));
  };

  const findFeatureIdForSelectedGeofence = (id) => {
    const mapData = mapboxDrawRef.current?.getAll();

    return mapData.features.find((f) => f.properties.geofenceId === id)?.id;
  };

  const setGeofenceFeatureToSimpleSelectMode = (id) => {
    // find selected geofence on map
    let selectedFeatureId = findFeatureIdForSelectedGeofence(id);

    // select that geofence (simple_select lets you select, delete, and drag features)
    if (selectedFeatureId) {
      mapboxDrawRef.current?.changeMode('simple_select', {
        featureIds: [selectedFeatureId],
      });
    }
  };

  const setGeofenceFeatureToDirectSelectMode = (id) => {
    // find selected geofence on map
    let selectedFeatureId = findFeatureIdForSelectedGeofence(id);

    // select that geofence (simple_select lets you select, delete, and drag features)
    if (selectedFeatureId) {
      mapboxDrawRef.current?.changeMode('direct_select', {
        featureId: selectedFeatureId,
      });
    }
  };

  const setGeofenceFeaturesToDeselected = () => {
    // deselect
    mapboxDrawRef.current?.changeMode('simple_select', {
      featureIds: [],
    });
  };

  const openUpdateConfirmation = ({ selectedGeofenceData, selectOrigin }) => {
    setUpdateConfirmation(
      selectedGeofenceData
        ? { selectedGeofenceData, selectOrigin }
        : { selectedGeofenceData: { geofenceId: null }, selectOrigin },
    );
  };

  const closeUpdateConfirmation = () => {
    setUpdateConfirmation(false);
  };

  const closeGeofenceAreaWarning = () => {
    setGeofenceAreaWarning(false);
    //clear out calculation
    setGeofenceArea(null);
  };

  // updates selectedGeofenceData state to match selected geofenceId from Props and caches previous id
  const updateSelectedGeofence = () => {
    // select or deslect on map
    if (selectedGeofenceId.id !== null) {
      // set geofence on map to select mode
      setGeofenceFeatureToSimpleSelectMode(selectedGeofenceId.id);
    } else {
      // deselect features on map
      setGeofenceFeaturesToDeselected();
    }

    // get the data of the geofence from props
    let selectedGeofenceData = getGeofenceDataFromPropsById(selectedGeofenceId.id);

    // set previously selected
    setPreviouslySelectedGeofenceId(selectedGeofenceId.id);
    // save the selected geofence data to state
    setSelectedGeofenceData(selectedGeofenceData);
  };

  const updateSelectedGeofenceDataPoints = () => {
    // get mapboxdraw ref and selected feature
    const selection = mapboxDrawRef.current?.getSelected();
    const selectedGeofence = selection.features[0];

    // reset points on selected geofence
    if (selectedGeofence)
      setSelectedGeofenceData((psgf) => ({
        ...psgf,
        ...{ points: selectedGeofence.geometry.coordinates[0] },
      }));
  };
  /* #endregion */

  /* #region  Mapbox Event Handlers */

  const onMapSelectionChanged = (e) => {
    // get selected feature
    let selectedGeofenceIdFromMapboxData = e.features[0]?.properties?.geofenceId || null;

    handleSelectGeofence({
      id: selectedGeofenceIdFromMapboxData,
      selectOrigin: selectionMethod.MAP,
    });
  };

  const onMapCreate = (e) => {
    let data = e.features[0];

    if (data) {
      let newGeoFence = {
        geofenceId: data.id,
        organizationId: currentOrganizationId,
        points: [...data.geometry.coordinates[0]],
        new: true,
      };

      // calculate area
      calculateGeofenceArea({ points: newGeoFence.points });

      setSelectedGeofenceData(newGeoFence);
    }
  };

  const onMapUpdate = (e) => {
    // set unsaved to true
    setUnsavedChanges(true);

    // get mapboxdraw ref and selected feature
    const selection = mapboxDrawRef.current?.getSelected();
    const selectedGeofence = selection.features[0];

    // reset points on selected geofence
    setSelectedGeofenceData((psgf) => ({
      ...psgf,
      ...{ points: selectedGeofence.geometry.coordinates[0] },
    }));

    // calculate area
    calculateGeofenceArea({ points: selectedGeofence.geometry.coordinates[0] });
  };

  const onMapDelete = (e) => {
    const data = mapboxDrawRef.current?.getAll();

    if (data?.features.length > 0) {
    }
  };

  const onMapDrawModeChanged = (e) => {
    if (mapboxDrawRef.current.getMode() == 'draw_polygon') {
      setDrawPolygon(true);
    } else {
      setDrawPolygon(false);
    }
  };

  /* #endregion */

  /* #region  Effects */

  // initializes mapbox
  useEffect(() => {
    if (!mapboxRef.current) initializeMap({ mapContainer: mapContainerRef });

    return () => {
      if (mapboxRef?.current) {
        mapboxRef.current.remove();
        mapboxRef.current = null;
      }
      if (mapboxDrawRef?.current) {
        mapboxDrawRef.current = null;
      }
    };
  }, []);

  useEffect(() => {
    if (mapboxRef.current.hasControl(mapControlsRef.current)) {
      mapboxRef.current.removeControl(mapControlsRef.current);
    }
    mapControlsRef.current = new MapboxCustomControls(
      (
        <MapControls
          map={mapboxRef.current}
          showSatelliteLayerToggle={true}
          showFocusToggle={true}
          filteredVehicleMapBounds={mapboxRef.bounds}
          toggleSatelliteView={() => {
            setMapStyle(toggleMapboxStyleUrl(mapStyle));
          }}
        />
      ),
    );
    mapboxRef.current.addControl(mapControlsRef.current, 'bottom-right');
    mapboxRef.current.setStyle(mapStyle, { diff: false });
  }, [mapStyle]);

  // on first load / geofence data change
  // reset mapbox features
  // set up mapbox draw event handlers
  useEffect(() => {
    // reset mapbox draw features
    resetMapboxDataFromGeofenceData();

    // register / reset events
    mapboxRef.current?.on('draw.selectionchange', onMapSelectionChanged); // https://github.com/mapbox/mapbox-gl-draw/blob/main/docs/API.md#drawselectionchange
    mapboxRef.current?.on('draw.create', onMapCreate); //https://github.com/mapbox/mapbox-gl-draw/blob/main/docs/API.md#drawcreate
    mapboxRef.current?.on('draw.update', onMapUpdate); // etc
    mapboxRef.current?.on('draw.delete', onMapDelete);
    mapboxRef.current?.on('draw.modechange', onMapDrawModeChanged);

    return () => {
      mapboxRef.current?.off('draw.selectionchange', onMapSelectionChanged);
      mapboxRef.current?.off('draw.create', onMapCreate);
      mapboxRef.current?.off('draw.update', onMapUpdate);
      mapboxRef.current?.off('draw.delete', onMapDelete);
      mapboxRef.current?.off('draw.modechange', onMapDrawModeChanged);
    };
  }, [geofencesData]);

  // whenever selected geofence id (from Props) changes
  // handles setting selected geofence data, selecting geofence on map
  // or throws up confirmation modal
  useEffect(() => {
    let { id, selectOrigin } = selectedGeofenceId;

    // creating a new geofence
    if (selectedGeofenceData?.new) {
      // reset the select mode to this id
      setGeofenceFeatureToDirectSelectMode(id);
    }
    // selecting the same geofence
    else if (id === getPreviouslySelectedGeofenceId()) {
      // reset the select mode to this id
      setGeofenceFeatureToDirectSelectMode(id);

      updateSelectedGeofenceDataPoints();
    }
    // no unsaved changes
    else if (!unsavedChanges) {
      updateSelectedGeofence();
    }
    // unsaved changes
    else {
      let selectedGeofenceData = getGeofenceDataFromPropsById(id);
      openUpdateConfirmation({ selectedGeofenceData, selectOrigin });
    }
  }, [selectedGeofenceId, selectedGeofenceId.id]);

  // takes mapbox ref and points of selected geofence and zooms in on geofence whenever selectedGeofenceData changes
  useFlyToGeofence(mapboxRef.current, selectedGeofenceData, unsavedChanges);

  /* #endregion */

  return (
    <MapContentDiv>
      <MapboxDiv ref={mapContainerRef} />
      <>
        {updateConfirmation && (
          <ModalPortal
            onRequestClose={() => {
              closeUpdateConfirmation();
            }}
          >
            <ConfirmationModal
              bodyText={<p>Are you sure you want to discard unsaved changes?</p>}
              // cancel changes
              cancelHandler={() => {
                // should keep unsaved changes state
                setUnsavedChanges(true);

                // reselect from parent context so sidebar is included
                handleSelectGeofence({
                  id: getPreviouslySelectedGeofenceId(),
                  selectOrigin: updateConfirmation.selectOrigin,
                });

                // close modal
                closeUpdateConfirmation();
              }}
              confirmHandler={async () => {
                // discard changes
                resetMapboxDataFromGeofenceData();

                // clear unsaved
                setUnsavedChanges(false);

                // select new or null geofence from parent context so sidebar is included
                handleSelectGeofence({
                  id: updateConfirmation?.selectedGeofenceData?.geofenceId,
                  selectOrigin: updateConfirmation.selectOrigin,
                });

                // close pop up
                closeUpdateConfirmation();
              }}
              cancelText={'Cancel'}
              confirmText={'Discard Changes'}
              title={'Discard Changes?'}
            />
          </ModalPortal>
        )}
        {geofenceAreaWarning && (
          <ModalPortal
            onRequestClose={() => {
              closeGeofenceAreaWarning();
            }}
          >
            <ConfirmationModal
              bodyText={`Your geofence is ${Math.floor(
                geofenceArea,
              )} square meters. Due to the limitations of GPS, geofences 120 square meters or below might not behave as expected.`}
              confirmHandler={() => {
                closeGeofenceAreaWarning();
              }}
              confirmText={'OK'}
              title={'Geofence Area Warning'}
            />
          </ModalPortal>
        )}
      </>
      {selectedGeofenceData && (
        <GeofenceMapCard
          currentOrganizationId={currentOrganizationId}
          geofenceMapData={selectedGeofenceData}
          handleRequestClose={({ saveChanges }) => {
            // if saving, clear unsaved
            if (saveChanges) {
              setUnsavedChanges(false);
            }
            if (selectedGeofenceData?.new) {
              updateSelectedGeofence();
            }
            // deselect everything
            handleSelectGeofence({
              id: null,
              selectOrigin: selectionMethod.CARD,
            });

            // alert if area is under 120
            if (geofenceArea && geofenceArea < 120) {
              setGeofenceAreaWarning(true);
            }
          }}
          unsavedChanges={unsavedChanges}
          setUnsavedChanges={setUnsavedChanges}
        ></GeofenceMapCard>
      )}
    </MapContentDiv>
  );
};

export default GeofencesMapboxComponent;
