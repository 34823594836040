import { useEffect, useState } from 'react';

import { convertTimeZone } from 'legacy/shared/utilities/misc/time';

export const LiveMapHeaderDateTime = () => {
  const [dateTime, setDateTime] = useState(new Date());

  useEffect(() => {
    const id = setInterval(() => setDateTime(new Date()), 1000);
    return () => {
      clearInterval(id);
    };
  }, []);

  return (
    <>
      {`${dateTime.toLocaleDateString()} ${dateTime.toLocaleTimeString()}`}{' '}
      {convertTimeZone(Intl.DateTimeFormat().resolvedOptions().timeZone)}
    </>
  );
};
