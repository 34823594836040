import WcpCheckboxField from 'legacy/shared/controls/WcpForm/v2/components/fields/WcpCheckboxField';
import { FormSectionLabel, OptInFeatureWrapper } from 'legacy/shared/styles/custom/Form';
import { useCheckUserRole, userRoleKeys } from 'legacy/shared/utilities/hooks/useCheckUserRole';

const AlertsActiveCheckbox = () => {
  const isUserRoleAuthorized = useCheckUserRole(
    userRoleKeys.SYSTEM_ADMIN,
    userRoleKeys.ORGANIZATION_ADMIN,
    userRoleKeys.FIELD_SOLUTION_ENGINEER,
  );

  return (
    isUserRoleAuthorized && (
      <>
        <FormSectionLabel gtt>Organization Alerts</FormSectionLabel>
        <OptInFeatureWrapper>
          <WcpCheckboxField
            dataKey={'alertsActive'}
            inverted={true}
            label="Mute Alerts"
            disabled={!isUserRoleAuthorized}
          />
        </OptInFeatureWrapper>
      </>
    )
  );
};

export default AlertsActiveCheckbox;
