import React, { useState, useEffect } from 'react';
import Media from 'react-media';
import { sizes } from 'legacy/shared/utilities/misc/media';
import { VehicleDeviceFields } from 'legacy/features/vehicles/components/devices/mobile/VehicleDeviceFields';
import { VehicleDeviceTable } from 'legacy/features/vehicles/components/devices/VehicleDeviceTable';
import { getWhitelists as getWhitelistsAPI } from 'legacy/core/api/whitelists';
import { getConfigurations as getConfigurationsAPI } from 'legacy/core/api/configuration';
import LoadingOverlay from 'legacy/shared/controls/WcpSpinner/LoadingOverlay';
import { connect } from 'react-redux';
import {
  dispatchDeviceUpdateToasts,
  createNotification,
  LEVELS,
} from 'legacy/shared/utilities/misc/notification';
import { addNotification } from 'legacy/core/redux/ui/actions';
import { checkForUserPermission } from 'legacy/core/redux/user/selectors';
import { permissionData } from 'legacy/shared/constants/users';
import { fetchAPICall, getAuthToken } from 'legacy/core/api/API';
import { baseApiUrlFirmwareRepo } from 'legacy/shared/v1/constants/api';
import {
  InlineSpinnerWithMessageWrapper,
  SpinnerSpacer,
} from 'legacy/shared/styles/custom/Spinner';
import SmallLoadingSpinner from 'legacy/shared/controls/WcpSpinner/SmallLoadingSpinner';

const VehicleDeviceContainer = ({ userState, dispatchNotification, deviceData, handleChange }) => {
  const [productsWithChannels, setProductsWithChannels] = useState(null);
  const [configurations, setConfigurations] = useState(null);

  const [channelsWithVersions, setChannelsWithVersions] = useState(null);

  const getLatestVersion = async (channel, productId) => {
    let response = await fetchAPICall({
      endpoint: 'cloudfrontAPI',
      baseURI: baseApiUrlFirmwareRepo,
      resourcePath: `/firmware/${productId}/channel/${channel}`,
      method: 'GET',
      headers: {
        Authorization: getAuthToken(),
      },
    });

    return response.version;
  };
  const getLatestProductionVersion = async (productId) => {
    let response = await fetchAPICall({
      endpoint: 'cloudfrontAPI',
      baseURI: baseApiUrlFirmwareRepo,
      resourcePath: `/firmware/${productId}/channel/PRODUCTION`,
      method: 'GET',
      headers: {
        Authorization: getAuthToken(),
      },
    });

    return response.version;
  };

  const getChannelsWithVersions = async () => {
    let results = [];

    for (const p of productsWithChannels) {
      for (const c of p.channels) {
        let latestVersion = await getLatestVersion(c, p.product_id);
        results.push({
          product_id: p.product_id,
          product_name: p.product_name,
          channel: c,
          version: latestVersion,
        });
      }

      let latestProductionVersion = await getLatestProductionVersion(p.product_id);
      let duplicate = results.find(
        (r) => r.channel === 'PRODUCTION' && r.product_id === p.product_id,
      );
      if (!duplicate)
        results.push({
          product_id: p.product_id,
          product_name: p.product_name,
          channel: 'PRODUCTION',
          version: latestProductionVersion,
        });
    }

    setChannelsWithVersions(results);
  };

  useEffect(() => {
    if (productsWithChannels) getChannelsWithVersions();
  }, [productsWithChannels]);

  const handleUpdateConfigChannel = (uid, target_id) => {
    return deviceData.map((device) => {
      if (device.uid === uid) {
        let test = {
          ...device,
          ...{
            config: {
              ...device.config,
              target_id,
            },
          },
        };
        return test;
      }
      return device;
    });
  };

  const handleUpdateFirmwareChannel = (uid, channel) => {
    return deviceData.map((device) => {
      if (device.uid === uid) {
        let test = {
          ...device,
          ...{
            firmware: {
              ...device.firmware,
              target: {
                ...device.firmware.channel,
                channel,
              },
            },
          },
        };
        return test;
      }
      return device;
    });
  };

  // EVENT HANDLERS
  const generateHandleSelectChange = (uid, selectType) => {
    return (option) => {
      const updatedDevices =
        selectType === 'configuration'
          ? handleUpdateConfigChannel(uid, option.value)
          : handleUpdateFirmwareChannel(uid, option.value);

      const vsg = updatedDevices.find((d) => d.product_type === 'Connectivity Module');
      const ccc = updatedDevices.find((d) => d.product_type === 'CenComCore');
      const peripherals = updatedDevices.filter(
        (d) => d.product_type !== 'CenComCore' && d.product_type !== 'Connectivity Module',
      );

      const nestedNextDeviceData = {
        ...(vsg && {
          ...vsg,
          ...(ccc && {
            CenComCore: {
              ...ccc,
              ...(peripherals && {
                devices: peripherals,
              }),
            },
          }),
        }),
      };

      handleChange(nestedNextDeviceData);
    };
  };

  useEffect(() => {
    getWhitelists();
    getConfigurations();
  }, []);

  useEffect(() => {
    // no cencomcore config section
    if (
      categorizedDevices.cenComCore &&
      categorizedDevices.cenComCore[0] &&
      !categorizedDevices.cenComCore[0].config
    ) {
      dispatchNotification.invalidDeviceTreeError({ err: 'CenComCore Config section missing' });
    }
  }, []);

  // HELPERS
  const categorizedDevices = deviceData.reduce(
    (memo, device) => {
      switch (device.product_type) {
        case 'Connectivity Module':
          return {
            ...memo,
            connectivityModule: [device],
          };
        case 'CenComCore':
          return {
            ...memo,
            cenComCore: [device],
          };
        default:
          return {
            ...memo,
            peripherals: [...memo.peripherals, device],
          };
      }
    },
    {
      connectivityModule: [],
      cenComCore: [],
      peripherals: [],
    },
  );

  /// API

  const getWhitelists = async () => {
    try {
      const {
        response: { message },
      } = await getWhitelistsAPI({ organizationId: userState.filteredOrganizationId });

      // by default devices are set to the PRODUCTION channel, but if there is no whitelist (most common scenario in production) then it cant select a channel that isnt in a non existant whitelist in the dropdown on the firmware channel
      // quick fix to deal with products that aren't in the whitelist
      let devicesNotInWhitelist = deviceData.filter((device) => {
        return !message.some((product) => product.product_id === device.product_id);
      });

      let productsWithChannels = [
        ...message,
        ...devicesNotInWhitelist.map((device) => ({
          product_id: device.product_id,
          channels: [],
          product_name: device.product_name,
        })),
      ];

      console.log(productsWithChannels);
      setProductsWithChannels(productsWithChannels);
    } catch (err) {
      dispatchNotification.getWhitelistsError({ err });
    }
  };

  const getConfigurations = async () => {
    try {
      // always use the current selected org when checking for configs
      const orgId = userState.filteredOrganizationId;

      const {
        response: { message },
      } = await getConfigurationsAPI(orgId);

      setConfigurations(message);
    } catch (err) {
      dispatchNotification.getConfigurationsError({ err });
    }
  };

  const isLoading = !configurations || !channelsWithVersions;

  return isLoading ? (
    <InlineSpinnerWithMessageWrapper>
      Loading vehicle device data
      <SpinnerSpacer />
      <SmallLoadingSpinner />
    </InlineSpinnerWithMessageWrapper>
  ) : (
    <Media
      queries={{
        tablet: { maxWidth: sizes.tablet },
      }}
    >
      {(matches) =>
        matches.tablet ? (
          <VehicleDeviceFields
            otaConfigEditPermissions={checkForUserPermission(
              userState,
              permissionData.editotaconfig,
            )}
            otaFirmwareEditPermissions={checkForUserPermission(
              userState,
              permissionData.editotafirmware,
            )}
            firmwareChannels={channelsWithVersions}
            categorizedDevices={categorizedDevices}
            configurations={configurations}
            generateHandleSelectChange={generateHandleSelectChange}
          />
        ) : (
          <VehicleDeviceTable
            otaConfigEditPermissions={checkForUserPermission(
              userState,
              permissionData.editotaconfig,
            )}
            otaFirmwareEditPermissions={checkForUserPermission(
              userState,
              permissionData.editotafirmware,
            )}
            firmwareChannels={channelsWithVersions}
            categorizedDevices={categorizedDevices}
            configurations={configurations}
            generateHandleSelectChange={generateHandleSelectChange}
          />
        )
      }
    </Media>
  );
};
export default connect(
  (state) => ({
    userState: state.user,
  }),
  (dispatch) => ({
    dispatchNotification: {
      getWhitelistsError: ({ err }) =>
        dispatchDeviceUpdateToasts(
          addNotification({
            notification: createNotification(LEVELS.ERROR, 'Error Getting whitelists', err),
          }),
        ),
      getConfigurationsError: ({ err }) =>
        dispatch(
          addNotification({
            notification: createNotification(LEVELS.ERROR, 'Error Getting configurations', err),
          }),
        ),
      invalidDeviceTreeError: ({ err }) =>
        dispatch(
          addNotification({
            notification: createNotification(
              LEVELS.ERROR,
              'A device on this vehicle is in an invalid state',
              err,
            ),
          }),
        ),
    },
  }),
)(VehicleDeviceContainer);
