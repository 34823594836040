import React from 'react';
import IconSvgComponent from 'legacy/shared/controls/WcpIcon/IconSvgComponent';
import LinkButton from 'legacy/shared/styles/custom/LinkButton';

export default () => {
  return (
    <div style={styles.container}>
      <IconSvgComponent svgFileName="danger" alt="Alert" style={styles.icon} />
      <h3>Something went wrong. Please try refreshing the page.</h3>
      <LinkButton to={'/livemap'}>Take me to the live map</LinkButton>
      <h5 style={styles.space}>Let us know what happened</h5>
      <LinkButton to={'/help'}>Submit a help request</LinkButton>
    </div>
  );
};

const styles = {
  container: {
    margin: '0 auto',
    textAlign: 'center',
  },
  icon: {
    width: '100px',
    marginBottom: '20px',
  },
  space: {
    marginTop: 70,
  },
};
