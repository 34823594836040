import { stripTrailingPercentage } from 'legacy/features/maintenance/utilities/formatting';
import AnimatedField from 'legacy/shared/controls/AnimatedField/v1/AnimatedField';

const ReminderOilLifeField = ({
  formikProps: { values, touched, errors, handleChange, handleBlur },
}) => {
  let reminderOilLifeWithPercentage = stripTrailingPercentage(values.reminderOilLife) + '%';

  return (
    <div>
      <AnimatedField
        name="reminderOilLife"
        placeholder="Oil Life"
        value={reminderOilLifeWithPercentage}
        touched={touched.reminderOilLife}
        validationError={errors.reminderOilLife}
        handleChange={handleChange}
        handleBlur={handleBlur}
        disabled={false}
        required
      />
    </div>
  );
};

export default ReminderOilLifeField;
