import React from 'react';
import { GroupTagDiv } from 'legacy/shared/styles/custom/GroupTags';
import { getAssociatedGroups } from 'legacy/shared/utilities/misc/vehicle';

export default function VehicleGroupTags({ vehicle, groups }) {
  // get associated groups
  if (groups) {
    vehicle.groups = getAssociatedGroups({ vehicle, groups });
  }

  return (
    <div>
      {vehicle &&
        vehicle.groups &&
        vehicle.groups.map((group) => (
          <GroupTagDiv key={group.groupName || group.group_name}>
            {group.groupName || group.group_name}
          </GroupTagDiv>
        ))}
    </div>
  );
}
