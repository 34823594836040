import useMakeFormikField from 'legacy/shared/controls/WcpForm/v1/hooks/useMakeFormikField';
import FilteredDropdown from 'legacy/shared/controls/WcpFilteredDropdown/v1/FilteredDropdown';
import { DropdownWrapper } from 'legacy/shared/styles/custom/ManageAlerts';
import useAlertFormsProviderContext from 'legacy/features/alerts/services/providers/AlertFormsProvider/hooks/useAlertFormsProviderContext';

const VehicleGroupDropdown = () => {
  const {
    state: {
      alert: {
        alertDetails: {
          vehicleInfo: { vehicleGroupId },
        },
      },
      vehicleGroups,
    },
    actions: { updateAlertDetailsAction },
  } = useAlertFormsProviderContext();

  useMakeFormikField({ fieldName: 'vehicleGroupId', fieldValue: vehicleGroupId });
  const defaultSelectedGroup = vehicleGroups?.find(
    (g) => g.group_id === vehicleGroupId,
  )?.group_name;

  return (
    vehicleGroups && (
      <DropdownWrapper>
        <FilteredDropdown
          restrictedHeightResults
          dropdownOptions={vehicleGroups?.map((g) => g.group_name)}
          defaultSelectedItem={defaultSelectedGroup}
          title={'Vehicle Group'}
          placeholder={defaultSelectedGroup || 'All Vehicle Groups'}
          onItemSelect={(selected) => {
            if (selected) {
              const vg = vehicleGroups?.find((g) => g.group_name === selected);
              updateAlertDetailsAction({ vehicleGroupId: vg.group_id });
            } else {
              updateAlertDetailsAction({ vehicleGroupId: null });
            }
          }}
        />
      </DropdownWrapper>
    )
  );
};

export default VehicleGroupDropdown;
