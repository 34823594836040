export const convertToCamelCase = (str) =>
  str
    .replace(/\s(.)/g, ($1) => $1.toUpperCase())
    .replace(/\s/g, '')
    .replace(/^(.)/, ($1) => $1.toLowerCase());

// TODO: move these to general utilities when refactoring configurations
export const convertSnakeToCamelCase = (key) => {
  return key
    .split('_')
    .map((segment, index) =>
      index > 0 ? `${segment[0].toUpperCase()}${segment.substring(1)}` : segment,
    )
    .join('');
};
