import { getCurrentHour, getToday } from 'legacy/shared/utilities/misc/time';

export const getDefaultEndTime = (endDate, endTime, readOnly) => {
  const today = getToday();
  const currentHour = getCurrentHour();

  // if the form is read only, end time should be null
  // otherwise, if today is selected for end date and the endTime is 11pm, set the end time to the current hour
  //else set to the current endTime
  if (readOnly) {
    return null;
  } else if (endDate === today && endTime === 23) {
    return currentHour;
  } else {
    return endTime;
  }
};
