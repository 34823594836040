import { maintenanceIntervalConstants } from 'legacy/features/maintenance/data/maintenanceIntervalConstants';
import {
  regexValidator,
  requiredValidator,
  characterLimitValidator,
  positiveIntegerOneHundredOrLess,
} from 'legacy/shared/utilities/helpers/validation/validators';

import {
  asciiRegex,
  positiveIntegerRegex,
} from 'legacy/shared/utilities/helpers/validation/validationRegularExpressions';
import {
  asciiRegexError,
  positiveIntegerRegexError,
} from 'legacy/shared/utilities/helpers/validation/validationErrorMessages';
import { stripCommas } from 'legacy/features/maintenance/utilities/formatting';

const maintenanceItemValidators = {
  intervalType: requiredValidator(),
  interval: requiredValidator(regexValidator(positiveIntegerRegex, positiveIntegerRegexError)),
  dueDateRequired: requiredValidator(),
  notes: characterLimitValidator(regexValidator(asciiRegex, asciiRegexError)),
  oilLifeInterval: requiredValidator(positiveIntegerOneHundredOrLess()),
};

const findIntervalValidator = (intervalType, interval) => {
  if (intervalType === maintenanceIntervalConstants.DATE) {
    return;
  } else if (intervalType === maintenanceIntervalConstants.OIL_LIFE) {
    const error = maintenanceItemValidators.oilLifeInterval(interval);
    return error;
  } else {
    const error = maintenanceItemValidators.interval(stripCommas(interval));
    return error;
  }
};

export const validateMaintenanceItem = (values) => {
  const intervalTypeErrors = maintenanceItemValidators.intervalType(values.intervalType);
  const intervalErrors = findIntervalValidator(values.intervalType?.value, values.interval);
  const dueDateErrors =
    values.intervalType?.value === maintenanceIntervalConstants.DATE &&
    maintenanceItemValidators.dueDateRequired(values.dueDate);
  const notesErrors = maintenanceItemValidators.notes(values.notes, 1000);

  return {
    ...(intervalTypeErrors ? { intervalType: intervalTypeErrors } : {}),
    ...(intervalErrors ? { interval: intervalErrors } : {}),
    ...(dueDateErrors ? { dueDate: dueDateErrors } : {}),
    ...(notesErrors ? { notes: notesErrors } : {}),
  };
};

export const validateInterval = (values) => {
  const intervalErrors =
    values.intervalType?.value !== maintenanceIntervalConstants.DATE &&
    maintenanceItemValidators.interval(stripCommas(values.interval));

  return {
    ...(intervalErrors ? { interval: intervalErrors } : {}),
  };
};
