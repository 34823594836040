import { SORT_ORDER } from 'legacy/shared/constants/table';

const sortDataByProperty = ({ sourceData, sortProperty, sortOrder, caseInsenstive = true }) => {
  return sourceData.sort((a, b) => {
    let aVal =
      caseInsenstive && typeof a[sortProperty] === 'string'
        ? a[sortProperty]?.toLowerCase()
        : a[sortProperty];
    let bVal =
      caseInsenstive && typeof b[sortProperty] === 'string'
        ? b[sortProperty]?.toLowerCase()
        : b[sortProperty];

    if (aVal < bVal) return sortOrder === SORT_ORDER.ASC ? -1 : 1;
    if (aVal > bVal) return sortOrder === SORT_ORDER.ASC ? 1 : -1;
    return 0;
  });
};
export default sortDataByProperty;
