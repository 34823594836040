import ButtonWithLoader from 'legacy/shared/controls/ButtonWithLoader/ButtonWithLoader';
import { useEffect } from 'react';
import { useState } from 'react';

const useSubmit = ({ submitFn }) => {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const callSubmitFn = async () => {
    await submitFn();
    setIsSubmitting(false);
  };

  useEffect(() => {
    if (isSubmitting) {
      callSubmitFn();
    } else {
      setIsSubmitting(false);
    }
  }, [isSubmitting]);

  return { isSubmitting, setIsSubmitting };
};

const ModalSubmitButton = ({ handleSubmit, buttonText, disabled }) => {
  const { isSubmitting, setIsSubmitting } = useSubmit({ submitFn: handleSubmit });

  const handleButtonClick = async () => {
    setIsSubmitting(true);
  };

  return (
    <ButtonWithLoader
      isLoading={isSubmitting}
      confirmText={buttonText}
      loadingStyleProp={'submittingWithSpinnerModal'}
      notLoadingStyleProp={'mediumAlt'}
      clickHandler={async () => await handleButtonClick()}
      disabled={disabled}
    />
  );
};

export default ModalSubmitButton;
