import { firmwareStatus } from 'legacy/shared/constants/firmwareStatus';
import { formatISOTimestampToLongDateWithTime } from 'legacy/shared/utilities/misc/time';

export const decorateOtaLogData = (logs) => {
  logs?.forEach((log) => {
    log.statusDisplayName = firmwareStatus[log.status]?.displayName;
    log.formattedTime = formatISOTimestampToLongDateWithTime(log.timestamp);
    //condense authorizer logic into single field to simply read in the table instead of doing that work there twice for display and tooltip
    log.authorizer = log.authorizer ? log.authorizer : 'N/A';
    log.authorizingGeofence = log.authorizer_geofence_id ? 'Geofence: ' + log.authorizer : null;
    log.authorizerDisplay = log.authorizingGeofence ? log.authorizingGeofence : log.authorizer;
    log.vehicle_name = log.vehicle_name ? log.vehicle_name : 'Unknown';
  });
};
