import AvatarImagePreviewControl from 'legacy/shared/controls/WcpAvatarControl/v1/components/AvatarImagePreviewControl';
import AvatarImageUploadControl from 'legacy/shared/controls/WcpAvatarControl/v1/components/AvatarImageUploadControl';
import { useState } from 'react';

const WcpAvatarControl = ({ avatarImage, handleChangeImage, handleRemoveImage }) => {
  const [uploadControlOpen, setUploadControlOpen] = useState(!avatarImage);

  return uploadControlOpen ? (
    <AvatarImageUploadControl
      handleChangeImage={(image) => {
        handleChangeImage(image);
        setUploadControlOpen(true);
      }}
    />
  ) : (
    <AvatarImagePreviewControl
      avatarImage={avatarImage}
      handleRemoveImage={() => {
        handleRemoveImage();
        setUploadControlOpen(true);
      }}
    />
  );
};

export default WcpAvatarControl;
