import {
  StyledDivModalContent,
  StyledModalText,
} from 'legacy/shared/controls/WcpModal/v2/styles/WcpModalStyles';
import { StyledBoldedText } from 'legacy/shared/styles/v2/Formatting';

export const DeactivateUserConfirmationModalContent = ({ modalState }) => {
  return (
    <StyledDivModalContent>
      <StyledModalText>
        {`Are you sure you want to deactivate: `}
        <StyledBoldedText>{`${modalState.user.username}`}</StyledBoldedText>
      </StyledModalText>
      <StyledModalText>{`They will be removed from any text or email alerts that they are associated with.`}</StyledModalText>
    </StyledDivModalContent>
  );
};
