import { useFetchOrganizationsQuery } from 'legacy/features/organizations/api/queries/useFetchOrganizationsQuery';
import { useOrganizationTableColumns } from 'legacy/features/organizations/hooks/useOrganizationTableColumns';
import LoadingWrapper from 'legacy/shared/controls/WcpLoadingWrapper/v1/LoadingWrapper';
import WcpTable from 'legacy/shared/controls/WcpTable/v2/WcpTable';

const OrganizationListingContainer = () => {
  const fetchOrganizationsQuery = useFetchOrganizationsQuery();

  const organizationTableColumns = useOrganizationTableColumns();

  return (
    <LoadingWrapper isLoading={fetchOrganizationsQuery.isFetching || !fetchOrganizationsQuery.data}>
      <WcpTable
        columns={organizationTableColumns}
        tableSourceData={fetchOrganizationsQuery.data || []}
        showListingCountHeader={true}
        listingCountItemName={'Organization'}
      />
    </LoadingWrapper>
  );
};

export default OrganizationListingContainer;
