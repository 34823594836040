import PageListWrapper, {
  PageListActionButtonContainer,
  PageListHead,
  PageListTitle,
} from 'legacy/shared/styles/custom/PageList';
import { Link } from 'react-router-dom';
import { ActionButtonText, ActionButtonWithIcon } from 'legacy/shared/styles/custom/Button';
import IconSvgComponent from 'legacy/shared/controls/WcpIcon/IconSvgComponent';
import ManageRecipientGroupsSummary from 'legacy/features/recipientGroups/components/ManageRecipientGroupsSummary/ManageRecipientGroupsSummary';

const ManageRecipientGroupsPage = () => {
  return (
    <PageListWrapper>
      <PageListHead stackable>
        <PageListTitle>Manage Recipient Groups</PageListTitle>

        <PageListActionButtonContainer>
          <Link
            to={{
              pathname: `/createrecipientgroup`,
            }}
            style={{ textDecoration: 'none' }}
          >
            <ActionButtonWithIcon create>
              <IconSvgComponent svgFileName="plus-simple" alt="Create" />

              <ActionButtonText>Create Recipient Group </ActionButtonText>
            </ActionButtonWithIcon>
          </Link>
        </PageListActionButtonContainer>
      </PageListHead>
      <ManageRecipientGroupsSummary />
    </PageListWrapper>
  );
};

export default ManageRecipientGroupsPage;
