import React, { useState } from 'react';
import { connect } from 'react-redux';

import { deleteWhitelist, fetchWhitelists } from 'legacy/core/redux/whitelists/actions';
import colors from 'legacy/shared/constants/colors';
import useAPIData from 'legacy/shared/utilities/hooks/useAPIData';
import ConfirmationModal from 'legacy/shared/controls/WcpModal/v1/ConfirmationModal';
import ModalPortal from 'legacy/shared/controls/WcpModal/v1/ModalPortal';
import { ActionButtonText, ActionButtonWithIcon } from 'legacy/shared/styles/custom/Button';
import { SimpleDropdownBtn, SimpleDropdownItem } from 'legacy/shared/styles/custom/DropdownMenu';
import PageListWrapper from 'legacy/shared/styles/custom/PageList';
import { SectionBody, SectionHead, SectionTitle } from 'legacy/shared/styles/custom/Section';
import { UploadButton, WhitelistLink } from 'legacy/shared/styles/custom/WhitelistList';
import IconSvgComponent from 'legacy/shared/controls/WcpIcon/IconSvgComponent';
import LoadingOverlay from 'legacy/shared/controls/WcpSpinner/LoadingOverlay';
import ActionMenu from 'legacy/shared/controls/WcpTable/v1/ActionMenu';
import SortableTableWithoutPagination from 'legacy/shared/controls/WcpTable/v1/SortableTableWithoutPagination';

const WhitelistsPage = ({
  whitelists,
  fetchWhitelists,
  deleteWhitelist,
  filteredOrganizationId,
}) => {
  const [deleteConfirmationModalActive, setDeleteConfirmationModalActive] = useState(false);
  const [columns] = useState([
    {
      title: 'Product',
      dataIndex: 'product_name',
      key: 'product',
      onHeaderCell: () => ({ style: styles.whitelistNameHeaderCell }),
      onCell: () => ({ style: styles.whitelistNameCell }),
      render(value, row) {
        return (
          <a onClick={() => {}} style={styles.whitelistNameLink}>
            <span style={styles.whitelistNameCell} title={'Title: ' + value}>
              <WhitelistLink
                to={{
                  pathname: `/whitelists/edit/${row.product_id}`,
                  referrer: '/whitelists',
                }}
              >
                <IconSvgComponent
                  svgFileName={'device'}
                  alt="Device"
                  svgStyle={styles.rowWhitelistIcon}
                />
                {value}
              </WhitelistLink>
            </span>
          </a>
        );
      },
    },

    {
      title: 'Whitelisted Firmware Channels',
      dataIndex: 'channels',
      key: 'channels',
      render: (channels, whitelist, index) => {
        return (
          <span title={'Whitelisted Firmware Channels: ' + channels.join(', ')}>
            {' '}
            {channels.join(', ')}
          </span>
        );
      },
    },

    {
      dataIndex: '',
      className: 'action',
      key: 'action',
      render: (value, row, index) => {
        return (
          <ActionMenu>
            <SimpleDropdownItem>
              <WhitelistLink to={`/whitelists/edit/${row.product_id}`}>
                <SimpleDropdownBtn>Edit</SimpleDropdownBtn>
              </WhitelistLink>
            </SimpleDropdownItem>
            <SimpleDropdownItem>
              <SimpleDropdownBtn
                onClick={(e) => {
                  e.preventDefault();
                  setDeleteConfirmationModalActive(row);
                }}
              >
                Delete
              </SimpleDropdownBtn>
            </SimpleDropdownItem>
          </ActionMenu>
        );
      },
    },
  ]);
  const whitelistsLoaded = useAPIData({
    fetchAPIData: fetchWhitelists,
    dependencies: [filteredOrganizationId],
  });
  return whitelistsLoaded ? (
    <PageListWrapper centered={!(whitelists && whitelists.length > 0)}>
      {whitelists && whitelists.length > 0 ? (
        <React.Fragment>
          <SectionHead style={styles.tableHead}>
            <SectionTitle style={styles.tableTitle}>Whitelists</SectionTitle>
            <WhitelistLink to="/whitelists/edit">
              <ActionButtonWithIcon create onClick={() => {}}>
                <ActionButtonText>Add Whitelist</ActionButtonText>
                <IconSvgComponent
                  svgFileName={'plus-simple'}
                  alt="Add"
                  style={styles.createButtonIcon}
                />
              </ActionButtonWithIcon>
            </WhitelistLink>
          </SectionHead>
          <SectionBody>
            <SortableTableWithoutPagination
              className="table-simple"
              columns={columns}
              data={whitelists}
              rowKey={(whitelist) => whitelist.id}
              rowClassName={(record, i) => `row-${i}`}
            />
          </SectionBody>
        </React.Fragment>
      ) : (
        <SectionBody>
          <React.Fragment>
            <IconSvgComponent svgFileName="cloud-large" alt="Cloud icon" />
            <h4>Create your first whitelist</h4>
            <WhitelistLink to={'/whitelists/edit'}>
              <UploadButton>
                <span>Add Whitelist</span>
                <IconSvgComponent
                  svgFileName={'arrow-up'}
                  alt="Upload"
                  style={styles.uploadButtonIcon}
                />
              </UploadButton>
            </WhitelistLink>
          </React.Fragment>
        </SectionBody>
      )}
      {deleteConfirmationModalActive ? (
        <ModalPortal onRequestClose={() => setDeleteConfirmationModalActive(false)}>
          <ConfirmationModal
            bodyText={`Do you really want to delete firmware whitelist for ${deleteConfirmationModalActive.product_name}?`}
            title="Delete Whitelist"
            svgFileName={'device'}
            iconDimensions={{ height: 18, width: 22.5 }}
            alt="Device"
            handleRequestClose={() => setDeleteConfirmationModalActive(false)}
            confirmHandler={() =>
              deleteWhitelist({
                productId: deleteConfirmationModalActive.product_id,
              })
            }
            confirmText="Delete"
            onSuccess={() => {
              setDeleteConfirmationModalActive(false);
              fetchWhitelists();
            }}
            cancelHandler={() => setDeleteConfirmationModalActive(false)}
            cancelText="Cancel"
          />
        </ModalPortal>
      ) : null}
    </PageListWrapper>
  ) : (
    <LoadingOverlay />
  );
};

const styles = {
  tableHead: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  uploadButtonIcon: {
    marginBottom: '0px',
    marginLeft: '11px',
    width: '10px',
  },
  rowWhitelistIcon: {
    marginRight: 10,
    fontSize: 0,
    width: '24px',
  },
  createButtonIcon: {
    marginBottom: '5px',
    marginLeft: '11px',
    width: '10px',
  },
  whitelistNameHeaderCell: {
    paddingLeft: 0,
  },
  whitelistNameCell: {
    alignItems: 'center',
    color: colors.midnight,
    fontSize: 14,
    paddingLeft: 0,
  },
  whitelistNameLink: {
    textDecoration: 'none',
  },
};

export default connect(
  (state) => ({
    whitelists: state.whitelists.whitelists,
    filteredOrganizationId: state.user.filteredOrganizationId,
  }),
  (dispatch) => ({
    fetchWhitelists: () => dispatch(fetchWhitelists()),
    deleteWhitelist: ({ productId }) => dispatch(deleteWhitelist({ productId })),
  }),
)(WhitelistsPage);
