import styled from 'styled-components';
import device from 'legacy/shared/constants/sizing';

export const HaasIntegrationWrapperDiv = styled.div`
  margin-bottom: 50px;
`;

export const CheckboxWrapperDiv = styled.div`
  margin-bottom: 5px;
  ${(props) =>
    props.enhancedTelematics &&
    `
    margin-bottom: 35px;
  `}
`;

export const HaasOptionWrapper = styled.div`
  margin-top: 30px;
  width: 100%;
  @media ${device.large} {
    width: 200px;
  }
`;

export const MaintenanceWrapperDiv = styled.div`
  margin-bottom: 10px;
`;
export const ObdFieldsWrapperDiv = styled.div`
  display: grid;
  grid-template-areas: 'FormCol FormCol FormCol';
  grid-template-columns: 120px 120px 120px;
  grid-gap: 15px;
`;

export const OutOfServiceWrapper = styled.div`
  margin-top: -20px;
`;

export const VsgIdSelectionWrapperDiv = styled.div`
  display: flex;
  margin-bottom: 20px;

  @media ${device.large} {
    margin-bottom: 0px;
  }
`;

export const VsgIdSelectionInnerWrapperDiv = styled.div`
  flex-grow: 4;

  ${(props) =>
    props.noVsgSelected &&
    `
    margin-right: 30px;
  `}
`;
