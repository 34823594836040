import WcpCheckboxField from 'legacy/shared/controls/WcpForm/v2/components/fields/WcpCheckboxField';
import { NonTextFieldContainer } from 'legacy/shared/styles/custom/Form';
import React from 'react';

const OptOutOfEmailsCheckbox = () => {
  return (
    <NonTextFieldContainer>
      <WcpCheckboxField dataKey={'optOutOfEmails'} label="Opt Out of Emails" />
    </NonTextFieldContainer>
  );
};

export default OptOutOfEmailsCheckbox;
