import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { PARTNERSHIP_NAMES, TAB_NAMES } from 'legacy/features/integrations/constants/constants';
import WcpTable from 'legacy/shared/controls/WcpTable/v2/WcpTable';

import { partnershipsTableColumns } from 'legacy/features/integrations/ui/components/tables/PartnershipsTableColumns';
import { universalApiTableColumns } from 'legacy/features/integrations/ui/components/tables/UniversalApiTableColumns';
import { UniversalApiWrapper } from 'legacy/shared/styles/custom/Integrations';
import MediaWrapper from 'legacy/shared/controls/WcpMediaWrapper/v1/MediaWrapper';
import PartnershipsSummaryCardContainer from 'legacy/features/integrations/ui/components/mobile/PartnershipsSummaryCardContainer';
import UniversalApiSummaryCardContainer from 'legacy/features/integrations/ui/components/mobile/UniversalApiSummaryCardContainer';
import LoadingWrapper from 'legacy/shared/controls/WcpLoadingWrapper/v1/LoadingWrapper';
import { useFetchPartnershipIntegrationsQuery } from 'legacy/features/integrations/api/queries/useFetchPartnershipIntegrationsQuery';
import { useFlags } from 'launchdarkly-react-client-sdk';

const IntegrationsSummary = ({ selectedTab }) => {
  const filteredOrganizationId = useSelector((state) => state.user.filteredOrganizationId);
  const fetchPartnershipIntegrationsQuery = useFetchPartnershipIntegrationsQuery({
    organizationId: filteredOrganizationId,
  });
  const [partnershipIntegrationsData, setPartnershipIntegrationsData] = useState([]);
  const { ldOrganizationPreliminaryIntegrations } = useFlags();
  console.log(ldOrganizationPreliminaryIntegrations);

  useEffect(() => {
    if (fetchPartnershipIntegrationsQuery.data) {
      // REMOVE CONDITIONAL FLAG BASED CHECK ONCE NEW PARTNERSHIPS ARE OFFICIAL
      setPartnershipIntegrationsData(
        ldOrganizationPreliminaryIntegrations
          ? fetchPartnershipIntegrationsQuery.data
          : fetchPartnershipIntegrationsQuery.data.filter(
              (p) =>
                p.name !== PARTNERSHIP_NAMES.APPLIED_INFORMATION &&
                p.name !== PARTNERSHIP_NAMES.THRUGREEN,
            ),
      );
    }
  }, [fetchPartnershipIntegrationsQuery.data]);

  const universalApiDummyData = [
    {
      keyName: 'New Key',
      keyType: 'Fleet Management',
      permissions: 'Read',
      createdBy: 'mrockwell',
      timestamp: '2025-11-11T15:39:58.620Z',
      formattedTime: '11/11/2025',
    },
    {
      keyName: 'New GPS Key',
      keyType: 'GPS',
      permissions: 'Read and Write',
      createdBy: 'mrockwell',
      timestamp: '2025-11-11T15:39:58.620Z',
      formattedTime: '11/11/2025',
    },
    {
      keyName: 'Matts Read/Write Key',
      keyType: 'GPS',
      permissions: 'Read and Write',
      createdBy: 'mrockwell',
      timestamp: '2025-09-22T15:39:58.620Z',
      formattedTime: '09/22/2025',
    },
  ];

  return (
    <LoadingWrapper
      isLoading={
        !fetchPartnershipIntegrationsQuery.data || fetchPartnershipIntegrationsQuery.isFetching
      }
    >
      <>
        {selectedTab === TAB_NAMES.PARTNERSHIPS ? (
          <MediaWrapper
            mobileComponent={
              <PartnershipsSummaryCardContainer partnerships={partnershipIntegrationsData} />
            }
            nonMobileComponent={
              <WcpTable
                id="partnershipsSummaryTable"
                columns={partnershipsTableColumns}
                tableSourceData={partnershipIntegrationsData}
                showSearch={false}
              />
            }
          />
        ) : (
          <UniversalApiWrapper>
            <MediaWrapper
              mobileComponent={
                <UniversalApiSummaryCardContainer universalApiKeys={universalApiDummyData} />
              }
              nonMobileComponent={
                <WcpTable
                  id="universalApiSummaryTable"
                  columns={universalApiTableColumns}
                  tableSourceData={universalApiDummyData}
                />
              }
            />
          </UniversalApiWrapper>
        )}
      </>
    </LoadingWrapper>
  );
};

export default IntegrationsSummary;
