import {
  sortAscendingAlpha,
  sortDescendingAlpha,
  sortAscendingLockStatus,
  sortDescendingLockStatus,
  compareValues,
} from 'legacy/shared/utilities/misc/general';
import { SORT_ORDER } from 'legacy/shared/constants/table';
import { isRegExp, escapeRegExp, isString, flatten } from 'lodash';

const sortByIndexAndOrder = (objA, objB, order, sortIndex) => {
  // do this is property to sort by is nested
  // currently supports only 1 level of nesting, will need to change if we add more levels
  if (sortIndex) {
    let compoundSortIndex = sortIndex.split('.');
    if (compoundSortIndex.length === 2) {
      sortIndex = compoundSortIndex.pop();
      objA = objA[compoundSortIndex];
      objB = objB[compoundSortIndex];
    }
  }

  const propOne = objA[sortIndex];
  const propTwo = objB[sortIndex];
  let sortOrderFunction;
  if (sortIndex === 'device') {
    sortOrderFunction =
      order === SORT_ORDER.DESC ? sortDescendingLockStatus : sortAscendingLockStatus;
  } else sortOrderFunction = order === SORT_ORDER.DESC ? sortDescendingAlpha : sortAscendingAlpha;

  return sortOrderFunction(propOne, propTwo);
};

export const tableColumnSort = ({ data, order, sortIndex }) => {
  const sortFunction = (objA, objB) => sortByIndexAndOrder(objA, objB, order, sortIndex);
  return data.sort(sortFunction);
};

const checkAllvalues = (objA, objB, sortIndex) => {
  // do this is property to sort by is nested
  // currently supports only 1 level of nesting, will need to change if we add more levels
  if (sortIndex) {
    let result1 = Array.isArray(objA[sortIndex]);
    if (result1) {
      objA = objA.orgdata;
    }
    let result2 = Array.isArray(objB[sortIndex]);
    if (result2) {
      objB = objB.orgdata;
    }
    let compoundSortIndex = sortIndex.split('.');
    if (compoundSortIndex.length === 2) {
      sortIndex = compoundSortIndex.pop();
      objA = objA[compoundSortIndex];
      objB = objB[compoundSortIndex];
    }
  }

  const propOne = objA[sortIndex];
  const propTwo = objB[sortIndex];
  return propOne === propTwo;
};

export const paginatedTableColumnSort = ({ data, order, sortIndex }) => {
  // copy the data to avoid mutating the original data
  data = [...data];
  const allEqual = data.every((item) => {
    return checkAllvalues(data[0], item, sortIndex);
  });
  let sortedData;
  if (allEqual) {
    sortedData = data.reverse();
  } else {
    sortedData = data.sort((a, b) => compareValues(a, b, sortIndex, order));
    if (order === SORT_ORDER.DESC) {
      sortedData.reverse();
    }
  }
  return sortedData;
};

/**
 * @param {string} str
 * @param {regexp|str} match Must contain a matching group
 * @param {function} fn
 * @return {array}
 */
const replaceString = (str, match, fn) => {
  var curCharStart = 0;
  var curCharLen = 0;

  if (str === '') {
    return str;
  } else if (!str || !isString(str)) {
    throw new TypeError('First argument to react-string-replace#replaceString must be a string');
  }

  var re = match;

  if (!isRegExp(re)) {
    re = new RegExp('(' + escapeRegExp(re) + ')', 'gi');
  }

  var result = str.split(re);

  // Apply fn to all odd elements
  for (var i = 1, length = result.length; i < length; i += 2) {
    curCharLen = result[i].length;
    curCharStart += result[i - 1].length;
    result[i] = fn(result[i], i, curCharStart);
    curCharStart += curCharLen;
  }

  return result;
};

export const reactStringReplace = (source, match, fn) => {
  if (!Array.isArray(source)) source = [source];

  return flatten(
    source.map(function (x) {
      return isString(x) ? replaceString(x, match, fn) : x;
    }),
  );
};
