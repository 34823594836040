// Organization modals
export const CREATE_ORGANIZATION_MODAL_KEY = 'CREATE_ORGANIZATION_MODAL_KEY';
export const EDIT_ORGANIZATION_MODAL_KEY = 'EDIT_ORGANIZATION_MODAL_KEY';
export const DEACTIVATE_ORGANIZATION_MODAL_KEY = 'DEACTIVATE_ORGANIZATION_MODAL_KEY';

// User modals
export const CREATE_USER_MODAL_KEY = 'CREATE_USER_MODAL_KEY';
export const EDIT_USER_MODAL_KEY = 'EDIT_USER_MODAL_KEY';
export const ASSOCIATE_FSE_MODAL_KEY = 'ASSOCIATE_FSE_MODAL_KEY';
export const DEACTIVATE_USER_CONFIRMATION_MODAL_KEY = 'DEACTIVATE_USER_CONFIRMATION_MODAL_KEY';

// GTT modals
export const RECEIVE_GTT_ACCESS_KEY_MODAL_KEY = 'RECEIVE_GTT_ACCESS_KEY_MODAL_KEY';
export const REGENERATE_GTT_ACCESS_KEY_MODAL_KEY = 'REGENERATE_GTT_ACCESS_KEY_MODAL_KEY';
