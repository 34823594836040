/**
 * @ External Dependecies
 */
import styled from 'styled-components';
import Dropdown, { DropdownTrigger, DropdownContent } from 'legacy/core/widgets/profile/Dropdown';
import { media } from 'legacy/shared/utilities/misc/media';
import { Link } from 'react-router-dom';
import colors from 'legacy/shared/constants/colors';
import device from 'legacy/shared/constants/sizing';

export const DropdownMenuTrigger = styled(DropdownTrigger)`
  display: block;
  text-decoration: none;
`;
export const DropdownMenuBody = styled.div``;

export const DropdownMenuPrepend = styled.span`
  font-size: 15px;
  font-weight: 400;
  letter-spacing: 0.1px;
  color: ${colors.midnight};
`;

export const DropdownMenuContent = styled(DropdownContent)`
  display: none;
  position: absolute;
  right: -8px;
  top: calc(100% + 18px);
  width: 433px;
  background: ${colors.cotton};
  border-radius: 3px;
  box-shadow: 0 1px 51px rgba(0, 0, 0, 0.15);

  &:after {
    content: '';
    position: absolute;
    bottom: 100%;
    right: 10px;
    width: 0;
    height: 0;
    border-width: 0 10px 10px 10px;
    border-style: solid;
    border-color: transparent;
    border-bottom-color: #fff;
  }

  ${(props) =>
    props.small &&
    `
    right: 25%;
		top: 100%;
		width: 235px;
	`}

  ${(props) =>
    props.large &&
    `
		top: calc(100% + 13px);
		width: 680px;
	`}

	${(props) =>
    props.right &&
    `
		right: 21px;

		&:after {
			right: 10px;
		}
	`}

	${(props) =>
    props.rightcenter &&
    `
		right: -94px;

		&:after {
			right: auto;
			left: 50%;
			transform: translateX(-50%);
		}
  `}

  ${(props) =>
    props.$profileMenu &&
    `
    list-style-type: none;
		width:250px;
	`}

  ${(props) =>
    props.$organizationFilterDropdown &&
    `
    width: auto;
    margin-right: 7px;
    right: -35px;
    top: 100%;

    @media ${device.large} {
      right: 2px;
  }
	`}

${(props) =>
    props.filterdropdown &&
    `
    width: 300px;
    margin-right: 7px;
    margin-top: 10px;
    left: 0px;
    top: 100%;

    @media ${device.large} {
      right: 2px;
  }

  &:after {
		content: '';
		position: absolute;
		bottom: 100%;
    left: 10px;
    width: 0;
		height: 0;
		border-width: 0 10px 10px 10px;
		border-style: solid;
		border-color: transparent;
		border-bottom-color: #fff;
	}
	`}
`;

export const SimpleDropdownWrapper = styled.div`
  position: relative;
  display: inline-block;

  & > a.active {
    use {
      fill: ${colors.midnight};
    }
  }
`;

export const SimpleDropdown = styled.ul`
  list-style-type: none;
  position: absolute;
  right: 0px;
  margin-right: -17px;
  top: calc(100% + 20px);
  width: 221px;
  box-shadow: 0 5px 21px rgba(0, 0, 0, 0.37);
  border-radius: 2px;
  background: #fff;
  z-index: 10;

  &:before {
    content: '';
    position: absolute;
    right: 10px;
    bottom: 100%;
    width: 0;
    height: 0;
    border-width: 0 15px 15px 15px;
    border-style: solid;
    border-color: transparent;
    border-bottom-color: ${colors.cotton};
  }

  ${media.tablet_portrait`
		right: 10px;

		&:before {
			right: 3px;
		}
	`};
`;

export const SimpleDropdownItem = styled.li`
  border-bottom: 2px solid rgba(138, 144, 156, 0.15);

  &:last-child {
    border-bottom: none;
  }
`;

const DropdownBtnStyles = `
  display: block;
  padding: 18px 25px;
  font-size: 14px;
  line-height: 1;
  text-decoration: none;
  color: #8a909c;
  transition: background 0.3s, color 0.3s;

  &:hover {
    background: ${colors.fog};
    color: ${colors.midnight};
  }
`;

export const SimpleDropdownBtn = styled.a`
  ${DropdownBtnStyles}
`;

export const DropdownLink = styled(Link)`
  ${DropdownBtnStyles}
  ${(props) =>
    props.disabled &&
    `
    pointer-events: none;
	`}
  line-height: 1.2;
`;

const DropdownMenu = styled(Dropdown)`
  position: relative;

  &.dropdown--active {
    ${DropdownMenuContent} {
      display: block;
      z-index: 11101;
    }
  }

  ${(props) =>
    props.$truncateText &&
    `
		min-width: 0;
	`}

  ${(props) =>
    props.disabled &&
    `
    pointer-events: none;
    opacity:.5;
	`}
`;

export const CurrentSelectionContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 25px 5px;
  color: ${colors.midnight};
  font-weight: bold;
  cursor: pointer;

  ${(props) =>
    props.filterDropdownContainer &&
    `
    width: 200px;
    padding: 5px 0px 2px 0px;
    border-bottom: 1px solid rgba(138, 144, 156, 0.25);
  `}

  ${(props) =>
    props.narrowWidth &&
    `
    width: 110px;
    padding: 5px 0px 2px 0px;
    border-bottom: 1px solid rgba(138, 144, 156, 0.25);
  `}
`;

export const CurrentSelectionDisplay = styled.div`
  margin-right: 10px;
  width: 160px;
  cursor: pointer;
`;

export const SearchResultsList = styled.ul`
  width: 300px;
  position: absolute;
  top: 68px;
  left: 0px;
  border-radius: 3px;
  max-height: 180px;
  overflow: auto;
  box-shadow: 0 21px 21px rgba(0, 0, 0, 0.12);
  @media ${device.large} {
    max-height: 60vh;
  }

  ${(props) =>
    props.$restrictedHeightResults &&
    `
    max-height: 100px;
    @media ${device.large} {
      max-height: 290px;
  }

	`};
`;

export const SearchContainer = styled.div`
  height: '100%';
  width: '100%';
`;

export const CreateNewItemOption = styled.div`
  list-style-type: none;
  color: white;
  font-size: 1.1rem;
  background-color: grey;
  font-weight: normal;
  border-top: 1px solid #eeeff0;
  border-bottom: 1px solid #eeeff0;
  padding: 18px 10px 18px 20px;
  background-color: lightgray;
`;
export default DropdownMenu;
