import { formatISOTimestampToLongDateWithTime } from 'legacy/shared/utilities/misc/time';
import { alertTypeConstants } from 'legacy/features/alerts/data/enums/alertTypeConstants';
import {
  mockFrontendUserTimmyTheTexter_GET,
  mockFrontendUserSarahTheTexter_GET,
  mockFrontendUserJohnTheTexterAndEmailer_GET,
} from 'legacy/features/alerts/data/mock/mockFrontendUsers';
import { alertTypeDropdownOptions } from 'legacy/features/alerts/data/ui/dropdown/alertTypeDropdownOptions';

const mockLog1 = {
  formattedTime: formatISOTimestampToLongDateWithTime('2023-09-27T14:31:17.582Z'),
  timestamp: '2023-09-27T14:31:17.582Z',
  vehicleName: 'Matts Fake Car',
  alertType: alertTypeDropdownOptions.getLabelByValue(alertTypeConstants.SPEED_EXCEEDS),
  triggerValueObject: '90',
  unitOfMeasure: 'MPH',
  measuredValue: '210',
  alertRecipients: [
    mockFrontendUserTimmyTheTexter_GET,
    mockFrontendUserJohnTheTexterAndEmailer_GET,
  ],
};

const mockLog2 = {
  formattedTime: formatISOTimestampToLongDateWithTime('2023-12-27T14:22:17.582Z'),
  timestamp: '2023-12-27T14:22:17.582Z',
  vehicleName: 'Mock Car 25',
  alertType: alertTypeDropdownOptions.getLabelByValue(alertTypeConstants.NR_SPEED_EXCEEDS),
  triggerValueObject: '80',
  unitOfMeasure: 'MPH',
  measuredValue: '105',
  alertRecipients: [mockFrontendUserTimmyTheTexter_GET, mockFrontendUserSarahTheTexter_GET],
};

export const mockAlertLogs = [mockLog1, mockLog2];
