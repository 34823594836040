import {
  CustomAlertRowWrapper,
  CustomAlertsTableHeader,
} from 'legacy/shared/styles/custom/ManageAlerts';
import CustomModal from 'legacy/shared/controls/WcpModal/v1/CustomModal';
import { FormTitle } from 'legacy/shared/styles/custom/Form';
import { ActionsContainer } from 'legacy/shared/styles/custom/Modal';
import Button from 'legacy/shared/styles/custom/Button';
import ButtonWithLoader from 'legacy/shared/controls/ButtonWithLoader/ButtonWithLoader';
import CustomAlertRowBuilder from 'legacy/features/alerts/ui/components/AlertForms/shared/modals/CustomAlertRowBuilder';
import { TruncatedTextWithWrap } from 'legacy/shared/styles/custom/TruncatedText';
import useAlertFormsProviderContext from 'legacy/features/alerts/services/providers/AlertFormsProvider/hooks/useAlertFormsProviderContext';
import { mapRecipientUserAlertMethod } from 'legacy/features/alerts/services/mapping/helpers/mapRecipientUserAlertMethod';
import { useState } from 'react';

const CustomAlertsModal = ({ modalData: { show, recipientRow }, handleCloseModal }) => {
  let { recipientGroup, recipientRowGuid } = recipientRow;

  const {
    actions: { persistCustomAlertMethodsAction },
    state: { alert },
  } = useAlertFormsProviderContext();

  const { alertName } = alert.alertDetails.alertInfo;

  let [updatedGroupMembers, setUpdatedGroupMembers] = useState();

  return (
    show && (
      <CustomModal
        customWidth={550}
        title={
          <FormTitle>
            <TruncatedTextWithWrap>{`Custom Alerts for ${
              alertName || '(Empty name)'
            }`}</TruncatedTextWithWrap>
          </FormTitle>
        }
        handleCloseModal={handleCloseModal}
      >
        <CustomAlertRowWrapper headers>
          <CustomAlertsTableHeader recipientName>Recipient</CustomAlertsTableHeader>
          <CustomAlertsTableHeader>Text</CustomAlertsTableHeader>
          <CustomAlertsTableHeader>Email</CustomAlertsTableHeader>
        </CustomAlertRowWrapper>
        <CustomAlertRowBuilder
          recipientRow={recipientRow}
          handleCheckboxesChanged={setUpdatedGroupMembers}
        />
        <ActionsContainer topSpacing>
          <Button
            onClick={async () => {
              handleCloseModal();
            }}
            withRightSpacer
            default
            type="button"
          >
            Cancel
          </Button>
          <ButtonWithLoader
            confirmText={'Save'}
            loadingStyleProp={'submittingWithSpinnerModal'}
            notLoadingStyleProp={'mediumAlt'}
            clickHandler={async () => {
              await persistCustomAlertMethodsAction({
                recipientRowGuid,
                updatedGroupMembers,
              });
              handleCloseModal();
            }}
          />
        </ActionsContainer>
      </CustomModal>
    )
  );
};

export default CustomAlertsModal;
