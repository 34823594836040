import { apiCall } from 'legacy/core/api/API';
import {
  mapAndMassageMaintenanceData,
  vehicleMaintenanceMapping,
} from 'legacy/features/maintenance/utilities/mapping';
import {
  vehicleLockStatusViewData,
  vehicleOnlineStatusViewData,
  vehicleRespondingStatusViewData,
  vehicleTypeViewData,
} from 'legacy/shared/constants/vehicle';
import { getGuardedObjectProp } from 'legacy/shared/utilities/misc/general';
import { getFormattedDuid } from 'legacy/shared/utilities/misc/vehicle';

/**
 * @ Decorators
 */
export const flattenDeviceData = (devicesObject) => {
  const vsgRegistrationID = devicesObject && {
    uid: devicesObject.uid,
    product_type: devicesObject.product_type,
    product_id: devicesObject.product_id,
    product_name: devicesObject.product_name,
    firmware: devicesObject.firmware,
  };
  const cenComCoreDevice =
    devicesObject && devicesObject.CenComCore
      ? {
          uid: devicesObject.CenComCore.uid,
          product_type: devicesObject.CenComCore.product_type,
          product_id: devicesObject.CenComCore.product_id,
          product_name: devicesObject.CenComCore.product_name,
          firmware: devicesObject.CenComCore.firmware,
          config: devicesObject.CenComCore.config,
        }
      : null;
  const peripheralDevices = getGuardedObjectProp('CenComCore.devices', devicesObject)
    ? getGuardedObjectProp('CenComCore.devices', devicesObject).map((device) => ({
        uid: device.uid,
        product_type: device.product_type,
        product_name: device.product_name,
        product_id: device.product_id,
        firmware: device.firmware,
      }))
    : null;
  return [
    ...(vsgRegistrationID ? [vsgRegistrationID] : []),
    ...(cenComCoreDevice ? [cenComCoreDevice] : []),
    ...(peripheralDevices ? peripheralDevices : []),
  ];
};

// THIS IS USED ON LIVEMAP, MAINTENANCE, AND VEHICLE EDIT
// WE SHOULD EXPLORE CONSOLIDATING WITH THE OTHER decorateVehicle in shared\utilities\vehicle.js
export const decorateVehicle = ({ vehicleFromAPI }) => {
  vehicleFromAPI.meta.formatted_device_id = getFormattedDuid(vehicleFromAPI);

  if (vehicleTypeViewData[vehicleFromAPI.meta.vehicle_type])
    vehicleFromAPI.meta.formatted_vehicle_type =
      vehicleTypeViewData[vehicleFromAPI.meta.vehicle_type].title;
  else vehicleFromAPI.meta.formatted_vehicle_type = 'Unknown';

  //this seems to be unused
  /* const lockedStatus = getGuardedObjectProp('CenComCoreAccess', vehicleFromAPI.device);

  vehicleFromAPI.meta.lock_status = lockedStatus;
  vehicleFromAPI.meta.formatted_lock_status = !lockedStatus
    ? 'Unavailable'
    : vehicleLockStatusViewData[lockedStatus].title; */

  if (!vehicleFromAPI.gps) return vehicleFromAPI;

  const vehicleIsResponding = vehicleFromAPI.gps.fr_mode_enabled === 1;

  return {
    ...vehicleFromAPI,
    onlineStatus: vehicleFromAPI.meta.online
      ? vehicleOnlineStatusViewData.ACTIVE.id
      : vehicleOnlineStatusViewData.INACTIVE.id,
    respondingStatus: vehicleIsResponding
      ? vehicleRespondingStatusViewData.RESPONDING.id
      : vehicleRespondingStatusViewData.NOT_RESPONDING.id,
  };
};

export const undecorateVehicleObject = ({ vehicle }) => {
  return {
    label: vehicle.label,
    vin: vehicle.vin,
    duid: vehicle.duid,
    license_plate: vehicle.licensePlate,
    license_state: vehicle.licenseState,
    make: vehicle.make,
    model: vehicle.model,
    vehicle_type: vehicle.vehicleType,
    mfg_year: vehicle.year,
    organization_id: vehicle.organizationId,
    vehicle_type: vehicle.type,
    device: vehicle.device,
    groups: vehicle.groups,
    notes: vehicle.notes,
    haas_enabled: vehicle.haasIntegrationEnabled,
    haas_public_alerting: vehicle.haasPublicAlerting,
    alias: vehicle.alias,
    odometer: vehicle.odometer,
    engine_hours: vehicle.engineHours,
    maintenance_events: vehicle.maintenance,
    out_of_service: vehicle.outOfServiceEnabled,
    alerts_muted: vehicle.muteAlertsEnabled,
    enhanced_telematics_enabled: vehicle.enhancedTelematicsEnabled,
    subscription_type: vehicle.subscriptionType,

    ...(vehicle.vehicleId ? { vehicle_id: vehicle.vehicleId } : {}),
  };
};

/**
 * @ Utilities
 */

const getQueryStringFromBoundsParam = (o) =>
  Object.entries(o)
    .map((entry) => `${entry[0]}:${entry[1]}`)
    .join(',');

/**
 * @ Services
 */
export const getVehicles = async ({ organizationId, apiFlags }) => {
  const queryStringParameters = { organization_id: organizationId, ...apiFlags };
  const vehicleData = await apiCall({
    path: '/vehicles',
    method: 'get',
    queryStringParameters,
  });
  return vehicleData;
};

export const getMapVehicles = async (organizationId) => {
  const queryStringParameters = {
    organization_id: organizationId,
    gps: true,
    meta: true,
    cell: false,
    device: false,
    addons: true,
    maintenance: false,
    obd: false,
  };
  const vehicleData = await apiCall({
    path: '/vehicles',
    method: 'get',
    queryStringParameters,
  });
  return vehicleData;
};

export const getVehiclesMeta = async (organizationId) => {
  const queryStringParameters = { organization_id: organizationId };
  const vehicleData = await apiCall({
    path: '/vehicles/meta',
    method: 'get',
    queryStringParameters,
  });
  return vehicleData;
};

export const getMapModeVehicles = async (organizationId) => {
  const queryStringParameters = {
    organization_id: organizationId,
    gps: true,
    meta: true,
    cell: false,
    device: false,
    maintenance: true,
    addons: true,
    obd: false,
  };
  const vehicleData = await apiCall({
    path: '/mapvehicles',
    method: 'get',
    queryStringParameters,
  });
  return vehicleData;
};

export const getVehicle = async ({ vehicleId, apiFlags }) => {
  const queryStringParameters = { ...apiFlags };
  return await apiCall({
    path: `/vehicles/${vehicleId}`,
    method: 'get',
    queryStringParameters,
  });
};
export const createVehicle = async (vehicle) => {
  vehicle.label = vehicle.label.trim();
  return await apiCall({
    path: `/vehicles/meta`,
    method: 'post',
    body: { ...vehicle },
  });
};

export const editVehicleMetaById = async (vehicle) => {
  return await apiCall({
    path: `/vehicles/${vehicle.vehicle_id}/meta`,
    method: 'put',
    body: {
      duid: vehicle.duid,
      license_plate: vehicle.license_plate,
      license_state: vehicle.license_state,
      make: vehicle.make,
      model: vehicle.model,
      vehicle_type: vehicle.vehicle_type,
      vin: vehicle.vin,
      label: vehicle.label.trim(),
      mfg_year: vehicle.mfg_year,
      groups: vehicle.groups,
      notes: vehicle.notes,
      haas_enabled: vehicle.haas_enabled,
      haas_public_alerting: vehicle.haas_public_alerting,
      alias: vehicle.alias,
      odometer: vehicle.odometer,
      engineHours: vehicle.engine_hours,
      maintenance_events: vehicle.maintenance_events,
      out_of_service: vehicle.out_of_service,
      alerts_muted: vehicle.alerts_muted,
      enhanced_telematics_enabled: vehicle.enhanced_telematics_enabled,
      subscription_type: vehicle.subscription_type,
    },
  });
};

export const editVehicleAddons = async (vehicleIds, organizationId, addons) => {
  return await apiCall({
    path: `/vehicles/addons`,
    method: 'put',
    queryStringParameters: {
      vehicle_ids: Array.isArray(vehicleIds) ? vehicleIds.join(',') : vehicleIds,
      organization_id: organizationId,
    },
    body: {
      addons,
    },
  });
};

export const editVehicleMaintenance = async ({ vehicleId, organizationId, maintenanceItems }) => {
  // map and massage maintenance data
  const maintenance_events = maintenanceItems.map((item) => mapAndMassageMaintenanceData(item));

  return await apiCall({
    path: `/vehicle/maintenance`,
    method: 'post',
    queryStringParameters: {
      vehicle_id: vehicleId,
      organization_id: organizationId,
    },
    body: {
      maintenance_events,
    },
  });
};

export const editVehicleDeviceById = async (vehicle) => {
  return await apiCall({
    path: `/vehicles/${vehicle.vehicle_id}/device`,
    method: 'put',
    body: {
      device: vehicle.device,
    },
  });
};

export const deleteVehicle = async ({ vehicleId }) => {
  return await apiCall({
    path: `/vehicles/${vehicleId}`,
    method: 'del',
  });
};
export const resetVehicle = async ({ vehicleId }) => {
  return await apiCall({
    path: `/vehicles/${vehicleId}/reset`,
    method: 'post',
  });
};

export const resetGroupVehicles = async (vehicleId) => {
  return await apiCall({
    path: `/vehicles/${vehicleId}/reset`,
    method: 'post',
  });
};

export const lockVehicles = async (vehicleIds) => {
  return await apiCall({
    path: `/vehicles/lock_device`,
    method: 'post',
    body: { vehicle_ids: vehicleIds },
  });
};

export const unlockVehicles = async (vehicleIds) => {
  return await apiCall({
    path: `/vehicles/unlock_device`,
    method: 'post',
    body: { vehicle_ids: vehicleIds },
  });
};

export const getVehicleVINInformation = async (VIN) => {
  return await apiCall({
    path: `/vehicles/vin_lookup/${VIN}`,
    method: 'get',
  });
};
export const verifyDuid = async (duid, filteredOrganizationId) => {
  return await apiCall({
    path: `/verifyduid/${duid}`,
    method: 'get',
    queryStringParameters: {
      organization_id: filteredOrganizationId,
    },
  });
};
