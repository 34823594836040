import { SORT_ORDER } from 'legacy/shared/constants/table';
import WcpTable from 'legacy/shared/controls/WcpTable/v2/WcpTable';
import { maintenanceLogsTableColumns } from 'legacy/features/maintenance/components/MaintenanceLogs/table/MaintenanceLogsTableColumns';

const MaintenanceLogsTable = ({ records, handleSetListingCount }) => {
  return (
    <WcpTable
      columns={maintenanceLogsTableColumns}
      tableSourceData={records || []}
      emptyText="No maintenance logs are currently associated with this vehicle"
      defaultSortOrder={SORT_ORDER.DESC}
      setListingCount={handleSetListingCount}
    />
  );
};

export default MaintenanceLogsTable;
