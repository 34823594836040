import colors from 'legacy/shared/constants/colors';

export const createSearchResultItemName = (searchValue, searchResult) => {
  //find where the match starts
  const startingIndex = searchResult.toLowerCase().indexOf(searchValue);

  //does that section start at the beginning? if not use a blank string
  const beginningOfWord = startingIndex === 0 ? '' : searchResult.substring(0, startingIndex);

  //find the section to highlight
  const matchingLetters = searchResult.substring(startingIndex, startingIndex + searchValue.length);

  //find the remainder of the search result
  const restOfWord = searchResult.substring(
    startingIndex + searchValue.length,
    searchResult.length,
  );

  //return a new result with bolded matched letters
  return (
    <>
      {beginningOfWord}
      <span style={{ fontWeight: 'bolder', color: `${colors.midnight}` }}>{matchingLetters}</span>
      {restOfWord}
    </>
  );
};
