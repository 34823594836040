import { useEffect, useRef, useState } from 'react';
import { MAP_ANIMATION_DURATION } from 'legacy/shared/constants/map';

const FRAME_RATE = 15;
const FRAME_DURATION = 1000 / FRAME_RATE;
/**
 * Helper Functions
 */

// Gets next heading direction for vehicle icon based on current heading and elapsed progress
const calculateNextHeading = (start, end, elapsed) => {
  // if elapsed progress is less than 0.5, speed up rotation
  elapsed = Math.min(elapsed * 2, 1);

  // calculate delta between start and end heading
  const delta = ((end - start + 540) % 360) - 180;

  // return new heading
  return start + delta * elapsed;
};

// get next coordinates based on last and next coordinates and elapsed progress
const calculateNextCoordinates = (start, end, elapsed) => {
  return start + (end - start) * elapsed;
};

// get next coordinates for vehicle based on elapsed progress
const updateAnimatedVehicles = (animatedVehicles, elapsedProgress) => {
  return animatedVehicles
    ? animatedVehicles
        .filter((av) => !!av.animationParameters)
        .map((av) => {
          // determine whether we are skipping this vehicle
          // let skipVehicle = vehiclesToSkip.find(v => av.vehicle_id === v.vehicle_id);

          // look up start and end coordinates for this animation cycle
          let {
            lastLongitude,
            lastLatitude,
            nextLongitude,
            nextLatitude,
            lastHeading,
            nextHeading,
          } = av.animationParameters;

          // new longitude
          av.gps.longitude = !!lastLongitude
            ? calculateNextCoordinates(lastLongitude, nextLongitude, elapsedProgress)
            : av.gps.longitude;

          // new latitude
          av.gps.latitude = !!lastLatitude
            ? calculateNextCoordinates(lastLatitude, nextLatitude, elapsedProgress)
            : av.gps.latitude;

          // new heading
          av.gps.heading = calculateNextHeading(lastHeading, nextHeading, elapsedProgress);

          return av;
        })
    : [];
};

/**
 * Hooks
 */

export const useVehicleAnimations = ({ visibleMapVehicles, isPerformanceMode }) => {
  /**
   * Refs
   */

  // animation cycle start time
  let startTime = useRef(null);
  let lastFrameTime = useRef(null);

  // animation frame handle
  let frameHandle = useRef(null);

  /**
   * State
   */

  // cached vehicles from previous animation cycle
  const [cachedVehicles, setCachedVehicles] = useState([]);

  // vehicles with animation parameters for current animation cycle
  // state is updated for each frame of animation cycle
  const [animatedVehicles, setAnimatedVehicles] = useState([]);
  /**
   * Animation Function
   */

  // called recursively to animate vehicles for each frame
  // the next coordinates are calculated based on elapsed time
  const animateVehicles = (animatedVehicles) => (timestamp) => {
    // set start time for first frame of animation cycle
    if (startTime.current === null) {
      startTime.current = timestamp;
    }

    if (!lastFrameTime.current) {
      lastFrameTime.current = timestamp;
    }

    // elapsed time is calculated since start of current animation cycle
    const elapsedTime = timestamp - startTime.current;

    // calculate elapsed progress as percentage of elapsed time and total animation duration
    const elapsedProgress = Math.min((elapsedTime / MAP_ANIMATION_DURATION).toFixed(2), 1);

    if (timestamp - lastFrameTime.current >= FRAME_DURATION) {
      // determine next coordinates for each vehicle
      let nextAnimatedVehicles = updateAnimatedVehicles(animatedVehicles, elapsedProgress);

      // update animation state
      // use this state change to trigger re-render of dependent components
      setAnimatedVehicles(nextAnimatedVehicles);

      // Update the time of last rendered frame
      lastFrameTime.current = timestamp;
    }
    // if animation cycle is not complete, request next frame
    if (elapsedTime < MAP_ANIMATION_DURATION) {
      // request next frame and assign handle
      frameHandle.current = requestAnimationFrame(animateVehicles(animatedVehicles));
    }
  };

  /**
   * Effects
   */

  // trigger animation cycle when incoming vehicles change
  useEffect(() => {
    if (visibleMapVehicles) {
      // set new animation parameters for each vehicle
      let newAnimatedVehicles = visibleMapVehicles.map((incomingVehicle) => {
        // deep copy
        let { longitude, latitude, heading } = incomingVehicle.gps;

        // find matching previous vehicle in cached
        const previousVehicle = cachedVehicles.find(
          (cv) => incomingVehicle.vehicle_id === cv.vehicle_id,
        );

        // establish new animation parameters for vehicle
        // if no previous vehicle, use current coordinates and heading
        let newAnimatedVehicle = {
          ...incomingVehicle,
          ...{
            // current frame coordinates and heading
            gps: {
              latitude: previousVehicle?.gps.latitude,
              longitude: previousVehicle?.gps.longitude,
              heading: previousVehicle?.gps.heading,
            },
            // animation cycle boundaries
            animationParameters: {
              lastLongitude: previousVehicle?.gps.longitude || longitude,
              lastLatitude: previousVehicle?.gps.latitude || latitude,
              lastHeading: previousVehicle?.gps.heading || heading,
              nextLongitude: longitude,
              nextLatitude: latitude,
              nextHeading: heading,
            },
          },
        };

        // return new animated vehicle
        return newAnimatedVehicle;
      });

      // cache incoming for next cycle
      setCachedVehicles(visibleMapVehicles);

      // set animated vehicles for current animation
      setAnimatedVehicles(newAnimatedVehicles);

      // reset animation cycle start time
      startTime.current = null;

      // if not in performance mode, start animation cycle
      if (!isPerformanceMode) {
        frameHandle.current = requestAnimationFrame(animateVehicles(newAnimatedVehicles));
      }
    }

    // cancel animation cycle on unmount
    return () => {
      cancelAnimationFrame(frameHandle.current);
    };
  }, [visibleMapVehicles]);

  // return animation all vehicle with new animation states for each frame of animation cycle
  return [animatedVehicles];
};
