import { addNotification } from 'legacy/core/redux/ui/actions';
import { createNotification, LEVELS } from 'legacy/shared/utilities/misc/notification';

export const groupFetchError = ({ err }) =>
  addNotification({
    notification: createNotification(LEVELS.ERROR, 'Error Getting Groups', e),
  });

export const resetSingleSuccess = ({ vehicleToReset }) =>
  addNotification({
    notification: createNotification(
      LEVELS.SUCCESS,
      'Vehicle Reset Successfully',
      `Successfully reset "${vehicleToReset}"`,
    ),
  });

export const resetGroupVehiclesSuccess = () =>
  addNotification({
    notification: createNotification(
      LEVELS.SUCCESS,
      'Group Vehicles Reset Successfully',
      'Successfully reset all group vehicles',
    ),
  });

export const resetGroupVehiclesError = ({ failedVehicles }) =>
  addNotification({
    notification: createNotification(
      LEVELS.ERROR,
      `Error Resetting Vehicles`,
      `Failed to reset "${failedVehicles}"`,
    ),
  });

export const updateHaasSuccess = ({ groupName }) =>
  addNotification({
    notification: createNotification(
      LEVELS.SUCCESS,
      'HAAS status updated successfully',
      `Successfully set HAAS status for group "${groupName}"`,
    ),
  });
