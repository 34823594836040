import FilteredDropdown from 'legacy/shared/controls/WcpFilteredDropdown/v4/FilteredDropdown';
import useWcpFormContext from 'legacy/shared/controls/WcpForm/v2/hooks/useWcpFormContext';
import { StyledDivFieldError } from 'legacy/shared/controls/WcpForm/v2/styles/WcpFieldStyles';

// TODO - better integration with FilteredDropdown
const WcpFilteredDropdownField = (props) => {
  if (!props.dataKey) {
    throw new Error(`dataKey prop is required for the field with label "${props.fieldLabel}"`);
  }

  const { formValues, setFormValues, validationSchema, validationErrors, validate } =
    useWcpFormContext();

  props.setup.preselectedValue = props.setup.preselectedValue || formValues[props.dataKey];

  const validationErrorText =
    validationErrors?.find((error) => error.path === props.dataKey)?.message || '';

  const eventHandlers = {
    ...props.eventHandlers,
    handleSelect: (selectedOption) => {
      setFormValues({ [props.dataKey]: selectedOption.value });
    },
  };

  const dataCyId = `${props.dataKey}Dropdown`;
  return (
    <>
      <FilteredDropdown dataCyId={dataCyId} {...{ ...props, eventHandlers }} />
      <StyledDivFieldError snugSpacing>{validationErrorText}</StyledDivFieldError>
    </>
  );
};

export default WcpFilteredDropdownField;
