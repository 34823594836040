import { DateTime } from 'luxon';
import { useContext, useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';

import { addNotification } from 'legacy/core/redux/ui/actions';
import { GeofencesApi } from 'legacy/features/geofences/geofences/components/GeofencesContainer';
import GeofenceExpiresAtMapCard from 'legacy/features/geofences/geofences/components/mapCard/GeofenceExpiresAtMapCard';
import GeofenceRangeMapCard from 'legacy/features/geofences/geofences/components/mapCard/GeofenceRangeMapCard';
import { createGeofence } from 'legacy/features/geofences/geofences/components/services/createGeofence';
import { updateGeofence } from 'legacy/features/geofences/geofences/components/services/updateGeofence';
import {
  activeTimeOptions,
  categoryOptions,
  timeZoneOptions,
} from 'legacy/features/geofences/geofences/constants/dropdownOptions';
import { validateGeofenceFields } from 'legacy/features/geofences/geofences/utilities/validateGeofences';
import device from 'legacy/shared/constants/sizing';
import Button, { TextButton } from 'legacy/shared/styles/custom/Button';
import { Checkbox } from 'legacy/shared/styles/custom/Checkbox';
import CheckboxInner from 'legacy/shared/styles/custom/CheckboxInner';
import FormWrapper, {
  FormActions,
  FormAsterisk,
  FormFoot,
  FormLabelAnimated,
  FormLegend,
} from 'legacy/shared/styles/custom/Form';
import {
  ActiveTimeOptionDiv,
  CheckboxDayLabelSpan,
  FlexWrapperDiv,
  GeofenceCardContainerDiv,
  GeofenceCardOuterWrapperDiv,
  GeofenceInfoDiv,
  GeofenceValidationDiv,
  LastSeenInfoDiv,
  NameTypeField,
  OtaUpdateCheckboxDiv,
  OtaUpdateOptionsDiv,
  UnsavedChangesDiv,
  UnsavedChangesWrapperDiv,
  GeofenceCardCollapsedName,
  CollapseCardButton,
  CollapsedCard,
  UnsavedChanges,
} from 'legacy/shared/styles/custom/Geofences';
import { CloseButton, ModalButtonContainerDiv } from 'legacy/shared/styles/custom/Modal';
import ButtonWithLoader from 'legacy/shared/controls/ButtonWithLoader/ButtonWithLoader';
import AnimatedField from 'legacy/shared/controls/AnimatedField/v1/AnimatedField';
import StyledReactSelect from 'legacy/shared/styles/custom/SelectField';
import IconSvgComponent from 'legacy/shared/controls/WcpIcon/IconSvgComponent';
import { createNotification, LEVELS } from 'legacy/shared/utilities/misc/notification';
import {
  formatISOTimestampToDescriptiveLog,
  getEndTimeFromStartTimeAndDuration,
  getTimeOptionsIn15MinuteIncrements,
} from 'legacy/shared/utilities/misc/time';
import { TextLink } from 'legacy/shared/styles/custom/Link';
let timeOptions = getTimeOptionsIn15MinuteIncrements();

const defaultActiveRange = [
  { label: 'Sunday', enabled: false, start: '12:00 am', duration: null },
  { label: 'Monday', enabled: false, start: '12:00 am', duration: null },
  { label: 'Tuesday', enabled: false, start: '12:00 am', duration: null },
  { label: 'Wednesday', enabled: false, start: '12:00 am', duration: null },
  { label: 'Thursday', enabled: false, start: '12:00 am', duration: null },
  { label: 'Friday', enabled: false, start: '12:00 am', duration: null },
  { label: 'Saturday', enabled: false, start: '12:00 am', duration: null },
];

const GeofenceMapCard = ({
  dispatchNotification,
  currentOrganizationId,
  geofenceMapData,
  handleRequestClose,
  unsavedChanges,
  setUnsavedChanges,
}) => {
  const { reloadGeofencesData } = useContext(GeofencesApi);

  let mobile = !window.matchMedia(device.large).matches;

  let refGeofenceName = useRef();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [initialGeofenceCardData, setInitialGeofenceCardData] = useState(null);
  const [geofenceCardData, setGeofenceCardData] = useState(null);
  const [geofenceValidationMessage, setGeofenceValidationMessage] = useState(null);
  const [cardCollapsed, setCardCollapsed] = useState(false);

  const getLastEnteredText = () => {
    let lastEnteredText = geofenceMapData.latest
      ? ` ${formatISOTimestampToDescriptiveLog(geofenceMapData.latest.timestamp)} by ${
          geofenceMapData.latest.vehicle_label
        }`
      : ' N/A';
    if (geofenceMapData.latest) {
      geofenceMapData.latest?.while_active ? null : (lastEnteredText += ' (while not active)');
    }
    return lastEnteredText;
  };

  const getInitialCardDataForNewGeofence = () => ({
    label: 'New Geofence',
    category: null,
    activeTimeType: null,
    rangeStartTime: null,
    rangeEndTime: null,
    expiresAtDate: DateTime.now().toLocaleString(),
    expiresAtTime: timeOptions[0],
    duration: 0,
    timeZone: null,
    activeRange: defaultActiveRange,
    performConfigUpdate: false,
    performFirmwareUpdate: false,
    dst: true,
  });

  const getInitialCardDataForExistingGeofence = () => {
    let activeRange = geofenceMapData.activeRange || defaultActiveRange;
    let activeDay = activeRange.find((d) => d.start);
    let tzone = timeZoneOptions.find((to) => to.value === geofenceMapData.timeZone)?.value;
    let expirationTime =
      (geofenceMapData.expirationTime &&
        DateTime.fromISO(geofenceMapData.expirationTime).setZone(tzone)) ||
      DateTime.now().toLocaleString();

    let rangeEndTime =
      (activeDay &&
        getEndTimeFromStartTimeAndDuration({
          fromTime: activeDay.start,
          duration: activeDay.duration,
        })) ||
      getEndTimeFromStartTimeAndDuration({ fromTime: '12:00 am', duration: 0 });

    return {
      ...geofenceMapData,
      ...{
        activeRange,
        activeDay,
        rangeStartTime: activeRange.find((d) => d.start)?.start,
        rangeEndTime,
        duration: activeDay.duration,
        expiresAtDate: expirationTime.toLocaleString(DateTime.DATE_SHORT),
        expiresAtTime:
          geofenceMapData.expirationTime &&
          (expirationTime.toLocaleString(DateTime.TIME_SIMPLE) || timeOptions[0]),
      },
    };
  };

  useEffect(() => {
    if (geofenceMapData?.geofenceId !== geofenceCardData?.geofenceId) {
      setCardCollapsed(false);
    }
    let cardData = geofenceMapData.new
      ? getInitialCardDataForNewGeofence()
      : getInitialCardDataForExistingGeofence();
    if (
      !initialGeofenceCardData ||
      initialGeofenceCardData.geofenceId !== geofenceMapData.geofenceId
    ) {
      setInitialGeofenceCardData(_.cloneDeep(cardData));
    }

    if (!unsavedChanges) setGeofenceCardData(_.cloneDeep(cardData));

    if (geofenceMapData.new && refGeofenceName.current) {
      refGeofenceName.current.focus();
    }
  }, [geofenceMapData]);

  // if validation message is already displayed, rerun validation after one of the affected fields changes
  useEffect(() => {
    if (geofenceValidationMessage !== null) {
      validateGeofenceFields({ geofenceCardData, setGeofenceValidationMessage });
    }

    let unsaved = unsavedChanges;
    _.isEqual(initialGeofenceCardData, geofenceCardData) ? (unsaved = false) : (unsaved = true);

    setUnsavedChanges(unsaved);
  }, [geofenceCardData]);

  return (
    geofenceCardData && (
      <GeofenceCardOuterWrapperDiv>
        <ModalButtonContainerDiv>
          <CollapseCardButton onClick={() => setCardCollapsed((c) => !c)}>
            {cardCollapsed ? (
              <IconSvgComponent
                svgFileName={'collapsed-toggle-gray'}
                alt="Expand"
                title="Expand"
                svgStyle={{ width: '12px' }}
              />
            ) : (
              <IconSvgComponent
                svgFileName={'expanded-toggle-gray'}
                alt="Collapse"
                title="Collapse"
                svgStyle={{ width: '12px' }}
              />
            )}
          </CollapseCardButton>
          <CloseButton
            onClick={async () => {
              handleRequestClose({ saveChanges: false });
              if (!unsavedChanges) await reloadGeofencesData();
            }}
            style={{
              position: 'relative',
              width: 'auto',
              marginRight: 7,
              marginTop: 7,
              cursor: 'pointer',
            }}
          >
            <IconSvgComponent
              svgFileName={'x'}
              alt="Close"
              title="Close"
              svgStyle={{ width: '12px' }}
            />
          </CloseButton>
        </ModalButtonContainerDiv>
        {cardCollapsed ? (
          <CollapsedCard onClick={() => setCardCollapsed((c) => !c)}>
            <GeofenceCardCollapsedName>
              {geofenceCardData.label}{' '}
              {unsavedChanges && <UnsavedChanges title="Unsaved changes">*</UnsavedChanges>}
            </GeofenceCardCollapsedName>
          </CollapsedCard>
        ) : (
          <GeofenceCardContainerDiv>
            <FormWrapper>
              <div>
                <FlexWrapperDiv>
                  <NameTypeField>
                    <AnimatedField
                      geofenceCard
                      name="label"
                      placeholder="Geofence Name"
                      innerRef={refGeofenceName}
                      disabled={false}
                      value={geofenceCardData.label}
                      handleChange={({ target: { value: label } }) => {
                        setGeofenceCardData((prev) => ({ ...prev, label }));
                      }}
                      required
                    />
                  </NameTypeField>

                  <NameTypeField>
                    <FormLabelAnimated animated>
                      Type<FormAsterisk>*</FormAsterisk>
                    </FormLabelAnimated>
                    <StyledReactSelect
                      mediumTextMobile
                      geofenceCard
                      name="category"
                      disabled={false}
                      isClearable={false}
                      isSearchable={false}
                      options={categoryOptions}
                      key={`categorySelect${geofenceCardData.category}`}
                      onChange={({ value }) => {
                        setGeofenceCardData((pgf) => ({ ...pgf, category: value }));
                      }}
                      defaultValue={{
                        label: categoryOptions.find((c) => c.value === geofenceCardData.category)
                          ?.label,
                      }}
                      field="true"
                    />
                  </NameTypeField>
                </FlexWrapperDiv>
                <FlexWrapperDiv>
                  <ActiveTimeOptionDiv>
                    <FormLabelAnimated animated>
                      Active Time<FormAsterisk>*</FormAsterisk>
                    </FormLabelAnimated>
                    <StyledReactSelect
                      geofenceCard
                      mediumTextMobile
                      name="activeTimeType"
                      disabled={false}
                      isClearable={false}
                      isSearchable={false}
                      options={activeTimeOptions}
                      key={`activeTimeTypeSelect${geofenceCardData.activeTimeType}`}
                      defaultValue={{
                        label: activeTimeOptions.find(
                          (a) => a.value === geofenceCardData.activeTimeType,
                        )?.label,
                      }}
                      onChange={({ value }) => {
                        setGeofenceCardData((pgf) => ({ ...pgf, activeTimeType: value }));
                      }}
                      field="true"
                    />
                  </ActiveTimeOptionDiv>
                  {/* THE FOLLOWING BLOCK SHOULD ONLY BE VISIBLE WHEN THE EXPIRES AT OPTION IS CHOSEN*/}
                  {geofenceCardData.activeTimeType === activeTimeOptions[1].value && (
                    <GeofenceExpiresAtMapCard
                      setGeofenceCardData={setGeofenceCardData}
                      expiresAtDate={geofenceCardData.expiresAtDate}
                      expiresAtTime={geofenceCardData.expiresAtTime}
                      timeZone={geofenceCardData.timeZone}
                      expirationTime={geofenceCardData.expirationTime}
                      duration={geofenceCardData.duration}
                    />
                  )}
                  {/* THE FOLLOWING BLOCK SHOULD ONLY BE VISIBLE WHEN THE RANGE OPTION IS CHOSEN*/}
                  {geofenceCardData.activeTimeType === activeTimeOptions[2].value && (
                    <GeofenceRangeMapCard
                      setGeofenceCardData={setGeofenceCardData}
                      rangeStartTime={geofenceCardData.rangeStartTime}
                      rangeEndTime={geofenceCardData.rangeEndTime}
                      timeZone={geofenceCardData.timeZone}
                      duration={geofenceCardData.duration}
                      activeRange={geofenceCardData.activeRange}
                    />
                  )}
                </FlexWrapperDiv>

                {/* THE FOLLOWING BLOCK SHOULD ONLY BE VISIBLE WHEN THE OTA UPDATE GEOFENCE TYPE IS CHOSEN*/}
                {geofenceCardData.category === 'OTA Updates' && (
                  <OtaUpdateOptionsDiv>
                    <OtaUpdateCheckboxDiv>
                      <Checkbox singleCheck>
                        <CheckboxInner
                          isChecked={geofenceCardData.performConfigUpdate}
                          onClick={() => {
                            setGeofenceCardData((pgf) => ({
                              ...pgf,
                              performConfigUpdate: !geofenceCardData.performConfigUpdate,
                            }));
                          }}
                        />
                      </Checkbox>
                      <CheckboxDayLabelSpan>
                        Perform configuration update automatically
                      </CheckboxDayLabelSpan>
                    </OtaUpdateCheckboxDiv>
                    <OtaUpdateCheckboxDiv>
                      <Checkbox singleCheck>
                        <CheckboxInner
                          isChecked={geofenceCardData.performFirmwareUpdate}
                          onClick={() => {
                            setGeofenceCardData((pgf) => ({
                              ...pgf,
                              performFirmwareUpdate: !geofenceCardData.performFirmwareUpdate,
                            }));
                          }}
                        />
                      </Checkbox>
                      <CheckboxDayLabelSpan>
                        Perform firmware update automatically
                        <IconSvgComponent
                          svgFileName="info"
                          title="Firmware OTA requires VSG to be on firmware version 4.1 or higher"
                        />
                      </CheckboxDayLabelSpan>
                    </OtaUpdateCheckboxDiv>
                  </OtaUpdateOptionsDiv>
                )}
                {/* THE FOLLOWING BLOCK SHOULD ONLY BE VISIBLE WHEN THE GEOFENCE ALREADY EXISTS*/}
                {!geofenceMapData.new && (
                  <>
                    <GeofenceInfoDiv title={getLastEnteredText()}>
                      <h6>Last entered on:</h6>
                      <LastSeenInfoDiv>{getLastEnteredText()}</LastSeenInfoDiv>
                      <TextButton type="button" largeFont>
                        <TextLink
                          to={`/geofences/logs/${encodeURIComponent(geofenceCardData.geofenceId)}`}
                        >
                          VIEW LOGS
                        </TextLink>
                      </TextButton>
                    </GeofenceInfoDiv>
                    <UnsavedChangesWrapperDiv>
                      {!geofenceValidationMessage && (
                        <UnsavedChangesDiv>
                          {unsavedChanges && 'You have unsaved changes for this geofence.'}
                        </UnsavedChangesDiv>
                      )}
                    </UnsavedChangesWrapperDiv>
                  </>
                )}
                {mobile && (
                  <GeofenceValidationDiv>{geofenceValidationMessage}</GeofenceValidationDiv>
                )}
                <FormFoot geofenceMapCard>
                  <FormLegend>
                    {!mobile && (
                      <GeofenceValidationDiv>{geofenceValidationMessage}</GeofenceValidationDiv>
                    )}
                  </FormLegend>
                  <FormActions>
                    {!geofenceMapData.new && (
                      <Button
                        type="button"
                        onClick={async () => {
                          if (!unsavedChanges)
                            setGeofenceCardData(_.cloneDeep(initialGeofenceCardData));

                          handleRequestClose({ saveChanges: false });
                        }}
                        withRightSpacer
                        mediumAlt
                        default
                      >
                        Cancel
                      </Button>
                    )}

                    <ButtonWithLoader
                      isLoading={isSubmitting}
                      confirmText={!geofenceMapData.new ? 'Save Changes' : 'Create Geofence'}
                      loadingStyleProp={'submittingWithSpinner'}
                      notLoadingStyleProp={'geofences'}
                      clickHandler={
                        !geofenceMapData.new
                          ? async () => {
                              setIsSubmitting(true);

                              let updatedGeofence = await updateGeofence({
                                geofenceCardData,
                                geofenceMapData,
                                setGeofenceValidationMessage,
                                dispatchNotification,
                                currentOrganizationId,
                              });
                              if (updatedGeofence) {
                                dispatchNotification.geofenceUpdateSuccess({
                                  geofenceCardData,
                                  geofenceMapData,
                                  setGeofenceValidationMessage,
                                  dispatchNotification,
                                  currentOrganizationId,
                                });
                                await reloadGeofencesData();
                                handleRequestClose({ saveChanges: true });
                              }
                              setIsSubmitting(false);
                            }
                          : async () => {
                              setIsSubmitting(true);

                              let createdGeofence = await createGeofence({
                                geofenceCardData,
                                geofenceMapData,
                                setGeofenceValidationMessage,
                                currentOrganizationId,
                                dispatchNotification,
                              });

                              if (createdGeofence) {
                                await reloadGeofencesData();
                                handleRequestClose({ saveChanges: true });
                              }
                              setIsSubmitting(false);
                            }
                      }
                      disabled={!unsavedChanges}
                    />
                  </FormActions>
                </FormFoot>
              </div>
            </FormWrapper>
          </GeofenceCardContainerDiv>
        )}
      </GeofenceCardOuterWrapperDiv>
    )
  );
};

export default connect(null, (dispatch) => ({
  dispatchNotification: {
    geofenceCreateSuccess: ({ label }) =>
      dispatch(
        addNotification({
          notification: createNotification(
            LEVELS.SUCCESS,
            'Geofence Created Successfully',
            `Successfully created geofence named "${label}"`,
          ),
        }),
      ),
    geofenceUpdateSuccess: ({ geofenceCardData }) =>
      dispatch(
        addNotification({
          notification: createNotification(
            LEVELS.SUCCESS,
            'Geofence Updated Successfully',
            `Successfully updated geofence named "${geofenceCardData.label}"`,
          ),
        }),
      ),
    geofenceCreateError: ({ err }) =>
      dispatch(
        addNotification({
          notification: createNotification(LEVELS.ERROR, `Error Creating Geofence`, err),
        }),
      ),
    geofenceUpdateError: ({ err }) =>
      dispatch(
        addNotification({
          notification: createNotification(LEVELS.ERROR, `Error Updating Geofence`, err),
        }),
      ),
  },
}))(GeofenceMapCard);
