import { FormikProvider } from 'formik';
import { useFormik } from 'formik';

import useConnectFormikWithProvider from 'legacy/shared/controls/WcpForm/v1/hooks/useConnectFormikWithProvider';
import VisualDebug from 'tools/debugging/VisualDebug';
import { useEffect } from 'react';

// takes provider state and maps it to formik field values
export const WcpFormikProvider = ({
  children,
  providerValues,
  providerToFormikMapper,
  actions,
  validation,
}) => {
  const { submitHandler } = actions;

  const { fieldValues } = useConnectFormikWithProvider({
    providerValues,
    providerToFormikMapper,
  });

  const formikBag = useFormik({
    initialValues: fieldValues,
    validate: validation,
    //validateOnMount: true,
    //enableReinitialize: true,
    validateOnChange: true,
    validateOnBlur: false,
    onSubmit: async (values) => {
      try {
        await submitHandler(values, formikBag);
      } catch (error) {
        throw new Error(error.message);
      }
    },
  });

  useEffect(() => {
    formikBag.setValues({ ...fieldValues });
  }, [fieldValues]);

  return (
    <FormikProvider value={formikBag}>
      <form
        noValidate="noValidate" // novalidate disables the built in HTML5 validation popup that we do not want to display
        onSubmit={(e) => {
          formikBag.setSubmitting(true);
          formikBag.handleSubmit(e);
        }}
      >
        {children}
      </form>
    </FormikProvider>
  );
};
