import { useFlags } from 'launchdarkly-react-client-sdk';
import useUpdateOrganizationMutation from 'legacy/features/organizations/api/mutations/useUpdateOrganizationMutation';
import AlertsActiveCheckbox from 'legacy/features/organizations/components/forms/OrganizationForm/checkboxes/AlertsActiveCheckbox';
import AppliedInformationIntegrationCheckboxField from 'legacy/features/organizations/components/forms/OrganizationForm/checkboxes/AppliedInformationIntegrationCheckboxField';
import GttIntegrationCheckbox from 'legacy/features/organizations/components/forms/OrganizationForm/checkboxes/GttIntegrationCheckbox';
import HaasIntegrationCheckbox from 'legacy/features/organizations/components/forms/OrganizationForm/checkboxes/HaasIntegrationCheckbox';
import ThruGreenIntegrationCheckbox from 'legacy/features/organizations/components/forms/OrganizationForm/checkboxes/ThruGreenIntegrationCheckbox';
import AddressStateDropdown from 'legacy/features/organizations/components/forms/OrganizationForm/dropdowns/AddressStateDropdown';
import CustomerTypeDropdown from 'legacy/features/organizations/components/forms/OrganizationForm/dropdowns/CustomerTypeDropdown';
import TimezoneDropdown from 'legacy/features/organizations/components/forms/OrganizationForm/dropdowns/TimezoneDropdown';
import Address1Field from 'legacy/features/organizations/components/forms/OrganizationForm/fields/Address1Field';
import Address2Field from 'legacy/features/organizations/components/forms/OrganizationForm/fields/Address2Field';
import CityField from 'legacy/features/organizations/components/forms/OrganizationForm/fields/CityField';
import ContactEmailField from 'legacy/features/organizations/components/forms/OrganizationForm/fields/ContactEmailField';
import ContactNameField from 'legacy/features/organizations/components/forms/OrganizationForm/fields/ContactNameField';
import ContactPhoneField from 'legacy/features/organizations/components/forms/OrganizationForm/fields/ContactPhoneField';
import OrganizationNameField from 'legacy/features/organizations/components/forms/OrganizationForm/fields/OrganizationNameField';
import WimAccountField from 'legacy/features/organizations/components/forms/OrganizationForm/fields/WimAccountField';
import { organizationValidationSchema } from 'legacy/features/organizations/components/forms/OrganizationForm/validation/organizationValidationSchema';
import {
  StyledDivFormCol,
  StyledDivFormGridRow,
  StyledDivFormSection,
} from 'legacy/shared/controls/WcpForm/v2/styles/WcpFormStyles';
import WcpForm from 'legacy/shared/controls/WcpForm/v2/WcpForm';

const EditOrganizationForm = ({ organization, handleCancel, handleSubmitCompleted }) => {
  const updateOrganizationMutation = useUpdateOrganizationMutation();
  const { ldOrganizationPreliminaryIntegrations } = useFlags();

  const handleSubmit = async ({ formValues }) => {
    await updateOrganizationMutation.mutateAsync({ organization: formValues });
    handleSubmitCompleted();
  };

  return (
    <WcpForm
      formData={organization}
      handleSubmit={handleSubmit}
      handleCancel={handleCancel}
      validationSchema={organizationValidationSchema}
      submitButtonText="Edit Organization"
      showFormHeader={false}
    >
      <StyledDivFormGridRow>
        <StyledDivFormCol>
          <OrganizationNameField showReadOnly={true} />
        </StyledDivFormCol>
        <StyledDivFormCol>
          <ContactNameField />
        </StyledDivFormCol>
      </StyledDivFormGridRow>
      <StyledDivFormGridRow>
        <StyledDivFormCol>
          <ContactPhoneField />
        </StyledDivFormCol>
        <StyledDivFormCol>
          <ContactEmailField />
        </StyledDivFormCol>
      </StyledDivFormGridRow>
      <StyledDivFormGridRow>
        <StyledDivFormCol>
          <Address1Field />
        </StyledDivFormCol>
        <StyledDivFormCol>
          <Address2Field />
        </StyledDivFormCol>
      </StyledDivFormGridRow>
      <StyledDivFormGridRow>
        <StyledDivFormCol>
          <CityField />
        </StyledDivFormCol>
        <StyledDivFormCol>
          <AddressStateDropdown />
        </StyledDivFormCol>
      </StyledDivFormGridRow>
      <StyledDivFormGridRow>
        <StyledDivFormCol>
          <WimAccountField />
        </StyledDivFormCol>
        <StyledDivFormCol>
          <CustomerTypeDropdown />
        </StyledDivFormCol>
      </StyledDivFormGridRow>
      <StyledDivFormGridRow noBottom>
        <StyledDivFormCol>
          <TimezoneDropdown />
        </StyledDivFormCol>
        <StyledDivFormCol />
      </StyledDivFormGridRow>

      <StyledDivFormGridRow>
        <StyledDivFormCol>
          <GttIntegrationCheckbox />
        </StyledDivFormCol>
        <StyledDivFormCol>
          <HaasIntegrationCheckbox />
        </StyledDivFormCol>
      </StyledDivFormGridRow>

      <StyledDivFormGridRow>
        {ldOrganizationPreliminaryIntegrations && (
          <StyledDivFormCol>
            <StyledDivFormSection gtt>Integrations</StyledDivFormSection>
            <ThruGreenIntegrationCheckbox />
            <AppliedInformationIntegrationCheckboxField />
          </StyledDivFormCol>
        )}

        <StyledDivFormCol>
          <AlertsActiveCheckbox />
        </StyledDivFormCol>
      </StyledDivFormGridRow>
    </WcpForm>
  );
};

export default EditOrganizationForm;
