import styled from 'styled-components';
import checkIcon from 'assets/images/check@2x.png';

export default styled.div`
  position: relative;
  display: inline-block;
  top: calc(50% + 2px);
  width: 14px;
  height: 14px;
  border-radius: 2;
  border: 1px solid rgba(138, 144, 156, 0.6);

  ${(props) =>
    props.isChecked &&
    `
    border: none;
    background-image: url(${checkIcon});
    background-repeat: no-repeat;
    background-size: cover;
  `}

  ${(props) =>
    props.fseTable &&
    `
    margin-right:20px;
  `}

${(props) =>
    props.disabledStyleProp &&
    `
    pointer-events: none;
    cursor: not-allowed;
    `}

${(props) =>
    props.customAlerts &&
    `
    margin-top:-12px;
  `}

  ${(props) =>
    props.disabledStyleProp &&
    props.isChecked &&
    `
      opacity:.5;
      `}
`;
