import { PARTNERSHIP_NAMES } from 'legacy/features/integrations/constants/constants';
import { getActionForPartnershipModal } from 'legacy/features/integrations/utilities/getActionForPartnershipModal';
import useUpdateOrganizationMutation from 'legacy/features/organizations/api/mutations/useUpdateOrganizationMutation';
import GenericConfirmationModalControls from 'legacy/shared/controls/WcpModal/v2/controls/GenericConfirmationModalControls';
import { useSelector } from 'react-redux';

const EnableDisablePartnershipModalControls = ({
  modalState: { partnership },
  modalActions: { closeModal },
}) => {
  const updateOrganizationMutation = useUpdateOrganizationMutation();

  const filteredOrganizationId = useSelector((state) => state.user.filteredOrganizationId);
  const submitEnableDisablePartnership = async () => {
    // activate or deactivate the partnership
    const organizationFieldsToUpdate = {
      organizationId: filteredOrganizationId,
      ...({
        [PARTNERSHIP_NAMES.APPLIED_INFORMATION]: {
          appliedInformationEnabled: !partnership.enabled,
        },
        [PARTNERSHIP_NAMES.HAAS]: { haasEnabled: !partnership.enabled },
        [PARTNERSHIP_NAMES.MIOVISION]: { gttEnabled: !partnership.enabled },
        [PARTNERSHIP_NAMES.THRUGREEN]: { thruGreenEnabled: !partnership.enabled },
      }[partnership.name] || {}),
    };
    await updateOrganizationMutation.mutateAsync({ organization: organizationFieldsToUpdate });

    // close the modal
    closeModal();
  };

  return (
    <GenericConfirmationModalControls
      confirmButtonText={getActionForPartnershipModal(partnership.enabled)}
      submitOnConfirm={true}
      handleConfirm={submitEnableDisablePartnership}
      handleCancel={closeModal}
    />
  );
};

export default EnableDisablePartnershipModalControls;
