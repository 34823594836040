import { useQuery } from '@tanstack/react-query';

import { getOrganization } from 'legacy/core/api/organizations';
import { logWcpError } from 'legacy/core/error/utilities/ErrorLogger';
import { PARTNERSHIP_NAMES } from 'legacy/features/integrations/constants/constants';
import { getStatusMessage } from 'legacy/features/integrations/utilities/getStatusMessage';
import { organizationFetchError } from 'legacy/features/organizations/helpers/notifications';
import { convertBoolToEnabledDisabled } from 'legacy/shared/utilities/helpers/general/convertBoolToEnabledDisabled';

export const useFetchPartnershipIntegrationsQuery = ({ organizationId }) => {
  try {
    return useQuery({
      queryKey: ['fetchPartnershipIntegrationsQuery', organizationId],
      queryFn: () => getOrganization(organizationId),
      select: (data) => {
        const responseData = data.body.message[0];

        const partnershipData = [
          {
            name: PARTNERSHIP_NAMES.MIOVISION,
            enabled: responseData.gtt_enabled,
            status: convertBoolToEnabledDisabled(responseData.gtt_enabled),
            detail: getStatusMessage(responseData.gtt_enabled, responseData.gtt_setup),
          },
          {
            name: PARTNERSHIP_NAMES.HAAS,
            enabled: responseData.haas_enabled,
            status: convertBoolToEnabledDisabled(responseData.haas_enabled),
            detail: getStatusMessage(responseData.haas_enabled, responseData.haas_setup),
          },
          {
            name: PARTNERSHIP_NAMES.APPLIED_INFORMATION,
            enabled: responseData.applied_information_enabled,
            status: convertBoolToEnabledDisabled(responseData.applied_information_enabled),
            detail: `Business ID: ${responseData.applied_information_business_id}`,
          },
          {
            name: PARTNERSHIP_NAMES.THRUGREEN,
            enabled: responseData.thrugreen_enabled,
            status: convertBoolToEnabledDisabled(responseData.thrugreen_enabled),
            detail: 'N/A',
          },
        ];

        return partnershipData;
      },
      onError: (err) => {
        logWcpError(err);
        organizationFetchError({ err });
      },
    });
  } catch (e) {
    console.log(e);
  }
};
